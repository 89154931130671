/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { UserProvider, useUserContext } from "@shared/client";
import { JanusRoutes } from "@shared/common/src/routes/JanusRoutes";

import { useNetworkContext } from "./NetworkContext";
import { ADOBE_IMS_CONFIG, IMS_ENV, getAppPath } from "../config";
import { saveSessionGuid } from "@src/hooks/useAnalytics";
import { useIsVRPage } from "@src/hooks/useIsVRPage";
import { useRedirects } from "@src/hooks/useRedirects";

import type { PropsWithChildren } from "react";

export function useHi5UserContext() {
    return useUserContext();
}

const AUTOMATION_USER = "automationUser";

export function isAutomationUser() {
    return localStorage.getItem(AUTOMATION_USER) === "true";
}

export function HI5UserProvider({ children }: PropsWithChildren) {
    const { connected } = useNetworkContext();
    const { landingRedirect } = useRedirects();
    const { isVRPage } = useIsVRPage();

    return (
        <UserProvider
            isConnected={connected}
            imsConfig={ADOBE_IMS_CONFIG}
            isProd={IMS_ENV === "prod"}
            onLogin={() => saveSessionGuid()}
            onLogout={() => {
                if (isVRPage) return;
                saveSessionGuid();
                landingRedirect();
            }}
            autoLogin={
                !(
                    getAppPath().includes(JanusRoutes.logInCheck.path) ||
                    getAppPath().includes(JanusRoutes.signedOut.path) ||
                    getAppPath().includes(JanusRoutes.assertLogOut.path) ||
                    getAppPath().includes(JanusRoutes.appSettings.path)
                )
            }
            isLoginRequired={() => {
                if (isVRPage) return true;
                if (getAppPath() === JanusRoutes.index.path) return false;
                return true;
            }}>
            {children}
        </UserProvider>
    );
}
