/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, ProgressBar, Button, Text } from "@adobe/react-spectrum";
import CancelIcon from "@spectrum-icons/workflow/Cancel";
import { useTranslation } from "react-i18next";

import { IngestDialogWrapper } from "./IngestDialogWrapper";
import { useIngestContext } from "@src/contexts/IngestContext";

export function IngestDialog() {
    const { t } = useTranslation("web");
    const {isUploading, uploadProgress, processingProgress, cancel} = useIngestContext();

    function content() {
        if (isUploading) {
            return (
                <Flex
                    width="100%"
                    height="100%"
                    key="upload"
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <ProgressBar
                        width="60%"
                        size="L"
                        label={t("accessibility.progress.uploading")}
                        value={uploadProgress}
                        valueLabel={t(
                            "accessibility.progress.uploading.percent",
                            { val: uploadProgress / 100 },
                        )}
                    />
                    <Button
                        marginTop="size-600"
                        style="fill"
                        variant="primary"
                        onPress={cancel}>
                        <CancelIcon />
                        <Text>{t("upload.button.cancel")}</Text>
                    </Button>
                </Flex>
            );
        }
        return (
            <Flex
                width="100%"
                height="100%"
                key="process"
                direction="column"
                justifyContent="center"
                alignItems="center">
                <ProgressBar
                    width="60%"
                    size="L"
                    label={t("accessibility.progress.processing")}
                    value={processingProgress * 100}
                    valueLabel={t("accessibility.progress.uploading.percent", {
                        val: processingProgress,
                    })}
                />
                <Button
                    marginTop="size-600"
                    style="fill"
                    variant="primary"
                    onPress={cancel}>
                    <CancelIcon />
                    <Text>{t("upload.button.cancel")}</Text>
                </Button>
            </Flex>
        );
    }

    return <IngestDialogWrapper>{content()}</IngestDialogWrapper>;
}
