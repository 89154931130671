/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Divider,
    Flex,
    Footer,
    Heading,
    Text,
    View,
    InlineAlert,
    Link,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useMessagingContext } from "@shared/client";
import Alert from "@spectrum-icons/workflow/Alert";
import { useTranslation } from "react-i18next";

import { TransferToVR } from "@src/components/TransferToVR";
import {
    type JoinReviewOptions,
} from "@src/contexts/NetworkContext";
import { useMetaLinkedAccount } from "@src/hooks/useMetaLinkedAccount";
import { useOpenInVR } from "@src/hooks/useOpenInVR";

import {VR_HOME_DESTINATION, VR_REVIEW_DESTINATION} from "../../config";

interface OpenInVRProps {
    assetUrn?: string;
    joinReviewOptions?: JoinReviewOptions;
}

export function OpenInVRDialog({assetUrn, joinReviewOptions }: OpenInVRProps) {
    const { t } = useTranslation("web");
    const { generateVrLink, linkAccount } = useOpenInVR(assetUrn);
    const { metaUserId, metaUserIdAlias } = useMetaLinkedAccount();
    const { showModal } = useMessagingContext();

    async function openVrLink() {
        let destination;
        if (assetUrn) {
            destination = VR_REVIEW_DESTINATION;
        } else {
            destination = VR_HOME_DESTINATION;
        }

        const link: string | undefined = await generateVrLink(metaUserId, destination);
        if (!link) {
            ToastQueue.negative(t("toast.openInVR.createLinkFailed"), {
                timeout: 5000,
            });
            return;
        }
        window.open(link, "_blank");

        if (joinReviewOptions) {
            showModal(<TransferToVR joinReviewOptions={joinReviewOptions} />);
        }
    }

    return (
        <Dialog width={!metaUserId ? "size-5000" : ""}>
            <Heading>{t("openInVr.dialog.title")}</Heading>
            {!!metaUserId && <Divider />}
            <Content>
                {!metaUserId ? (
                    <View marginTop="size-300">
                        <Text>
                            {t("openInVr.dialog.linkAccount.description")}
                        </Text>
                    </View>
                ) : (
                    <Flex
                        direction="column"
                        justifyContent="space-between">
                        <InlineAlert
                            UNSAFE_style={{
                                borderColor: "rgb(249, 137, 23)",
                            }}>
                            <Heading
                                UNSAFE_style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                <div>
                                    <Alert color="notice" />
                                </div>
                                <div style={{ marginLeft: "15px" }}>
                                    <Text>
                                        {t(
                                            "openInVr.dialog.openLink.description.2",
                                        )}
                                    </Text>
                                </div>
                            </Heading>
                        </InlineAlert>
                        <br></br>
                        <Text>
                            {t("openInVr.dialog.openLink.description.3")}
                        </Text>
                        <Text>{t("web:openInVr.dialog.openLink.description.4")}</Text>
                        <Text>{t("web:openInVr.dialog.openLink.description.5")}</Text>
                        <Link href="https://helpx.adobe.com/" target={"_blank"}>{t("web:openInVr.dialog.openLink.description.6") + t("web:openInVr.dialog.openLink.description.7") + t("web:openInVr.dialog.openLink.description.8")}</Link>
                    </Flex>
                )}
            </Content>
            <Footer>
                <Flex
                    direction="row"
                    width="100%"
                    justifyContent={metaUserIdAlias ? "space-between" : "end"}>
                    {metaUserIdAlias && (
                        <Text
                            UNSAFE_style={{ width: "30%", fontWeight: "bold" }}>
                            {t("openInVr.dialog.openLink.linkedAccount")}
                            {metaUserIdAlias}
                        </Text>
                    )}
                <Flex direction="column">
                {!metaUserId ? (
                    <Button variant="accent" onPress={linkAccount}>
                    {t("actions.linkNewAccount")}
                    </Button>
                ) : (
                    <>
                    <ButtonGroup>
                        <Button variant="accent" onPress={() => openVrLink()}>
                        {t("actions.openInVR")}
                        </Button>
                    </ButtonGroup>
                    <Link
                        onPress={linkAccount}
                        UNSAFE_style={{
                        position: "absolute",
                        left: "40",
                        top: "370",
                        }}
                    >
                        {t("web:openInVr.dialog.switchAccount")}
                    </Link>
                    </>
                )}
                </Flex>

                </Flex>
            </Footer>
        </Dialog>
    );
}
