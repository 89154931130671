/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Avatar,
    View,
    DialogTrigger,
    ActionButton,
} from "@adobe/react-spectrum";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { UserProfileDropdown } from "./UserProfileDropdown";
import { getTrimmedString } from "../util/StringUtils";
import { useOnboardingContext } from "@src/contexts/OnboardingContext";

import type { UserProfile } from "@shared/client";

interface UserProfileViewProps {
    userProfile: UserProfile;
    avatarUrl: string;
    trimStrings?: boolean;
    isCompactView?: boolean;
    isForVr?: boolean;
    showTourOption?: boolean;
}

export function UserProfileView({
    userProfile,
    avatarUrl,
    trimStrings = false,
    isCompactView = false,
    isForVr = false,
    showTourOption = false,
}: UserProfileViewProps) {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const { shouldForceUserProfileMenuOpen } = useOnboardingContext();
    const { t } = useTranslation("common");

    function displayName() {
        if (!userProfile) return t("account.displayName.default");
        return trimStrings
            ? getTrimmedString(userProfile.displayName)
            : userProfile.displayName;
    }

    function email() {
        if (!userProfile) return t("account.email.default");
        return trimStrings
            ? getTrimmedString(userProfile.email)
            : userProfile.email;
    }

    function avatar() {
        return (
            <View>
                <Avatar
                    alt={t("accessibility.account.profilePicture")}
                    src={avatarUrl}
                    size={isCompactView ? "avatar-size-500" : isForVr ? 50 : 44}
                />
            </View>
        );
    }

    return (
        <>
            {isCompactView ? (
                userProfile !== undefined && (
                    <DialogTrigger
                        isOpen={
                            shouldForceUserProfileMenuOpen ? true : undefined
                        }
                        type="popover">
                        <ActionButton
                            onPress={() => {
                                setIsDropdownOpen(!isDropdownOpen);
                            }}
                            isQuiet
                            autoFocus={false}>
                            {avatar()}
                        </ActionButton>
                        {(close) => (
                            <UserProfileDropdown
                                name={displayName()}
                                email={email()}
                                avatarUrl={avatarUrl}
                                onClose={close}
                                showTourOption={showTourOption}
                            />
                        )}
                    </DialogTrigger>
                )
            ) : (
                <Flex
                    direction="row"
                    width="100%"
                    justifyContent="start"
                    alignItems="center"
                    gap="size-200">
                    {avatar()}
                    <View>
                        <View>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.75em" : "1.25em",
                                }}>
                                {displayName()}
                            </Text>
                        </View>
                        <View>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.25em" : "1em",
                                }}>
                                {email()}
                            </Text>
                        </View>
                    </View>
                </Flex>
            )}
        </>
    );
}
