/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Avatar,
    Divider,
    Flex,
    Text,
    View,
    Link as LinkSpectrum,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Preferences } from "./Preferences";
import { StorageQuotaView } from "../components/StorageQuotaView";
import { ADOBE_ACCOUNT_URL } from "../config";
import { useOpenInNewTab } from "../hooks/useOpenInNewTab";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useOnboardingContext } from "@src/contexts/OnboardingContext";
import { AboutDialog } from "@src/pages/dialogs/AboutDialog";
import { legalNoticeLinks, thirdPartyLink } from "@src/util/LinkUtils";

interface UserProfileDropdownProps {
    name: string;
    email: string;
    avatarUrl: string;
    onClose: () => void;
    showTourOption?: boolean;
}

export function UserProfileDropdown({
    name,
    email,
    avatarUrl,
    onClose,
    showTourOption = false,
}: UserProfileDropdownProps) {
    const { t } = useTranslation(["common", "web"]);
    const { startOnboarding, anchorElementRef } = useOnboardingContext();
    const { openInNewTab } = useOpenInNewTab();
    const { logIn, logOut, userProfile } = useHi5UserContext();
    const [isLegalNoticeOpen, setIsLegalNoticeOpen] = useState<boolean>(false);
    const { showModal } = useMessagingContext();

    function renderUserProfileDropdown() {
        return (
            <>
                <Flex direction="column">
                    <Flex
                        alignItems="center"
                        justifyContent="start"
                        columnGap="size-200"
                        marginX="size-200"
                        marginY="size-150"
                        marginEnd="size-1000">
                        <Avatar
                            src={avatarUrl}
                            size={64}
                            alt={t("accessibility.account.profilePicture")}
                        />
                        <Flex direction="column" alignItems="start">
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.25em",
                                    fontWeight: "700",
                                }}>
                                {name}
                            </Text>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1em",
                                }}>
                                {email}
                            </Text>
                            <Flex
                                direction="column"
                                alignItems="start"
                                marginTop="1vh">
                                <LinkSpectrum
                                    onPress={() => {
                                        openInNewTab(ADOBE_ACCOUNT_URL);
                                    }}
                                    variant="secondary">
                                    {t("web:account.adobeAccount")}
                                </LinkSpectrum>
                            </Flex>
                        </Flex>
                    </Flex>
                    <StorageQuotaView />
                </Flex>
                <Divider size="S" />
                <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="start"
                    columnGap="size-200"
                    marginTop="size-100"
                    marginBottom="size-100"
                    width="100%">
                    <Text
                        UNSAFE_style={{
                            fontSize: "0.75em",
                            fontWeight: "600",
                            color: "gray-600",
                            marginLeft: "22px",
                            marginBottom: "8px",
                            marginTop: "5px",
                            textTransform: "uppercase",
                        }}>
                        {t("web:header.adobe")}
                    </Text>
                    <Preferences isSignedIn={true} />
                    {showTourOption && (
                        <ActionButton
                            id="Coachmark-orientation"
                            isQuiet
                            isDisabled={!anchorElementRef}
                            onPress={() => {
                                onClose();
                                startOnboarding();
                            }}
                            UNSAFE_style={{
                                display: "block",
                                width: "100%",
                                paddingLeft: "8px",
                                textAlign: "left",
                            }}>
                            <Text>{t("web:onboarding.orientationTour")}</Text>
                        </ActionButton>
                    )}
                    <ActionButton
                        isQuiet
                        onPress={() =>
                            showModal(<AboutDialog />, { isDismissable: true })
                        }
                        UNSAFE_style={{
                            display: "block",
                            width: "100%",
                            paddingLeft: "8px",
                            textAlign: "left",
                        }}>
                        <div>
                            <Text
                                UNSAFE_style={{
                                    padding: "0 size-150",
                                }}>
                                {t("web:account.about")}
                            </Text>
                        </div>
                    </ActionButton>
                </Flex>
                <Divider size="S" />
                <Flex
                    direction="column"
                    alignItems="start"
                    justifyContent="start"
                    columnGap="size-200"
                    marginY="size-100"
                    width="100%">
                    <ActionButton
                        isQuiet
                        onPress={() => setIsLegalNoticeOpen(true)}
                        UNSAFE_style={{
                            display: "flex",
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: "8px",
                            borderRadius: "0px",
                            justifyContent: "space-between",
                        }}>
                        <div>
                            <Text
                                UNSAFE_style={{
                                    padding: "0 size-150",
                                }}>
                                {t("web:account.legalNotices")}
                            </Text>
                        </div>
                        <div>
                            <ChevronRight />
                        </div>
                    </ActionButton>
                    <ActionButton
                        isQuiet
                        onPress={userProfile ? logOut : logIn}
                        UNSAFE_style={{
                            display: "block",
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: "8px",
                            borderRadius: "0px",
                        }}>
                        <Text>
                            {userProfile
                                ? t("common:account.signOut")
                                : t("web:account.signin")}
                        </Text>
                    </ActionButton>
                </Flex>
            </>
        );
    }

    function renderLegalNotice() {
        return (
            <Flex direction="column" marginX="size-300" marginY="size-200">
                <ActionButton
                    isQuiet
                    onPress={() => setIsLegalNoticeOpen(false)}
                    UNSAFE_style={{
                        alignItems: "center",
                        marginBottom: "size-100",
                        justifyContent: "start",
                    }}>
                    <Flex>
                        <ChevronLeft size={"S"} />
                    </Flex>
                    <Flex>
                        <Text
                            UNSAFE_style={{
                                fontSize: "0.9em",
                                padding: "0",
                            }}>
                            {t("web:account.legalNotices.back")}
                        </Text>
                    </Flex>
                </ActionButton>
                <Divider size="S" />
                <Flex
                    direction="column"
                    marginTop="size-100"
                    marginBottom="size-100">
                    <View marginBottom="size-200">
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.25em",
                                fontWeight: "700",
                            }}>
                            {t("web:account.legalNotices")}
                        </Text>
                    </View>
                    {legalNoticeLinks.map(({ label, link }) => {
                        return (
                            <ActionButton
                                isQuiet
                                UNSAFE_style={{
                                    fontSize: "1em",
                                    justifyContent: "start",
                                }}>
                                <LinkSpectrum
                                    href={link}
                                    target="_blank"
                                    isQuiet
                                    variant="secondary"
                                    UNSAFE_style={{
                                        fontSize: "1em",
                                    }}>
                                    {t(label)}
                                </LinkSpectrum>
                            </ActionButton>
                        );
                    })}
                </Flex>
                <Divider size="S" />
                <Flex
                    direction="column"
                    marginTop="size-100"
                    marginBottom="size-100"
                    gap="size-200">
                    <View>
                        {t("web:account.legalNotices.copyright.content.1")}
                    </View>
                    <View>
                        {t("web:account.legalNotices.copyright.content.2")}
                    </View>
                    <View>
                        {t("web:account.legalNotices.copyright.content.3")}
                        <LinkSpectrum
                            href={thirdPartyLink.link}
                            target="_blank"
                            isQuiet>
                            {t(thirdPartyLink.label)}
                        </LinkSpectrum>
                        {t("web:account.legalNotices.copyright.content.4")}
                    </View>
                </Flex>
            </Flex>
        );
    }

    return (
        <View
            maxWidth="size-4600"
            borderColor="gray-50"
            borderWidth="thin"
            borderRadius="small">
            <Flex direction="column" alignItems="start" marginTop="size-100">
                {isLegalNoticeOpen
                    ? renderLegalNotice()
                    : renderUserProfileDropdown()}
            </Flex>
        </View>
    );
}
