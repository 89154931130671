/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Item,
    Menu,
    MenuTrigger,
    Text,
    View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useMessagingContext } from "@shared/client";
import Delete from "@spectrum-icons/workflow/Delete";
import Flag from "@spectrum-icons/workflow/Flag";
import More from "@spectrum-icons/workflow/More";
import Rename from "@spectrum-icons/workflow/Rename";
import Share from "@spectrum-icons/workflow/Share";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ReportAbuseView } from "./ReportAbuseView";
import { ShareSheetView } from "./ShareSheet";
import { OpenInVR } from "@src/components/custom-icons/OpenInVR";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useContentListContext } from "@src/contexts/ContentListContext";
import { useOpenInVR } from "@src/hooks/useOpenInVR";
import { ReviewItem } from "@src/lib/acp/AcpClientModels";
import { AssetRenameDialog } from "@src/pages/dialogs/AssetRenameDialog";
import { OpenInVRDialog } from "@src/pages/dialogs/OpenInVRDialog";

interface AssetActionMenuProps {
    asset: ReviewItem;
    isForVr: boolean;
}

const assetsMenuItems = [
    { name: "actions.share", icon: <Share /> },
    {
        name: "actions.openInVR",
        icon: <OpenInVR />,
    },
    { name: "actions.rename", icon: <Rename /> },
    { name: "actions.delete", icon: <Delete /> },
];

const sharedAssetMenuItems = [
    {
        name: "actions.openInVR",
        icon: <OpenInVR />,
    },
    { name: "web:accessibility.actions.reportAbuse", icon: <Flag /> },
]

const vrMenuItems = [
    { name: "actions.rename", icon: <Rename /> },
    { name: "actions.delete", icon: <Delete /> },
];


export function AssetActionMenu({ asset, isForVr }: AssetActionMenuProps) {
    const { t } = useTranslation(["common", "web"]);

    const { selectedTab } = useContentListContext();

    let menuItems = assetsMenuItems;

    if (isForVr) {
        menuItems = vrMenuItems;
    }

    if (selectedTab === "sharedWithYou") {
        menuItems = sharedAssetMenuItems;
    }

    const { useDiscardReviewMutation, useRestoreReviewMutation } = useAcpContext();
    const [searchParams] = useSearchParams();

    const shouldShowDialog = searchParams.get("accountLinked") || undefined;

    const { mutateAsync: deleteAsset } = useDiscardReviewMutation();
    const { mutateAsync: restoreAsset } = useRestoreReviewMutation();
    const { showModal } = useMessagingContext();
    // Disable this query until the menu is open
    const { isOpenInVrReady } = useOpenInVR(asset.assetId);

    useEffect(() => {
        if (shouldShowDialog) showVRDialog();
    }, [shouldShowDialog]);

    function showRenameDialog() {
        showModal(
            <AssetRenameDialog
                assetId={asset.assetId}
                displayName={asset.displayName}
                isForVr={isForVr}
            />,
        );
    }

    function showSharingDialog() {
        showModal(
            <View width="size-5000" maxHeight="size-6000">
                <ShareSheetView
                    assetUrn={asset.assetId}
                    assetName={asset.name}
                />
            </View>,
            { isDismissable: true },
        );
    }

    function showVRDialog() {
        showModal(<OpenInVRDialog assetUrn={asset.assetId} />, {
            isDismissable: true,
        });
    }

    function showReportAbuseDialog() {
        showModal(<ReportAbuseView assetUrn={asset.assetId} />)
    }

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // workaround for auto dismissing toast after 10 seconds 
    // toasts wont autodismiss if they have action button 
    async function dismissToast(toastElem: Element, delayMs?: number) {
        const buttonChildren = toastElem.childNodes;
        if (buttonChildren.length > 0) {
            if (delayMs) {
                await delay(delayMs);
            }
            buttonChildren.forEach(button => {
                if (button instanceof HTMLButtonElement) {
                    button.click();
                }
            });
        }
    }

    async function handleDelete(urn: string) {
        // check for existing toast close button and dismiss it
        const existingToastButton = document.body.querySelector('[class*="spectrum-Toast-buttons"]');
        if (existingToastButton) {
            dismissToast(existingToastButton);
        }
    
        const isSuccessful = await deleteAsset(urn);
    
        if (isSuccessful) {
            ToastQueue.positive(t("toast.delete.successful"), {
                actionLabel: t("actions.undo"),
                onAction: () => handleRestore(urn),
                shouldCloseOnAction: true,
                timeout: 5000,
            });
        } else {
            ToastQueue.negative(t("toast.delete.fail"), { timeout: 5000 });
        }
    
        await delay(1000);
        // dismiss new toast 
        const newToastButton = document.body.querySelector('[class*="spectrum-Toast-buttons"]');
        if (newToastButton) {
            dismissToast(newToastButton, 8000);
        }
    }

    async function handleRestore(urn: string) {
        const isSuccessful = await restoreAsset(urn);
        if (isSuccessful) {
            ToastQueue.positive(t("toast.undo.successful"), { timeout: 5000 });
        } else {
            ToastQueue.negative(t("toast.undo.fail"), { timeout: 5000 });
        }
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <MenuTrigger align="end">
                <ActionButton isQuiet>
                    <More />
                </ActionButton>
                <Menu
                    items={menuItems}
                    disabledKeys={
                        !isOpenInVrReady ? [t("actions.openInVR")] : []
                    }
                    onAction={(key) => {
                        switch (key) {
                            case "web:accessibility.actions.reportAbuse":
                                showReportAbuseDialog();
                                break;
                            case "actions.share":
                                showSharingDialog();
                                break;
                            case "actions.openInVR":
                                showVRDialog();
                                break;
                            case "actions.rename":
                                showRenameDialog();
                                break;
                            case "actions.delete":
                                handleDelete(asset.assetId);
                                break;
                        }
                    }}>
                    {(item) => (
                        <Item key={item.name} aria-label={item.name}>
                            {item.icon}
                            <Text>{t(item.name)} </Text>
                        </Item>
                    )}
                </Menu>
            </MenuTrigger>
        </div>
    );
}
