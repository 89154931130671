/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";

import { IngestDialog } from "./dialogs/IngestDialog";
import { ShareDialog } from "./dialogs/ShareDialog";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useRedirects } from "@src/hooks/useRedirects";
import { EnterReviewDialog } from "@src/pages/dialogs/EnterReviewDialog";
import { EnvironmentDialog } from "@src/pages/dialogs/EnvironmentDialog";
import { PreviewDialog } from "@src/pages/dialogs/PreviewDialog";

interface Props {
    assetId: string;
    modal?: string;
}

export function HomeDialogs({ assetId, modal }: Props) {
    const { homeModalRedirect, reviewModalRedirect, homeRedirect } =
        useRedirects();

    const { useReviewListItem } = useAcpContext();
    const { data: reviewItem } = useReviewListItem(assetId || "");

    const [assetName, setAssetName] = useState<string>("");

    useEffect(() => {
        if (!modal && !assetName) {
            setAssetName("");
        }
    }, [modal]);

    useEffect(() => {
        if (reviewItem?.displayName) {
            setAssetName(reviewItem?.displayName);
        }
    }, [reviewItem]);

    function getModal() {
        switch (modal) {
            case "ingest":
                return <IngestDialog />;
            case "preview":
                return (
                    <PreviewDialog
                        assetId={assetId}
                        confirmHandler={() =>
                            homeModalRedirect("environment", assetId)
                        }
                    />
                );
            case "environment":
                return (
                    <EnvironmentDialog
                        assetId={assetId}
                        confirmHandler={() =>
                            homeModalRedirect("share", assetId)
                        }
                    />
                );
            case "share":
                return (
                    <ShareDialog
                        assetId={assetId}
                        confirmHandler={() => {
                            homeRedirect(true);
                            reviewModalRedirect(assetId, false);
                        }}
                    />
                );
            case "enter":
                return (
                    <EnterReviewDialog
                        assetId={assetId}
                        assetName={assetName}
                    />
                );
        }
        return;
    }

    return (
        <DialogContainer isDismissable={false} onDismiss={() => {}}>
            {getModal()}
        </DialogContainer>
    );
}
