/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Heading,
    IllustratedMessage,
    Link,
    Text,
    View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { mediaTypeModelUsd } from "@shared/types";
import UploadIcon from "@spectrum-icons/illustrations/Upload";
import { useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { useIngestContext } from "@src/contexts/IngestContext";
import { useRedirects } from "@src/hooks/useRedirects";
import { MAX_FILE_SIZE, MAX_NUMBER_OF_FILES } from "@src/hooks/useUploadAsset";


const types = [...mediaTypeModelUsd];

const dropAcceptTypes = {
    "application/binary": types,
};

export function AssetUploadView() {
    const { t } = useTranslation("web");

    const { uploadAndProcessAsset, validateInputs } = useIngestContext();

    const { homeModalRedirect, homeRedirect } = useRedirects();

    const [dragErrorState, setDragErrorState] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);

    const onDrop = (acceptedFiles: File[], rejections: FileRejection[]) => {
        setIsDragOver(false);
        const isValid = validateInputs(acceptedFiles, rejections);

        if (!isValid) {
            setDragErrorState(true);
            return;
        }

        uploadAndProcessAsset(acceptedFiles[0], (assetId) => {
            if (assetId) {
                homeModalRedirect("preview", assetId);
            }
        }).catch(() => {
            ToastQueue.negative(
                t("web:toast.upload.error.fileConversion", {
                    timeout: 5000,
                }),
            );
            homeRedirect(true);
        });

        const input: HTMLInputElement | null = document.querySelector("#dropper input")

        if (input) {
            input.value = '';
        }

        homeModalRedirect("ingest", "new");
    };

    const onDragEnter = () => {
        setDragErrorState(false);
        setIsDragOver(true);
    };

    const onDragLeave = () => {
        setIsDragOver(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: dropAcceptTypes,
        onDragEnter,
        onDragLeave,
        maxSize: MAX_FILE_SIZE,
        maxFiles: MAX_NUMBER_OF_FILES,
        multiple: false,
    });

    function doStupidHack() {
        // The dropzone uses window.showOpenFilePicker() which won't support file extensions with _ in them
        // This is a hack to trigger the file selection dialog without calling that method so we can support
        // x_t and x_b extensions. (S4RVR-2031)[https://jira.corp.adobe.com/browse/S4RVR-2031]
        // @ts-ignore
        document.querySelector("#dropper input")?.click();
    }
    return (
        <View
            backgroundColor="gray-200"
            paddingX="4vh"
            paddingY="4vh"
            borderRadius="medium">
            <View
                id="dropper"
                borderColor={
                    dragErrorState
                        ? "red-400"
                        : isDragOver
                          ? "blue-600"
                          : "gray-300"
                }
                borderWidth="thick"
                borderRadius="medium">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <View padding="size-300" paddingTop="size-600">
                        <IllustratedMessage maxHeight="size-3000">
                            <UploadIcon />
                            <Heading>{t("upload.title")}</Heading>
                            <View marginTop="size-150">
                                <Text>
                                    <Link
                                        variant="primary"
                                        onPress={doStupidHack}>
                                        {t("upload.fileLink")}
                                    </Link>{" "}
                                    {t("upload.fromComputer")}
                                </Text>
                            </View>
                        </IllustratedMessage>
                    </View>
                </div>
            </View>
        </View>
    );
}
