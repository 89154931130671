/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PropsWithChildren, createContext, useContext, useState } from "react";

import { useLocaleContext } from "./LocaleContext";

type ClickHandler = (assetId: string) => void;

export const ContentTabsKeys = [
    "yourReviews",
    "sharedWithYou",
] as const;
export type ContentTab = typeof ContentTabsKeys[number];

export type SortType = "recent" | "name";

function useContentListContextValue(assetClickHandler: ClickHandler) {
    const { locale } = useLocaleContext();
    const [selectedTab, setSelectedTab] = useState<ContentTab>(ContentTabsKeys[0]);
    const [loadedLists, setLoadedLists] = useState<Record<ContentTab, boolean>>({
        yourReviews: false,
        sharedWithYou: false,
    })
    const isCurrentTabLoaded =
        (selectedTab === ContentTabsKeys[0] && loadedLists.yourReviews) ||
        (selectedTab === ContentTabsKeys[1] && loadedLists.sharedWithYou);


    const [sortType, setSortType] = useState<SortType>("recent");
    const [filter, setFilter] = useState("");

    return {
        locale,
        assetClickHandler,
        selectedTab,
        changeTab: (key: string | number) => {
            switch(key) {
                case ContentTabsKeys[0]:
                    setSelectedTab(ContentTabsKeys[0]);
                    break;
                case ContentTabsKeys[1]:
                    setSelectedTab(ContentTabsKeys[1]);
                    break;
            }
        },
        isCurrentTabLoaded,
        updateLoadedLists: (type: ContentTab) => {
            setLoadedLists((currentLoadedLists) => {
                return {
                    ...currentLoadedLists,
                    [type]: true,
                }
            })
        },
        sortType,
        setSortType,
        filter,
        setFilter,
    }
}

export type ContentListContextValue = ReturnType<typeof useContentListContextValue>;

const ContentListContext = createContext<ContentListContextValue>({} as ContentListContextValue);

export function useContentListContext() {
    const contextValue = useContext(ContentListContext);
    if (!contextValue.assetClickHandler) {
        throw new Error("useContentListContext has to be called within a ContentListContextProvider");
    }
    return contextValue;
}

export function ContentListContextProvider({assetClickHandler, children}:PropsWithChildren<{assetClickHandler: ClickHandler}>) {
    const contextValue = useContentListContextValue(assetClickHandler);

    return (
        <ContentListContext.Provider value={contextValue}>
            {children}
        </ContentListContext.Provider>
    )
}
