/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, Image, View } from "@adobe/react-spectrum";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import appIcon from "../../assets/images/rw_appicon_noshadow_128.png";
import {
    AppSettingsTabsView,
    AppSettingsTabType,
} from "../../components/AppSettingsTabsView";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { getCurrentTime, getTrimmedString } from "../../util/StringUtils";
import { useAcpContext } from "@src/contexts/AcpContext";

import type { Key } from "@react-types/shared";

const CHAR_LIMIT = 23;

export function AppSettingsDialog() {
    const [selectedSettingsTab, setSelectedSettingsTab] = useState<Key>(
        AppSettingsTabType.preferences,
    );

    const { t } = useTranslation("common");
    const { locale } = useLocaleContext();
    const { useReviewListItem } = useAcpContext();

    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("urn") || "";

    const { data: reviewItem } = useReviewListItem(assetId);

    return (
        <View
            height="100%"
            UNSAFE_style={{
                overflow: "hidden",
            }}
            paddingX="30px">
            <View height="50px" paddingBottom="35px" paddingTop="30px">
                <Flex direction="row" alignItems="center" gap="20px">
                    <Image
                        width="30px"
                        height="30px"
                        minWidth="64px"
                        minHeight="64px"
                        alt="reviewer-app-icon"
                        marginStart="20px"
                        marginBottom="45px"
                        marginTop="12px"
                        src={appIcon}
                        UNSAFE_style={{
                            borderRadius: "3px",
                            overflow: "hidden",
                        }}
                    />
                    <Flex direction="column" alignItems="start">
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.75em",
                            }}>
                            {getCurrentTime(locale)}
                        </Text>
                        <Text
                            marginBottom="30px"
                            UNSAFE_style={{
                                fontSize: "1.8em",
                                fontWeight: "bold",
                            }}>
                            {reviewItem?.displayName
                                ? getTrimmedString(
                                      reviewItem.displayName,
                                      CHAR_LIMIT,
                                  )
                                : t("account.home")}
                        </Text>
                    </Flex>
                </Flex>
            </View>
            <View backgroundColor="gray-100" paddingY="10px" paddingX="30px">
                <AppSettingsTabsView
                    selectedSettingsTab={selectedSettingsTab}
                    setSelectedSettingsTab={setSelectedSettingsTab}
                />
            </View>
        </View>
    );
}
