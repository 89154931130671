/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Animation } from "@babylonjs/core/Animations/animation";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Scene } from "@babylonjs/core/scene";
import { Sprite } from "@babylonjs/core/Sprites/sprite";
import { SpriteManager } from "@babylonjs/core/Sprites/spriteManager";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { RadialGradient } from "@babylonjs/gui/2D/controls/gradient/RadialGradient";
import { GradientMaterial } from "@babylonjs/materials/gradient/gradientMaterial";

import ResetSpriteSheet from "@src/assets/images/reset-sprite-sheet.png";
import { Pedestal, pedHeightMap } from "@src/lib/babylon/EnvironmentBuilder";

export function renderResetButton(scene: Scene, pedestal: Pedestal) {
    const resetSpriteManager = new SpriteManager(
        "resetSpriteManager",
        ResetSpriteSheet,
        1,
        540,
        scene,
    );
    resetSpriteManager.isPickable = true;

    const reset = new Sprite("resetButton", resetSpriteManager);

    if (pedestal === "none") {
        reset.position = Vector3.Zero();
    } else {
        reset.position.y = pedHeightMap[pedestal] - 0.2;
    }

    reset.width = 0.8;
    reset.height = 0.8;
    resetSpriteManager.renderingGroupId = 3;
    reset.cellIndex = 32;
    reset.isVisible = false;
    reset.isPickable = true;

    return reset;
}

export function renderAutoResetCylinder(radius: number, pedestal: Pedestal) {
    const cylinder = MeshBuilder.CreateCylinder("autoResetCylinder", {
        diameter: radius * 2,
        height: 0.1,
        cap: Mesh.NO_CAP,
        tessellation: 48,
    });
    const pedestalHeight = pedestal === "none" ? 0 : pedHeightMap[pedestal];
    cylinder.position = new Vector3(0, pedestalHeight + 0.05, 0);

    const sideGradient = new GradientMaterial("sideGradient");
    sideGradient.bottomColor = Color3.FromHexString("#00B370");
    sideGradient.bottomColorAlpha = 0.8;
    sideGradient.topColor = Color3.White();
    sideGradient.topColorAlpha = 0;
    sideGradient.offset = 0.8;
    sideGradient.scale = 5;

    cylinder.material = sideGradient;
    cylinder.material.backFaceCulling = false;

    // Hemispheric light is required for the gradient material to render properly
    var light = new HemisphericLight("light1", new Vector3(0, 1, 0));
    light.groundColor = new Color3(1, 1, 1);
    light.intensity = 1;

    light.includedOnlyMeshes = [cylinder];

    cylinder.isPickable = false;

    return cylinder;
}

export function renderAutoResetBase(radius: number, pedestal: Pedestal) {
    const pedestalHeight = pedestal === "none" ? 0 : pedHeightMap[pedestal];
    const discMesh = MeshBuilder.CreateDisc("autoResetDisc", {
        radius,
    });
    // slight offset to avoid z fighting
    discMesh.position = new Vector3(0, pedestalHeight + 0.001, 0);
    discMesh.rotation = new Vector3(Math.PI / 2, 0, 0);

    const radialGradient = new RadialGradient(512, 512, 300, 512, 512, 500);
    radialGradient.addColorStop(0, "#FFFFFF");
    radialGradient.addColorStop(1, "#00B370");

    const disc = new Ellipse("autoResetDisc");
    disc.backgroundGradient = radialGradient;
    disc.alpha = 0.2;

    const advancedTexture = AdvancedDynamicTexture.CreateForMesh(discMesh);
    advancedTexture.addControl(disc);

    discMesh.isPickable = false;

    return discMesh;
}

export function renderAutoResetOutline(radius: number, pedestal: Pedestal) {
    const pedestalHeight = pedestal === "none" ? 0 : pedHeightMap[pedestal];

    const outlineMesh = MeshBuilder.CreateDisc("autoResetDiscOutline", {
        radius: radius,
    });
    outlineMesh.position = new Vector3(0, pedestalHeight + 0.001, 0);
    outlineMesh.rotation = new Vector3(Math.PI / 2, 0, 0);
    const outline = new Ellipse("autoResetOutline");
    outline.thickness = 25;
    outline.color = "#00B370";
    const advancedTextureOutline =
        AdvancedDynamicTexture.CreateForMesh(outlineMesh);
    advancedTextureOutline.addControl(outline);

    outlineMesh.isPickable = false;

    return outlineMesh;
}

export function createAutoResetOutlineAnimation() {
    const outlineAnimation = new Animation(
        "outlineAnimation",
        "scaling",
        30,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CYCLE,
    );
    const keyFrames = [];
    keyFrames.push({
        frame: 0,
        value: new Vector3(1, 1, 1),
    });
    keyFrames.push({
        frame: 30,
        value: new Vector3(0.75, 0.75, 0.75),
    });
    keyFrames.push({
        frame: 60,
        value: new Vector3(0.75, 0.75, 0.75),
    });
    outlineAnimation.setKeys(keyFrames);

    const opacityAnimation = new Animation(
        "opacityAnimation",
        "visibility",
        30,
        Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CYCLE,
    );
    const keyFramesOpacity = [];
    keyFramesOpacity.push({ frame: 0, value: 1 });
    keyFramesOpacity.push({ frame: 30, value: 1 });
    keyFramesOpacity.push({ frame: 40, value: 0 });
    keyFramesOpacity.push({ frame: 60, value: 0 });
    opacityAnimation.setKeys(keyFramesOpacity);

    return [outlineAnimation, opacityAnimation];
}

