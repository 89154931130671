/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import { HI5_API_KEY, IMS_ENV } from "@src/config";
import {
    useNetworkContext,
    type JoinReviewOptions,
} from "@src/contexts/NetworkContext";
import { EnvironmentBuilder } from "@src/lib/babylon/EnvironmentBuilder";

export function useNetworking(
    joinReviewOptions: JoinReviewOptions,
    accessToken: string | undefined,
    isJoinReviewReady: boolean = true,
    envBuilder: EnvironmentBuilder | undefined,
) {
    const { networkManager, voiceManager, connected } = useNetworkContext();

    const [realtimeStatus, setRealtimeStatus] = useState<string | undefined>();

    const [authParameters, setAuthParameters] = useState("");
    const authType =
        Photon.LoadBalancing.Constants.CustomAuthenticationType.Custom;

    useEffect(() => {
        return () => {
            networkManager?.dispose();
        };
    }, []);

    useEffect(() => {
        if (accessToken) {
            setAuthParameters(
                `user_token=${accessToken}&api_key=${HI5_API_KEY}`,
            );
        }
    }, [accessToken]);

    // auths + connects to servers and voice room
    useEffect(() => {
        if (
            voiceManager &&
            !voiceManager.isJoinedRoom() &&
            realtimeStatus === "Joined" &&
            !joinReviewOptions.isSolo
        ) {
            Photon.setOnLoad(() => {
                try {
                    // only auth in prod env
                    if (IMS_ENV === "prod") {
                        voiceManager.setCustomAuthentication(
                            authParameters,
                            authType,
                        );
                    }

                    voiceManager.initializeVoiceRoom(
                        joinReviewOptions.assetId + "_voice",
                        joinReviewOptions.displayName,
                    );
                } catch (error) {
                    console.error("Error initializing voice:", error);
                }
            });
        }
    }, [voiceManager, realtimeStatus]);

    // connect to realtime room
    useEffect(() => {
        if (
            !networkManager ||
            !joinReviewOptions.viewer ||
            !isJoinReviewReady ||
            !authParameters ||
            !joinReviewOptions.pinningSession ||
            !envBuilder ||
            !connected
        )
            return;

        const initRealtimeConnection = async () => {
            try {
                if (IMS_ENV === "prod") {
                    networkManager.setCustomAuthentication(
                        authParameters,
                        authType,
                    );
                }
                const {
                    isSolo,
                    pinningSession,
                    assetId,
                    viewer,
                    displayName,
                    avatarUrl,
                    envBuilder,
                } = joinReviewOptions;
                if (!pinningSession || !viewer || !envBuilder) return;
                await networkManager.joinReviewRoom(
                    isSolo,
                    pinningSession,
                    assetId,
                    viewer,
                    envBuilder,
                    displayName,
                    avatarUrl,
                );
            } catch (error) {
                console.error("Error initializing realtime:", error);
            }
        };

        if (envBuilder.envLoaded) {
            initRealtimeConnection();
        } else {
            envBuilder.onEnvironmentLoadedObservable.addOnce(() => {
                initRealtimeConnection();
            });
        }

        return () => {
            if (voiceManager) {
                voiceManager.disconnect();
            }
            if (networkManager) {
                networkManager.disconnect();
            }
        };
    }, [
        joinReviewOptions.viewer,
        isJoinReviewReady,
        authParameters,
        joinReviewOptions.pinningSession,
        envBuilder,
        connected,
    ]);

    useEffect(() => {
        const handleStateChange = () => {
            setRealtimeStatus(networkManager?.updateStatus());
        };
        if (networkManager) {
            networkManager.onStateChange = handleStateChange;
        }
    }, [networkManager]);

    // detect AFK status based on window visibility
    useEffect(() => {
        if (!networkManager) return;
        const handleVisibilityChange = () => {
            networkManager.setIsIdleFlag(document.hidden);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [networkManager]);

    // detect AFK status based on mouse movement (idle after 2 mins of inactivity)
    useEffect(() => {
        if (!networkManager) return;
        let timeout: Timer;

        const handleIdleStatusChange = () => {
            networkManager.setIsIdleFlag(false);

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                networkManager.setIsIdleFlag(true);
            }, 120000);
        };

        document.addEventListener("mousemove", handleIdleStatusChange);

        return () => {
            document.removeEventListener("mousemove", handleIdleStatusChange);
        };
    }, [networkManager]);

    return;
}
