/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { useEffect } from "react";
import { ProgressBar, Flex} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

import { useMetaLinkedAccount } from "@src/hooks/useMetaLinkedAccount";
import { useOpenInVR } from "@src/hooks/useOpenInVR";
import { ToastQueue } from "@react-spectrum/toast";

import {VR_HOME_DESTINATION,} from "../config";


export function MetaVRAppRedirect(){
    const { userProfile, authState, accessToken } = useHi5UserContext();
    const { metaUserId,  } = useMetaLinkedAccount();
    const { generateVrLink } = useOpenInVR("");
    const { t } = useTranslation(["common", "web"]);

    useEffect(() => {
            if (accessToken && metaUserId &&
                (authState === "authorized" || authState === "authenticated")) {
                    OpenMetaDeepLinkPage();
            }
    
        }, [userProfile, accessToken, metaUserId]);

        async function OpenMetaDeepLinkPage()
        {
            const link = await generateVrLink(metaUserId, VR_HOME_DESTINATION);
            console.log("Meta Link generated:", link);
            if (!link) {
                ToastQueue.negative(t("toast.openInVR.createLinkFailed"), {
                    timeout: 5000,
                });
                return;
            }
            window.open(link, "_self");
        }

        return (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                 <ProgressBar
                    label={t("common:app.redirect")}
                    isIndeterminate
                />
            </Flex>
           
        );
}
