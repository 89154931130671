/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Animation } from "@babylonjs/core/Animations/animation";
import { AnimationEvent } from "@babylonjs/core/Animations/animationEvent";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Scene } from "@babylonjs/core/scene";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { LinearGradient } from "@babylonjs/gui/2D/controls/gradient/LinearGradient";
import { Image as BabylonImage } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";

import {
    scaleMesh,
    screenToWorld,
    worldToScreen,
    getScreenSpaceSize,
    calculateScaleForPosition,
} from "./BabylonGUIUtils";
import Arrows from "../assets/images/arrows.png";
import MicMuteDark from "../assets/images/mic-mute-dark.png";
import MicMuteLight from "../assets/images/mic-mute-light.png";

export const NAME_TAG_RATIO = 2.5;
export const NAME_TAG_HEIGHT = 1 / (NAME_TAG_RATIO * 3.5);

export const MIN_SIZE_DISTANCE = 16;
export const MAX_SIZE_DISTANCE = 0.5;
const MIN_AVATAR_SIZE = 120;
const MAX_AVATAR_SIZE = 200;

export const ARROW_WIDTH = 107;
export const ARROW_HEIGHT = 128;
// mapping of arrow color to the source image left and top
// coordinates to render the correct colored arrow
export const ARROW_SOURCE_MAPPING: Record<string, [number, number]> = {
    "#008CB8": [ARROW_WIDTH, ARROW_HEIGHT], // Dark Blue
    "#E34850": [0, 0], // Red
    "#EDCC00": [2 * ARROW_WIDTH, 0], // Yellow
    "#4BCCA2": [3 * ARROW_WIDTH, 0], // Turquoise
    "#F69500": [ARROW_WIDTH, 0], // Orange
    "#B247C2": [3 * ARROW_WIDTH, ARROW_HEIGHT], // Magenta
    "#00C7FF": [0, ARROW_HEIGHT], // Light Blue
    "#7E4BF3": [2 * ARROW_WIDTH, ARROW_HEIGHT], // Purple
    "#B2B2B2": [0, 2 * ARROW_HEIGHT], // AFK
};
const ARROW_DIRECTIONS = {
    NONE: 0,
    TOP: 1,
    BOTTOM: 2,
    LEFT: 3,
    RIGHT: 4,
};

export const AFK_COLOR = "#B2B2B2";
export const DEFAULT_COLOR = "#00C7FF";
export const LIGHT_FOREGROUND = "#FFFFFF";
export const DARK_FOREGROUND = "#3F3F3F";
export const FOREGROUND_COLOR_MAP: Record<string, string> = {
    "#008CB8": LIGHT_FOREGROUND,
    "#E34850": LIGHT_FOREGROUND,
    "#EDCC00": DARK_FOREGROUND,
    "#4BCCA2": DARK_FOREGROUND,
    "#F69500": DARK_FOREGROUND,
    "#B247C2": LIGHT_FOREGROUND,
    "#00C7FF": DARK_FOREGROUND,
    "#7E4BF3": LIGHT_FOREGROUND,
    "#B2B2B2": DARK_FOREGROUND,
    "#009112": LIGHT_FOREGROUND,
    "#A0004D": LIGHT_FOREGROUND,
};

const TEXT_PADDING_DEFAULT_WEB = {
    top: 8,
    right: 30,
    bottom: 8,
    left: 75,
};
const TEXT_PADDING_DEFAULT_VR = {
    top: 8,
    right: 60,
    bottom: 8,
    left: 75,
};

export function renderNameplate(
    actorNr: number,
    displayName: string,
    avatarColor: string,
    color: string,
    isVR: boolean,
) {
    const plane = MeshBuilder.CreatePlane(displayName, {
        width: 1,
        height: 1 / NAME_TAG_RATIO,
    });
    plane.isPickable = false;
    plane.billboardMode = 7;
    const advancedTexture = AdvancedDynamicTexture.CreateForMesh(
        plane,
        1024,
        1024 / NAME_TAG_RATIO,
    );

    // GUI components
    const textContainer = new Rectangle("nameTagContainer");
    textContainer.thickness = 0;
    textContainer.background = color;
    textContainer.cornerRadius = 20;
    textContainer.scaleX = 2.8;
    textContainer.scaleY = 2.8;

    // name tag
    // truncate name if it exceeds 18 characters
    if (displayName.length >= 18) {
        displayName = displayName.substring(0, 18) + "...";
    }
    const text = new TextBlock("nameTag: " + actorNr, displayName);
    text.fontSizeInPixels = 20;

    textContainer.adaptHeightToChildren = true;
    textContainer.adaptWidthToChildren = true;
    text.resizeToFit = true;
    text.color = FOREGROUND_COLOR_MAP[color] ?? DARK_FOREGROUND;
    text.fontFamily = "adobe-clean";

    // VR tag
    if (isVR) {
        const vrTagContainer = new Rectangle("vrTagContainer");
        vrTagContainer.thickness = 0;
        vrTagContainer.background = "#FFFFFF";
        vrTagContainer.cornerRadius = 20;
        vrTagContainer.alpha = 0.3;
        vrTagContainer.widthInPixels = 38;
        vrTagContainer.heightInPixels = 26;
        vrTagContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        vrTagContainer.left = -10;

        const vrText = new TextBlock("vrTag", "VR");
        vrText.color = FOREGROUND_COLOR_MAP[color] ?? DARK_FOREGROUND;
        vrText.fontFamily = "adobe-clean";
        vrText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        vrText.left = -22;
        vrText.fontSizeInPixels = 12;

        textContainer.addControl(vrTagContainer);
        textContainer.addControl(vrText);
    }

    setTextPaddingToDefault(text, isVR);

    const imgContainer = new Rectangle("imgContainer");
    imgContainer.thickness = 2;
    imgContainer.color = FOREGROUND_COLOR_MAP[color] ?? DARK_FOREGROUND;
    imgContainer.cornerRadius = 20;
    imgContainer.left = 6;
    imgContainer.heightInPixels = 30;
    imgContainer.widthInPixels = 30;
    imgContainer.horizontalAlignment = 0;

    const image = new BabylonImage("profile", avatarColor);
    image.stretch = BabylonImage.STRETCH_NONE;
    image.autoScale = true;
    image.scaleX = 0.1;
    image.scaleY = 0.1;

    // container for audio states
    const audioStateContainer = new Rectangle("audioContainer");
    audioStateContainer.thickness = 0;
    audioStateContainer.left = 41;
    audioStateContainer.top = 1;
    audioStateContainer.heightInPixels = 30;
    audioStateContainer.widthInPixels = 30;
    audioStateContainer.horizontalAlignment = 0;

    let mutedImg;
    if (FOREGROUND_COLOR_MAP[color] === LIGHT_FOREGROUND) {
        mutedImg = new BabylonImage("muted", MicMuteLight);
    } else {
        mutedImg = new BabylonImage("muted", MicMuteDark);
    }
    mutedImg.scaleX = 0.6;
    mutedImg.scaleY = 0.6;
    mutedImg.stretch = BabylonImage.STRETCH_UNIFORM;
    audioStateContainer.addControl(mutedImg);

    // container for direction arrow -- not used currently
    // TODO: update ARROW_SOURCE_MAPPING with the last two colors if this is used again
    /*
    const directionContainer = new Rectangle("directionContainer");
    directionContainer.thickness = 0;

    const arrowImg = new BabylonImage("arrow", Arrows);
    arrowImg.widthInPixels = 107;
    arrowImg.heightInPixels = 128;
    arrowImg.sourceWidth = 107;
    arrowImg.sourceHeight = 128;
    arrowImg.sourceLeft = ARROW_SOURCE_MAPPING[color][0];
    arrowImg.sourceTop = ARROW_SOURCE_MAPPING[color][1];
    arrowImg.isVisible = false;
    arrowImg.scaleX = 0.8;
    arrowImg.scaleY = 0.8;

    directionContainer.addControl(arrowImg);
    */

    // gradient outline for talking state
    const outlineContainer = new Rectangle("outlineContainer");
    outlineContainer.thickness = 5;
    outlineContainer.cornerRadius = 50;
    outlineContainer.heightInPixels = 54;
    outlineContainer.scaleX = 3;
    outlineContainer.scaleY = 3;

    const gradient = new LinearGradient(200, 200, 480, 480);
    gradient.addColorStop(0, "#CCE9FF");
    gradient.addColorStop(0.33, "#AEDBFE");
    gradient.addColorStop(0.66, "#0367E0");
    gradient.addColorStop(1, "#00418A");

    outlineContainer.gradient = gradient;
    outlineContainer.isVisible = false;

    textContainer.addControl(text);
    imgContainer.addControl(image);
    textContainer.addControl(imgContainer);
    textContainer.addControl(audioStateContainer);
    // advancedTexture.addControl(directionContainer);
    advancedTexture.addControl(textContainer);
    advancedTexture.addControl(outlineContainer);

    // hide until we receive the initial position
    plane.isVisible = false;

    if (plane.material) {
        plane.material.disableDepthWrite = true;
    }

    return plane;
}

/**
 * @summary converts an image URL to base64 format and also gets a grayscale version of the image
 * @param avatarUrl
 * @returns [base64 color image, base64 grayscale image]
 */
export async function getBase64Images(avatarUrl: string) {
    const img = new Image();
    img.src = avatarUrl;
    img.crossOrigin = "anonymous";
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
        return new Promise((resolve, reject) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                // render color image
                ctx.drawImage(img, 0, 0, img.width, img.height);
                const base64Color = canvas.toDataURL();

                // render grayscale image
                ctx.filter = "grayscale(1)";
                ctx.drawImage(img, 0, 0, img.width, img.height);
                const base64Grayscale = canvas.toDataURL();
                resolve([base64Color, base64Grayscale]);
            };
            img.onerror = (error) => {
                reject(error);
            };
        });
    }
    return "";
}

export function setTextPaddingToDefault(text: Control, isVR: boolean) {
    if (isVR) {
        text.setPadding(
            TEXT_PADDING_DEFAULT_VR.top,
            TEXT_PADDING_DEFAULT_VR.right,
            TEXT_PADDING_DEFAULT_VR.bottom,
            TEXT_PADDING_DEFAULT_VR.left,
        );
    } else {
        text.setPadding(
            TEXT_PADDING_DEFAULT_WEB.top,
            TEXT_PADDING_DEFAULT_WEB.right,
            TEXT_PADDING_DEFAULT_WEB.bottom,
            TEXT_PADDING_DEFAULT_WEB.left,
        );
    }
}

export function lerpAvatarPosition(
    avatar: Mesh,
    targetPosition: Vector3,
    scene: Scene,
    isNameplateVisible: boolean,
    onAnimationFrame?: () => void,
    onAnimationEnd?: () => void,
) {
    scene.stopAnimation(avatar);

    // TODO: temporarily disabling offscreen visual
    // const { arrowDirection, clampedWorldPos } =
    //     this.calculateOffscreenVisual(remoteAvatar, targetPosition, false);

    // lerp to new position when velocity decrearses
    const userPosAnimation = new Animation(
        "userAnimation",
        "position",
        15,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CONSTANT,
    );

    const keyFramesPos = [];
    keyFramesPos.push({ frame: 0, value: avatar.position.clone() });
    keyFramesPos.push({ frame: 15, value: targetPosition });
    userPosAnimation.setKeys(keyFramesPos);
    if (onAnimationFrame) {
        for (let i = 0; i <= 15; i++) {
            userPosAnimation.addEvent(
                new AnimationEvent(i, onAnimationFrame, true),
            );
        }
    }

    // hide arrows while animating
    // this.updateArrowDirection(ARROW_DIRECTIONS.NONE, avatar);

    scene.beginDirectAnimation(
        avatar,
        [userPosAnimation],
        0,
        15,
        false,
        undefined,
        () => {
            if (!avatar.isVisible && isNameplateVisible) {
                avatar.isVisible = true;
            }
            if (onAnimationEnd) {
                onAnimationEnd();
            }
        },
    );
}

export function calculateScaleForAvatarFinalPosition(
    position: Vector3,
    viewportPixelSize: number,
    scene: Scene,
) {
    return calculateScaleForPosition(
        position,
        viewportPixelSize,
        1 / NAME_TAG_RATIO,
        MIN_AVATAR_SIZE,
        MAX_AVATAR_SIZE,
        scene,
    );
}

export function scaleAvatar(
    scene: Scene,
    avatar: Mesh,
    viewportPixelSize: number,
    applyScaling: boolean = true,
) {
    return scaleMesh(
        avatar,
        viewportPixelSize,
        1 / NAME_TAG_RATIO,
        MIN_AVATAR_SIZE,
        MAX_AVATAR_SIZE,
        scene,
        applyScaling,
    );
}

/**
 *
 * @param avatar the avatar mesh
 * @param position (optional) position used to calculate the offscreen visual
 * @param applyUpdate whether or not the function should apply the changes or just return the calculated position
 * @returns direction of arrow & updated position
 */
export function calculateOffscreenVisual(
    scene: Scene,
    viewportWidth: number,
    viewportHeight: number,
    avatar: Mesh,
    position?: Vector3,
    applyUpdate: boolean = true,
) {
    // 1. convert avatar position from world space to screen space
    let screenPos = worldToScreen(avatar.position.clone(), scene);
    if (position) {
        screenPos = worldToScreen(position, scene);
    }

    // 2. clamp screen space position to viewport bounds (0 to 1) so avatar stays on edge of screen
    // note that this the position of the center of the name tag
    const clamepdScreenPos = new Vector3(
        Math.max(0, Math.min(screenPos.x, viewportWidth)),
        Math.max(0, Math.min(screenPos.y, viewportHeight)),
        screenPos.z >= 1 ? 0.998 : screenPos.z, // 0.998 to render in front of user
    );

    // 3. adjust for name tag width / height so that the name tag is fully visible at the edge of the screen
    // and modify arrow UI based on offscreen position
    const boundingInfo = avatar.getBoundingInfo().boundingBox;
    // calculate screen space height and width of name tag based on bounding box world space coordinates
    const { width, height } = getScreenSpaceSize(boundingInfo, scene);
    // if name tag position has been clamped to edge of viewport, then adjust position with width/height offset so name tag is fully visible
    let arrowDirection = ARROW_DIRECTIONS.NONE;
    if (clamepdScreenPos.x <= 0) {
        arrowDirection = ARROW_DIRECTIONS.LEFT;
        clamepdScreenPos.x += width / 2;
    } else if (clamepdScreenPos.x >= viewportWidth) {
        arrowDirection = ARROW_DIRECTIONS.RIGHT;
        clamepdScreenPos.x -= width / 2;
    }
    if (clamepdScreenPos.y <= 0) {
        arrowDirection = ARROW_DIRECTIONS.TOP;
        clamepdScreenPos.y += height / 2;
    } else if (clamepdScreenPos.y >= viewportHeight) {
        arrowDirection = ARROW_DIRECTIONS.BOTTOM;
        clamepdScreenPos.y -= height / 2;
    }
    if (screenPos.z >= 1) {
        // if avatar is behind the camera, then render at bottom of the screen
        clamepdScreenPos.y = viewportHeight - height / 2;
        arrowDirection = ARROW_DIRECTIONS.BOTTOM;
    }

    // 4. convert avatar clamped screen space position back to world space
    const clampedWorldPos = screenToWorld(clamepdScreenPos, scene);

    // 5. apply modified world space position to avatar to ensure "offscreen" avatars stay on the edge of the screen
    if (applyUpdate) {
        updateArrowDirection(arrowDirection, avatar);
        avatar.position = clampedWorldPos;
    }

    return { arrowDirection, clampedWorldPos };
}

export function updateArrowDirection(direction: number, avatar: Mesh) {
    const texture = getTexture(avatar);
    if (!texture) {
        console.error("No texture found for avatar");
        return;
    }

    const arrowImg = getArrowImg(texture);
    if (!arrowImg) {
        console.error("No arrow image found for avatar");
        return;
    }

    if (direction === ARROW_DIRECTIONS.NONE) {
        arrowImg.isVisible = false;
    } else {
        arrowImg.isVisible = true;
        switch (direction) {
            case ARROW_DIRECTIONS.TOP:
                arrowImg.horizontalAlignment =
                    Control.HORIZONTAL_ALIGNMENT_CENTER;
                arrowImg.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
                arrowImg.rotation = 0;
                break;
            case ARROW_DIRECTIONS.BOTTOM:
                arrowImg.horizontalAlignment =
                    Control.HORIZONTAL_ALIGNMENT_CENTER;
                arrowImg.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
                arrowImg.rotation = 180 * (Math.PI / 180);
                break;
            case ARROW_DIRECTIONS.LEFT:
                arrowImg.horizontalAlignment =
                    Control.HORIZONTAL_ALIGNMENT_LEFT;
                arrowImg.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
                arrowImg.rotation = -90 * (Math.PI / 180);
                break;
            case ARROW_DIRECTIONS.RIGHT:
                arrowImg.horizontalAlignment =
                    Control.HORIZONTAL_ALIGNMENT_RIGHT;
                arrowImg.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
                arrowImg.rotation = 90 * (Math.PI / 180);
                break;
            default:
                break;
        }
    }
}

export function updateIdleVisual(
    viewer: AdobeViewer,
    actorNr: number,
    avatar: Mesh,
    isIdle: boolean,
    color: string,
    profilePics: string[],
) {
    const texture = getTexture(avatar);
    if (!texture) {
        console.error("No texture found for avatar: " + actorNr);
        return;
    }

    const nameTagContainer = getNameTagContainer(texture);
    const imgContainer = getImgContainer(texture);
    const text = getTextElement(texture, actorNr);
    const mutedImg = getMutedImg(texture);
    const profileImg = getProfileImg(texture);
    const arrowImg = getArrowImg(texture);
    if (
        !nameTagContainer ||
        !imgContainer ||
        !text ||
        !mutedImg ||
        !profileImg
    ) {
        console.error(
            "One or more GUI elements not found for actor: " + actorNr,
        );
        return;
    }

    if (isIdle && nameTagContainer.background === color) {
        const foregroundColor = FOREGROUND_COLOR_MAP[AFK_COLOR];
        text.color = foregroundColor;
        imgContainer.color = foregroundColor;
        nameTagContainer.background = AFK_COLOR;
        mutedImg.source = MicMuteDark;
        profileImg.source = profilePics[1];
        if (arrowImg) {
            arrowImg.sourceLeft = ARROW_SOURCE_MAPPING[AFK_COLOR][0];
            arrowImg.sourceTop = ARROW_SOURCE_MAPPING[AFK_COLOR][1];
        }
        viewer.renderLoop.toggle();
    } else if (!isIdle && nameTagContainer.background === AFK_COLOR) {
        const foregroundColor = FOREGROUND_COLOR_MAP[color] ?? DARK_FOREGROUND;
        text.color = foregroundColor;
        imgContainer.color = foregroundColor;
        nameTagContainer.background = color;
        mutedImg.source =
            foregroundColor === LIGHT_FOREGROUND ? MicMuteLight : MicMuteDark;
        profileImg.source = profilePics[0];
        if (arrowImg) {
            arrowImg.sourceLeft = ARROW_SOURCE_MAPPING[color][0];
            arrowImg.sourceTop = ARROW_SOURCE_MAPPING[color][1];
        }
        viewer.renderLoop.toggle();
    }
}

export function updateMutedVisual(
    viewer: AdobeViewer,
    actorNr: number,
    avatar: Mesh,
    isMuted: boolean,
    isVR: boolean,
) {
    const texture = getTexture(avatar);
    if (!texture) {
        console.error("No texture found for avatar: " + actorNr);
        return;
    }

    const nameTagContainer = getNameTagContainer(texture);
    const audioStateContainer = getAudioContainer(texture);
    const text = getTextElement(texture, actorNr);
    if (!nameTagContainer || !audioStateContainer || !text) {
        console.error(
            "One or more GUI elements not found for actor: " + actorNr,
        );
        return;
    }

    if (isMuted && !audioStateContainer.isVisible) {
        audioStateContainer.isVisible = true;
        setTextPaddingToDefault(text, isVR);
        viewer.renderLoop.toggle();
    } else if (!isMuted && audioStateContainer.isVisible) {
        audioStateContainer.isVisible = false;
        text.paddingLeft = 45;
        viewer.renderLoop.toggle();
    }
}

export function updateTalkingVisual(
    viewer: AdobeViewer,
    actorNr: number,
    avatar: Mesh,
    isTalking: boolean,
) {
    const texture = getTexture(avatar);
    if (!texture) {
        console.error("No texture found for avatar: " + actorNr);
        return;
    }

    const nameTagContainer = getNameTagContainer(texture);
    const outlineContainer = getOutlineContainer(texture);
    if (!nameTagContainer || !outlineContainer) {
        console.error(
            "One or more GUI elements not found for actor: " + actorNr,
        );
        return;
    }

    outlineContainer.widthInPixels = nameTagContainer.widthInPixels + 3;
    if (isTalking && !outlineContainer.isVisible) {
        outlineContainer.isVisible = true;
        viewer.renderLoop.toggle();
    } else if (!isTalking && outlineContainer.isVisible) {
        outlineContainer.isVisible = false;
        viewer.renderLoop.toggle();
    }
}

export function setAvatarDirectionArrowVisibility(
    avatar: Mesh,
    isVisible: boolean,
) {
    const advancedTexture = getTexture(avatar);
    const directionContainer = getDirectionContainer(advancedTexture);
    if (advancedTexture && directionContainer) {
        directionContainer.alpha = isVisible ? 1 : 0;
    }
}

export function removeMesh(mesh: Mesh | undefined) {
    if (!mesh) return;
    mesh.geometry?.dispose();
    mesh.material?.dispose();
    mesh.dispose();
}

export function getTexture(avatar: Mesh) {
    return avatar.material?.getActiveTextures()[0] as AdvancedDynamicTexture;
}

export function getNameTagContainer(texture: AdvancedDynamicTexture) {
    return texture.getControlByName("nameTagContainer") as Rectangle;
}

function getImgContainer(texture: AdvancedDynamicTexture) {
    return texture.getControlByName("imgContainer") as Rectangle;
}

function getAudioContainer(texture: AdvancedDynamicTexture) {
    return texture.getControlByName("audioContainer") as Rectangle;
}

function getDirectionContainer(texture: AdvancedDynamicTexture) {
    return texture.getControlByName("directionContainer") as Rectangle;
}

function getOutlineContainer(texture: AdvancedDynamicTexture) {
    return texture.getControlByName("outlineContainer") as Rectangle;
}

function getTextElement(texture: AdvancedDynamicTexture, actorNr: number) {
    return getNameTagContainer(texture)?.getChildByName("nameTag: " + actorNr);
}

function getMutedImg(texture: AdvancedDynamicTexture) {
    return getAudioContainer(texture)?.getChildByName("muted") as BabylonImage;
}

function getProfileImg(texture: AdvancedDynamicTexture) {
    return getImgContainer(texture)?.getChildByName("profile") as BabylonImage;
}

function getArrowImg(texture: AdvancedDynamicTexture) {
    return getDirectionContainer(texture)?.getChildByName(
        "arrow",
    ) as BabylonImage;
}
