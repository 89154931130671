/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { JanusRoutes } from "@shared/common/src/routes/JanusRoutes";
import { useEffect, useState } from "react";

import { getAppPath } from "@src/config";
import { useRedirects } from "./useRedirects";
import { checkUserAccess } from "../lib/invitation/Access";
import { AccessRequestedView } from "@src/components/AccessRequestedView";
import { AccessRequiredView } from "@src/components/AccessRequiredView";
import { AssetNotFoundView } from "@src/components/AssetNotFoundView";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function checkAccessIssue(statusCode = 0) {
    return [403, 409].includes(statusCode || 0) || false;
}

export function useAccessCheck(assetId: string) {
    const { accessToken } = useHi5UserContext();
    const { showModal, closeModal } = useMessagingContext();
    const { homeRedirect } = useRedirects();

    const [accessStatus, setAccessStatus] = useState<number | undefined>(
        undefined,
    );

    const isAccessIssue = checkAccessIssue(accessStatus);

    useEffect(() => {
        if (accessToken) {
            checkUserAccess(accessToken, assetId).then(handleAccess);
        }
    }, [accessToken, assetId]);

    function handleAccess(status: number | undefined) {
        setAccessStatus(status);
        if (status && status < 400) return;
        switch (status) {
            case 403:
                console.error("user does not have access to this asset");
                showAccessRequiredDialog();
                break;
            case 404:
                console.error("asset not found");
                showAssetNotFoundDialog();
                break;
            case 409:
                console.error(
                    "user has already requested access to this asset",
                );
                showAccessRequestedDialog(status.toString());
                break;
            default:
                console.error(
                    "unexpected response from checkUserAccess with status: ",
                    status,
                );
                break;
        }
    }

    function goHome() {
        closeModal();
        homeRedirect();
    }

    function clearHomeDialog() {
        if (getAppPath().startsWith(JanusRoutes.home.path + "/")){
            homeRedirect(true);
        }
    }

    function showAccessRequiredDialog() {
        clearHomeDialog();
        const onSuccessCallback = () => {
            closeModal();
            showAccessRequestedDialog();
        };

        const onNotFoundCallback = () => {
            closeModal();
            showAssetNotFoundDialog();
        };

        const onFailureCallback = (statusCode: string) => {
            closeModal();
            showAccessRequestedDialog(statusCode);
        };

        showModal(
            <AccessRequiredView
                assetId={assetId}
                onGoHome={goHome}
                onSuccess={onSuccessCallback}
                onNotFound={onNotFoundCallback}
                onFailure={onFailureCallback}
            />,
            { isDismissable: false },
        );
    }

    function showAccessRequestedDialog(statusCode?: string) {
        clearHomeDialog();
        showModal(
            <View height="250px">
                <AccessRequestedView
                    success={(!statusCode).toString()}
                    statusCode={statusCode}
                    onGoHome={goHome}
                />
            </View>,
            { isDismissable: false },
        );
    }

    function showAssetNotFoundDialog() {
        clearHomeDialog();
        showModal(
            <View height="256px">
                <AssetNotFoundView onGoHome={goHome} />
            </View>,

            { isDismissable: false },
        );
    }

    return { accessStatus, isAccessIssue };
}
