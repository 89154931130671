/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ToastQueue } from "@react-spectrum/toast";
import { JobClient, type JobsApiConfig } from "@shared/client";
import { ModelMediaExtensionsUsd } from "@shared/types";
import { mediaTypeModelUsd } from "@shared/types/src/openapi-generated-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useRedirects } from "./useRedirects";
import { HI5_API_KEY, ASSET_APIS } from "../config";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { getConversionSpec, getThumbnailSpec } from "@src/util/JobSpecs";
import { fileNameToExtension, getUniqueFileName } from "@src/util/StringUtils";

import type { FileRejection } from "react-dropzone";

/* eslint-disable prefer-const */

export const MAX_FILE_SIZE = 1024 * 1024 * 500; // 500MB
export const MAX_NUMBER_OF_FILES = 1;

const apiConfig: JobsApiConfig = {
    url: ASSET_APIS.substance3d,
    key: HI5_API_KEY,
};

export function useUploadAsset() {
    const { t } = useTranslation(["common", "web"]);
    const { accessToken } = useHi5UserContext();
    const {
        acpClient,
        queryClient,
        useStorageQuota,
        useMyReviews,
        useDiscardReviewMutation,
    } = useAcpContext();
    const {homeRedirect} = useRedirects();

    const [isUploading, setIsUploading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [processingProgress, setProcessingProgress] = useState<number>(0);
    const [uploadCancel, setUploadCancel] = useState<() => void>();
    const [processCancel, setProcessCancel] = useState<() => Promise<void>>();

    const { data: storageQuota } = useStorageQuota();
    const { data: myReviews = [] } = useMyReviews();
    const { mutateAsync: discardAsset } = useDiscardReviewMutation();

    function resetUpload() {
        setIsUploading(false);
        setUploadProgress(0);
        setUploadCancel(undefined);
    }

    function resetProcessing() {
        setIsProcessing(false);
        setProcessingProgress(0);
        setProcessCancel(undefined);
    }

    function validateInputs(accepted: File[], rejections: FileRejection[]) {
        if (rejections.length > 1) {
            console.error("Expected exactly one file to upload");
            ToastQueue.negative(t("web:toast.upload.uploadSingleFile"), {
                timeout: 5000,
            });
            return false;
        } else if (rejections.length) {
            rejections[0].errors.forEach((error) => {
                if (error.code === "file-invalid-type") {
                    console.error("File format not supported");
                    ToastQueue.negative(
                        t("web:toast.upload.error.fileNotSupported"),
                        {
                            timeout: 5000,
                        },
                    );
                }
            });
            return false;
        }

        if (storageQuota && storageQuota.percent > 0.99) {
            ToastQueue.negative(
                t("web:toast.upload.uploadFailed.storageFull"),
                {
                    actionLabel: t("web:actions.upgrade"),
                    onAction: () =>
                        window.open(t("web:actions.upgrade.link"), "_blank"),
                },
            );
            return false;
        }

        const ext = fileNameToExtension(
            accepted[0].name.toLocaleLowerCase(),
        ) as ModelMediaExtensionsUsd;

        if (!ext || !mediaTypeModelUsd.includes(ext)) {
            throw new Error(`Unrecognized file extension ${ext}`);
        }

        return true;
    }

    async function uploadAsset(file: File) {
        if (!accessToken) return;
        const uploadProgressCallback = (startBuf: number, endBuf: number) => {
            setUploadProgress((startBuf / endBuf) * 100);
        };

        let uploadCancel: () => void;
        let assetId: string;

        const ext = fileNameToExtension(
            file.name.toLocaleLowerCase(),
        ) as ModelMediaExtensionsUsd;

        let bail = false;

        setIsUploading(true);

        setUploadCancel(() => () => {
            bail = true;
            uploadCancel?.();
            if (assetId) {
                discardAsset(assetId);
            }
        });

        if (bail) return;
        let compositeName = file.name.replace(/[^.]+$/, "usdcx");
        compositeName = getUniqueFileName(compositeName, myReviews.map(review => review.displayName))
        const composite = await acpClient.createNewComposite(
            compositeName,
            `cloud-content/${compositeName}`,
            "application/vnd.adobe.usdcx+dcx",
        );
        if (!composite.assetId) throw new Error("Error generating composite");
        assetId = composite.assetId;

        if (bail) {
            discardAsset(assetId);
        }

        queryClient.invalidateQueries({ queryKey: ["myReviews"] });

        if (bail) return;
        const upload = await acpClient.uploadComponent(
            composite.assetId,
            file,
            file.name,
            "original",
            {
                "usdcx#version": "0",
                "usdcx#product-id": HI5_API_KEY,
                "usdcx#source-document": file.name,
            },
            uploadProgressCallback,
        );
        uploadCancel = upload.cancel;

        if (bail) {
            uploadCancel();
        }

        return upload?.uploadComponentPromise.then((component) => {
            resetUpload();

            return {
                assetId: composite.assetId!,
                componentId: component.id,
                componentVersion: component.version || "0",
                originalExtension: ext,
            };
        });
    }

    async function processUpload(
        uploadData: Awaited<ReturnType<typeof uploadAsset>>,
    ) {
        if (uploadData && accessToken) {
            setIsProcessing(true);
            const jobs: JobClient[] = [];

            const thumbJob = new JobClient(
                accessToken,
                apiConfig,
                getThumbnailSpec(
                    accessToken,
                    uploadData.assetId,
                    uploadData.componentId,
                    uploadData.componentVersion,
                    uploadData.originalExtension,
                ),
            );

            thumbJob.jobCompletePromise.then(() => {
                queryClient.invalidateQueries({
                    queryKey: ["thumbnailUrls", uploadData.assetId],
                });
            });

            if (uploadData.originalExtension !== ".glb") {
                const convertJob = new JobClient(
                    accessToken,
                    apiConfig,
                    getConversionSpec(
                        accessToken,
                        uploadData.assetId,
                        uploadData.componentId,
                        uploadData.componentVersion,
                        uploadData.originalExtension,
                    ),
                );

                convertJob.jobCompletePromise.then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ["myReviews"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["reviewListItem", uploadData.assetId],
                    });
                });

                jobs.push(convertJob);
            }

            setProcessCancel(() => async () => {
                await Promise.all(jobs.map((job) => job.cancel()));
                discardAsset(uploadData.assetId);
            });

            const updateProgress = () => {
                const progress =
                    jobs.reduce((acc, val) => acc + val.progress, 0) /
                    jobs.length;
                setProcessingProgress(progress);
            };

            jobs.forEach((job) => job.on("progress", updateProgress));

            await Promise.all(jobs.map((job) => job.jobCompletePromise));

            jobs.forEach((job) => job.off("progress", updateProgress));
        } else {
            ToastQueue.negative(t("web:toast.upload.uploadFailed"), {
                timeout: 5000,
            });
        }
        resetProcessing();
    }

    async function uploadAndProcessAsset(
        file: File,
        onComplete: (assetId?: string, componentId?: string) => void,
    ) {
        window.onbeforeunload = () =>
            "You are still uploading would you like to cancel?";

        const upload = await uploadAsset(file).catch(() => {
            resetUpload();
            window.onbeforeunload = null;
        });

        if (upload) {
            const uploadData = await upload;
            if (uploadData) {
                try {
                    await processUpload(uploadData);
                    onComplete(uploadData.assetId, uploadData.componentId);
                } catch (e) {
                    discardAsset(uploadData.assetId);
                    resetProcessing();
                    window.onbeforeunload = null;
                    throw e;
                }
            }
        }

        window.onbeforeunload = null;

        onComplete();
    }

    async function cancel() {
        if (isUploading) {
            uploadCancel?.();
            resetUpload();
        }
        if (isProcessing) {
            await processCancel?.();
            resetProcessing();
        }
        window.onbeforeunload = null;
        homeRedirect(true);
    }

    return {
        isUploading,
        isProcessing,
        uploadProgress,
        processingProgress,
        validateInputs,
        uploadAsset,
        processUpload,
        uploadAndProcessAsset,
        cancel,
    };
}
