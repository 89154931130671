/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Matrix, Quaternion, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Sprite } from "@babylonjs/core/Sprites/sprite";

import { getMeshByPath, getSerializedPinDataFromScene } from "../utils/pinData";

import type { SerializedPin, SerializedVector3 } from "../PinDataTypes";
import type { PinViewport } from "../PinManager";
import type { Material } from "@babylonjs/core/Materials/material";
import type { Mesh } from "@babylonjs/core/Meshes/mesh";
import type { Scene } from "@babylonjs/core/scene";
import type { SpriteManager } from "@babylonjs/core/Sprites/spriteManager";

import "@babylonjs/core/Animations/animatable";
import "@babylonjs/core/Engines/Extensions/engine.query";
import "@babylonjs/core/Rendering/boundingBoxRenderer";

export interface PinTextures {
    poleMat: Material;
    headSprites: { [key in PinState]: SpriteManager };
}

interface PinStyle {
    hitBoxDistanceScale: number;
    headRadiusScale: number;
    headDistanceScale: number;
    outlineEnabled: boolean;
    outlineColor: [number, number, number];
    outlineScaleFactor: number;
}

interface PinInteractionAnimation {
    enabled: boolean; // default to true
    duration: number; // seconds
    targetSize: number;
}

interface PinBehaviors {
    framesPerSecond: number;
    defaultScale: number;
    hoverAnimation: PinInteractionAnimation;
    occludedAnimation: PinInteractionAnimation;
}

export type PinConfig = {
    radius: number;
    pinTextures: PinTextures;
    pinStyle?: Partial<PinStyle>;
    pinBehaviors?: Partial<PinBehaviors>;
};

export const DEFAULT_PIN_Behaviors: PinBehaviors = {
    framesPerSecond: 60,
    defaultScale: 1,
    hoverAnimation: {
        enabled: true,
        duration: .25,
        targetSize: 2,
    },
    occludedAnimation: {
        enabled: true,
        duration: 0.125,
        targetSize: 0.8,
    },
};

export const DEFAULT_PIN_STYLE_OPTIONS: PinStyle = {
    hitBoxDistanceScale: 0.25,
    headRadiusScale: 1,
    headDistanceScale: 0.25,
    outlineEnabled: true,
    outlineColor: [0.2, 0.2, 0.2],
    outlineScaleFactor: 1 / 9,
};

export enum PinState {
    normal = "normal",
    occluded = "occluded",
    edit = "edit",
    hover = "hover",
    selected = "selected",
}

export const HITBOX_NAME_PREFIX = "hitBox_";

interface PinArguments {
    scene: Scene,
    pinViewport: PinViewport,
    pinOptions: PinConfig,
    modelRootMesh?: AbstractMesh,
    spriteIndex?: number,
    state?: PinState,
}

export class Pin {
    scene: Scene;
    mesh: Mesh;
    modelRootMesh?: AbstractMesh;
    playerRootMesh?: AbstractMesh;
    head: Sprite;
    radius: number;
    pinOptions: PinConfig;
    pinData?: SerializedPin;
    pinViewport: PinViewport;
    spriteIndex?: number;

    wasSelected: boolean;
    animating = false;

    guid = window.crypto.randomUUID();
    private _state: PinState; 

    constructor(args: PinArguments) {
        const { scene, pinViewport, pinOptions, modelRootMesh, spriteIndex, state } = args;

        this.scene = scene;
        this.modelRootMesh = modelRootMesh;
        this.playerRootMesh = this.modelRootMesh?.getChildren()[0] as AbstractMesh;
        this.spriteIndex = spriteIndex;
        this.pinViewport = pinViewport;
        this.pinOptions = pinOptions;
        const { radius } = pinOptions;

        this._state = state ? state : PinState.normal;

        this.radius = radius;

        const hitBox = MeshBuilder.CreateBox(HITBOX_NAME_PREFIX+this.guid, {
            width: radius / 2,
            height: radius / 2,
            depth: radius / 2,
        }, scene);

        hitBox.material = new StandardMaterial("clear", scene);
        hitBox.material.alpha = 0;
        // Makes this material invisible to iblShadows
        hitBox.material.disableDepthWrite = true;

        hitBox.occlusionQueryAlgorithmType =
            AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
        hitBox.occlusionType = AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
        hitBox.renderingGroupId = 0;

        const pinTexture = this.pinOptions.pinTextures.headSprites[this._state];
        const head = new Sprite("head", pinTexture);
        head.isPickable = true;
        head.useAlphaForPicking = true;
        head.width = head.height = radius * 2;
        if (spriteIndex) head.cellIndex = spriteIndex;

        this.head = head;
        this.mesh = hitBox;

        this.wasSelected = false;
    }

    setAnimationState(oldState: PinState, newState: PinState) {
        const behaviorConfig =
            this.pinOptions.pinBehaviors || DEFAULT_PIN_Behaviors;
        if (
            behaviorConfig.hoverAnimation &&
            [PinState.hover, PinState.selected].includes(newState) &&
            oldState === PinState.normal
        ) {
            this.animateScale(
                behaviorConfig.hoverAnimation.targetSize,
                behaviorConfig.hoverAnimation.duration,
            );
        }
        if (
            behaviorConfig.hoverAnimation &&
            [PinState.hover, PinState.selected].includes(oldState) &&
            newState === PinState.normal
        ) {
            this.animateScale(1, behaviorConfig.hoverAnimation.duration);
        }
        if (
            behaviorConfig.occludedAnimation &&
            [PinState.hover, PinState.selected, PinState.normal].includes(
                oldState,
            ) &&
            newState === PinState.occluded
        ) {
            this.animateScale(
                behaviorConfig.occludedAnimation.targetSize,
                behaviorConfig.occludedAnimation.duration,
            );
        }
        if (
            behaviorConfig.occludedAnimation &&
            [PinState.hover, PinState.selected, PinState.normal].includes(
                newState,
            ) &&
            oldState === PinState.occluded
        ) {
            this.animateScale(1, behaviorConfig.occludedAnimation.duration);
        }
    }

    animateScale(targetScale: number, duration: number) {
        var ease = new CubicEase();
        ease.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);

        this.animating = true;
        Animation.CreateAndStartAnimation(
            window.crypto.randomUUID(),
            this,
            "scale",
            60,
            60 * duration,
            this.scale,
            this.getClampedScale(),
            Animation.ANIMATIONLOOPMODE_CONSTANT,
            ease,
            () => {
                this.animating = false;
            },
            this.scene,
        );
    }

    animateHeadOpacity(
        targetOpacity: number,
        duration: number,
        callback = () => {},
    ) {
        Animation.CreateAndStartAnimation(
            window.crypto.randomUUID(),
            this.head,
            "color.a",
            60,
            60 * duration,
            this.head.color.a,
            targetOpacity,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
            undefined,
            callback,
            this.scene,
        );
    }

    private getOption<T extends keyof PinStyle>(type: T): PinStyle[T] {
        const option = this.pinOptions.pinStyle?.[type];
        if (option !== undefined) {
            return option as PinStyle[T];
        }
        return DEFAULT_PIN_STYLE_OPTIONS[type];
    }

    positionFromData() {
        const pinData = this.pinData;
        if (pinData) {
            const {
                modelPath,
                pinModelLocalPosition,
                pinUnscaledModelRootPosition,
                pinNormal,
            } = pinData;
            // pins created after the addition of pinUnscaledModelRootPosition will place pins using unscaled coordindates relative to the model root mesh
            // pinModelLocalPosition and positionFromWorld, which use scaled coordinates relative to submesh space and world space repspectively, are left unmodified as to not affect pins placed before this change
            // visually, pins placed using these different positioning methods should look the same
            if (pinUnscaledModelRootPosition) {
                this.positionFromModelRoot(
                    pinUnscaledModelRootPosition,
                    pinNormal,
                );
            } else if (modelPath && pinModelLocalPosition) {
                this.positionFromParent(
                    modelPath,
                    pinModelLocalPosition,
                    pinNormal,
                );
            } else {
                this.positionFromWorld(
                    pinData.pinWorldPosition || [0, 0, 0],
                    pinData.pinNormal,
                );
            }
        }
    }

    private positionFromModelRoot(
        pinUnscaledModelRootPosition: SerializedVector3,
        pinNormal: SerializedVector3,
    ) {
        const modelRootWorld = new Vector3();
        const modelRootWorldRotation = new Quaternion();
        const modelRootScale = new Vector3();
        if (this.playerRootMesh) {
            this.playerRootMesh
                .getWorldMatrix()
                .decompose(
                    modelRootScale,
                    modelRootWorldRotation,
                    modelRootWorld,
                );
        }
        const scaledModelRootPosition = new Vector3(
            ...pinUnscaledModelRootPosition,
        ).scaleInPlace(modelRootScale.x);
        scaledModelRootPosition.applyRotationQuaternionInPlace(
            modelRootWorldRotation,
        );

        this.updatePinLocation(
            modelRootWorld.add(scaledModelRootPosition),
            new Vector3(...pinNormal),
        );
    }

    private positionFromParent(
        modelPath: string[],
        pinLocalPosition: SerializedVector3,
        pinNormalSVec: SerializedVector3,
    ) {
        const pinLocal = new Vector3(...pinLocalPosition);
        const pinNormal = new Vector3(...pinNormalSVec);
        const parentWorld = new Vector3();
        const parent = getMeshByPath(modelPath, this.scene);
        if (parent) {
            parent
                .getWorldMatrix()
                .decompose(undefined, undefined, parentWorld);
        }
        this.updatePinLocation(parentWorld.add(pinLocal), pinNormal);
    }

    private positionFromWorld(
        pinWorldPosition: SerializedVector3,
        pinNormal: SerializedVector3,
    ) {
        this.updatePinLocation(
            new Vector3(...pinWorldPosition),
            new Vector3(...pinNormal),
        );
    }

    updatePinLocation(point: Vector3, normal: Vector3) {
        const { mesh, head } = this;
        mesh.position.copyFrom(point);
        head.position.copyFrom(point);
        head.position.addInPlace(
            normal
                .clone()
                .scale(this.radius * this.getOption("headDistanceScale")),
        );
        mesh.position.addInPlace(
            normal
                .clone()
                .scale(this.radius * this.getOption("hitBoxDistanceScale")),
        );
        mesh.lookAt(point.clone().addInPlace(normal));
    }

    updatePinData(parentMesh: AbstractMesh, point: Vector3, normal: Vector3) {
        if (this.pinData) {
            const {
                modelPath,
                pinWorldPosition,
                pinModelLocalPosition,
                pinUnscaledModelRootPosition,
                pinNormal,
            } = getSerializedPinDataFromScene(
                parentMesh,
                point,
                normal,
                this.modelRootMesh,
            );

            this.pinData = {
                ...this.pinData,
                pinWorldPosition,
                modelPath,
                pinModelLocalPosition,
                pinUnscaledModelRootPosition,
                pinNormal,
            };
        }
    }

    _enabled = true;

    set isEnabled(enabled: boolean) {
        this._enabled = enabled;
        this.mesh.isVisible = enabled;
        this.setVisible(enabled);
    }

    get isEnabled() {
        return this._enabled;
    }

    _visible = true;

    setVisible(visible: boolean, animate = false, onComplete = () => {}) {
        if (animate) {
            if (!visible && this._visible) {
                this.animateHeadOpacity(0, 0.25, () => {
                    this.head.isVisible = visible;
                    onComplete();
                });
            }
            if (visible && !this._visible) {
                this.head.isVisible = visible;
                this.animateHeadOpacity(1, 0.25);
            }
        } else {
            this.head.isVisible = visible;
            onComplete();
        }
        this._visible = visible;
    }

    get visible() {
        return this._visible;
    }

    getSpriteSize(viewportPixelSize: number) {
        const distanceToCamera = Vector3.Distance(
            this.scene.cameras[0].position,
            this.head.position,
        );

        return (viewportPixelSize * this.head.size) / distanceToCamera;
    }

    getScaleForSize(size: number, distanceToCamera: number) {
        const { viewportPixelSize } = this.pinViewport;

        return ((size / viewportPixelSize) * distanceToCamera) / (2 * this.radius);
    }

    getClampedScale() {
        const behaviorConfig =
            this.pinOptions.pinBehaviors || DEFAULT_PIN_Behaviors;
        const { viewportPixelSize, minSpriteSize, maxSpriteSize } =
            this.pinViewport;

        const distanceToCamera = Vector3.Distance(
            this.scene.cameras[0].position,
            this.head.position,
        );

        let stateScaleFactor = 1;
        if (this._state === PinState.occluded) {
            stateScaleFactor = behaviorConfig.occludedAnimation?.targetSize || 0.8;
        }
        if(this._state === PinState.hover || this._state === PinState.selected) {
            stateScaleFactor = behaviorConfig.hoverAnimation?.targetSize || 2;
        }

        const unScaledWidth = viewportPixelSize * this.radius * 2 / distanceToCamera;

        const targetSize = unScaledWidth * stateScaleFactor;

        const max = maxSpriteSize * stateScaleFactor;
        const min = minSpriteSize * stateScaleFactor;

        if (targetSize > max) {
            return this.getScaleForSize(max, distanceToCamera);
        } else if (targetSize < min) {
            return this.getScaleForSize(min, distanceToCamera);
        }
        return this.getScaleForSize(targetSize, distanceToCamera);
    }

    clampPinSize() {
        if (this.animating) return;
        this.scale = this.getClampedScale();
    }

    getSpriteScreenBoundingBox() {
        const { viewportPixelSize, viewport } = this.pinViewport;
        const size = this.getSpriteSize(viewportPixelSize);

        const screenPosition = new Vector3();

        Vector3.ProjectToRef(
            this.head.position,
            Matrix.IdentityReadOnly,
            this.scene.getTransformMatrix(),
            viewport,
            screenPosition,
        );

        return {
            width: size,
            height: size,
            x: screenPosition.x - size / 2,
            y: screenPosition.y - size / 2,
        };
    }

    disposed = false;
    dispose() {
        this.mesh.dispose();
        this.head.dispose();
        this.disposed = true;
    }

    private _scale = 1;

    public set scale(value: number) {
        const { head, radius } = this;

        this._scale = value;
        head.size = radius * 2 * value;
    }

    public get scale() {
        return this._scale;
    }

    private _isPickable = true;

    set isPickable(toPickable: boolean) {
        this._isPickable = toPickable;
        this.head.isPickable = this._isPickable;
        this.mesh.isPickable = this._isPickable;
    }

    get isPickable() {
        return this.isPickable;
    }

    private switchSpriteManager(manager: SpriteManager) {
        if (this.disposed) {
            return;
        }
        const oldHead = this.head;
        this.head = new Sprite("head", manager);
        this.head.position.copyFrom(oldHead.position);
        this.head.width = oldHead.width;
        this.head.height = oldHead.height;
        this.head.useAlphaForPicking = true;
        this.head.isPickable = oldHead.isPickable;
        this.head.actionManager = oldHead.actionManager;
        this.head.isVisible = oldHead.isVisible;
        this.head.color.a = oldHead.color.a;
        if (this.spriteIndex) this.head.cellIndex = this.spriteIndex;
        oldHead.dispose();
    }

    set state(state: PinState) {
        if (this._state !== state) {
            const oldState = this.state;
            this._state = state;
            this.switchSpriteManager(
                this.pinOptions.pinTextures.headSprites[state],
            );
            this.setAnimationState(oldState, state);
        }
    }

    get state() {
        return this._state;
    }

    setInitialState(state: PinState) {
        this.switchSpriteManager(
            this.pinOptions.pinTextures.headSprites[state],
        );
        this._state = state;
    }
}
