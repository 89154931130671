/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { createContext, PropsWithChildren, useContext } from "react";

import { useUploadAsset } from "@src/hooks/useUploadAsset";

export type IngestContextValue = ReturnType<typeof useUploadAsset>;

const IngestContext = createContext<IngestContextValue>({} as IngestContextValue);

export function useIngestContext() {
    const context = useContext(IngestContext);
    if (!context.uploadAndProcessAsset) {
        throw new Error("useIngestContext must be used within the context of a IngestContextProvider");
    }
    return context
}

export function IngestContextProvider({children}: PropsWithChildren) {
    const value = useUploadAsset();

    return (<IngestContext.Provider value={value}>
        {children}
    </IngestContext.Provider>)
}
