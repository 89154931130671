/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

export function useIsMetaBrowser() {
    const [isMeta, setIsMeta] = useState<boolean | undefined>();
   
    useEffect(() => {
        checkMetaBrowser();
    }, [navigator,isMeta]);

    async function checkMetaBrowser() {
        if (navigator.xr){
            console.log("navigator.xr is supported");
            navigator.xr.isSessionSupported("immersive-vr").then((supported) => {
                if (supported) {
                    console.log("XR immersive-vr is supported");
                    setIsMeta(true);
                }
                else
                {
                    setIsMeta(false);
                }
               
            });
        }
    }

    return isMeta;
}
