/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Dialog,
    Heading,
    Divider,
    Content,
    Flex,
    TextField,
    ButtonGroup,
    Button,
    Form,
    Image,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { useTranslation } from "react-i18next";

import noThumbnail from "@src/assets/images/no-thumbnail.svg";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useRenameAsset } from "@src/hooks/useRenameAsset";
import { isValidAssetName } from "@src/util/StringUtils";

interface AssetRenameDialogProps {
    assetId: string;
    displayName: string;
    isForVr: boolean;
}

export function AssetRenameDialog({
    assetId,
    displayName,
    isForVr,
}: AssetRenameDialogProps) {
    const { t } = useTranslation("common");
    const { useReviewThumbnailUrl } = useAcpContext();
    const { closeModal } = useMessagingContext();

    const { data: thumbnailUrl } = useReviewThumbnailUrl(assetId);
    const { assetName, formError, setAssetName, handleRename } =
        useRenameAsset(assetId);

    return (
        <Dialog size="S">
            <Heading>{t("rename.header")}</Heading>
            <Divider />
            <Content>
                <Flex direction="row" gap="size-200">
                    <Image
                        minWidth={isForVr ? "2vw" : "size-600"}
                        minHeight={isForVr ? "2vw" : "size-600"}
                        maxWidth={isForVr ? "2vw" : "size-600"}
                        maxHeight={isForVr ? "2vw" : "size-600"}
                        alt={t("accessibility.asset.thumbnail")}
                        src={thumbnailUrl || noThumbnail}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                    <Form
                        width="100%"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleRename();
                        }}>
                        <TextField
                            aria-label="rename"
                            defaultValue={displayName}
                            onChange={setAssetName}
                            isRequired
                            validationState={!formError ? "valid" : "invalid"}
                            errorMessage={formError}
                        />
                    </Form>
                </Flex>
            </Content>
            <ButtonGroup>
                <Button variant="secondary" onPress={() => closeModal()}>
                    {t("actions.cancel")}
                </Button>
                <Button
                    type="submit"
                    variant="accent"
                    onPress={() => {
                        handleRename();
                        closeModal();
                    }}
                    isDisabled={
                        assetName === "" ||
                        assetName === displayName ||
                        !isValidAssetName(assetName)
                    }>
                    {t("actions.save")}
                </Button>
            </ButtonGroup>
        </Dialog>
    );
}
