/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Text, Flex, View, Image } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import appIcon from "../assets/images/rw_appicon_noshadow_128.png";

const aboutDialogContentSections = [
    "about.h5.core.team",
    "about.h5.extended.team",
    "about.h5.prototyping",
    "about.h5.interns",
    "about.h5.special.thanks",
];

const aboutCopyrightSections = [
    "about.h5.copyright",
    "about.h5.software.contains",
    "about.h5.program.written",
    "about.h5.portions.utilize",
];

export function AboutHeader({ isForVr }: { isForVr: boolean }) {
    const { t } = useTranslation("common");

    return (
        <Flex direction="row" alignItems="start" gap="size-200">
            <Image
                width={isForVr ? "90px" : "60px"}
                height={isForVr ? "90px" : "60px"}
                alt={t("accessibility.header.appIcon")}
                src={appIcon}
                UNSAFE_style={{
                    borderRadius: "8px",
                    marginTop: `${isForVr ? "15px" : "0px"}`,
                }}
            />
            <Flex direction="column" alignItems="start">
                <Text
                    UNSAFE_style={{
                        fontSize: `${isForVr ? "3em" : "1.5em"}`,
                        paddingTop: "size-200",
                        fontWeight: "bold",
                    }}>
                    {t("about.h5.name")}
                </Text>
                <Flex
                    direction="column"
                    UNSAFE_style={{
                        fontWeight: "normal",
                        paddingTop: "size-100",
                        fontSize: `${isForVr ? "1.8em" : "0.9em"}`,
                        marginTop: "size-100",
                    }}>
                    <Text>{t("about.h5.version")}</Text>
                    <Text>{t("about.h5.build")}</Text>
                </Flex>
            </Flex>
        </Flex>
    );
}

function AboutContentSection({
    title,
    content,
}: {
    title: string;
    content: string;
}) {
    return (
        <Flex
            direction="column"
            UNSAFE_style={{
                fontSize: "1.25em",
            }}>
            {title && content && (
                <Text
                    UNSAFE_style={{
                        fontWeight: "bold",
                    }}>
                    {title + ":"}
                </Text>
            )}
            {title && <Text>{content || title}</Text>}
        </Flex>
    );
}

export function AboutContent({ isForVr }: { isForVr: boolean }) {
    const { t } = useTranslation("common");

    return (
        <View UNSAFE_style={{ fontSize: `${isForVr ? "1.6em" : ""}` }}>
            <Flex direction="column" gap="size-200" marginTop="size-300">
                {aboutDialogContentSections.map((section, i) => {
                    if (!section || !t(section)) {
                        return;
                    }
                    const [title, content] = t(section).split(":");

                    return (
                        <AboutContentSection
                            key={i}
                            title={title}
                            content={content}
                        />
                    );
                })}

                <Flex
                    direction="column"
                    UNSAFE_style={{
                        fontSize: "1.25em",
                    }}>
                    <Text
                        UNSAFE_style={{
                            fontWeight: "bold",
                        }}>
                        {t("about.h5.support")}
                    </Text>
                    <Text>{t("about.h5.alumni")}</Text>
                </Flex>

                {isForVr &&
                    aboutCopyrightSections.map((section, i) => {
                        if (!section || !t(section)) {
                            return;
                        }
                        const [title, content] = t(section).split(":");
                        return (
                            <AboutContentSection
                                key={i}
                                title={title}
                                content={content}
                            />
                        );
                    })}
            </Flex>
        </View>
    );
}
