/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Switch,
    Item,
    Picker,
    ContextualHelp,
    Content,
    Heading,
} from "@adobe/react-spectrum";
import { Key } from "@react-types/shared";
import { useTranslation } from "react-i18next";

import { BODY_FONT_SIZE } from "@src/util/AppSettingsUtils";

export type PreferencesOptionType = {
    isPicker: boolean;
    label: string;
    selection: Key | boolean;
    items?: { [key: string]: string }[];
    onChange: ((key: Key) => void) | ((isSelected: boolean) => void);
    contextualHelp?: string;
    isCustomPreset?: boolean;
};

function PickerSetting({
    label,
    selectedKey,
    items,
    onSelectionChange,
}: {
    label: string;
    selectedKey: Key;
    items: { [key: string]: string }[];

    onSelectionChange: (key: Key) => void;
}) {
    const { t } = useTranslation("common");

    return (
        <Flex
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center">
            <Text
                UNSAFE_style={{
                    fontSize: BODY_FONT_SIZE,
                }}>
                {t(label)}
            </Text>
            <Picker
                aria-label={t(label)}
                minWidth="size-3000"
                selectedKey={selectedKey}
                onSelectionChange={onSelectionChange}>
                {items.map((item) => {
                    const itemText =
                        label === "preferences.language"
                            ? item.language
                            : t(item.name);
                    return (
                        <Item key={item.key ?? item.code} textValue={itemText}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: BODY_FONT_SIZE,
                                    padding: "5px",
                                }}>
                                {itemText}
                            </Text>
                        </Item>
                    );
                })}
            </Picker>
        </Flex>
    );
}

function SwitchSetting({
    label,
    isSelected,
    onChange,
    contextualHelp,
}: {
    label: string;
    isSelected: boolean;
    onChange: (isSelected: boolean) => void;
    contextualHelp?: string;
}) {
    const { t } = useTranslation("common");
    return (
        <Flex direction="row" alignItems="center">
            <Switch
                aria-label={t(label)}
                isEmphasized
                isSelected={isSelected}
                onChange={onChange}
            />
            <Text
                UNSAFE_style={{
                    fontSize: BODY_FONT_SIZE,
                }}>
                {t(label)}
            </Text>
            {contextualHelp && (
                <ContextualHelp
                    variant="info"
                    marginTop="5px"
                    marginStart="10px">
                    <Heading>{t(label)}</Heading>
                    <Content>
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.25em",
                            }}>
                            {t(contextualHelp)}
                        </Text>
                    </Content>
                </ContextualHelp>
            )}
        </Flex>
    );
}

export function VRPreferencesOption({
    option,
}: {
    option: PreferencesOptionType;
}) {
    return (
        <>
            {option.isPicker ? (
                <PickerSetting
                    label={option.label}
                    selectedKey={option.selection as Key}
                    items={option.items ?? []}
                    onSelectionChange={option.onChange as (key: Key) => void}
                />
            ) : (
                <SwitchSetting
                    label={option.label}
                    isSelected={option.selection as boolean}
                    onChange={option.onChange as () => void}
                    contextualHelp={option.contextualHelp}
                />
            )}
        </>
    );
}
