/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer } from "@adobe/react-spectrum";
import {
    SpectrumToastOptions,
    ToastContainer,
    ToastQueue,
} from "@react-spectrum/toast";
import { PropsWithChildren, ReactNode, createContext, useContext, useState } from "react";

export interface ModalOptions {
    isDismissable?: boolean
}
interface MessagingContextType {
    error: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    info: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    warn: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    success: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    showModal: (modal: ReactNode, options?: ModalOptions) => void,
    closeModal: () => void
}

const MessagingContext = createContext<MessagingContextType>(
    {} as MessagingContextType,
);

export function useMessagingContext() {
    const context = useContext(MessagingContext);
    if (!context.error) {
        throw new Error(
            "You must use messaging context within a <MessagingContextProvider>",
        );
    }
    return context;
}

const defaultToastOptions: Partial<SpectrumToastOptions> = {
    timeout: 8_000, // 8 seconds
};

export function MessagingContextProvider({ children }: PropsWithChildren) {
    const [modal, setModal] = useState<{node: ReactNode, options: ModalOptions}>();

    const showModal = (node: ReactNode, options: ModalOptions = {}) => {
        setModal({node, options});
    }

    const closeModal = () => {
        setModal(undefined);
    }

    const error = (text: string, options: Partial<SpectrumToastOptions> = {}) => {
        ToastQueue.negative(text, { ...defaultToastOptions, ...options });
    };
    const info = (text: string, options: Partial<SpectrumToastOptions> = {}) => {
        ToastQueue.info(text, { ...defaultToastOptions, ...options });
    };
    const warn = (text: string, options: Partial<SpectrumToastOptions> = {}) => {
        ToastQueue.neutral(text, { ...defaultToastOptions, ...options });
    };
    const success = (text: string, options: Partial<SpectrumToastOptions> = {}) => {
        ToastQueue.positive(text, { ...defaultToastOptions, ...options });
    };

    return (
        <MessagingContext.Provider
            value={{
                error,
                info,
                warn,
                success,
                showModal,
                closeModal,
            }}>
            {children}
            <ToastContainer />
            <DialogContainer {...modal?.options} onDismiss={closeModal} type="modal">
                {modal?.node}
            </DialogContainer>
        </MessagingContext.Provider>
    );
}
