/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Heading,
    Divider,
    Text,
    Flex,
    Link,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    type JoinReviewOptions,
    useNetworkContext,
} from "@src/contexts/NetworkContext";
import { useRedirects } from "@src/hooks/useRedirects";

export function TransferToVR({
    joinReviewOptions,
}: {
    joinReviewOptions: JoinReviewOptions;
}) {
    const { t } = useTranslation(["common", "web"]);
    const { homeRedirect } = useRedirects();
    const { closeModal } = useMessagingContext();
    const { networkManager, voiceManager } = useNetworkContext();

    const { isSolo, pinningSession, assetId, viewer, displayName, avatarUrl, envBuilder } =
        joinReviewOptions;

    useEffect(() => {
        if (networkManager?.isJoinedToRoom()) {
            networkManager.leaveRoom();
        }
        if (voiceManager?.isJoinedToRoom()) {
            voiceManager.leaveRoom();
        }
        homeRedirect();
    }, []);

    async function reenterReview() {
        closeModal();
        if (networkManager && pinningSession && viewer && envBuilder) {
            await networkManager?.joinReviewRoom(
                isSolo,
                pinningSession,
                assetId,
                viewer,
                envBuilder,
                displayName,
                avatarUrl,
            );
        }
        if (voiceManager) {
            voiceManager.initializeVoiceRoom(assetId + "_voice", displayName);
        }
    }

    function goHome() {
        closeModal();
        homeRedirect();
    }

    return (
        <Dialog>
            <Heading>{t("web:transfer.heading")}</Heading>
            <Divider />
            <Content>
                {
                    <Flex direction="column">
                        <Text>{t("web:transfer.content")}</Text>
                        <br></br>
                        <Text>{t("web:openInVr.dialog.openLink.description.3")}</Text>
                        <Text>{t("web:openInVr.dialog.openLink.description.4")}</Text>
                        <Text>{t("web:openInVr.dialog.openLink.description.5")}</Text>
                        <Link href="https://helpx.adobe.com/" target="_blank">{t("web:openInVr.dialog.openLink.description.6") + t("web:openInVr.dialog.openLink.description.7") + t("web:openInVr.dialog.openLink.description.8")}</Link>
                    </Flex>
                }
            </Content>
            <ButtonGroup>
                <Button variant="cta" onPress={closeModal} autoFocus>{t("web:actions.confirm")}</Button>
            </ButtonGroup>
        </Dialog>
    );
}
