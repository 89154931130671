/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ToastQueue } from "@react-spectrum/toast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAcpContext } from "@src/contexts/AcpContext";
import { usePostMessageContext } from "@src/contexts/PostMessageContext";
import { isValidAssetName } from "@src/util/StringUtils";

export function useRenameAsset(assetId: string) {
    const { t } = useTranslation(["common", "web"]);
    const { refreshMenuInputFields } = usePostMessageContext();
    const { useReviewListItem, useRenameReviewMutation } = useAcpContext();
    const { data: reviewItem } = useReviewListItem(assetId || "");
    const { mutateAsync: renameAsset } = useRenameReviewMutation();

    const [assetName, setAssetName] = useState<string>("");
    const [formError, setFormError] = useState<string>();

    useEffect(() => {
        if (reviewItem?.displayName) {
            setAssetName(reviewItem?.displayName);
        }
    }, [reviewItem]);

    useEffect(() => {
        !isValidAssetName(assetName)
            ? setFormError(t("rename.error"))
            : setFormError(undefined);
    }, [assetName]);

    useEffect(() => {
        setTimeout(() => {
            refreshMenuInputFields();
        }, 500);
    }, []);

    async function handleRename() {
        if (assetId && reviewItem && reviewItem.displayName !== assetName) {
            const isSuccessful = await renameAsset({
                assetId,
                name: assetName,
            });
            if (isSuccessful) {
                ToastQueue.positive(t("toast.rename.successful"), {
                    timeout: 5000,
                });
            } else {
                ToastQueue.negative(t("toast.rename.fail"), { timeout: 5000 });
            }
        }
    }

    return {
        assetName,
        formError,
        setAssetName,
        handleRename,
    };
}
