/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export const legalNoticeLinks = [
    {
        label: "web:landing.footer.termsOfUse",
        link: "https://www.adobe.com/legal/terms.html",
    },
    {
        label: "web:landing.footer.privacy",
        link: "https://www.adobe.com/privacy/policy.html",
    },
    {
        label: "web:landing.footer.personalInfo",
        link: "https://www.adobe.com/privacy/us-rights.html",
    },
];

export const thirdPartyLink = {
    label: "web:account.legalNotices.copyright.content.thirdPartyLink",
    link: "https://www.adobe.com/products/eula/third_party.html",
};
export type LegalNoticeLink = { label: string; link: string };
