/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import { IconProps } from "../../util/IconUtils";

export function LaserPointerMode(props: IconProps) {
    return (
        <Icon {...props}>
            <svg viewBox="0 0 18 18">
                <path d="M11,1c-3.30859,0-6,2.69141-6,6,0,.92456.21631,1.79736.59137,2.58026l1.55768-1.55768c-.08746-.32843-.14905-.66693-.14905-1.02258,0-2.20605,1.79443-4,4-4s4,1.79395,4,4-1.79443,4-4,4c-.35559,0-.69409-.06158-1.02252-.14905l-1.55768,1.55768c.7829.37506,1.6557.59137,2.5802.59137,3.30859,0,6-2.69141,6-6S14.30859,1,11,1Z" />
                <path d="M11,5c-1.10455,0-2,.89539-2,2,0,.17792.0307.34723.07428.51147L1.10638,15.47937c-.19525.19531-.19525.5119,0,.70715l.70709.70709c.19525.19525.51184.19525.70709,0l7.9679-7.9679c.16425.04358.33356.07428.51154.07428,1.10455,0,2-.89545,2-2,0-1.10461-.89545-2-2-2Z" />
            </svg>
        </Icon>
    );
}
