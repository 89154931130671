/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// Implement https://developers.corp.adobe.com/ons/onsHowToUse.md
// https://wiki.corp.adobe.com/display/SS/ONS+-+How+to+use+Flow+Diagram

// ... falls back to periodic refresh if this encounters 3 consecutive
// subscription errors. Subscription is re-triggered if there are 3
// consecutive polling errors

import EventEmitter from "events";

import { changes } from "./changeLog";
import { create, update } from "./subscription";
import { discover, poll } from "./syncService";

export interface SubscriptionConfig {
    id: string;
    type: "asset" | "repository";
    directoryLevel?: "self" | "shallow";
    includeResources?: Record<"reltype", string>[];
}

export interface EventMap {
    changedAsset: [string],
    reviewListChanged: [string],
    changedAll: [],
}

export type Assets = Record<string, SubscriptionConfig>;

export type SubscriptionState = {
    subscribed: false,
    timeLastAttempt: number | undefined,
} | {
    subscribed: true,
    id: string,
    assets: Assets,
    expires: number,
    url: string,
    longPollState: LongPollState,
    changeLogState: ChangeLogState,
};

export type LongPollState = {
    longPollUrlTemplate: string
} | {
    nextUrl: string,
}

export type ChangeLogState = {
    changeLogUrlTemplate: string
} | {
    nextUrl: string,
}

type RetriableMethod = 'subscribe' | 'longPoll' | 'update';

const storageKey = "onsClientState";
const timeBackoffSubscribeMs = 10_000;
const timeBackoffPollMs = 1_000;
const timeBackofUpdateMs = 1_000;
const timePollMs = 15_000;
const maxRetry = 3;

export class OnsClient extends EventEmitter<EventMap>{
    public accessToken: string | undefined;

    private deviceId = window.crypto.randomUUID();
    private pendingAssets: Map<string, SubscriptionConfig> = new Map();
    private inFlightAssets: Array<SubscriptionConfig> | undefined;

    private isSubscribing: boolean = false;
    private isPolling: boolean = false;
    private isUpdating: boolean = false;

    private needsUpdate: boolean = false;
    private pending: Partial<Record<RetriableMethod, Timer>> = {};

    private failCtrSubscribe: number = 0;
    private failCtrPoll: number = 0;
    private tripPollRetry = false;

    // @ts-ignore
    private _state: SubscriptionState;
    private get state(): SubscriptionState {
        return this._state;
    }
    private set state(x: SubscriptionState) {
        this._state = x;
        try {
            window.sessionStorage.setItem(storageKey, JSON.stringify(x));
        } catch (err) {
            console.debug('ons/setState - fail', err);
        }
    }
    constructor() {
        super();
        let savedState;
        try {
            savedState = window.sessionStorage.getItem(storageKey);
        } catch(err) {
            console.debug('ons/init - session storage error, disabling', err);
        }
        if (typeof savedState === 'string') {
            try {
                this.state = JSON.parse(savedState);
                console.debug('ons/init - local state read');
            } catch (err) {
                console.debug('ons/init - local state error', err);
                savedState = undefined;
            }
        }
        if (!savedState) {
            this.state = {
                subscribed: false,
                timeLastAttempt: undefined,
            };
        }
        console.debug('ons/init - ok', this.state);
    }

    watchAsset(config: SubscriptionConfig) {
        this.pendingAssets.set(config.id, config);
        if (!this.accessToken) return;
        if (!this.state.subscribed) {
            this.subscribe();
        } else {
            this.scheduleIfNeeded('longPoll', 0);
            this.scheduleIfNeeded('update', timeBackofUpdateMs);
        }
    }

    private async subscribe() {
        if (this.state.subscribed) {
            console.debug('ons/subscribe - already subscribed');
            return;
        }
        // this is when a new asset is added while subscribing
        if (this.isSubscribing) {
            console.debug('ons/subscribe - busy');
            this.needsUpdate = true;
            return;
        }
        if (this.pending?.subscribe) {
            console.debug('ons/subscribe - pending');
            return;
        }

        if (!this.accessToken) {
            console.debug('ons/subsribe - no-token');
            // this will get re-invoked when access token is set
            return
        }

        if (this.state.timeLastAttempt) {
            const elapsed = Date.now() - this.state.timeLastAttempt;
            const remaining = timeBackoffSubscribeMs - elapsed;
            if (remaining > 0) {
                console.debug('ons/subsribe - throttling');
                this.scheduleIfNeeded('subscribe', remaining);
                return;
            }
        }

        this.state.timeLastAttempt = Date.now();

        // Note: set objects immediately after the flag, otherwise
        // pendingAssets can lose subs
        this.isSubscribing = true;
        this.inFlightAssets = [...this.pendingAssets.values()];

        try {
            const endpoints = await discover(this.accessToken);
            const subscription = await create(endpoints.subscriptionCreateUrl, this.inFlightAssets, this.deviceId, this.accessToken);
            this.state = {
                subscribed: true,
                longPollState: {
                    longPollUrlTemplate: endpoints.longPollUrlTemplate,
                },
                changeLogState: {
                    changeLogUrlTemplate: endpoints.changeLogUrlTemplate,
                },
                ...subscription,
            }
            this.failCtrSubscribe = 0;
            console.debug(
                'ons/subscribe - ok',
                this.state.id,
                ' expiring ',
                new Date(this.state.expires)
            );
            this.scheduleIfNeeded('longPoll', 0);
        } catch (err) {
            if (!this.state.subscribed) {
                console.warn('ons/subscribe fail', err);
                this.handleFailure();
            } else {
                console.error('ons/subscribe - unexpected error, but subscribed', err);
            }
        } finally {
            this.isSubscribing = false;
            if (this.state.subscribed) {
                for (const asset of this.inFlightAssets) {
                    if (this.pendingAssets.has(asset.id)) {
                        this.pendingAssets.delete(asset.id);
                    }
                }
                // do we need to schedule an update?
                if (this.needsUpdate) setTimeout(() => {
                    this.needsUpdate = false;
                    this.update();
                }, 0);
            } else {
                // we coudn't create a sub, so inFlightAssets are
                // still pending
                this.inFlightAssets = undefined;
            }
        }
    }

    private async longPoll() {
        if (this.isPolling) {
            console.debug('ons/poll - busy');
            return;
        }
        if (this.hasExpired()) {
            console.debug('ons/poll - expired');
            return;
        }
        if (!this.state.subscribed) {
            console.debug('ons/poll - no-subscription');
            return;
        }
        if (this.pending?.longPoll) {
            console.debug('ons/poll - pending');
            return;
        }
        if (!this.accessToken) {
            console.debug('ons/poll - no-token');
            return;
        }
        try {
            this.isPolling = true;

            let url: string;
            if ('nextUrl' in this.state.longPollState) {
                url = this.state.longPollState.nextUrl;
            } else {
                const templateUrl = this.state.longPollState.longPollUrlTemplate.split('{')[0];
                url = `${templateUrl}?subscriptionId=${this.state.id}&includeJournal=false`;
            }
            const res = await poll(url, this.accessToken);
            this.state.longPollState = {
                nextUrl: res.nextUrl,
            }
            await this.handleEvent();
            this.scheduleIfNeeded('longPoll', 0);
            this.failCtrPoll = 0;
            this.tripPollRetry = false;
        } catch (err) {
            const msg = 'ons/subscribe/poll - longpoll fail';
            this.handleFailure();
            console.error(msg, err);
        } finally {
            this.isPolling = false;
        }
    }

    private async handleEvent() {
        if (!this.accessToken) {
            console.debug('ons/handleEvent - no-token');
            return;
        }
        if (!this.state.subscribed) {
            console.debug('ons/handleEvent - no-subscription');
            return;
        }
        try {
            // I am going to regret this instead of using a URL builder
            const template = 'nextUrl' in this.state.changeLogState
                ? this.state.changeLogState.nextUrl.split('{')[0] + '&'
                : this.state.changeLogState.changeLogUrlTemplate.split('{')[0] + '?';

            const url = `${template}subscriptionId=${this.state.id}`;

            const res = await changes(this.state.id, url, this.accessToken);
            // store next url
            this.state.changeLogState = {
                nextUrl: res.nextUrl,
            }

            if (res.changes) {
                for (const change of res.changes) {
                    const directoryId = change?.["ons:subscribedDirectoryAssetId"];
                    const assetId = change["repo:assetId"];
                    const event =  directoryId ? "reviewListChanged" : "changedAsset";
                    console.debug(`ons/handleEvent - change ${event}`);
                    this.emit(event, assetId);
                }
            }
        } catch (err) {
            const msg = 'ons/handleEvent - error-refresh';
            // this is caught and handled by longPoll()
            throw new Error(msg);
        }
    }

    private async update() {
        if (this.pending?.update) {
            console.debug('ons/update - pending');
            return;
        }
        if (this.isUpdating) {
            // only run 1 at a time
            console.debug('ons/update - already-running');
            return;
        }
        if (!this.accessToken) {
            console.debug('ons/update - no-token');
            return
        }
        // call to subscribe will pick it up, do nothing
        if (!this.state.subscribed && !this.isSubscribing) {
            console.debug('ons/update - no-op');
            return;
        }
        // in-flight subscription, let subscribe() know to run us finally
        if (!this.state.subscribed) {
            this.needsUpdate = true;
            return;
        }

        const subscribedIds = new Set();
        Object.keys(this.state.assets).forEach(id => subscribedIds.add(id));

        this.isUpdating = true;
        try {
            const patchAssetIds = [...this.pendingAssets.keys()].filter(id => !subscribedIds.has(id));
            if (patchAssetIds.length === 0) {
                console.debug('ons/update - no-op');
                return
            }
            const objects = patchAssetIds.map(id => this.pendingAssets.get(id) as SubscriptionConfig);

            this.state = {
                ...this.state,
                ...await update(this.state.url, objects, this.accessToken),
            }
            console.debug('ons/update - ok');
        } catch (err) {
            console.warn('ons/update - error', err);
            // TODO retry just an update
            this.pendingAssets
            this.hasExpired(true);
        } finally {
            for (const id of Object.keys(this.state.assets)) {
                if (this.pendingAssets.has(id)) {
                    this.pendingAssets.delete(id);
                }
            }
            this.isUpdating = false;
            if (this.pendingAssets.size > 0) {
                this.scheduleIfNeeded('update', timeBackofUpdateMs);
            }
        }
    }

    private scheduleIfNeeded(name: RetriableMethod, timeout: number) {
        if (!(name in this.pending) || this.pending[name] === undefined) {
            this.pending[name] = setTimeout(() => {
                this.pending[name] = undefined;
                this[name]();
            }, timeout);
        }
    }

    private handleFailure() {
        if (this.state.subscribed) {
            this.failCtrPoll++;
        } else {
            this.failCtrSubscribe++;
        }
        // if subscription creation fails repeatedly, give up

        // if polls are failing, retry making a subscription and if it
        // still fails, give up

        if (this.failCtrSubscribe > maxRetry || (this.tripPollRetry && this.failCtrPoll > maxRetry)) {
            // give up and just refresh the UI at some interval
            console.error('ons/handleFailure - give-up');
            this.giveUp();
        } else if (this.failCtrPoll > maxRetry) {
            this.tripPollRetry = true;
            this.hasExpired(true)
            this.scheduleIfNeeded('subscribe', timeBackoffSubscribeMs);
        } else {
            this.state.subscribed
                ? this.scheduleIfNeeded('longPoll', timeBackoffPollMs)
                : this.scheduleIfNeeded('subscribe', timeBackoffSubscribeMs);
        }
    }

    private giveUp() {
        setTimeout(() => {
            this.emit("changedAll");
            this.giveUp()
        }, timePollMs);
    }

    private hasExpired(force: boolean = false): boolean {
        const now = Date.now();
        if (force || (this.state.subscribed && this.state.expires && now > this.state.expires)) {
            this.state = {
                subscribed: false,
                timeLastAttempt: undefined,
            }
            const msg = force ? 'forced' : 'expired';
            console.debug(`ons/maybeExpire - ${msg}`);
            this.scheduleIfNeeded('subscribe', timeBackoffSubscribeMs);
            return true;
        }
        return false;
    }
}
