/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Animation } from "@babylonjs/core/Animations/animation";
import { GizmoManager } from "@babylonjs/core/Gizmos/gizmoManager";
import "@babylonjs/core/Shaders/glowBlurPostProcess.fragment";
import "@babylonjs/core/Shaders/pass.fragment";
import "@babylonjs/core/Rendering/outlineRenderer";
import { HighlightLayer } from "@babylonjs/core/Layers/highlightLayer";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Quaternion, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering";
import { Scene } from "@babylonjs/core/scene";
import { Sprite } from "@babylonjs/core/Sprites/sprite";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import EventEmitter from "events";

import {
    EnvironmentBuilder,
    envCameraLimitsMap,
    type Environment,
} from "@src/lib/babylon/EnvironmentBuilder";
import { PinningSession } from "@src/lib/babylon/PinningSession";
import { scaleSprite } from "@src/util/BabylonGUIUtils";
import type {
    Quaternion as NetworkQuaternion,
    Vector3 as NetworkVector3,
} from "@src/util/NetworkDataUtils";
import {
    createAutoResetOutlineAnimation,
    renderAutoResetBase,
    renderAutoResetCylinder,
    renderAutoResetOutline,
    renderResetButton,
} from "@src/util/ObjectManagerUtils";
import { ObjectMode } from "@src/util/PanelUtils";

const MIN_SIZE_DISTANCE = 13;
const MAX_SIZE_DISTANCE = 0.5;
const MIN_SIZE = 1.2;
const MAX_SIZE = 0.1;

const HOLD_START_INDEX = 0;
const HOLD_END_INDEX = 31;
const DEFAULT_INDEX = 32;
const DISABLED_INDEX = 33;
const HOVER_INDEX = 34;

const PERSONAL_HIGHLIGHT_COLOR = "#1379F3";

interface ObjectManagerEvents {
    isAssetLocked: [isLocked: boolean];
}

export class ObjectManager extends EventEmitter<ObjectManagerEvents> {
    private scene: Scene;

    private pinningSession: PinningSession | undefined;
    private envBuilder: EnvironmentBuilder;

    gizmoManager: GizmoManager;
    isGizmoEnabled: boolean = false;

    metadataRootMesh: AbstractMesh | null;
    playerRootMesh: AbstractMesh | null;

    autoResetEnabled: boolean = false;
    private autoResetVisualEnabled: boolean = false;
    resetSprite: Sprite | undefined;
    isResetDisabled: boolean = false;
    isResetVisible: boolean = false;

    private envBounds: { min: Vector3; max: Vector3; minEnvCollision?: Vector3 } | undefined;
    private pedestalBounds: { min: Vector3; max: Vector3 } | undefined;

    previousPosition: Vector3 = Vector3.Zero();
    previousRotation: Vector3 = Vector3.Zero();

    private highlightLayer: HighlightLayer;

    private resetTimer: Timer | undefined;
    private isLaserPointerEnabled: boolean = false;
    private autoResetOutline: Mesh | undefined;
    private autoResetAnimations: Animation[] | undefined;
    private autoResetMeshes: Mesh[] | undefined;
    private autoResetRadius: number = 1;
    private prevIsResetVisible: boolean = false;

    isResetSpritePicked: boolean = false;
    isResetSpriteHovering: boolean = false;

    constructor(
        private viewer: AdobeViewer,
        pinningSession: PinningSession,
        envBuilder: EnvironmentBuilder,
    ) {
        super();
        this.scene = viewer.scene;

        this.pinningSession = pinningSession;
        this.envBuilder = envBuilder;

        this.metadataRootMesh = viewer.model;
        this.playerRootMesh = viewer.rootPlayerTransform;
        UtilityLayerRenderer._DefaultUtilityLayer = null;
        this.gizmoManager = new GizmoManager(this.scene);
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = true;

        this.highlightLayer = new HighlightLayer("highlightLayer", this.scene, {
            isStroke: true,
            renderingGroupId: 0,
            camera: this.viewer.camera,
        });

        this.highlightLayer.innerGlow = false;
        // this.pointerMeshMap = {};

        if (this.playerRootMesh) {
            this.gizmoManager.attachableMeshes = [this.playerRootMesh];

            const bounds = this.playerRootMesh.getHierarchyBoundingVectors();
            this.autoResetRadius =
                (Vector3.Distance(bounds.min, bounds.max) * 0.75) / 2;
            this.autoResetRadius = Math.min(
                2,
                Math.max(0.14, this.autoResetRadius),
            );
        }

        this.renderResetButton();
        this.renderResetVisual();
        this.disableAutoResetVisual();

        this.envBounds = 
            envCameraLimitsMap[
                this.envBuilder.environment.environmentModel as Environment
            ];

        const pedestalMesh = this.getPedestalMesh();
        if (pedestalMesh) {
            this.pedestalBounds =
                this.getPedestalMesh().getHierarchyBoundingVectors();
        }
    }

    enableAllGizmos() {
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = true;
        this.enableGizmos();
    }

    enablePositionGizmoOnly() {
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = false;
        this.enableGizmos();
    }

    enableRotationGizmoOnly() {
        this.gizmoManager.positionGizmoEnabled = false;
        this.gizmoManager.rotationGizmoEnabled = true;
        this.enableGizmos();
    }

    private enableGizmos() {
        if (!this.isGizmoEnabled) {
            this.gizmoManager.attachToMesh(this.playerRootMesh);
            this.highlightMesh();
            this.isGizmoEnabled = true;
            this.pinningSession?.togglePinsVisibility(false);
        }
    }

    disableGizmos() {
        if (this.isGizmoEnabled) {
            this.gizmoManager.attachToMesh(null);
            this.unhighlightMesh();
            this.isGizmoEnabled = false;
            this.pinningSession?.togglePinsVisibility(true);
        }
    }

    highlightMesh(color: string = PERSONAL_HIGHLIGHT_COLOR) {
        if (!this.metadataRootMesh) return;

        this.highlightLayer.addMesh(
            this.metadataRootMesh as Mesh,
            Color3.FromHexString(color),
        );

        const childMeshes = this.metadataRootMesh.getChildMeshes();
        childMeshes.forEach((mesh) => {
            this.highlightLayer.addMesh(
                mesh as Mesh,
                Color3.FromHexString(color),
            );

            if (mesh.name !== "__root__") {
                mesh.renderOverlay = true;
                mesh.overlayColor = Color3.FromHexString(color);
                mesh.overlayAlpha = 0.2;
            }
        });
    }

    unhighlightMesh() {
        if (!this.metadataRootMesh) return;

        this.highlightLayer.removeAllMeshes();

        const childMeshes = this.metadataRootMesh.getChildMeshes();
        childMeshes.forEach((mesh) => {
            mesh.renderOverlay = false;
        });
    }

    renderResetButton() {
        this.resetSprite = renderResetButton(
            this.scene,
            this.envBuilder.pedestal,
        );
        this.scaleButton();
    }

    startBorderAnimation() {
        this.resetSprite?.playAnimation(
            HOLD_START_INDEX,
            HOLD_END_INDEX,
            false,
            32,
        );
    }

    stopBorderAnimation() {
        if (this.resetSprite) {
            this.resetSprite.stopAnimation();
            this.resetSprite.cellIndex = DEFAULT_INDEX;
        }
    }

    scaleButton() {
        if (!this.resetSprite) {
            console.error("Button sprite not found");
            return;
        }

        scaleSprite(
            this.resetSprite,
            MIN_SIZE_DISTANCE,
            MAX_SIZE_DISTANCE,
            MIN_SIZE,
            MAX_SIZE,
            this.scene,
        );
    }

    enableAutoResetVisual() {
        if (this.autoResetAnimations && this.autoResetMeshes) {
            this.autoResetVisualEnabled = true;
            this.autoResetMeshes.forEach((mesh) => {
                mesh.visibility = 1;
            });
            this.scene.beginDirectAnimation(
                this.autoResetOutline,
                this.autoResetAnimations,
                0,
                60,
                true,
            );
        }
    }

    disableAutoResetVisual() {
        if (this.autoResetAnimations && this.autoResetMeshes) {
            this.autoResetVisualEnabled = false;
            this.autoResetMeshes.forEach((mesh) => {
                mesh.visibility = 0;
            });
            this.scene.stopAnimation(this.autoResetOutline);
        }
    }

    renderResetVisual() {
        const cylinder = renderAutoResetCylinder(
            this.autoResetRadius,
            this.envBuilder.pedestal,
        );
        const base = renderAutoResetBase(
            this.autoResetRadius,
            this.envBuilder.pedestal,
        );
        const outline = renderAutoResetOutline(
            this.autoResetRadius,
            this.envBuilder.pedestal,
        );
        this.autoResetMeshes = [cylinder, base, outline];
        this.autoResetOutline = outline;
        this.autoResetAnimations = createAutoResetOutlineAnimation();
    }

    autoReset(sendAssetData: () => void, onResetComplete: () => void) {
        if (!this.playerRootMesh) return;
        const distanceFromOrigin = Vector3.Distance(
            Vector3.Zero(),
            this.playerRootMesh.position,
        );

        if (distanceFromOrigin > this.autoResetRadius) {
            this.autoResetEnabled = true;
            if (!this.autoResetVisualEnabled) {
                this.enableAutoResetVisual();
            }
        } else {
            if (this.autoResetEnabled) {
                this.disableAutoResetVisual();
                this.resetModelTransform(sendAssetData, onResetComplete);
            }
        }
    }

    handlePointerMove() {
        if (!this.resetSprite) {
            console.error("Button sprite not found");
            return;
        }
        if (!this.isResetVisible || this.isResetDisabled) {
            window.document.body.style.cursor = "";
            return;
        }

        const pickResult = this.scene.pickSprite(
            this.scene.pointerX,
            this.scene.pointerY,
        );

        const isCurrentlyPicked =
            pickResult && pickResult.pickedSprite === this.resetSprite;

        if (isCurrentlyPicked && !this.isResetSpriteHovering) {
            window.document.body.style.cursor = "pointer";
            this.resetSprite.cellIndex = HOVER_INDEX;
            this.isResetSpriteHovering = true;
        } else if (!isCurrentlyPicked && this.isResetSpriteHovering) {
            window.document.body.style.cursor = "";
            this.resetSprite.cellIndex = DEFAULT_INDEX;
            this.isResetSpriteHovering = false;
        }
    }

    handlePickResetStart(
        requestAssetControl: () => void,
        sendAssetData: () => void,
        onResetComplete: () => void,
    ) {
        const pickSpriteResult = this.scene.pickSprite(
            this.scene.pointerX,
            this.scene.pointerY,
        );
        if (
            pickSpriteResult &&
            pickSpriteResult.pickedSprite === this.resetSprite
        ) {
            if (!this.isResetVisible || this.isResetDisabled) return;
            this.startBorderAnimation();
            this.isResetSpritePicked = true;
            this.resetTimer = setTimeout(() => {
                requestAssetControl();
                this.resetModelTransform(sendAssetData, onResetComplete);
            }, 1000);
        }
    }

    handlePickResetStop() {
        if (this.isResetSpritePicked) {
            this.stopBorderAnimation();
            clearTimeout(this.resetTimer);
            this.isResetSpritePicked = false;
        }
    }

    handlePickMesh(selectionMode: ObjectMode) {
        // block mesh highlighting while in pinning mode
        if (this.pinningSession?.inPinningMode) return;
        // block mesh highlighting while laser pointer is enabled
        if (this.isLaserPointerEnabled) return;

        // block mesh highlighting when selecting pin
        const pickSprite = this.scene.pickSprite(
            this.scene.pointerX,
            this.scene.pointerY,
        );
        if (pickSprite && pickSprite.hit) return;

        const pickMeshResult = this.scene.pick(
            this.scene.pointerX,
            this.scene.pointerY,
        );

        if (
            pickMeshResult &&
            this.metadataRootMesh &&
            pickMeshResult.pickedMesh?.isDescendantOf(this.metadataRootMesh)
        ) {
            this.switchGizmoSelectionMode(selectionMode);
        } else {
            this.disableGizmos();
        }
    }

    switchGizmoSelectionMode(selectionMode: ObjectMode) {
        if (selectionMode === "select") {
            this.enableAllGizmos();
        } else if (selectionMode === "move") {
            this.enablePositionGizmoOnly();
        } else if (selectionMode === "rotate") {
            this.enableRotationGizmoOnly();
        }
    }

    toggleResetVisibility(isVisible: boolean) {
        if (this.isResetVisible === isVisible) return;
        if (!this.resetSprite) return;
        this.resetSprite.isVisible = isVisible;
        this.isResetVisible = isVisible;
        if (isVisible) {
            this.resetSprite.manager.isPickable = true;
            this.resetSprite.isPickable = true;
            this.scaleButton();
        } else {
            this.resetSprite.manager.isPickable = false;
            this.resetSprite.isPickable = false;
        }
    }

    toggleResetDisabled(isDisabled: boolean) {
        if (this.isResetDisabled === isDisabled) return;
        if (!this.resetSprite) {
            console.error("Button sprite not found");
            return;
        }
        this.isResetDisabled = isDisabled;
        if (this.isResetDisabled) {
            this.resetSprite.cellIndex = DISABLED_INDEX;
        } else {
            this.resetSprite.cellIndex = DEFAULT_INDEX;
        }
    }

    resetModelTransform(
        sendAssetData: () => void,
        onResetComplete: () => void,
    ) {
        if (!this.playerRootMesh) return;

        // animate lerping
        const positionAnimation = new Animation(
            "resetPosAnimation",
            "position",
            30,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const keyFramesPos = [];
        keyFramesPos.push({
            frame: 0,
            value: this.playerRootMesh.position.clone(),
        });
        keyFramesPos.push({
            frame: 15,
            value: Vector3.Zero(),
        });

        positionAnimation.setKeys(keyFramesPos);

        const rotationAnimation = new Animation(
            "resetRotAnimation",
            "rotation",
            30,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const keyFramesRot = [];
        keyFramesRot.push({
            frame: 0,
            value: this.playerRootMesh.rotation.clone(),
        });
        keyFramesRot.push({
            frame: 15,
            value: Vector3.Zero(),
        });

        rotationAnimation.setKeys(keyFramesRot);

        this.scene.onAfterAnimationsObservable.add(sendAssetData);
        this.toggleShadows(false);

        this.scene.beginDirectAnimation(
            this.playerRootMesh,
            [positionAnimation, rotationAnimation],
            0,
            15,
            false,
            undefined,
            () => {
                onResetComplete();
                sendAssetData();
                this.scene.onAfterAnimationsObservable.removeCallback(
                    sendAssetData,
                );
                this.toggleShadows(true);
            },
        );

        this.autoResetEnabled = false;
        this.toggleResetVisibility(false);
    }

    getPosition() {
        if (!this.playerRootMesh) return;
        return this.playerRootMesh.position.asArray() as NetworkVector3;
    }

    getRotation() {
        if (!this.playerRootMesh) return;
        return Quaternion.FromEulerVector(
            this.playerRootMesh.rotation,
        ).asArray() as NetworkQuaternion;
    }

    updatePosition(position: NetworkVector3) {
        if (!this.playerRootMesh) return;
        this.playerRootMesh.position.x = position[0];
        this.playerRootMesh.position.y = position[1];
        this.playerRootMesh.position.z = position[2];
    }

    updateRotation(rotation: NetworkQuaternion) {
        if (!this.playerRootMesh) return;
        const eulerRotation = Quaternion.FromArray(rotation).toEulerAngles();
        this.playerRootMesh.rotation = eulerRotation;
    }

    getEnvironmentMesh() {
        const envNode = this.scene
            .getNodes()
            .find((node) => node.name.includes("reviewer_environment"));
        if (envNode?.parent) {
            return envNode.parent as AbstractMesh;
        } else {
            return envNode as AbstractMesh;
        }
    }

    getPedestalMesh() {
        const pedestalNode = this.scene
            .getNodes()
            .find((node) => node.name.includes("pedestal"));
        if (pedestalNode?.parent) {
            return pedestalNode.parent as AbstractMesh;
        } else {
            return pedestalNode as AbstractMesh;
        }
    }

    getMeshWorldPosition() {
        return new Vector3(
            (this.metadataRootMesh?.position.x || 0) +
                (this.playerRootMesh?.position.x || 0),
            (this.metadataRootMesh?.position.y || 0) +
                (this.playerRootMesh?.position.y || 0),
            (this.metadataRootMesh?.position.z || 0) +
                (this.playerRootMesh?.position.z || 0),
        );
    }

    getMeshBounds() {
        return this.playerRootMesh?.getHierarchyBoundingVectors();
    }

    /**
     *
     * @returns true if the asset is intersecting the environment
     */
    isCollidingEnv() {
        const bounds = this.getMeshBounds();
        if (!bounds || !bounds.min || !bounds.max || !this.envBounds)
            return false;

        const minEnvBounds = this.envBounds.minEnvCollision ? this.envBounds.minEnvCollision : this.envBounds.min
        if (this.getEnvironmentMesh().name.includes("grid")) {
            return bounds.min.y < minEnvBounds.y;
        } else {
            return !(
                bounds.min.x >= minEnvBounds.x &&
                bounds.max.x <= this.envBounds.max.x &&
                bounds.min.y >= minEnvBounds.y &&
                bounds.max.y <= this.envBounds.max.y &&
                bounds.min.z >= minEnvBounds.z &&
                bounds.max.z <= this.envBounds.max.z
            );
        }
    }

    activateLaserPointerMode() {
        this.isLaserPointerEnabled = true;
        this.prevIsResetVisible = this.isResetVisible;
        if (this.prevIsResetVisible) {
            this.toggleResetVisibility(false);
        }
        this.disableGizmos();
    }

    deactivateLaserPointerMode() {
        this.isLaserPointerEnabled = false;
        if (this.prevIsResetVisible) {
            this.toggleResetVisibility(true);
        }
    }

    isDefaultTransform(
        position?: NetworkVector3,
        rotation?: NetworkQuaternion,
    ) {
        const pos = position ?? this.getPosition();
        const rot = rotation ?? this.getRotation();
        if (!pos || !rot) {
            throw new Error(
                "isDefaultTransform(): No position or rotation value found",
            );
        }
        return (
            pos.every((val) => val === 0) &&
            rot.slice(0, 2).every((val) => val === 0)
        );
    }

    emitIsAssetLocked(isLocked: boolean) {
        this.emit("isAssetLocked", isLocked);
    }

    releaseGizmo() {
        // force release of gizmo if not already released (for case when asset is auto reset)
        this.gizmoManager.releaseDrag();
        // releaseDrag() will prevent the user from using the gizmos anymore but the gizmo still
        // remains selected, so simulate a pointer up event to deselect the gizmo
        this.scene.simulatePointerUp(
            this.scene.pick(this.scene.pointerX, this.scene.pointerY),
        );
    }

    toggleShadows(on: boolean) {
        const { shadowPipeline } = this.viewer;
        if (!shadowPipeline) return;
        if (on) {
            this.highlightLayer.outerGlow = false;
            shadowPipeline.resetAccumulation();
            this.viewer.updateIblShadows();
            shadowPipeline.toggleShadow(true);
            // Wait 2 frames to turn back on highlighting because otherwise there is a big flash
            requestAnimationFrame(() =>
                requestAnimationFrame(
                    () => (this.highlightLayer.outerGlow = true),
                ),
            );
        } else {
            shadowPipeline.toggleShadow(false);
        }
    }
}
