/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Quaternion, Vector3 } from "@babylonjs/core/Maths/math.vector";

import type { SerializedPin, SerializedVector3 } from "../PinDataTypes";
import type { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import type { Scene } from "@babylonjs/core/scene";

export const getEmptySerializedVector = (): SerializedVector3 => [0, 0, 0];

export function serializeVec3(vec3: Vector3) {
  const serialVec3: SerializedVector3 = [0, 0, 0];
  vec3.toArray(serialVec3);
  return serialVec3;
}

export function createPinData(
  pinData: Partial<SerializedPin> & { id: string }
) {
  const {
    id,
    cameraPosition,
    pinWorldPosition,
    modelPath,
    pinModelLocalPosition,
    pinUnscaledModelRootPosition,
    pinNormal,
  } = pinData;
  const emptyVector = getEmptySerializedVector();
  return {
    id,
    cameraPosition: cameraPosition || emptyVector,
    pinWorldPosition: pinWorldPosition || emptyVector,
    modelPath: modelPath || ["root"],
    pinModelLocalPosition: pinModelLocalPosition || { ...emptyVector },
    pinUnscaledModelRootPosition: pinUnscaledModelRootPosition || {
      ...emptyVector,
    },
    pinNormal: pinNormal || { ...emptyVector },
  };
}

export function getSerializedPinDataFromScene(
  hitMesh: AbstractMesh,
  hitPointWorld: Vector3,
  hitNormal: Vector3,
  modelRootMesh: AbstractMesh | undefined
): Omit<SerializedPin, "id" | "cameraPosition"> {
  const modelPath = [hitMesh.name];
  let parent = hitMesh.parent;
  while (parent) {
    modelPath.push(parent.name);
    parent = parent.parent;
  }
  modelPath.push("root");
  modelPath.reverse();

  // Use the world matrix of the mesh, so the local position is relative to the
  // mesh's transform in space
  const meshScale = new Vector3();
  const meshRotation = new Quaternion();
  const meshPosition = new Vector3();
  hitMesh.getWorldMatrix().decompose(meshScale, meshRotation, meshPosition);
  const localPosition = hitPointWorld
    .subtract(meshPosition)
    .applyRotationQuaternion(meshRotation.invert())
    .scale(1 / meshScale.x);

  let unscaledModelRootPosition = undefined;
  if (modelRootMesh) {
    const rootMeshScale = new Vector3();
    const rootMeshRotation = new Quaternion();
    const rootMeshPosition = new Vector3();
    modelRootMesh
      .getWorldMatrix()
      .decompose(rootMeshScale, rootMeshRotation, rootMeshPosition);
    unscaledModelRootPosition = hitPointWorld
      .subtract(rootMeshPosition)
      .applyRotationQuaternion(rootMeshRotation.invert())
      .scale(1 / rootMeshScale.x);
  }
  return {
    modelPath: modelPath,
    pinWorldPosition: serializeVec3(hitPointWorld),
    pinModelLocalPosition: serializeVec3(localPosition),
    pinUnscaledModelRootPosition: unscaledModelRootPosition
      ? serializeVec3(unscaledModelRootPosition)
      : serializeVec3(localPosition),
    pinNormal: serializeVec3(hitNormal),
  };
}

export function getMeshByPath(modelPath: string[], scene: Scene) {
  if (modelPath.length > 1) {
    let pathIndex = 1;
    let child = scene.meshes.find((mesh) => mesh.name === modelPath[pathIndex]);
    if (child) {
      pathIndex++;
      while (modelPath[pathIndex]) {
        if (child) {
          child = child
            .getChildren()
            .find((mesh) => mesh.name === modelPath[pathIndex]) as AbstractMesh;
        }
        pathIndex++;
      }
      if (child) {
        return child;
      }
    }
    // try looking up by modelName
    return scene.getMeshByName(modelPath[modelPath.length - 1]);
  }
  return;
}
