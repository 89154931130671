/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { usePostMessageContext } from "@src/contexts/PostMessageContext";
import { useEffect } from "react";

export function useTutorialEnabled() {
    const { postMessageBusApi } = usePostMessageContext();
    const tutorialEnabledKey = "tutorialEnabled";

    useEffect(() => {
        if (!postMessageBusApi) return;
        postMessageBusApi.addEventListener("vr-ready", (data: any) => {
            const tutorialEnabled = sessionStorage.getItem(tutorialEnabledKey);

            // don't override existing tutorial flag, VR ready is sent multiple times
            if (tutorialEnabled === null) {
                console.log("setting tutorial enabled to", data.detail.tutorialEnabled);
                sessionStorage.setItem(tutorialEnabledKey, data.detail.tutorialEnabled);
            }
        });
    }, [postMessageBusApi]);

    function setTutorialEnabledFalse() {
        sessionStorage.setItem(tutorialEnabledKey, "false");
    }

    return {
        tutorialEnabled: sessionStorage.getItem(tutorialEnabledKey) === "true", setTutorialEnabledFalse
    };
}
