/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AbstractEngine } from "@babylonjs/core/Engines/abstractEngine";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Observable } from "@babylonjs/core/Misc/observable";
import { IblShadowsRenderPipeline } from "@babylonjs/core/Rendering/IBLShadows/iblShadowsRenderPipeline";
import {
    CanvasViewerOptions,
    EnvironmentOptions,
    LoadModelOptions,
    Viewer,
    ViewerOptions,
    createViewerForCanvas,
} from "@babylonjs/viewer";

import { ActivationTimer } from "../utils/ActivationTimer";

import type { ThinEngine } from "@babylonjs/core/Engines/thinEngine";


export type AdobeViewerOptions = ViewerOptions &
    EnvironmentOptions & {
        autoSuspendRenderingDelay?: number;
        useIblShadows?: boolean;
    };

export type AdobeCanvasViewerOptions = CanvasViewerOptions & AdobeViewerOptions;

export async function createAdobeViewerForCanvas(
    canvas: HTMLCanvasElement,
    options?: AdobeCanvasViewerOptions,
) {
    return await createViewerForCanvas(canvas, {
        ...options,
        viewerClass: AdobeViewer,
    });
}

let IblShadowsDisabled = false;

export class AdobeViewer extends Viewer {
    renderLoop: ActivationTimer;
    modelInitialized = false;
    __engine: AbstractEngine;
    plugins: Record<string, any> = {};

    constructor(engine: AbstractEngine, options?: AdobeViewerOptions) {
        super(engine, options);
        this.__engine = engine;

        this.engineDetection(engine as ThinEngine);

        let handler: any;

        this.renderLoop = new ActivationTimer(
            () => {
                if (handler) {
                    this.scene.onAfterRenderObservable.remove(handler);
                }
                handler = this.scene.onAfterRenderObservable.add(() => {
                    this._markSceneMutated();
                });
                this._markSceneMutated();
            },
            () => {
                this.scene.onAfterRenderObservable.remove(handler);
            },
            options?.autoSuspendRenderingDelay || 1000,
        );

        this.camera.onViewMatrixChangedObservable.add(() => {
            this.renderLoop.toggle();
        });

        if (options?.useIblShadows) {
            /***
            * IBL Shadows additional imports
            */
            Promise.all([
                import("@babylonjs/core/Rendering/geometryBufferRendererSceneComponent"),
                import("@babylonjs/core/Engines/Extensions/engine.multiRender"),
                import("@babylonjs/core/Rendering/iblCdfGeneratorSceneComponent"),
                import("@babylonjs/core/PostProcesses/RenderPipeline/postProcessRenderPipelineManagerSceneComponent"),
                import("@babylonjs/core/Shaders/copyTexture3DLayerToTexture.fragment"),
                import("@babylonjs/core/ShadersWGSL/copyTexture3DLayerToTexture.fragment"),
                import("@babylonjs/core/Shaders/iblScaledLuminance.fragment"),
                import("@babylonjs/core/ShadersWGSL/iblScaledLuminance.fragment"),
            ]).then(() => {
                this.enabledIblShadows();
            })

        }

        this.renderLoop.toggle();
    }

    engineDetection(engine: ThinEngine) {
        const info = engine.getGlInfo();
        console.warn("GL Info", info);
        if (info.renderer.match(/SwiftShader/)) {
            IblShadowsDisabled = true;
        }
    }

    onModelLoaded = new Observable<void>();

    currentModelSource: string | File | ArrayBufferView | undefined;

    async loadModel(
        source: string | File | ArrayBufferView,
        options?: LoadModelOptions,
        abortSignal?: AbortSignal,
    ): Promise<void> {
        if (!source) return;
        this.currentModelSource = source;

        await super.loadModel(source, options, abortSignal);
        this.scene.getNodeByName("defaultLight")?.dispose();
        this.attachTransformWrapper();
        if (!this.modelInitialized) {
            this.modelInitialized = true;
        }
        this.onModelLoaded.notifyObservers();
    }

    private attachTransformWrapper() {
        const root = this.scene.getMeshByName("__root__");
        if (root) {
            const metadataRoot = new Mesh("rootModelMesh", this.scene);
            const playerRoot = new Mesh("rootPlayerTransform", this.scene);
            playerRoot.addChild(root);
            metadataRoot.addChild(playerRoot);
        }
    }

    get canvas() {
        return this.engine.getRenderingCanvas();
    }

    get engine() {
        return this.__engine;
    }

    get scene() {
        return this._scene;
    }

    get camera() {
        return this._camera;
    }

    get model() {
        return this.scene.getMeshByName("rootModelMesh");
    }

    get rootPlayerTransform() {
        return this.scene.getMeshByName("rootPlayerTransform");
    }

    get modelScale() {
        const scaling = this.model?.scaling;
        return scaling ? Math.max(...scaling.asArray()) : 1;
    }

    shadowPipeline: IblShadowsRenderPipeline | undefined;

    enabledIblShadows() {
        if (IblShadowsDisabled) return;
        if (this.camera) {
            this.shadowPipeline = new IblShadowsRenderPipeline(
                "ibl shadows", // The name of the pipeline
                this.scene, // The scene to which the pipeline belongs
                {
                    resolutionExp: 5,
                    sampleDirections: 8,
                    ssShadowsEnabled: true,
                    shadowRemanence: 0.8,
                    triPlanarVoxelization: true,
                    shadowOpacity: 0.6,
                }, // The options for the pipeline
                [this.camera], // The list of cameras to attach the pipeline to
            );

            const appendCasters = () => {
                if (!this.shadowPipeline) return;
                const getChildMeshes = this.model?.getChildMeshes();
                if (getChildMeshes && this.shadowPipeline) {
                    this.shadowPipeline.addShadowCastingMesh(
                        getChildMeshes as Mesh[],
                    );
                }

                this.updateIblShadows();
            };

            this.onModelLoaded.add(appendCasters);

            if (this.modelInitialized) {
                appendCasters();
            }

        }
    }

    updateIblShadows() {
        if (this.shadowPipeline) {
            this.shadowPipeline.updateSceneBounds();
            this.shadowPipeline.updateVoxelization();
        }
    }
}
