/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import type { IconProps } from "@adobe/react-spectrum";

export const CameraFrame = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2H16V6H17V1.5C17 1.22382 16.7761 1 16.5 1H12V2Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 6V2H6V1H1.5C1.22388 1 1 1.22382 1 1.5V6H2Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 16H2V12H1V16.5C1 16.7761 1.22388 17 1.5 17H6V16Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 12V16H12V17H16.5C16.7761 17 17 16.7761 17 16.5V12H16Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2H16V6H17V1.5C17 1.22382 16.7761 1 16.5 1H12V2Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 6V2H6V1H1.5C1.22388 1 1 1.22382 1 1.5V6H2Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 16H2V12H1V16.5C1 16.7761 1.22388 17 1.5 17H6V16Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 12V16H12V17H16.5C16.7761 17 17 16.7761 17 16.5V12H16Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.17213 3.22107C9.02963 3.14955 8.86156 3.15032 8.71972 3.22314L4.27148 5.50683C4.26721 5.50903 4.24731 5.52007 4.24023 5.52441C4.0913 5.61474 4 5.77685 4 5.95166V11.4038C4 11.5752 4.08776 11.7346 4.23255 11.8263L8.73242 14.6748C8.81396 14.7256 8.90674 14.752 9 14.752C9.08301 14.752 9.16602 14.7314 9.24121 14.6904C9.24615 14.6877 9.24817 14.6819 9.25299 14.679C9.25751 14.6764 9.26312 14.6776 9.26758 14.6748L13.7675 11.8263C13.9122 11.7346 14 11.5752 14 11.4038V5.95265C14 5.76354 13.8933 5.5906 13.7243 5.50577L9.17213 3.22107ZM8.95068 4.22851L12.4006 5.95934L9 7.72643L5.58966 5.95427L8.95068 4.22851ZM5 6.7749L8.5 8.59375V13.3437L5 11.1284V6.7749ZM13 11.1284L9.5 13.3437V8.59374L13 6.77489V11.1284Z"
                />
            </svg>
        </Icon>
    );
};
