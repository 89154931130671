/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useOverlayTriggerState } from "@react-stately/overlays";
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";

import { useHi5UserContext } from "./HI5UserProvider";
import {
    ONBOARDING_COACH_MARKS_SPECS,
    hasUserCompletedOnboarding,
    addUserHasCompletedOnboarding,
} from "@src/util/OnboardingUtils";

export type OnboardingContextValue = ReturnType<
    typeof useOnboardingContextValue
>;

export const OnboardingContext = createContext<OnboardingContextValue>(
    {} as OnboardingContextValue,
);

export function useOnboardingContext() {
    const context = useContext(OnboardingContext);
    if (!context) {
        throw new Error(
            "useOnboardingContext must be used within a OnboardingContext.Provider",
        );
    }
    return context;
}

function useOnboardingContextValue() {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [anchorElementRef, setAnchorElementRef] = useState<
        HTMLElement | null | undefined
    >();
    const { userId } = useHi5UserContext();

    useEffect(() => {
        if (userId && !hasUserCompletedOnboarding(userId)) {
            state.setOpen(true);
        }
    }, [userId]);

    const state = useOverlayTriggerState({});
    const totalStepCount = ONBOARDING_COACH_MARKS_SPECS.length;
    const currentStepSpec =
        currentStep < totalStepCount
            ? ONBOARDING_COACH_MARKS_SPECS[currentStep]
            : undefined;

    function resetHighlightStyles() {
        ONBOARDING_COACH_MARKS_SPECS.forEach((spec) => {
            spec.visualEffects?.forEach(({ elementId, effect }) => {
                const refElement = document.getElementById(
                    `Coachmark-${elementId}`,
                );
                if (refElement) {
                    refElement.classList.remove(effect);
                }
            });
        });
    }

    function startOnboarding() {
        resetHighlightStyles();
        state.setOpen(true);
        setCurrentStep(0);
    }

    function finishOnboarding() {
        resetHighlightStyles();
        state.setOpen(false);
        userId && addUserHasCompletedOnboarding(userId);
    }

    function waitForAnchorElementRender() {
        if (currentStepSpec) {
            const ref = document.getElementById(
                `Coachmark-${currentStepSpec.anchorElementId}`,
            );
            if (!ref) {
                const observer = new MutationObserver(() => {
                    const ref = document.getElementById(
                        `Coachmark-${currentStepSpec.anchorElementId}`,
                    );
                    if (ref != null) {
                        setAnchorElementRef(ref);
                        observer.disconnect();
                    }
                });
                observer.observe(document, {
                    attributes: false,
                    childList: true,
                    characterData: false,
                    subtree: true,
                });
            } else {
                setAnchorElementRef(ref);
            }
        }
    }

    useEffect(() => {
        if (currentStepSpec && anchorElementRef) {
            currentStepSpec.visualEffects?.forEach(({ elementId, effect }) => {
                const effectElement = document.getElementById(
                    `Coachmark-${elementId}`,
                );

                if (effectElement) {
                    effectElement.classList.add(effect);
                }
            });
        }
    }, [anchorElementRef]);

    useEffect(() => {
        if (state.isOpen) {
            waitForAnchorElementRender();
        }
    }, [state]);

    useEffect(() => {
        if (currentStep >= totalStepCount) {
            finishOnboarding();
        }

        resetHighlightStyles();
        waitForAnchorElementRender();
    }, [currentStep]);

    return {
        state,
        currentStep,
        setCurrentStep,
        totalStepCount,
        currentStepSpec,
        anchorElementRef,
        shouldForceSelectMenuOpen:
            state.isOpen &&
            currentStepSpec?.anchorElementId === "objectPanelButtons",
        shouldForceUserProfileMenuOpen:
            state.isOpen && currentStepSpec?.anchorElementId === "orientation",
        startOnboarding,
        finishOnboarding,
    };
}

export function OnboardingProvider({ children }: PropsWithChildren) {
    const onboardingContextValue = useOnboardingContextValue();

    return (
        <OnboardingContext.Provider value={onboardingContextValue}>
            {children}
        </OnboardingContext.Provider>
    );
}
