/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";

import { Pin, PinConfig, PinState } from "./Pin";

import type { Ray } from "@babylonjs/core/Culling/ray";
import type { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import type { Vector3 } from "@babylonjs/core/Maths/math.vector";
import type { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import type { Observer } from "@babylonjs/core/Misc/observable";
import type { Scene } from "@babylonjs/core/scene";
import type { Nullable } from "@babylonjs/core/types";

export interface Hit {
    point: Vector3;
    normal: Vector3;
    mesh: AbstractMesh;
}

export class NormalTracer {
    private viewer: AdobeViewer;
    private scene: Scene;
    private activePin?: Pin;
    private advancedTexture: AdvancedDynamicTexture;
    private temporaryPinImage: Image;
    private tempImageOffset: number;
    private observer: Nullable<Observer<PointerInfo>> = null;
    private rootMesh: Nullable<AbstractMesh>;
    tracing = false;
    hit?: Hit;
    tracingCallback?: (hit?: Hit) => void;

    pinOptions: PinConfig;

    constructor(
        viewer: AdobeViewer,
        pinOptions: PinConfig,
        temporaryPinUrl: string,
        pinScreenSize: number,
    ) {
        this.viewer = viewer;
        this.scene = viewer.scene;
        this.pinOptions = pinOptions;
        this.pinOptions = pinOptions;

        this.advancedTexture =
            AdvancedDynamicTexture.CreateFullscreenUI("temp-pin-layer");

        this.temporaryPinImage = new Image("temp-pin", temporaryPinUrl);
        this.temporaryPinImage.width = pinScreenSize + "px";
        this.temporaryPinImage.height = pinScreenSize + "px";
        this.temporaryPinImage.horizontalAlignment = 0;
        this.temporaryPinImage.verticalAlignment = 0;
        this.temporaryPinImage.isVisible = false;
        this.tempImageOffset = (-1 * pinScreenSize) / 2;

        this.advancedTexture.addControl(this.temporaryPinImage);

        this.rootMesh = this.viewer.model;
    }

    // Public utilities, trace and stop
    public trace(pin: Pin, cb: (hit?: Hit) => void) {
        if (this.tracing) {
            this.stop();
        }
        this.tracing = true;
        this.tracingCallback = cb;
        this.activePin = pin;
        this.activePin.isPickable = false;
        this.activePin.setVisible(false);
        this.temporaryPinImage.isVisible = false;
        this.observer = this.scene.onPointerObservable.add((e) =>
            this.onPointerEvent(e),
        );
    }

    public stop() {
        this.scene.onPointerObservable.remove(this.observer);
        this.observer = null;
        this.tracing = false;
        this.activePin = undefined;
        this.temporaryPinImage.isVisible = false;
        if (this.tracingCallback) {
            this.tracingCallback(this.hit);
        }
        this.viewer.renderLoop.deactivate();
    }

    // Private utilities
    private onPointerEvent(pointerInfo: PointerInfo) {
        switch (pointerInfo.type) {
            case PointerEventTypes.POINTERUP:
                this.stop();
                break;
            case PointerEventTypes.POINTERWHEEL:
            case PointerEventTypes.POINTERMOVE:
                if (pointerInfo.pickInfo?.ray) {
                    const hit = this.getHit(pointerInfo.pickInfo?.ray);
                    if (hit) {
                        if (this.activePin) {
                            this.temporaryPinImage.isVisible = false;
                            this.activePin.updatePinLocation(
                                hit.point,
                                hit.normal,
                            );
                            this.activePin.clampPinSize();
                            this.activePin.setVisible(true);
                            this.activePin.state = PinState.edit;
                        }
                        this.hit = hit;
                    } else {
                        if (this.activePin) {
                            this.activePin.setVisible(false);
                            this.temporaryPinImage.isVisible = true;
                            const { offsetX, offsetY } = pointerInfo.event;
                            const engine = this.scene.getEngine();
                            const hardwareScale =
                                engine.getHardwareScalingLevel();
                            this.temporaryPinImage.left =
                                (offsetX + this.tempImageOffset) /
                                hardwareScale;
                            this.temporaryPinImage.top =
                                (offsetY + this.tempImageOffset) /
                                hardwareScale;
                        }
                        this.hit = undefined;
                    }
                }
                this.viewer.renderLoop.toggle();
                break;
            default:
        }
    }

    private getHit(ray: Ray) {
        const pickInfo = this.scene.pickWithRay(ray);
        if (pickInfo?.hit && pickInfo.pickedPoint && pickInfo.pickedMesh) {
            // We generally want to use vertex normals, so that the normal is interpolated properly, instead of being
            // faceted. But some assets fail to provide normals in this case if the vertex data seems to not have normals,
            // so in that case, use the faceted normals
            const normal =
                pickInfo.getNormal(true, true) ??
                pickInfo.getNormal(true, false);
            if (!normal) {
                throw new Error("Failed to extract normal from pick info");
            }
            // checking if picked mesh is pedestal (or something other than the root mesh)
            if (
                this.rootMesh &&
                !pickInfo.pickedMesh.isDescendantOf(this.rootMesh)
            ) {
                return;
            }
            if (normal) {
                return {
                    point: pickInfo.pickedPoint,
                    mesh: pickInfo.pickedMesh,
                    normal,
                };
            }
        }
        return;
    }
}
