/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// Client for ACP Sync Service Discovery and Long Poll API
// https://developers.corp.adobe.com/ons/SyncService/api/syncServiceApiSwagger.json

import { ADOBE_IMS_CONFIG, ASSET_APIS } from "@src/config";

type Endpoints = {
    subscriptionCreateUrl: string,
    longPollUrlTemplate: string,
    changeLogUrlTemplate: string,
}

export async function discover(
    accessToken: string
): Promise<Endpoints> {
    const headers = {
        'x-api-key': ADOBE_IMS_CONFIG.client_id,
        'x-user-token': `Bearer ${accessToken}`,
    }
    const res = await fetch(`${ASSET_APIS.ss}/api/v1`, {
        headers: {
            ...headers,
            'accept': 'application/vnd.syncservice.v2.hal+json',
        }
    });
    if (!res.ok) {
        throw new Error('discovery-res-not-ok-' + res.statusText);
    }
    const body = await res.json();
    const subscriptionCreateUrl = body?._links?.['subscription/create']?.href;
    const longPollUrlTemplate = body?._links?.['notifications/longpoll']?.href;
    const changeLogUrlTemplate = body?._links?.['ons/changelog']?.href;

    if (!subscriptionCreateUrl || !longPollUrlTemplate || !changeLogUrlTemplate) {
        throw new Error('discovery-res-ok-but-unexpected-data');
    } else {
        return {
            subscriptionCreateUrl,
            longPollUrlTemplate,
            changeLogUrlTemplate,
        }
    }
}

type PollRes = {
    nextUrl: string,
};
export async function poll(
    url: string,
    accessToken: string,
): Promise<PollRes> {
    const headers = {
        'x-api-key': ADOBE_IMS_CONFIG.client_id,
        'x-user-token': `Bearer ${accessToken}`,
    }
    const res = await fetch(url, {
        headers: {
            ...headers,
            'x-poll-timeout': '160',
        },
    });
    if (!res.ok) {
        throw new Error('ons/poll - fetch res not ok' + res.statusText);
    }
    const body = await res.json();
    return {
        nextUrl: (body as any)._links.next.href as string,
    }
}
