/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Dialog, Heading, Content } from "@adobe/react-spectrum";

import { AboutContent, AboutHeader } from "@src/components/About";

export function AboutDialog() {
    return (
        <Dialog size="L">
            <Heading>
                <AboutHeader isForVr={false} />
            </Heading>
            <Content>
                <AboutContent isForVr={false} />
            </Content>
        </Dialog>
    );
}
