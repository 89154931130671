/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, View } from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { HomeDialogs } from "./HomeDialogs";
import { AssetTabsView } from "../components/AssetTabsView";
import { AssetUploadView } from "../components/AssetUploadView";
import { EventType, useAnalytics } from "../hooks/useAnalytics";
import { AppHeader } from "@src/components/AppHeader";
import { getAppPath } from "@src/config";
import { useAcpContext } from "@src/contexts/AcpContext";
import { ContentListContextProvider } from "@src/contexts/ContentListContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useCreateSampleDialog } from "@src/hooks/useCreateSampleDialog";
import { useRedirects } from "@src/hooks/useRedirects";

export function Home() {
    const { t } = useTranslation(["common", "web"]);
    const { modal, assetId } = useParams();
    const { closeModal } = useMessagingContext();
    const { userProfile, imsReady } = useHi5UserContext();

    const { homeModalRedirect } = useRedirects();

    const { getEnvironmentMeta } = useAcpContext();

    const { sendLaunchAnalytics } = useAnalytics();

    useCreateSampleDialog();

    useEffect(() => {
        if (imsReady && userProfile == undefined) {
            sendLaunchAnalytics(EventType.success, getAppPath());
        }
    }, [imsReady]);

    async function onAssetClick(assetId: string) {
        const envMeta = await getEnvironmentMeta(assetId);
        if (!envMeta?.physicalSize) {
            homeModalRedirect("preview", assetId);
        } else if (!envMeta.environment) {
            homeModalRedirect("environment", assetId);
        } else {
            closeModal();
            homeModalRedirect("enter", assetId);
        }
    }

    return (
        <ContentListContextProvider assetClickHandler={onAssetClick}>
            <View height="100vh">
                <HomeDialogs modal={modal} assetId={assetId || ""} />
                <Flex direction="column" gap="size-250">
                    <AppHeader />
                    <View paddingX="4vw">
                        <View marginBottom="1vh">
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.75em",
                                    fontWeight: 500,
                                }}>
                                {userProfile
                                    ? `${t("web:home.welcome")}, ${
                                          userProfile.displayName
                                      }`
                                    : `${t("web:home.welcome")}`}
                            </Text>
                        </View>
                        <AssetUploadView />
                    </View>
                    <View
                        backgroundColor="gray-100"
                        paddingX="4vw"
                        paddingBottom="1vh">
                        <AssetTabsView />
                    </View>
                </Flex>
            </View>
        </ContentListContextProvider>
    );
}
