/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Flex, Text, Image, View } from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import Help from "@spectrum-icons/workflow/Help";
import Settings from "@spectrum-icons/workflow/Settings";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Tutorials } from "./Tutorials";
import appIcon from "../../assets/images/rw_appicon_noshadow_128.png";
import { AssetTabsView } from "../../components/AssetTabsView";
import { UserProfileView } from "../../components/UserProfileView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import useNotifyVRAuth from "../../hooks/useNotifyVRAuth";
import { ContentListContextProvider } from "@src/contexts/ContentListContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function StartScreen() {
    const { t } = useTranslation(["common", "vr"]);
    const { logIn, userProfile, avatarUrl, authState } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const { showModal } = useMessagingContext();

    const [searchParams] = useSearchParams();
    const tutorialEnabled = searchParams.get("tutorial") || undefined;

    useNotifyVRAuth();

    useEffect(() => {
        if (postMessageBusApi && authState === "failed") {
            console.log("startScreen, authState: ", authState);
            postMessageBusApi.showDialog("signed-out", "", true);
        }
    }, [postMessageBusApi, authState]);

    function onAssetClick(urn: string) {
        if (postMessageBusApi)
            postMessageBusApi.showDialog("access-check", `urn=${urn}`, false);
    }

    function logOutAndClearCookies() {
        if (postMessageBusApi) {
            postMessageBusApi.clearCookies();
            postMessageBusApi.signOutVR();
            postMessageBusApi.showDialog("assert-log-out", "", false);
        }
    }

    useEffect(() => {
        if (tutorialEnabled === "true") {
            showModal(<Tutorials />);
        }
    }, [tutorialEnabled]);

    return (
        <ContentListContextProvider assetClickHandler={onAssetClick}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    paddingTop: "10vh",
                    paddingBottom: "4vh",
                    paddingLeft: "4vw",
                    paddingRight: "2vw",
                    boxSizing: "border-box",
                }}>
                <Flex
                    direction="row"
                    height="100%"
                    width="100%"
                    gap="4vw"
                    justifyContent="space-between">
                    <Flex
                        direction="column"
                        height="100%"
                        justifyContent="space-between">
                        <Flex direction="column">
                            <Flex
                                direction="row"
                                width="385px"
                                justifyContent="start"
                                alignItems="center"
                                gap="size-300">
                                <Image
                                    width="128px"
                                    height="128px"
                                    minWidth="128px"
                                    minHeight="128px"
                                    alt={t("common:header.appIcon")}
                                    src={appIcon}
                                    UNSAFE_style={{
                                        borderRadius: "8px",
                                    }}
                                />
                                <View>
                                    <div>
                                        <div style={{ lineHeight: "2em" }}>
                                            <Text
                                                UNSAFE_style={{
                                                    fontSize: "2.25em",
                                                }}>
                                                {t("vr:header.appTitle.1")}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                UNSAFE_style={{
                                                    fontSize: "2.25em",
                                                }}>
                                                {t("vr:header.appTitle.2")}
                                            </Text>
                                        </div>
                                    </div>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.5em",
                                            color: "rgb(150,150,150)",
                                        }}>
                                        {t("vr:header.version")}
                                    </Text>
                                </View>
                            </Flex>
                            <Button
                                marginTop="2vh"
                                width="100%"
                                height="4vh"
                                variant="primary"
                                style="fill"
                                UNSAFE_style={{
                                    borderRadius: "100px",
                                }}
                                onPress={() => {
                                    if (postMessageBusApi)
                                        postMessageBusApi.showDialog(
                                            "tutorial",
                                        );
                                }}>
                                <Flex alignItems="center" gap="size-50">
                                    <Help />
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.5em",
                                        }}>
                                        {t("vr:button.tutorial")}
                                    </Text>
                                </Flex>
                            </Button>
                            <Button
                                marginTop="2vh"
                                width="100%"
                                height="4vh"
                                variant="primary"
                                style="fill"
                                UNSAFE_style={{
                                    borderRadius: "100px",
                                }}
                                onPress={() => {
                                    if (postMessageBusApi)
                                        postMessageBusApi.showAppSettings();
                                }}>
                                <Flex
                                    direction="row"
                                    alignItems="center"
                                    gap="size-50">
                                    <Settings />
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.5em",
                                        }}>
                                        {t("common:account.preferences")}
                                    </Text>
                                </Flex>
                            </Button>
                        </Flex>
                        <View width="100%">
                            <Flex direction="column" gap="10px">
                                <UserProfileView
                                    userProfile={userProfile}
                                    avatarUrl={avatarUrl}
                                    trimStrings={true}
                                    isForVr={true}
                                />
                                <Button
                                    onPress={
                                        userProfile
                                            ? logOutAndClearCookies
                                            : logIn
                                    }
                                    marginTop="1.5vh"
                                    width="100%"
                                    height="4vh"
                                    variant="primary"
                                    style="fill"
                                    UNSAFE_style={{
                                        borderRadius: "100px",
                                    }}>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.5em",
                                        }}>
                                        {userProfile
                                            ? t("common:account.signOut")
                                            : t("vr:account.signIn")}
                                    </Text>
                                </Button>
                            </Flex>
                        </View>
                    </Flex>
                    <Flex
                        direction="column"
                        flexGrow={1}
                        height="100%"
                        marginStart="20px">
                        <View>
                            <AssetTabsView />
                        </View>
                    </Flex>
                </Flex>
            </div>
        </ContentListContextProvider>
    );
}
