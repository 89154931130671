/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Picker, Item, SearchField, Text } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetsView } from "./AssetsView";
import { ContentLayout } from "./AssetTabsView";
import { NoAssetsView } from "./NoAssetsView";
import { sortByRecent, sortByName } from "../util/SortUtils";
import { SortType, useContentListContext } from "@src/contexts/ContentListContext";
import { useIsVRPage } from "@src/hooks/useIsVRPage";
import { ReviewListItem } from "@src/lib/acp/AcpClientModels";

function labelText(text: string, isForVr?: boolean) {
    return (
        <Text
            UNSAFE_style={
                isForVr
                    ? {
                          fontSize: "1.5em",
                          marginTop: "-5px",
                          paddingRight: "8px",
                      }
                    : {}
            }>
            {text}
        </Text>
    );
}

interface SortAndFilterViewProps {
    assets: ReviewListItem[] | undefined;
    layout: ContentLayout;
}

function sortAssets<
    T extends { modifyDate?: string; displayName?: string },
>(assets: T[] | undefined, sortType: SortType) {
    if (!assets) return;
    const sortingFn = sortType === "recent" ? sortByRecent : sortByName;
    return assets.slice().sort(sortingFn);
}

export function SortAndFilterView({
    assets,
    layout,
}: SortAndFilterViewProps) {
    const { t } = useTranslation("common");
    const {
        isCurrentTabLoaded,
        filter,
        setFilter,
        sortType,
        setSortType,
    } = useContentListContext();

    const [filteredAssets, setFilteredAssets] = useState<ReviewListItem[]>();
    const { isVRPage } = useIsVRPage();

    useEffect(() => {
        const filterFn = (data: ReviewListItem) => {
            return (
                data.displayName &&
                data.displayName.toUpperCase().includes(filter.toUpperCase())
            );
        };
        if (!assets) return;
        setFilteredAssets(sortAssets(assets.filter(filterFn), sortType));
    }, [filter, sortType, assets]);

    function assetsView() {
        const viewHeight = isVRPage ? "65vh" : "calc(80vh - 420px)";

        return (
            <div
                style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: viewHeight,
                }}>
                {isCurrentTabLoaded &&
                filteredAssets &&
                filteredAssets.length > 0 ? (
                    <AssetsView
                        assets={filteredAssets}
                        gridLayoutEnabled={layout === "grid"}
                    />
                ) : (
                    <NoAssetsView
                        currentCount={filteredAssets?.length}
                        totalCount={assets?.length}
                    />
                )}
            </div>
        );
    }

    return (
        <>
            <Flex
                marginY="2vh"
                direction="row"
                width="100%"
                justifyContent="space-between">
                <Picker
                    label={labelText(t("sortBy.sort"), isVRPage)}
                    labelPosition="side"
                    selectedKey={sortType}
                    onSelectionChange={(selected) =>
                        setSortType(selected as SortType)
                    }
                    isQuiet>
                    <Item
                        key="recent"
                        aria-label={t("accessibility.sortBy.recent")}>
                        <Text
                            UNSAFE_style={{
                                fontSize: isVRPage ? "1.6em" : "",
                            }}>
                            {t("sortBy.recent")}
                        </Text>
                    </Item>
                    <Item
                        key="name"
                        aria-label={t("accessibility.sortBy.name")}>
                        <Text
                            UNSAFE_style={{
                                fontSize: isVRPage ? "1.6em" : "",
                            }}>
                            {t("sortBy.name")}
                        </Text>
                    </Item>
                </Picker>
                <SearchField
                    id="SearchField-Filter"
                    label={labelText(t("search.filter"), isVRPage)}
                    labelPosition="side"
                    onChange={setFilter}
                    defaultValue={filter}
                    isQuiet
                />
            </Flex>
            {assetsView()}
        </>
    );
}
