/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import { IconProps } from "../../util/IconUtils";

export function OpenInVR(props: IconProps) {
    return (
        <Icon {...props}>
            <svg viewBox="0 0 18 18">
                <path d="M2,6v2.5h-.5c-.27614,0-.5-.22386-.5-.5v-1.5c0-.27614.22386-.5.5-.5h.5ZM15.5,8.5h.5v.738c0,3.1835-3.033,3.472-3.762,3.472-.7735,0-1.496-.0965-1.925-.74l-.688-1.033c-.22896-.34518-.6944-.43939-1.03958-.21042-.08349.05538-.15504.12693-.21042.21042l-.688,1.033c-.429.6435-1.15.74-1.925.74-.6815,0-3.762-.2715-3.762-3.472v-.738h.5c.27614,0,.5-.22386.5-.5v-1.5c0-.27614-.22386-.5-.5-.5h-.4815c.14388-1.1428,1.11568-1.99995,2.2675-2h9.428c1.15182.00005,2.12362.8572,2.2675,2h-.4815c-.27614,0-.5.22386-.5.5v1.5c0,.27614.22386.5.5.5ZM7.8,7.875c0-1.03553-.83947-1.875-1.875-1.875s-1.875.83947-1.875,1.875.83947,1.875,1.875,1.875,1.875-.83947,1.875-1.875h0ZM14,7.875c0-1.03553-.83947-1.875-1.875-1.875s-1.875.83947-1.875,1.875.83947,1.875,1.875,1.875,1.875-.83947,1.875-1.875h0ZM16.5,6h-.5v2.5h.5c.27614,0,.5-.22386.5-.5v-1.5c0-.27614-.22386-.5-.5-.5Z" />
            </svg>
        </Icon>
    );
}
