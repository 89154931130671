/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Studio, StudioTool } from "@components/studio";
import { RenderSettings, SceneManager } from "@components/studio/src/scene/SceneManager";
import { useTranslation } from "react-i18next";

import type { Scene } from "@babylonjs/core/scene";
import type { StudioStrings } from "@components/studio";
import type { AdobeViewer, AdobeCanvasViewerOptions } from "@components/studio/src/scene/AdobeViewer";

interface StudioViewProps {
    modelUrl: string;
    iblUrl?: string;
    editorTools?: StudioTool[];
    setSceneInstance?: (sceneInstance: Scene) => void;
    setViewerInstance?: (viewerInstance: AdobeViewer) => void;
    setSceneManager?: (sceneManager: SceneManager) => void;
}

export const renderSettings: RenderSettings = {
    groundPlaneEnabled: false,
};

export const viewerConfig: AdobeCanvasViewerOptions = {
    autoSuspendRendering: true,
    antialias: true,
    engine: "WebGL",
    useIblShadows: true,
    clearColor: [1,1,1,0],
};

export function StudioView({
    modelUrl,
    iblUrl,
    editorTools = [StudioTool.none],
    setSceneInstance,
    setViewerInstance,
    setSceneManager,
}: StudioViewProps) {
    const { t } = useTranslation("web");

    const editorStrings: StudioStrings = {
        cameraOrbit: t("studio.cameraTools.orbit"),
        cameraPan: t("studio.cameraTools.pan"),
        cameraDolly: t("studio.cameraTools.dolly"),
        frameButton: t("studio.cameraTools.frameButton"),
    };

    function updateManager(sceneManager: SceneManager) {
        sceneManager.viewer.engine.getCaps().parallelShaderCompile = undefined;
        sceneManager.viewer.renderLoop.toggle(5000);
        setSceneManager?.(sceneManager);
        setViewerInstance?.(sceneManager.viewer);
        setSceneInstance?.(sceneManager.scene);
    }

    return (
        <Studio
            enableLimitedZoom={false}
            modelUrl={modelUrl}
            iblUrl={iblUrl}
            cameraName={undefined}
            tools={editorTools}
            strings={editorStrings}
            wideFraming={true}
            renderSettings={renderSettings}
            setSceneManager={updateManager}
            viewerConfig={viewerConfig}
        />
    );
}
