/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ComponentLoader } from "@ccx-public/component-loader";
import { useEffect, useMemo, useState } from "react";

import { ADOBE_IMS_CONFIG, IMS_ENV } from "@src/config.js";
import { useAcpContext } from "@src/contexts/AcpContext.js";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider.js";
import { useLocaleContext } from "@src/contexts/LocaleContext.js";
import { useThemeContext } from "@src/contexts/ThemeContext.js";

import type { CommentsApi } from "../lib/bus-api/CommentsApi.js";
import type {
    CcxCommentsApi,
    CommentsProviderProps,
    GroupBy,
} from "@ccx-public/ccx-comments";
import type { Commenting } from "@ccx-public/component-loader";

declare module "react" {
    namespace JSX {
        interface IntrinsicElements {
            "cc-comments-list": any;
        }
    }
}

type CommentingOptions = Parameters<Commenting.Init>[0];

const COMMENTING_VERSION = "30.10.0";

const DISABLE_COLOR_PICKER = true;

const env = IMS_ENV === "prod" ? "prod" : "stage";

const COMMENTS_FEATURE_CONFIG = {
    peopleGraphAPIKey: ADOBE_IMS_CONFIG.client_id,
    reCaptchaSiteKey: ADOBE_IMS_CONFIG.client_id,
    appKey: ADOBE_IMS_CONFIG.client_id,
    appName: "Pin 4 Review",

    spectrumUIScale: "medium",
    annotationsEnabled: true,
    annotationsMultiPageEnabled: true,
    groupBy: "node" as GroupBy,
    enableEmojiPicker: false,
    enableReadUnread: false,
    mentionsEnabled: true,
    pollForUpdates: true,
    locale: "en",
    globalCommentsViewAll: false,
    annotationsCreationEnabled: true,
    isOffline: false,
    authenticateFetch: true,
    cacheComments: true,
    showBetaLabel: false,
    allowCommentCreation: true,
    annotationTypes: { pin: true, draw: false },
};
export type CommentsPanelWrapperProps = {
    visible?: boolean;
    commentsApi: CommentsApi;
    assetId: string;
    onCommentsApiInitialized?: () => void;
    onCommentsLoaded?: () => void;
};

let activeCommentApi: CommentsApi;

export function CommentsPanel({
    visible,
    commentsApi,
    assetId,
    onCommentsApiInitialized,
    onCommentsLoaded,
}: CommentsPanelWrapperProps) {
    const { locale } = useLocaleContext();
    const { accessToken, userId } = useHi5UserContext();
    const { isDarkMode } = useThemeContext();

    const { useReviewListItem } = useAcpContext();

    const { data: reviewItem } = useReviewListItem(assetId);

    const componentLoader = useMemo(
        () => new ComponentLoader({ cdnEnvironment: env }),
        [env],
    );
    const [componentLoaded, setComponentLoaded] = useState(false);
    const [commentComponentApi, setCommentComponentApi] = useState<any>();

    activeCommentApi = commentsApi;

    const commentOptions = useMemo<
        Partial<CommentsProviderProps & CommentingOptions>
    >(
        () => ({
            ...COMMENTS_FEATURE_CONFIG,
            disableCommentCreation: !accessToken,
            environment: env,
            imsData: { id: userId, token: accessToken },
            theme: isDarkMode ? "darkest" : "light",
            locale: locale,

            onAnnotatingChange: (event) =>
                activeCommentApi.onAnnotatingChange(
                    event,
                    DISABLE_COLOR_PICKER,
                ),
            onCommentInteraction: (event) =>
                activeCommentApi.onCommentInteraction(event),
            onNodeChange: (...props) => activeCommentApi.onNodeChange(...props),
            onCommentsSuccess: (...props) =>
                activeCommentApi.onCommentsSuccess(props),
            onCommentsUpdate: (context, delta) =>
                activeCommentApi.onCommentsUpdate(context, delta),
            onCommentsError: (error) => activeCommentApi.onCommentsError(error),
            onAnnotationToggle: () => activeCommentApi.onAnnotationToggle(),
        }),
        [assetId, userId, accessToken, isDarkMode, env, locale],
    );

    useEffect(() => {
        if (!accessToken || !userId || !reviewItem) return;
        if (!componentLoaded) {
            componentLoader
                .load(
                    "Commenting",
                    COMMENTING_VERSION,
                    commentOptions as CommentingOptions,
                )
                .then((commentComponent: any) => {
                    commentComponent().updateConfig(commentOptions);
                    setCommentComponentApi(commentComponent);
                    setComponentLoaded(true);
                    onCommentsLoaded?.();
                });
        }
        if (componentLoaded && commentComponentApi) {
            // commentComponentApi.updateConfig(commentOptions);
        }
    }, [
        accessToken,
        reviewItem,
        commentOptions,
        componentLoaded,
        commentComponentApi,
    ]);

    useEffect(() => {
        if (componentLoaded && !commentsApi.initialized && reviewItem) {
            const node = document.getElementsByTagName("cc-comments-list")[0];
            if (node) {
                // trust me this works
                const commentListApi = node as unknown as CcxCommentsApi;
                (node as any).assetData = {
                    id: assetId,
                    nodeId: assetId,
                    shareStatus: "unshared",
                    ownerId: reviewItem.createdBy,
                    validNodes: [{ id: assetId, title: "3d Asset" }],
                };
                commentsApi.initializeApi(commentListApi);
                node.setAttribute("disablerichtextentry", "true");
                onCommentsApiInitialized?.();
            }
        }
    }, [assetId, reviewItem, componentLoaded, commentsApi.initialized]);

    useEffect(() => {
        if (!visible) {
            commentsApi.disableComments();
        } else {
            commentsApi.enableComments();
        }
    }, [visible]);

    return (
        userId &&
        accessToken && (
            <cc-comments-list
                visible
                style={{ display: visible === false ? "none" : undefined }}
            />
        )
    );
}
