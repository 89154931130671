/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import { useAcpContext } from "@src/contexts/AcpContext";

export type ThumbnailState = "loading" | "error" | "success";

export function useThumbnail(assetId: string, isVisible: boolean) {
    const { useReviewThumbnailUrl } = useAcpContext();
    const {
        data: thumbnailUrl,
        isFetched: thumbFetched,
        isError,
    } = useReviewThumbnailUrl(assetId, isVisible);

    const [thumbTimeout, setThumbTimeout] = useState(false);

    useEffect(() => {
        let timer: number;
        if (!thumbnailUrl && thumbFetched) {
            timer = window.setTimeout(() => {
                setThumbTimeout(true);
            }, 20_000);
        }
        if (thumbnailUrl) {
            setThumbTimeout(false);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [thumbFetched, thumbnailUrl]);


    const thumbnailState: ThumbnailState = thumbTimeout
        ? "error"
        : isError
          ? "error"
          : thumbnailUrl
            ? "success"
            : "loading";

    return {
        thumbnailUrl,
        thumbnailState
    };
}
