/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState, useEffect } from "react";

export function useIsHover(ref?: HTMLElement) {
    const [isHovered, setIsHover] = useState(false);
    const [hoveredRef, setIsHoveredRef] = useState<HTMLElement | null | undefined>(ref)

    useEffect(() => {
        if (!hoveredRef) return;
        const handleMouseEnter = () => setIsHover(true);
        const handleMouseLeave = () => setIsHover(false);

        hoveredRef.addEventListener("mouseenter", handleMouseEnter);
        hoveredRef.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            hoveredRef.removeEventListener("mouseenter", handleMouseEnter);
            hoveredRef.addEventListener("mouseleave", handleMouseLeave);
        }
    }, [hoveredRef])

    return { isHovered, hoveredRef, setIsHoveredRef };
}
