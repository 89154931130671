/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { TextField } from "@adobe/react-spectrum";

import { useRenameAsset } from "@src/hooks/useRenameAsset";

interface Props {
    assetId: string;
}

export function AssetRenameTextField({ assetId }: Props) {
    const { assetName, formError, setAssetName, handleRename } =
        useRenameAsset(assetId);

    return (
        <TextField
            aria-label="rename"
            value={assetName}
            onChange={setAssetName}
            onBlur={async () => await handleRename()}
            isQuiet
            errorMessage={formError}
        />
    );
}
