/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Heading,
    Text,
    View,
    Content
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

interface Props {
    hoveredRef: HTMLElement
    isHovered: boolean
}

export function LaserPointerTooltipView({ hoveredRef, isHovered }: Props) {
    const { t } = useTranslation("web");
    return (
        <View backgroundColor="gray-50" 
            borderWidth="thin" 
            borderColor="gray-400" 
            borderRadius="small" 
            isHidden={!isHovered}
            padding="size-250"
            UNSAFE_style={{
                zIndex: 1000,
                top: hoveredRef.offsetTop + "px",
                left: hoveredRef.offsetLeft + hoveredRef.offsetWidth + 12 + "px",
                position: "absolute"
            }}>
            <Flex direction="row" alignItems="center" justifyContent="space-between">
                <Heading marginY="size-0">{t("nav.tooltip.pointer.heading")}</Heading>
                <Text UNSAFE_style={{ 
                    backgroundColor:"var(--spectrum-global-color-gray-200)",
                    border:"blue", 
                    borderRadius:"2px", 
                    paddingLeft:"5px", 
                    paddingRight:"5px",
                    }}>
                    {t("nav.tooltip.pointer.alternate")}
                </Text>
            </Flex>
            <Content marginTop="size-100">
                <div>
                {t("nav.tooltip.pointer.content1")}<br/>{t("nav.tooltip.pointer.content2")}
                </div>
            </Content>
        </View>
    ); 
}