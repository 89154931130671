/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import {
    AppendSceneAsync,
    SceneLoader,
} from "@babylonjs/core/Loading/sceneLoader.js";
import { PBRMetallicRoughnessMaterial } from "@babylonjs/core/Materials/PBR/pbrMetallicRoughnessMaterial";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { CreateDisc } from "@babylonjs/core/Meshes/Builders/discBuilder";
import { Observable } from "@babylonjs/core/Misc/observable";
import { FxaaPostProcess } from "@babylonjs/core/PostProcesses/fxaaPostProcess";
import { ShadowOnlyMaterial } from "@babylonjs/materials/shadowOnly";
import {
    DEFAULT_RENDER_SETTINGS,
} from "@shared/common/src/render-defaults";

import { AdobeViewer, AdobeViewerOptions } from "./AdobeViewer";
import { throttle } from "../utils/throttle";

import type { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import type { Camera } from "@babylonjs/core/Cameras/camera";
import type { SceneOptimizer } from "@babylonjs/core/Misc/sceneOptimizer";
import type { Nullable } from "@babylonjs/core/types";
import type {
    CameraOverride,
    SerializedVector3,
} from "@shared/types";

import "@babylonjs/core/Shaders/fxaa.fragment";
import "@babylonjs/core/Shaders/fxaa.vertex";
import "@babylonjs/core/Shaders/shadowMap.fragment";
import "@babylonjs/core/Shaders/shadowMap.vertex";
import "@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent";

// import {
//     SceneOptimizerOptions,
//     CustomOptimization,
// } from "@babylonjs/core/Misc/sceneOptimizer";

// class ContactHardeningShadowOptimization extends CustomOptimization {
//     private shadowGenerator?: ShadowGenerator;

//     constructor(priority: number, shadowGenerator?: ShadowGenerator) {
//         super(priority);
//         this.shadowGenerator = shadowGenerator;
//     }

//     onApply = (scene: Scene, optimizer: SceneOptimizer) => {
//         if (this.shadowGenerator) {
//             // Turn dynamic shadow hardening off, unless optimizer is in improvement mode
//             this.shadowGenerator.useContactHardeningShadow =
//                 optimizer.isInImprovementMode;

//             // Always ensure transparencyShadow is the same, otherwise transparency artifacts may appear that
//             // look crosshatched in the shadow
//             this.shadowGenerator.transparencyShadow =
//                 optimizer.isInImprovementMode;

//             this.shadowGenerator.recreateShadowMap();

//             return true;
//         } else {
//             return false;
//         }
//     };

//     onGetDescription = () => {
//         return "Disabling contact hardening shadows (enabling in improvement mode)";
//     };
// }

// class SsaoOptimization extends CustomOptimization {
//     private viewer?: Adobe3dViewer;

//     constructor(priority: number, viewer?: Adobe3dViewer) {
//         super(priority);
//         this.viewer = viewer;
//     }

//     onApply = (scene: Scene, optimizer: SceneOptimizer) => {
//         if (this.viewer) {
//             // Turn SSAO off, unless optimizer is in improvement mode
//             this.viewer.ssaoEnabled = optimizer.isInImprovementMode;
//             return true;
//         } else {
//             return false;
//         }
//     };

//     onGetDescription = () => {
//         return "Disabling SSAO (enabling in improvement mode)";
//     };
// }

SceneLoader.ShowLoadingScreen = false;

// Settings passed through that indicate to the web viewer the render settings of the template. If this is being
// updated with new requried fields, ensure that Studio's index.tsx validates loaded config files by checking for any
// and all required properties -jakes
export type RenderSettings = {
    backgroundColor?: number[]; // Should always have 4 values
    groundPlaneEnabled?: boolean;
    domeLightRotation?: number;
    domeLightIntensity?: number;
    directionalLightVector?: number[];
    shadowIntensity?: number;
};

export interface SceneManagerOptions extends AdobeViewerOptions {
    modelUrl: string;
    iblUrl?: string;

    cameraOverride?: CameraOverride;
    onUpdateCameraPosition?: (override: CameraOverride) => void;
    enableLimitedZoom?: boolean;
    cameraName?: string;
    renderSettings?: RenderSettings;
    wideFraming?: boolean;
    debugLayerEnabled?: boolean;
}

export class SceneManager {
    camera?: Nullable<ArcRotateCamera>;
    optimizer?: SceneOptimizer;
    shadowGenerator?: ShadowGenerator;
    debuggerInitialized = false;

    onLoadProgress = new Observable<number | boolean>();

    constructor(
        public viewer: AdobeViewer,
        private options: SceneManagerOptions,
    ) {
        this.viewer = viewer;

        this.viewer.onModelLoaded.addOnce(() => {
            this.setupScene();
        });

        this.viewer.loadModel(options.modelUrl, {
            pluginExtension: ".glb",
            animationAutoPlay: false,
        });

        this.viewer.cameraAutoOrbit = { enabled: false };

        if (options.iblUrl) {
            this.viewer.loadEnvironment(options.iblUrl, options);
        }

        viewer.onLoadingProgressChanged.add(() => {
            this.onLoadProgress.notifyObservers(viewer.loadingProgress);
        });
    }

    get modelBounds() {
        if (this.viewer.model) {
            return this.viewer.model.getHierarchyBoundingVectors();
        }
        // dummy bounds while loading or switching
        return {
            min: new Vector3(-1, 0, -1),
            max: new Vector3(1, 1, 1),
        };
    }

    get scene() {
        return this.viewer.scene;
    }

    private setupScene() {
        if (!this.scene) {
            throw new Error(`Scene doesn't exist`);
        }
        const {
            cameraOverride,
            onUpdateCameraPosition,
            enableLimitedZoom,
            renderSettings,
        } = this.options;

        const domeLightRotationY =
            renderSettings?.domeLightRotation != undefined
                ? renderSettings?.domeLightRotation * (Math.PI / 180) - Math.PI
                : DEFAULT_RENDER_SETTINGS.domeLightRotation;

        if (this.viewer.scene.environmentTexture) {
            const texture = this.viewer.scene.environmentTexture as CubeTexture;
            texture.rotationY = domeLightRotationY;
        }

        this.viewer.scene.onAfterRenderObservable.addOnce(() => {
            let camera: Nullable<ArcRotateCamera> = null;
            if (this.options.cameraName) {
                const foundCamera = this.viewer.scene.cameras.find(
                    (camera) => camera.name === this.options.cameraName,
                );
                if (foundCamera) {
                    this.scene.activeCamera =
                        foundCamera as unknown as Nullable<Camera>;
                    camera = foundCamera as unknown as ArcRotateCamera;
                }
            }
            if (!camera) {
                camera = this.viewer.camera as Nullable<ArcRotateCamera>;
            }
            if (camera) {
                const modelBounds = this.modelBounds;
                camera.minZ = 0.01;
                const sceneRadius =
                    Vector3.Distance(modelBounds.min, modelBounds.max) / 2;

                if (enableLimitedZoom) {
                    camera.lowerRadiusLimit = sceneRadius * 1.5;
                    camera.upperRadiusLimit = sceneRadius * 8;
                } else {
                    camera.lowerRadiusLimit = 0;
                    camera.upperRadiusLimit = null;
                }

                if (cameraOverride) {
                    // The values in the webviewer are normalized to the scene bounding box for rendering purposes.
                    // For the overrides that are fed back into the webviewer, we must apply the modelScale scale factor
                    // to get units back into the right coordinate space
                    const translation = cameraOverride.translation.map(
                        (value) => value * this.viewer.modelScale,
                    ) as SerializedVector3;
                    const target = cameraOverride.target.map(
                        (value) => value * this.viewer.modelScale,
                    ) as SerializedVector3;
                    camera.target.set(...target);
                    camera.setPosition(new Vector3(...translation));
                }

                if (onUpdateCameraPosition) {
                    camera.onViewMatrixChangedObservable.add(
                        throttle(() => {
                            let translation: SerializedVector3 = [0, 0, 0];
                            let target: SerializedVector3 = [0, 0, 0];
                            if (camera) {
                                camera.position.toArray(translation);
                                camera.target.toArray(target);
                                // The values in the webviewer are normalized to the scene bounding box for rendering purposes.
                                // But the overrides require the original, unscaled units.  This inverse scale factor will correct
                                // for this and return the original asset units
                                translation = translation.map(
                                    (value) => value / this.viewer.modelScale,
                                ) as SerializedVector3;
                                target = target.map(
                                    (value) => value / this.viewer.modelScale,
                                ) as SerializedVector3;
                            }
                            onUpdateCameraPosition({
                                target,
                                translation,
                            });
                        }, 250),
                    );
                }
                this.camera = camera;

                if (this.camera) {
                    new FxaaPostProcess("fxaa", 1, this.camera);
                }

                this.frame();
            }
        });

        if (!this.viewer.shadowPipeline) {
            const lightDirArr: number[] =
                renderSettings?.directionalLightVector ??
                DEFAULT_RENDER_SETTINGS.directionalLightVector;
            let lightDirVec: Vector3 = new Vector3(...lightDirArr);
            if (renderSettings?.domeLightRotation != undefined) {
                lightDirVec = lightDirVec.applyRotationQuaternion(
                    Quaternion.FromEulerAngles(
                        0,
                        -renderSettings.domeLightRotation * (Math.PI / 180) +
                            Math.PI / 2,
                        0,
                    ),
                );
            }

            const light = new DirectionalLight(
                "light",
                lightDirVec,
                this.scene,
            );
            light.shadowMinZ = 0.07;
            light.shadowMaxZ = 3.5;

            light.intensity = 0;

            const shadowIntensity: number =
                renderSettings?.shadowIntensity ??
                DEFAULT_RENDER_SETTINGS.shadowIntensity;

            this.shadowGenerator = new ShadowGenerator(1024, light);
            this.shadowGenerator.forceBackFacesOnly = true;
            this.shadowGenerator.useContactHardeningShadow = true;
            this.shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
            this.shadowGenerator.enableSoftTransparentShadow = true;
            this.shadowGenerator.transparencyShadow = true;
            this.shadowGenerator.setDarkness(1.0 - shadowIntensity);
            this.scene.meshes.forEach((mesh) => {
                this.shadowGenerator?.getShadowMap()?.renderList?.push(mesh);
                mesh.receiveShadows = true;
            });
        }

        const groundPlaneEnabled =
            renderSettings?.groundPlaneEnabled ??
            DEFAULT_RENDER_SETTINGS.groundPlaneEnabled;

        if (groundPlaneEnabled) {
            const ground = CreateDisc(
                "ground",
                {
                    radius: 20,
                },
                this.scene,
            );
            ground.rotation.x = Math.PI / 2;
            if (this.viewer.shadowPipeline) {
                const shadowMaterial = new PBRMetallicRoughnessMaterial(
                    "ground_shadow_material",
                    this.scene,
                );

                ground.material = shadowMaterial;
                ground.isPickable = false;
                this.viewer.shadowPipeline.addShadowReceivingMaterial(shadowMaterial);
            } else {
                ground.material = new ShadowOnlyMaterial(
                    "groundShadows",
                    this.scene,
                );
                ground.isPickable = false;
                ground.receiveShadows = true;
            }
        }

        this.scene.actionManager = new ActionManager(this.scene);
        this.scene.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnKeyDownTrigger,
                    parameter: "d",
                },
                this.toggleDebugger,
            ),
        );

        //@ts-ignore
        window.viewer = this.viewer;
    }


    toggleDebugger = async () => {
        if (this.options.debugLayerEnabled && !this.debuggerInitialized) {
            await Promise.all([
                import("@babylonjs/core/Debug/debugLayer"),
                import("@babylonjs/inspector"),
            ]);
            this.debuggerInitialized = true;
        }

        if (this.scene.debugLayer.isVisible()) {
            this.scene.debugLayer.hide();
        } else {
            this.scene.debugLayer.show();
            const inspector = document.getElementById("inspector-host");
            const sceneExplorer = document.getElementById(
                "scene-explorer-host",
            );
            if (inspector) {
                inspector.style.position = "absolute";
                inspector.style.zIndex = "4";
            }
            if (sceneExplorer) {
                sceneExplorer.style.position = "absolute";
                sceneExplorer.style.zIndex = "4";
            }
        }
    };

    async appendAsync(url: string) {
        await AppendSceneAsync(url, this.scene, {
            onProgress: ({ loaded, total, lengthComputable }) => {
                if (lengthComputable) {
                    this.onLoadProgress.notifyObservers(loaded / total);
                } else {
                    this.onLoadProgress.notifyObservers(true);
                }
            },
        });
        this.onLoadProgress.notifyObservers(false);
    }

    changeModel(source: string | File | ArrayBufferView) {
        return new Promise<void>((res, rej) => {
            if (source !== this.viewer.currentModelSource) {
                const loadNewModel = () => {
                    if (this.viewer.model) {
                        this.viewer.model.dispose();
                    }
                    this.viewer
                        .loadModel(source, { pluginExtension: ".glb" })
                        .then(res)
                        .catch(rej);
                };
                if (this.viewer.loadingProgress) {
                    this.viewer.onModelLoaded.addOnce(loadNewModel);
                } else {
                    loadNewModel();
                }
            } else {
                res();
            }
        });
    }

    keyDownHandler = (e: KeyboardEvent) => {
        switch (e.key) {
            case "f":
                this.frame();
                break;
        }
    };

    /**
     * Enable hot keys for web viewer. Currently, this is simply "f" to frame the subject
     */
    public attachWindowListeners() {
        window.addEventListener("keydown", this.keyDownHandler);
    }

    /**
     * Disable hot keys for web viewer. Currently, this is simply "f" to frame the subject
     */
    public detachWindowListeners() {
        window.removeEventListener("keydown", this.keyDownHandler);
    }
    /**
     * Frames the camera centered on the loaded model
     * @param fixedAspect If using a fixed aspect this number will be used. If not passed it will use the canvas aspect
     * @param framePaddingScaleFactor [default 1] a scaling factor to adjust the distance from the camera to the object. Numbers less than 1 are not recommended
     */
    frame(fixedAspect: number = 0, framePaddingScaleFactor = 1) {
        const camera = this.camera;
        let aspect = fixedAspect;
        if (!aspect) {
            const rect = this.viewer.canvas?.getBoundingClientRect();
            if (rect) {
                aspect = rect.width / rect.height;
            }
        }

        if (camera) {
            // Babylon default FOV is always vertical
            const fov = Math.min(camera.fov, camera.fov * aspect);
            const bounds = this.modelBounds;
            const center = Vector3.Center(bounds.max, bounds.min);
            let radius = Vector3.Distance(center, bounds.max);
            radius *= framePaddingScaleFactor;
            const offset = radius / Math.sin(fov / 2);
            camera.target = center;
            camera.radius = offset;
        }
        this.viewer.renderLoop.toggle();
    }

    dispose() {
        this.detachWindowListeners();
        this.viewer.dispose();
    }
}
