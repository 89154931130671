/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Provider, defaultTheme as SpectrumTheme } from "@adobe/react-spectrum";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useLocaleContext } from "./LocaleContext";
import { UI_SCALE } from "@src/config";
import { useIsVRPage } from "@src/hooks/useIsVRPage";

const LOCAL_STORAGE_ERROR_MSG = "Error accessing local storage";
const LOCAL_STORAGE_KEY = "preferredTheme";

const systemTheme = "system";
const darkTheme = "dark";
const lightTheme = "light";

interface ThemeContextProps {
    themeOptions: { theme: string; name: string }[];
    isDarkMode: boolean;
    currentTheme: string;
    simplifiedThemeOptions: { theme: string; name: string }[];
    handleThemeChange: (theme: string) => void;
}

interface ThemeProviderProps {
    children: React.ReactNode;
}

function readSavedTheme() {
    try {
        const savedThemePreference = localStorage.getItem(LOCAL_STORAGE_KEY);
        return savedThemePreference;
    } catch (e) {
        console.error(LOCAL_STORAGE_ERROR_MSG, e);
    }
    return undefined;
}

function saveTheme(theme: string) {
    try {
        localStorage.setItem(LOCAL_STORAGE_KEY, theme);
    } catch (e) {
        console.error(LOCAL_STORAGE_ERROR_MSG, e);
    }
}

const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

const ThemeContext = createContext<ThemeContextProps>({
    themeOptions: [],
    isDarkMode: false,
    currentTheme: "",
    simplifiedThemeOptions: [],
    handleThemeChange: () => {},
});

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useThemeContext must be used within a ThemeProvider.");
    }
    return context;
};

export const ThemeProvider = ({ children }: ThemeProviderProps): any => {
    const { locale } = useLocaleContext();
    const { isVRPage } = useIsVRPage();

    const themeOptions = [
        {
            theme: systemTheme,
            name: "account.preferences.colorTheme.mirrorSystem",
        },
        { theme: darkTheme, name: "account.preferences.colorTheme.dark" },
        { theme: lightTheme, name: "account.preferences.colorTheme.light" },
    ];

    const simplifiedThemeOptions = [
        {
            theme: darkTheme,
            name: "account.preferences.colorTheme.dark.simple",
        },
        {
            theme: lightTheme,
            name: "account.preferences.colorTheme.light.simple",
        },
    ];

    const [currentTheme, setCurrentTheme] = useState(
        readSavedTheme() || darkTheme,
    );
    const [isDarkMode, setIsDarkMode] = useState(
        currentTheme === systemTheme
            ? mediaQuery.matches
            : currentTheme === darkTheme,
    );

    useEffect(() => {
        const handler = (e: MediaQueryListEvent) => {
            if (!readSavedTheme() || readSavedTheme() === systemTheme) {
                setIsDarkMode(e.matches);
            }
        };
        mediaQuery.addEventListener("change", handler);
    }, []);

    const handleThemeChange = (theme: string) => {
        setCurrentTheme(theme);
        saveTheme(theme);
        if (theme == darkTheme) {
            setIsDarkMode(true);
        } else if (theme == lightTheme) {
            setIsDarkMode(false);
        } else {
            setIsDarkMode(mediaQuery.matches);
        }
    };

    useEffect(() => {
        if (isVRPage) {
            document.body.classList.add("is-vr");
        }
    }, []);

    console.log("UI_SCALE", UI_SCALE);

    return (
        <ThemeContext.Provider
            value={{
                themeOptions,
                isDarkMode,
                currentTheme,
                simplifiedThemeOptions,
                handleThemeChange,
            }}>
            <style>
                {`
                        :root .is-vr * {
                            --spectrum-selectlist-option-background-color-hover: var(--spectrum-gray-400);
                            --spectrum-rule-medium-height: 4px;
                            --spectrum-button-border-width: 3px;
                            --spectrum-alias-border-size-thick: 4px;
                            --spectrum-switch-text-size: 1.4em;
                            --spectrum-switch-track-height: 24px;
                            --spectrum-switch-track-width: 46px;
                            --spectrum-switch-handle-size: 24px;
                        }
                        .is-vr * hr {
                            margin: 6px 0;
                        }
                        .is-vr * #SearchField-Filter {
                            border-bottom-width: thick;
                        }
                    `}
            </style>
            <Provider
                locale={locale}
                theme={SpectrumTheme}
                colorScheme={isDarkMode ? "dark" : "light"}
                scale={UI_SCALE}
                UNSAFE_className="app">
                {children}
            </Provider>
        </ThemeContext.Provider>
    );
};
