/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Heading,
    Image,
    View,
    Button,
    ButtonGroup,
    ActionButton,
    MenuTrigger,
    Menu,
    Item,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import Help from "@spectrum-icons/workflow/Help";
import OpenIn from "@spectrum-icons/workflow/OpenIn";
import { useTranslation } from "react-i18next";

import appIcon from "../assets/images/rw_appicon_noshadow_64.png";
import { Preferences } from "../components/Preferences";
import { UserProfileView } from "../components/UserProfileView";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { OpenInVRDialog } from "@src/pages/dialogs/OpenInVRDialog";

export function AppHeader() {
    const { t } = useTranslation(["common", "web"]);
    const { userProfile, avatarUrl, logIn } = useHi5UserContext();
    const { showModal } = useMessagingContext();

    function openCDOInVr() {
        console.log("Open CDO in VR");
        showModal(<OpenInVRDialog assetUrn={""} />, { isDismissable: true });
    }

    return (
        <View backgroundColor="gray-200" paddingX="2vw">
            <Flex
                direction="row"
                width="100%"
                alignItems="center"
                justifyContent="space-between">
                <View>
                    <Flex
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        gap="size-200">
                        <Image
                            width="32px"
                            height="32px"
                            minWidth="32px"
                            minHeight="32px"
                            alt={t("common:header.appIcon")}
                            src={appIcon}
                            UNSAFE_style={{
                                borderRadius: "8px",
                            }}
                        />
                        <View>
                            <Heading level={3}>
                                {t("common:header.appTitle")}
                            </Heading>
                        </View>
                    </Flex>
                </View>
                <View>
                    <Flex gap="size-200" alignItems="center">
                        {!userProfile && (
                            <ButtonGroup>
                                <Preferences isSignedIn={false} />
                                <Button onPress={logIn} variant="cta">
                                    <Text>{t("web:account.signin")}</Text>
                                </Button>
                            </ButtonGroup>
                        )}
                        {userProfile && (
                            <Button onPress={openCDOInVr} variant={"secondary"}>
                                <Text>{t("actions.openInVR")}</Text>
                            </Button>
                        )}
                        <MenuTrigger>
                            <ActionButton aria-label="AppHeader-Info" isQuiet>
                                <Help />
                            </ActionButton>
                            <Menu minWidth="200px">
                                <Item
                                    href="https://www.adobe.com/go/reviewer-helpx"
                                    target="_blank">
                                    <Text>
                                        <Flex
                                            justifyContent="space-between"
                                            width="100%">
                                            <Text>
                                                {t("web:home.documentation")}
                                            </Text>
                                            <OpenIn />
                                        </Flex>
                                    </Text>
                                </Item>
                            </Menu>
                        </MenuTrigger>
                        <UserProfileView
                            userProfile={userProfile}
                            avatarUrl={avatarUrl}
                            trimStrings={true}
                            isCompactView={true}
                        />
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
