/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Image, Text, View } from "@adobe/react-spectrum";
import Globe from "@spectrum-icons/workflow/Globe";
import UserGroup from "@spectrum-icons/workflow/UserGroup";
import { useTranslation } from "react-i18next";

import { AssetActionMenu } from "./AssetActionMenu";
import { ProgressCircleView } from "./ProgressCircleView";
import noThumbnail from "../assets/images/no-thumbnail.svg";
import { getRelativeFormattedTime } from "../util/StringUtils";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useContentListContext } from "@src/contexts/ContentListContext";
import { useIsHover } from "@src/hooks/useIsHover";
import { useIsVisible } from "@src/hooks/useIsVisible";
import { useIsVRPage } from "@src/hooks/useIsVRPage";
import { useThumbnail } from "@src/hooks/useThumbnail";

interface AssetViewProps {
    assetId: string;
}

export function AssetGridView({ assetId }: AssetViewProps) {
    const { t } = useTranslation("common");
    const { isVisible, setRef } = useIsVisible();
    const { isHovered, setIsHoveredRef } = useIsHover();
    const { locale } = useContentListContext();
    const { useCollaboratorCount, useReviewListItem } = useAcpContext();
    const { thumbnailState, thumbnailUrl} = useThumbnail(assetId, isVisible);

    const { data } = useReviewListItem(assetId);
    const { data: collaboratorCount } = useCollaboratorCount(
        assetId,
        isVisible,
    );
    const { isVRPage } = useIsVRPage();
    const width = isVRPage ? "14vw" : "size-2400";

    function collaborators() {
        if (collaboratorCount === -1) {
            return (
                <Flex
                    direction="row"
                    gap={isVRPage ? "size-100" : "size-50"}
                    alignItems="center">
                    <Globe
                        aria-label={t("accessibility.asset.public")}
                        size={isVRPage ? "M" : "XS"}
                    />
                </Flex>
            );
        } else {
            return (
                <Flex
                    direction="row"
                    gap={isVRPage ? "size-100" : "size-50"}
                    alignItems="center">
                    <UserGroup
                        aria-label={t("accessibility.asset.collaborators")}
                        size={isVRPage ? "M" : "XS"}
                    />
                    <Text
                        UNSAFE_style={
                            isVRPage
                                ? {
                                      fontSize: "1.75em",
                                      paddingLeft: "2px",
                                  }
                                : {}
                        }>
                        {collaboratorCount}
                    </Text>
                </Flex>
            );
        }
    }

    function thumbnail() {
        if (thumbnailState === "error") {
            return (
                <Flex
                    width={width}
                    height={width}
                    alignItems="center"
                    justifyContent="center">
                    <Image
                        width={isVRPage ? "size-700" : "size-800"}
                        height={isVRPage ? "size-700" : "size-800"}
                        alt={t("accessibility.asset.thumbnail")}
                        src={noThumbnail}
                    />
                </Flex>
            );
        } else if (thumbnailUrl) {
            return (
                <View
                    ref={(ref) => {
                        setIsHoveredRef(ref?.UNSAFE_getDOMNode());
                    }}
                    width={width}
                    height={width}
                    backgroundColor={isHovered ? "gray-200" : {}}
                    UNSAFE_style={{
                        borderRadius: "8px",
                        transition: "background-color 0.2s, border-radius 0.2s",
                    }}>
                    <Image
                        minWidth={width}
                        minHeight={width}
                        maxWidth={width}
                        maxHeight={width}
                        alt={t("accessibility.asset.thumbnail")}
                        src={thumbnailUrl}
                    />
                </View>
            );
        } else {
            return (
                <View
                    width={width}
                    height={width}
                    backgroundColor="gray-200"
                    UNSAFE_style={{
                        borderRadius: "8px",
                    }}>
                    <ProgressCircleView />
                </View>
            );
        }
    }

    return (
        <Flex
            ref={(ref) => {
                setRef(ref?.UNSAFE_getDOMNode());
            }}
            data-uia="review"
            direction="column"
            alignContent="start"
            marginBottom="size-200"
            width={width}>
            {thumbnail()}
            <View marginTop="size-200" width="100%">
                <Flex direction="row" justifyContent="space-between">
                    <Text
                        UNSAFE_style={{
                            fontSize: isVRPage ? "1.75em" : "",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            flexGrow: 1,
                            overflow: "hidden",
                            textWrap: "nowrap",
                        }}>
                        {data?.displayName}
                    </Text>
                    {data && (
                        <AssetActionMenu asset={data} isForVr={isVRPage} />
                    )}
                </Flex>
                <Flex direction="row" justifyContent="space-between">
                    <Text
                        data-uia="asset-age"
                        UNSAFE_style={{
                            color: "rgb(150,150,150)",
                            fontSize: isVRPage ? "1.75em" : "",
                        }}>
                        {data?.modifyDate &&
                            getRelativeFormattedTime(data.modifyDate, locale)}
                    </Text>
                    {collaborators()}
                </Flex>
            </View>
        </Flex>
    );
}
