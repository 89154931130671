/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// @ts-ignore
import "./photon-voice.js";

import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents.js";
import { Vector3, Quaternion } from "@babylonjs/core/Maths/math.vector";
import { Scene } from "@babylonjs/core/scene.js";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer.js";
import { throttle } from "@components/studio/src/utils/throttle.js";
import { ToastQueue } from "@react-spectrum/toast";

import { AvatarsManager } from "../babylon/AvatarsManager.js";
import { EnvironmentBuilder } from "../babylon/EnvironmentBuilder.js";
import { ObjectManager } from "../babylon/ObjectManager.js";
import {
    ENV,
    PHOTON_CONFIG,
    PHOTON_MAX_PLAYERS,
    PHOTON_PLAYER_TTL,
    PHOTON_REALTIME_APPID,
} from "@src/config";
import { PinningSession } from "@src/lib/babylon/PinningSession.js";
import { throttleAnimation } from "@src/util/AnimationThrottleUtils.js";
import { DEFAULT_COLOR } from "@src/util/AvatarUtils.js";
import type {
    NetworkAssetData,
    Vector3 as NetworkVector3,
    Quaternion as NetworkQuaternion,
    NetworkTeleportData,
} from "@src/util/NetworkDataUtils";
import {
    HeartbeatData,
    INTEREST_GROUPS,
    NetworkPlayerHighFreqData,
    NetworkPlayerLowFreqData,
    NetworkLaserPointerData,
    PlayerStateFlags,
    packHeartbeatData,
    packHighFreqNetworkAssetData,
    packHighFreqNetworkPlayerData,
    packLowFreqNetworkPlayerData,
    setPackedStateFlag,
    unpackHeartbeatData,
    unpackHighFreqNetworkPlayerData,
    unpackLowFreqNetworkPlayerData,
    unpackHighFreqNetworkAssetData,
    packNetworkTeleportData,
    unpackNetworkTeleportData,
    packNetworkLaserPointerData,
    unpackNetworkLaserPointerData,
    LaserStateFlags,
    LaserSource,
} from "@src/util/NetworkDataUtils";
import { ObjectMode } from "@src/util/PanelUtils.js";

import type { TFunction } from "i18next";

// const HIGH_FREQUENCY_INTERVAL = 33;
const HEARTBEAT_INTERVAL = 1000;

enum EVENT_CODES {
    HEARTBEAT_DATA = 0,
    PLAYER_STATE_FLAGS = 1,
    PLAYER_HIGH_FREQUENCY_DATA = 2,
    ASSET_HIGH_FREQUENCY_DATA = 3,
    LASER_POINTER_HIGH_FREQUENCY_DATA = 4,
    // 5 is reserved for META_AVATAR_DATA
    REQUEST_ASSET_CONTROL = 6,
    RELEASE_ASSET_CONTROL = 7,
    RESET_ASSET = 8,
    TELEPORT = 9,
    AUTO_RESET_ASSET = 10,
}

const LBC = Photon.LoadBalancing.LoadBalancingClient;

export class NetworkManager extends Photon.LoadBalancing.LoadBalancingClient {
    private ticker: Timer | undefined = undefined;
    private heartbeatTicker: Timer | undefined = undefined;

    private assetOwner: number = 0;
    // player number and state flags
    private playerData: Record<number, number> = {};
    // state flags for local player
    private playerStateFlags: number = 0;

    private lastHeartbeatUpdateTime: number = 0;
    private lastLowFreqPlayerUpdateTime: Record<number, number> = {};
    private lastHighFreqPlayerUpdateTime: Record<number, number> = {};
    private lastAssetUpdateTime: number = -1;
    private lastLaserPointerUpdateTime: Record<number, number> = {};

    private scene: Scene | undefined;
    avatarsManager: AvatarsManager | undefined;
    objectManager: ObjectManager | undefined;
    private pinningSession: PinningSession | undefined;
    private envBuilder: EnvironmentBuilder | undefined;

    private rooms: Photon.LoadBalancing.RoomInfo[] = [];

    private usersInTeleport: Set<number> = new Set();

    private t: TFunction<"web">;

    private isLaserPointerModeActive: boolean = false;
    private isLaserPointerVisible: boolean = false;

    private isGizmoSelectionActive: boolean = false;
    private objectSelectionMode: ObjectMode = "none";

    private viewer: AdobeViewer | undefined;
    private displayName: string | undefined;
    private avatarUrl: string | undefined;

    private isSolo: boolean = false;

    constructor(t: TFunction) {
        super(
            PHOTON_CONFIG.Wss
                ? Photon.ConnectionProtocol.Wss
                : Photon.ConnectionProtocol.Ws,
            PHOTON_REALTIME_APPID,
            PHOTON_CONFIG.AppVersion,
        );
        this.t = t;
    }

    dispose() {
        this.avatarsManager?.dispose();
    }

    currentLobbyId: string | undefined;
    reconnectCount = 0;
    canReconnect = false;

    startConnection(lobbyId: string) {
        this.currentLobbyId = lobbyId;
        return new Promise<void>((resolve, reject) => {
            if (PHOTON_CONFIG.NameServer) {
                this.setNameServerAddress(PHOTON_CONFIG.NameServer);
            }

            if (this.isJoinedLobby()) {
                this.disconnect();
            }

            const options = {
                region: PHOTON_CONFIG.Region,
                lobbyName: lobbyId,
                lobbyStats: true,
            };
            this.canReconnect = this.connectToNameServer(options);
            console.log("Can reconnect", this.canReconnect);
            console.log("Attempting to join lobby", { lobbyId });

            // join room when joined to lobby successfully
            const checkJoinedLobby = () => {
                if (this.isJoinedLobby()) {
                    this.requestLobbyStats();
                    clearInterval(checkInterval);
                    clearInterval(timeoutInterval);
                    console.log("Joined lobby", { lobbyId });
                    resolve();
                } else if (this.isJoinedError()) {
                    clearInterval(checkInterval);
                    clearInterval(timeoutInterval);
                    console.error("Running is joined error");
                    reject(new Error("Connection to realtime failed"));
                }
            };

            const checkInterval = setInterval(checkJoinedLobby, 1000);
            const timeoutInterval = setTimeout(() => {
                clearInterval(checkInterval);
                const e = new Error("Timeout while joining realtime lobby");
                console.error(e);

                reject(e);
            }, 30000);
        });
    }

    onError(errorCode: number, errorMsg: string): void {
        console.error(errorMsg, errorCode);
        if (
            this.canReconnect &&
            (this.isJoinedError() || !this.isConnectedToNameServer()) &&
            this.currentLobbyId &&
            this.reconnectCount < 4
        ) {
            setTimeout(
                async () => {
                    if (this.currentLobbyId) {
                        this.reconnectCount++;
                        this.reconnectAndRejoin();
                    }
                },
                3_000 * (this.reconnectCount + 1),
            );
        }
    }

    async joinReviewRoom(
        isSolo: boolean,
        pinningSession: PinningSession,
        roomName: string,
        viewer: AdobeViewer,
        envBuilder: EnvironmentBuilder,
        displayName?: string,
        avatarUrl?: string,
    ) {
        if (!this.isJoinedLobby()) {
            console.log("Not joined to lobby, starting connection");
            await this.startConnection(roomName);
        }
        this.avatarsManager = new AvatarsManager(viewer);
        this.objectManager = new ObjectManager(
            viewer,
            pinningSession,
            envBuilder,
        );
        this.viewer = viewer;
        this.scene = viewer.scene;
        this.pinningSession = pinningSession;
        this.envBuilder = envBuilder;

        let avatarUrlUpdated = avatarUrl;
        if (avatarUrl) {
            const resIndex = avatarUrl.lastIndexOf("/");
            // get 276 resolution profile picture
            const res = avatarUrl.substring(resIndex + 1);
            if (res !== "276") {
                avatarUrl = avatarUrl.substring(0, resIndex + 1).concat("276");
            }
            avatarUrlUpdated = avatarUrl;
        }
        this.avatarUrl = avatarUrlUpdated;
        this.displayName = displayName;

        this.setupPointerEvents(this.scene);
        this.setupGizmoObservables();
        this.setupLaserPointerObservables(this.scene);

        this.isSolo = isSolo;
        if (isSolo) {
            return;
        }

        try {
            this.joinRoom(
                roomName,
                { createIfNotExists: true },
                {
                    isVisible: true,
                    isOpen: true,
                    maxPlayers: PHOTON_MAX_PLAYERS,
                    playerTTL: PHOTON_PLAYER_TTL,
                    lobbyName: roomName,
                    propsListedInLobby: ["vrPlayerCount"],
                },
            );
            this.updatePlayerInfo(displayName ?? "", avatarUrlUpdated ?? "");
            this.changeGroups([INTEREST_GROUPS.VR], [INTEREST_GROUPS.WEB]);

            this.heartbeatTicker = setInterval(() => {
                this.heartbeatTick();
            }, HEARTBEAT_INTERVAL);

            if (!this.scene.activeCamera) {
                throw new Error("Scene has no active camera");
            }
            viewer.scene.onBeforeRenderObservable.add(() => {
                this.avatarsManager?.renderExistingAvatarsAndScale();
            });

            throttleAnimation(
                this.scene.activeCamera.onViewMatrixChangedObservable,
                () => {
                    this.sendPlayerData();
                    // scale reset button
                    if (this.objectManager?.isResetVisible) {
                        this.objectManager?.scaleButton();
                    }
                },
            );
        } catch (error) {
            console.error("Failed to initialize realtime room: ", error);
        }
    }

    setupLaserPointerObservables(scene: Scene) {
        const throttledLaserPointerData = throttle(() => {
            this.sendLaserPointerData();
        }, 100);
        scene.onPointerMove = () => {
            if (this.isLaserPointerVisible) {
                throttledLaserPointerData();
                this.avatarsManager?.handleLaserPointerMove();
            }
        };

        scene.onPointerDown = () => {
            this.turnOnLaserPointer();
        };

        scene.onPointerUp = () => {
            this.turnOffLaserPointer();
        };
    }

    turnOnLaserPointer() {
        if (
            this.isLaserPointerModeActive &&
            !this.pinningSession?.inPinningMode
        ) {
            this.isLaserPointerVisible = true;
            this.sendLaserPointerData();
            this.avatarsManager?.handleLaserPointerDown();
        }
    }

    turnOffLaserPointer() {
        if (this.isLaserPointerVisible) {
            this.isLaserPointerVisible = false;
            this.sendLaserPointerData();
            this.avatarsManager?.handleLaserPointerUp();
        }
    }
  
    setupGizmoObservables() {
        if (!this.objectManager) return;

        const positionGizmo =
            this.objectManager.gizmoManager.gizmos.positionGizmo;
        const rotationGizmo =
            this.objectManager.gizmoManager.gizmos.rotationGizmo;

        if (!positionGizmo || !rotationGizmo) return;

        positionGizmo.onDragStartObservable.add(() => {
            this.sendRequestAssetControl();
            if (!this.objectManager || !this.objectManager.playerRootMesh)
                return;
            this.objectManager.toggleResetVisibility(true);
            this.objectManager.toggleResetDisabled(true);
            this.objectManager.previousPosition =
                this.objectManager.playerRootMesh.position.clone();
            this.objectManager.toggleShadows(false);
        });

        throttleAnimation(positionGizmo.onDragObservable, () => {
            if (!this.objectManager || !this.objectManager.playerRootMesh)
                return;
            if (
                this.assetOwner === this.myActor().actorNr ||
                this.assetOwner === 0 ||
                this.isSolo
            ) {
                if (this.objectManager.isCollidingEnv()) {
                    this.objectManager.playerRootMesh.position =
                        this.objectManager.previousPosition.clone();
                } else {
                    this.objectManager.previousPosition =
                        this.objectManager.playerRootMesh.position.clone();
                }

                this.sendAssetData();
                // Temporariliy disabling auto reset feature for user study
                // this.objectManager.autoReset(
                //     () => this.sendAssetData(),
                //     () => {
                //         this.raiseEvent(EVENT_CODES.AUTO_RESET_ASSET);
                //         this.sendReleaseAssetControl();
                //     },
                // );
            } else if (this.assetOwner !== 0) {
                console.log("You are not the asset owner - disabling gizmos");
                this.objectManager.disableGizmos();
            }
            this.viewer?.renderLoop.toggle();
        });

        positionGizmo.onDragEndObservable.add(() => {
            if (this.objectManager?.isDefaultTransform()) {
                this.objectManager?.toggleResetVisibility(false);
            }
            this.objectManager?.toggleResetDisabled(false);
            this.objectManager?.disableAutoResetVisual();
            this.sendReleaseAssetControl();
            this.objectManager?.toggleShadows(true);
        });

        rotationGizmo.onDragStartObservable.add(() => {
            this.sendRequestAssetControl();
            if (!this.objectManager || !this.objectManager.playerRootMesh)
                return;
            this.objectManager.toggleResetVisibility(true);
            this.objectManager.toggleResetDisabled(true);
            this.objectManager.previousRotation =
                this.objectManager.playerRootMesh.rotation.clone();
            this.objectManager?.toggleShadows(false);
        });

        throttleAnimation(rotationGizmo.onDragObservable, () => {
            if (!this.objectManager || !this.objectManager.playerRootMesh)
                return;
            if (
                this.assetOwner === this.myActor().actorNr ||
                this.assetOwner === 0 ||
                this.isSolo
            ) {
                this.objectManager.playerRootMesh.computeWorldMatrix(true);
                if (this.objectManager.isCollidingEnv()) {
                    this.objectManager.playerRootMesh.rotation =
                        this.objectManager.previousRotation.clone();
                } else {
                    this.objectManager.previousRotation =
                        this.objectManager.playerRootMesh.rotation.clone();
                }
                this.sendAssetData();
            } else if (this.assetOwner !== 0) {
                console.log("You are not the asset owner - disabling gizmos");
                this.objectManager.disableGizmos();
            }
            this.viewer?.renderLoop.toggle();
        });

        rotationGizmo.onDragEndObservable.add(() => {
            if (this.objectManager?.isDefaultTransform()) {
                this.objectManager?.toggleResetVisibility(false);
            }
            this.objectManager?.toggleResetDisabled(false);
            this.sendReleaseAssetControl();
            this.objectManager?.toggleShadows(true);
        });
    }

    setupPointerEvents(scene: Scene) {
        scene.onPointerObservable.add((e: any) => {
            switch (e.type) {
                case PointerEventTypes.POINTERMOVE:
                    if (!this.objectManager?.isResetDisabled) {
                        this.objectManager?.handlePointerMove();
                    }
                    break;
                case PointerEventTypes.POINTERDOWN:
                    if (this.objectManager) {
                        // S4RVR-2792: disable object selection at scroll wheel click
                        if (e.event.button === 1) {
                            break;
                        }
                        if (
                            e.event.button === 2 &&
                            this.objectManager.isResetDisabled
                        ) {
                            // force release of gizmo if user right clicks while dragging
                            this.objectManager.releaseGizmo();
                        } else {
                            this.objectManager.handlePickResetStart(
                                () => this.sendRequestAssetControl(),
                                () => this.sendAssetData(),
                                () => {
                                    this.raiseEvent(
                                        EVENT_CODES.RESET_ASSET,
                                        null,
                                    );
                                    this.sendReleaseAssetControl();
                                },
                            );

                            if (this.isGizmoSelectionActive) {
                                this.objectManager.handlePickMesh(
                                    this.objectSelectionMode,
                                );
                            }
                        }
                    }
                    break;
                case PointerEventTypes.POINTERUP:
                    this.objectManager?.handlePickResetStop();
                    break;
            }
        });
    }

    stopConnection() {
        clearTimeout(this.ticker);
        clearTimeout(this.heartbeatTicker);
        this.dispose();
        this.leaveRoom();
        this.disconnect();
    }

    heartbeatTick() {
        if (!this.isJoinedToRoom()) return;
        // only the host sends heartbeat data
        if (this.isHost()) {
            this.sendHeartbeatData();
        }
    }

    isJoinedLobby(): boolean {
        return this.state() == LBC.State.JoinedLobby;
    }

    isJoinedError(): boolean {
        return this.state() == LBC.State.Error;
    }

    isConnectedToNameServer(): boolean {
        return this.state() == LBC.State.ConnectedToNameServer;
    }

    updateStatus() {
        // updates status
        var statusText = LBC.StateToName(this.state());
        return statusText;
    }

    setIsIdleFlag(isIdle: boolean) {
        this.playerStateFlags = setPackedStateFlag(
            this.playerStateFlags,
            PlayerStateFlags.isIdle,
            isIdle === true ? 1 : 0,
        );
        this.sendPlayerStateFlags();
    }

    handleIsIdleFlag(playerStateFlags: number, actorNr: number) {
        const isIdle =
            (playerStateFlags & PlayerStateFlags.isIdle) === 0 ? false : true;
        // update nameplate visual
        if (actorNr !== this.myActor().actorNr) {
            this.avatarsManager?.updateIsIdleState(actorNr, isIdle);
        }
    }

    /**
     * @summary sets isMuted flag for the local player and sends the updated state flags to all other players
     *
     * @param isMuted
     */
    setIsMutedFlag(isMuted: boolean) {
        this.playerStateFlags = setPackedStateFlag(
            this.playerStateFlags,
            PlayerStateFlags.isMuted,
            isMuted === true ? 1 : 0,
        );
        this.sendPlayerStateFlags();
    }

    handleIsMutedFlag(playerStateFlags: number, actorNr: number) {
        const isMuted =
            (playerStateFlags & PlayerStateFlags.isMuted) === 0 ? false : true;
        // update nameplate visual
        if (actorNr !== this.myActor().actorNr) {
            const actor = this.myRoomActorsArray().find(
                (actor) => actor.actorNr === actorNr,
            );
            if (actor) {
                const { platform } = actor.getCustomProperties();
                this.avatarsManager?.updateIsMutedState(
                    actorNr,
                    isMuted,
                    platform === "VR",
                );
            }
        }
    }

    /**
     * @summary sets isTalking flag for the local player and sends the updated state flags to all other players
     *
     * @param isTalking
     */
    setIsTalkingFlag(isTalking: boolean) {
        this.playerStateFlags = setPackedStateFlag(
            this.playerStateFlags,
            PlayerStateFlags.isTalking,
            isTalking === true ? 1 : 0,
        );
        this.sendPlayerStateFlags();
    }

    /**
     * @summary handles updates to the isTalking flag for a player:
     * 1. updates nameplate visual
     * 2. dispatches window event to presence bubbles component
     *
     * @param playerStateFlags
     * @param actorNr
     */
    handleIsTalkingFlag(playerStateFlags: number, actorNr: number) {
        const isTalking =
            (playerStateFlags & PlayerStateFlags.isTalking) === 0
                ? false
                : true;

        const isMuted =
            (playerStateFlags & PlayerStateFlags.isMuted) === 0 ? false : true;

        // update nameplate visual
        if (!isMuted && actorNr !== this.myActor().actorNr) {
            this.avatarsManager?.updateIsTalkingState(actorNr, isTalking);
        }
    }

    /**
     * @summary sends heartbeat data
     * @param assetOwner -- actor number [actor.actorNr] of actor who owns the asset
     */
    sendHeartbeatData(assetOwner?: number) {
        this.playerData[this.myActor().actorNr] = this.playerStateFlags;
        if (assetOwner) {
            if (this.assetOwner !== assetOwner) this.assetOwner = assetOwner;
        }
        const heartbeatData: HeartbeatData = {
            assetOwner: this.assetOwner,
            playerCount: this.myRoomActorCount(),
            playerData: this.playerData,
        };

        // Cleanup playerData if user has been disconnected
        const playerNrs = [...Object.keys(this.playerData)];
        if (heartbeatData.playerCount < playerNrs.length) {
            const connectedPlayers = Object.keys(this.myRoomActors());
            playerNrs.forEach((playerNr) => {
                if (!connectedPlayers.includes(playerNr)) {
                    this.handleUserLeft(Number(playerNr));
                }
            });
        }

        const packedHeartbeatData = packHeartbeatData(heartbeatData);
        this.raiseEvent(EVENT_CODES.HEARTBEAT_DATA, packedHeartbeatData);
    }

    receiveHeartbeatData(rawData: number[]) {
        const heartbeatData = unpackHeartbeatData(
            rawData,
            this.lastHeartbeatUpdateTime,
        );

        if (!heartbeatData) {
            console.warn("No heartbeat data unpacked");
            return;
        }

        if (window.debugPhoton === true) {
            console.debug("Heartbeat data:", heartbeatData);
        }
        this.lastHeartbeatUpdateTime = heartbeatData.time;

        if (!this.objectManager) {
            console.error("Object manager not initialized");
            return;
        }

        // update asset owner
        if (this.assetOwner != heartbeatData.assetOwner) {
            // reset last asset data update time
            this.lastAssetUpdateTime = 0;
            if (
                heartbeatData.assetOwner == 0 ||
                heartbeatData.assetOwner == undefined
            ) {
                // if nobody owns the asset anybody can reset it
                this.onAssetLockoutEnd();
            } else {
                this.onAssetLockoutStart(heartbeatData.assetOwner);
            }
        }

        this.assetOwner = heartbeatData.assetOwner;

        if (!heartbeatData.playerCount || !heartbeatData.playerData) return;

        this.playerData = heartbeatData.playerData;

        for (const playerNumber in this.playerData) {
            const actorNr = parseInt(playerNumber);
            if (actorNr !== this.myActor().actorNr) {
                if (this.avatarsManager?.hasAvatar(actorNr)) {
                    this.handlePlayerStateFlags(
                        this.playerData[actorNr],
                        actorNr,
                    );
                } else {
                    // spawn avatar if player does not have an existing one
                    const actor = this.getActorByNr(actorNr);
                    if (!actor) {
                        console.warn(
                            "onReceiveHeartbeatData(): trying to spawn avatar for player, no actor data found",
                        );
                        return;
                    }
                    const { avatarUrl, color, platform } =
                        actor.getCustomProperties();
                    this.avatarsManager?.spawnAvatar(
                        actorNr,
                        this.getActorName(actor),
                        avatarUrl,
                        color,
                        platform === "VR",
                    );
                }
            }
        }
    }

    sendPlayerStateFlags() {
        const playerData: NetworkPlayerLowFreqData = {
            packedStateFlags: this.playerStateFlags,
        };
        const packedPlayerData = packLowFreqNetworkPlayerData(playerData);
        this.raiseEvent(EVENT_CODES.PLAYER_STATE_FLAGS, packedPlayerData);
    }

    receivePlayerStateFlags(rawData: number[], actorNr: number) {
        if (this.lastLowFreqPlayerUpdateTime[actorNr] === undefined) {
            this.lastLowFreqPlayerUpdateTime[actorNr] = 0;
        }
        const playerData = unpackLowFreqNetworkPlayerData(
            rawData,
            this.lastLowFreqPlayerUpdateTime[actorNr],
        );
        if (playerData) {
            this.lastLowFreqPlayerUpdateTime[actorNr] = playerData.time ?? 0;

            if (this.avatarsManager?.hasAvatar(actorNr)) {
                this.handlePlayerStateFlags(
                    playerData.packedStateFlags,
                    actorNr,
                );
            }
        }
    }

    handlePlayerStateFlags(playerStateFlags: number, actorNr: number) {
        this.playerData[actorNr] = playerStateFlags;
        this.handleIsMutedFlag(playerStateFlags, actorNr);
        this.handleIsTalkingFlag(playerStateFlags, actorNr);
        this.handleIsIdleFlag(playerStateFlags, actorNr);
    }

    sendPlayerData() {
        const cameraPos = this.avatarsManager?.getCameraPosition();
        const localCameraPosVector = cameraPos?.asArray() as NetworkVector3;

        const playerData: NetworkPlayerHighFreqData = {
            position: localCameraPosVector,
            headPosition: Vector3.Zero().asArray() as NetworkVector3,
            headRotation: Quaternion.Zero().asArray() as NetworkQuaternion,
        };
        const packedPlayerData = packHighFreqNetworkPlayerData(playerData);
        this.raiseEvent(
            EVENT_CODES.PLAYER_HIGH_FREQUENCY_DATA,
            packedPlayerData,
        );
    }

    receivePlayerData(rawData: number[], actorNr: number) {
        if (this.lastHighFreqPlayerUpdateTime[actorNr] === undefined) {
            this.lastHighFreqPlayerUpdateTime[actorNr] = 0;
        }
        const playerData = unpackHighFreqNetworkPlayerData(
            rawData,
            this.lastHighFreqPlayerUpdateTime[actorNr],
        );

        if (playerData) {
            this.lastHighFreqPlayerUpdateTime[actorNr] = playerData.time ?? 0;
            const position = playerData.headPosition.every((val) => val === 0)
                ? playerData.position
                : playerData.headPosition;
            this.avatarsManager?.updateAvatarPosition(
                actorNr,
                position,
                this.lastHighFreqPlayerUpdateTime[actorNr],
                this.objectManager?.playerRootMesh
                    ? this.objectManager.getMeshWorldPosition()
                    : undefined,
            );
        } else {
            console.warn("Player data unsuccessfully unpacked for: ", actorNr);
        }
    }

    sendAssetData() {
        if (this.objectManager) {
            const position = this.objectManager.getPosition();
            const rotation = this.objectManager.getRotation();
            if (!position || !rotation) {
                console.warn(
                    "sendAssetData(): position or rotation data is undefined",
                );
                return;
            }
            const assetData: NetworkAssetData = {
                position: position,
                rotation: rotation,
                scale: 1,
            };
            const packedAssetData = packHighFreqNetworkAssetData(assetData);
            this.raiseEvent(
                EVENT_CODES.ASSET_HIGH_FREQUENCY_DATA,
                packedAssetData,
            );
            console.log("sending asset data");
        }
    }

    receiveAssetData(rawData: number[], sourceActorNr: number) {
        let isInitAssetData = false;
        if (this.lastAssetUpdateTime === -1) {
            // this is the first time we are receiving the asset data after joining the room
            isInitAssetData = true;
        }
        const assetData = unpackHighFreqNetworkAssetData(
            rawData,
            this.lastAssetUpdateTime,
        );

        if (!this.objectManager) return;

        if (assetData) {
            this.lastAssetUpdateTime = assetData.time ?? 0;
            if (isInitAssetData) {
                if (
                    !this.objectManager.isDefaultTransform(
                        assetData.position,
                        assetData.rotation,
                    )
                ) {
                    // asset has been moved prior to joining room
                    this.objectManager?.toggleResetVisibility(true);
                }
            }

            if (this.assetOwner && this.assetOwner != this.myActor().actorNr) {
                const position = assetData.position;
                this.avatarsManager?.displayNetworkLaserObjectGrabber(
                    sourceActorNr,
                    new Vector3(
                        (this.objectManager.metadataRootMesh?.position.x || 0) +
                            position[0],
                        (this.objectManager.metadataRootMesh?.position.y || 0) +
                            position[1],
                        (this.objectManager.metadataRootMesh?.position.z || 0) +
                            position[2],
                    ),
                );
            }

            this.objectManager.updatePosition(assetData.position);
            this.objectManager.updateRotation(assetData.rotation);

            this.viewer?.renderLoop.toggle();
        }
    }

    sendRequestAssetControl() {
        console.log("request asset control");
        this.raiseEvent(EVENT_CODES.REQUEST_ASSET_CONTROL, null, {
            receivers: Photon.LoadBalancing.Constants.ReceiverGroup.All,
        });
        this.sendLaserPointerData(true);
    }

    sendReleaseAssetControl() {
        console.log("release asset control");
        this.raiseEvent(EVENT_CODES.RELEASE_ASSET_CONTROL, null, {
            receivers: Photon.LoadBalancing.Constants.ReceiverGroup.All,
        });
        this.sendLaserPointerData();

        this.objectManager?.releaseGizmo();
    }

    sendLaserPointerData(isInGrabbingState: boolean = false) {
        const endPosition = isInGrabbingState
            ? this.objectManager?.playerRootMesh?.position
            : this.avatarsManager?.localPointerTarget;

        const stateFlag = isInGrabbingState
            ? LaserStateFlags.grabbingObject
            : this.isLaserPointerVisible
              ? LaserStateFlags.pointingObject
              : LaserStateFlags.idle;

        const laserPointerData: NetworkLaserPointerData = {
            laserSource: LaserSource.webCursor,
            packedStateFlags: stateFlag,
        };

        if (stateFlag !== LaserStateFlags.idle && endPosition) {
            laserPointerData["endPosition"] = [
                endPosition.x,
                endPosition.y,
                endPosition.z,
            ];
        }

        const packedLaserPointerData =
            packNetworkLaserPointerData(laserPointerData);

        this.raiseEvent(
            EVENT_CODES.LASER_POINTER_HIGH_FREQUENCY_DATA,
            packedLaserPointerData,
        );
    }

    receiveLaserPointerData(content: any, sourceActorNr: number) {
        const unpackedLaserPointerData = unpackNetworkLaserPointerData(
            content,
            this.lastLaserPointerUpdateTime[sourceActorNr],
        );
        if (unpackedLaserPointerData) {
            const shouldDisplay =
                unpackedLaserPointerData?.packedStateFlags ===
                LaserStateFlags.pointingObject;

            const endPosition = unpackedLaserPointerData?.endPosition;

            this.lastLaserPointerUpdateTime[sourceActorNr] =
                unpackedLaserPointerData?.time ?? 0;

            const avatar = this.avatarsManager?.getAvatarMap()[sourceActorNr];
            if (shouldDisplay && endPosition) {
                // override name plate visibility option
                if (!this.avatarsManager?.isNameplateVisible) {
                    if (avatar) {
                        avatar.isVisible = true;
                    }
                }
                this.avatarsManager?.displayNetworkLaserPointer(
                    sourceActorNr,
                    new Vector3(endPosition[0], endPosition[1], endPosition[2]),
                );
            } else {
                if (!this.avatarsManager?.isNameplateVisible) {
                    if (avatar) {
                        avatar.isVisible = false;
                    }
                }
                this.avatarsManager?.removeNetworkLaserPointer(sourceActorNr);
            }
        }
    }

    receiveTeleportData(content: any, sourceActorNr: number) {
        const unpackedTeleportData = unpackNetworkTeleportData(content);
        const start = sourceActorNr;
        const target = unpackedTeleportData.targetActorNumber;
        const isCurrentActorTarget = this.myActor().actorNr === target;
        const teleportDuration = 1000;

        //disable the camera if somebody is teleporting to me
        if (isCurrentActorTarget) {
            this.avatarsManager?.disableCamera();
        }

        const avatarMap = this.avatarsManager?.getAvatarMap();
        //if target avatar does not exist in the room - do not attempt to teleport
        if (avatarMap && !(target in avatarMap) && !isCurrentActorTarget) {
            return;
        }
        this.usersInTeleport.add(start);
        //this.usersInTeleport.add(target);
        this.avatarsManager?.getNetworkTeleportEffect(
            start,
            target,
            this.myActor().actorNr,
        );

        setTimeout(() => {
            if (isCurrentActorTarget && !this.isLaserPointerModeActive) {
                this.avatarsManager?.enableCamera();
            }
            this.usersInTeleport.clear();
            if (this.myActor().actorNr == target) {
                const avatarMap = this.avatarsManager?.getAvatarMap();
                if (avatarMap && avatarMap[start]) {
                    console.log(
                        "showing toast now for username",
                        avatarMap[start]?.name,
                    );

                    ToastQueue.info(
                        `${this.t("web:toast.review.userTeleportedToYou", { userName: avatarMap[start]?.name })}`,
                        {
                            timeout: 5000,
                        },
                    );
                }
            }
        }, teleportDuration);
    }

    /**
     * @summary Updates the player's info with the display name and avatar url
     */
    updatePlayerInfo(displayName: string, avatarUrl: string) {
        this.myActor().setName(displayName);
        const properties = {
            avatarUrl: avatarUrl,
            platform: "WEB",
        };
        this.myActor().setCustomProperties(properties);
    }

    isHost() {
        return this.myRoomMasterActorNr() === this.myActor().actorNr;
    }

    checkVersion() {
        // version checking
        const roomVersion = this.myRoom().getCustomProperty("version");
        if (roomVersion !== PHOTON_CONFIG.AppVersion) {
            // TODO: placeholder for version mismatch handling
            console.error("Room version mismatch");
            this.disconnect();
            return;
        }
    }

    getTotalPlayerCountInRoom(roomName: string) {
        const room = this.rooms.find((room) => room.name === roomName);
        return room ? room.playerCount : 0;
    }

    getVRPlayerCountInRoom(roomName: string) {
        const room = this.rooms.find((room) => room.name === roomName);
        return room ? room.getCustomProperty("vrPlayerCount") : 0;
    }

    /**
     * @summary called when another user has control of the asset
     *
     * @param ownerNr actor number of the asset owner
     */
    onAssetLockoutStart(ownerNr: number) {
        if (!this.objectManager) return;

        // disable gizmos if not the owner
        if (ownerNr !== this.myActor().actorNr) {
            this.objectManager.disableGizmos();
        }

        // hide pins
        if (this.pinningSession?.pinsVisible) {
            this.pinningSession?.togglePinsVisibility(false);
        }

        // highlight mesh
        if (ownerNr === this.myActor().actorNr) {
            this.objectManager.highlightMesh();
        } else {
            const owner = this.getActorByNr(ownerNr);
            this.objectManager.highlightMesh(
                owner?.getCustomProperties().color,
            );
            const objectPosition = this.objectManager.getMeshWorldPosition();
            this.avatarsManager?.displayNetworkLaserObjectGrabber(
                ownerNr,
                objectPosition,
            );

            // override name plate visibility option
            if (
                this.avatarsManager &&
                !this.avatarsManager.isNameplateVisible
            ) {
                const avatar = this.avatarsManager.getAvatarMap()[ownerNr];
                if (avatar) {
                    avatar.isVisible = true;
                }
            }
        }

        // show reset button in disabled state
        this.objectManager.toggleResetVisibility(true);
        this.objectManager.toggleResetDisabled(true);

        // send window event
        if (ownerNr !== this.myActor().actorNr) {
            this.objectManager.emitIsAssetLocked(true);
        }

        this.objectManager.toggleShadows(false);

        this.viewer?.renderLoop.toggle();
    }

    onAssetLockoutEnd() {
        if (!this.objectManager) return;

        // unhighlight mesh
        if (this.objectManager.isGizmoEnabled) {
            this.objectManager.highlightMesh();
        } else {
            this.objectManager.unhighlightMesh();
            // show pins
            this.pinningSession?.togglePinsVisibility(true);
            this.avatarsManager?.removeNetworkLaserPointer();
        }

        // show reset button in enabled state
        this.objectManager.toggleResetDisabled(false);

        if (this.objectManager.isDefaultTransform()) {
            this.objectManager.toggleResetVisibility(false);
        }

        // respect name plate visibility option
        if (this.avatarsManager && !this.avatarsManager.isNameplateVisible) {
            this.avatarsManager.toggleNameplateVisibility(false);
        }

        this.objectManager.emit("isAssetLocked", false);

        this.objectManager.toggleShadows(true);

        this.viewer?.renderLoop.toggle();
    }

    handleUserLeft(actorNr: number) {
        // update heartbeat data
        delete this.playerData[actorNr];
        this.lastHeartbeatUpdateTime = 0;
        this.lastAssetUpdateTime = 0;

        // update asset owner if player who left was asset owner
        if (this.assetOwner === actorNr) {
            this.assetOwner = 0;

            this.objectManager?.emitIsAssetLocked(false);
            this.sendReleaseAssetControl();
            this.objectManager?.unhighlightMesh();
        }
        // remove avatar and color for actor
        this.avatarsManager?.removeUser(actorNr);

        // host migration -- if the host has left and you are now the new host, takeover responsibilities
        if (this.isHost()) {
            this.heartbeatTicker = setInterval(() => {
                this.heartbeatTick();
            }, HEARTBEAT_INTERVAL);

            this.myRoom().setCustomProperty(
                "version",
                PHOTON_CONFIG.AppVersion,
            );

            // update VR player count
            const actor = this.getActorByNr(actorNr);
            if (actor?.getCustomProperty("platform") === "VR") {
                const vrPlayerCount =
                    this.myRoom().getCustomProperty("vrPlayerCount");
                this.myRoom().setCustomProperty(
                    "vrPlayerCount",
                    vrPlayerCount - 1,
                );
            }
        }

        this.avatarsManager?.emit("leave", actorNr);

        this.viewer?.renderLoop.toggle(500);
    }

    // OVERRIDES
    onEvent(code: number, content: any, actorNr: number) {
        try {
            switch (code) {
                case EVENT_CODES.HEARTBEAT_DATA:
                    this.receiveHeartbeatData(content);
                    break;
                case EVENT_CODES.PLAYER_STATE_FLAGS:
                    this.receivePlayerStateFlags(content, actorNr);
                    break;
                case EVENT_CODES.PLAYER_HIGH_FREQUENCY_DATA:
                    this.receivePlayerData(content, actorNr);
                    break;
                case EVENT_CODES.ASSET_HIGH_FREQUENCY_DATA:
                    this.receiveAssetData(content, actorNr);
                    break;
                case EVENT_CODES.LASER_POINTER_HIGH_FREQUENCY_DATA:
                    this.receiveLaserPointerData(content, actorNr);
                    break;
                case EVENT_CODES.REQUEST_ASSET_CONTROL:
                    if (this.isHost() && this.objectManager) {
                        if (this.assetOwner == 0) {
                            this.lastAssetUpdateTime = 0;
                            this.assetOwner = actorNr;
                            this.onAssetLockoutStart(actorNr);
                            this.sendHeartbeatData(actorNr);
                        }
                    }
                    break;
                case EVENT_CODES.RELEASE_ASSET_CONTROL:
                    if (this.isHost() && this.objectManager) {
                        this.assetOwner = 0;
                        this.sendHeartbeatData();
                        this.onAssetLockoutEnd();
                    }
                    break;
                case EVENT_CODES.RESET_ASSET:
                    if (this.objectManager) {
                        this.objectManager.autoResetEnabled = false;
                        this.objectManager.toggleResetVisibility(false);
                    }
                    this.queueResetAssetToast(this.getActorNameByNr(actorNr));

                    break;
                case EVENT_CODES.TELEPORT:
                    this.receiveTeleportData(content, actorNr);
                    break;
                case EVENT_CODES.AUTO_RESET_ASSET:
                    if (this.objectManager) {
                        this.objectManager.autoResetEnabled = false;
                        this.objectManager.toggleResetVisibility(false);
                    }
                    // no toast for auto resetting
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error("Error handling event", {
                code,
                content,
                actorNr,
                e,
            });
        }
    }

    /**
     * @summary Callback when the client joins a room; overrides the default
     * behavior provided by Photon.
     * 1. Spawns avatars for all existing users in the room
     * 2. Master client:
     *   a. Assigns user colors for all users in the room
     *   b. Sets room version
     * 3. Non-master clients:
     *   a. Checks room version for compatibility
     *   b. Gets color assigned to all other users and updates local copy of color map
     * 4. Send local state flags and player data to all other players
     */
    onJoinRoom() {
        // populate avatars for all existing users in the room
        const currentActors = this.myRoomActorsArray();
        currentActors.forEach((actor) => {
            let color;
            if (this.isHost()) {
                // init player data for all existing actors in the room
                this.playerData[actor.actorNr] = 0;

                // host assigns user colors
                color = this.avatarsManager?.assignUserColor(actor.actorNr);
                actor.setCustomProperty("color", color);

                // set version for room
                this.myRoom().setCustomProperty(
                    "version",
                    PHOTON_CONFIG.AppVersion,
                );

                // init VR user count
                this.myRoom().setCustomProperty("vrPlayerCount", 0);

                // spawn avatar for all existing actors
                const { avatarUrl, platform } = actor.getCustomProperties();
                this.avatarsManager?.spawnAvatar(
                    actor.actorNr,
                    this.getActorName(actor),
                    avatarUrl,
                    color ?? DEFAULT_COLOR,
                    platform === "VR",
                );
            } else {
                // local actor will receive color assignment from master client
                if (!actor.isLocal) {
                    // get color from custom property
                    color = actor.getCustomProperties().color;
                    this.avatarsManager?.updateUserColor(actor.actorNr, color);
                }
            }
        });

        if (!this.isHost()) {
            this.checkVersion();
        }

        this.sendPlayerData();
        // send local state flags to all other players
        this.sendPlayerStateFlags();
    }

    onActorJoin(actor: Photon.LoadBalancing.Actor): void {
        const { avatarUrl, platform } = actor.getCustomProperties();
        const displayName = this.getActorName(actor);

        console.log(
            `new player joined: ${actor.actorNr} ${displayName} ${platform}`,
        );

        // check for duplicate user on prod only
        if (ENV === "prod") {
            if (
                this.myActor().actorNr < actor.actorNr &&
                this.myActor().name == displayName
            ) {
                console.log("Duplicate user detected");
                this.onDuplicateUserDetected();
                return;
            }
        }

        this.avatarsManager?.removeUserMeshes(actor.actorNr);
        if (this.isHost()) {
            // update this.playerData with new actor
            this.playerData[actor.actorNr] = 0;
            // send heartbeat data with new actor
            this.sendHeartbeatData();

            // send asset data once to catch up new user
            this.sendAssetData();

            // assign color and set custom property to notify all other users
            const color = this.avatarsManager?.assignUserColor(actor.actorNr);
            if (color) {
                actor.setCustomProperty("color", color);
            }

            // spawn avatar for new actor -- note that non master clients will spawn avatar
            // on receiving onActorPropertiesChange callback
            if (!actor.isLocal) {
                this.avatarsManager?.spawnAvatar(
                    actor.actorNr,
                    displayName,
                    avatarUrl,
                    color ?? DEFAULT_COLOR,
                    platform === "VR",
                );
            }

            if (platform === "VR") {
                const vrPlayerCount =
                    this.myRoom().getCustomProperty("vrPlayerCount");
                this.myRoom().setCustomProperty(
                    "vrPlayerCount",
                    vrPlayerCount + 1,
                );
            }
        }
        this.sendPlayerData();

        this.avatarsManager?.emit("join", actor.actorNr);
    }

    onActorPropertiesChange(actor: Photon.LoadBalancing.Actor): void {
        console.log("Actor properties changed: " + actor);
        this.avatarsManager?.removeUser(actor.actorNr);
        if (this.assetOwner === actor.actorNr) {
            this.sendReleaseAssetControl();
            this.objectManager?.unhighlightMesh();
        }

        const { avatarUrl, color, platform } = actor.getCustomProperties();
        this.avatarsManager?.updateUserColor(actor.actorNr, color);

        if (!actor.isLocal) {
            this.avatarsManager?.spawnAvatar(
                actor.actorNr,
                this.getActorName(actor),
                avatarUrl,
                color ?? DEFAULT_COLOR,
                platform === "VR",
            );
        }
    }

    onActorLeave(actor: Photon.LoadBalancing.Actor): void {
        console.log("player left: " + actor.actorNr);
        this.handleUserLeft(actor.actorNr);
    }

    onActorSuspend(actor: Photon.LoadBalancing.Actor): void {
        console.log("actor suspended: " + actor.actorNr);
    }

    onMyRoomPropertiesChange(): void {
        this.checkVersion();
    }

    onRoomList(rooms: Photon.LoadBalancing.RoomInfo[]): void {
        this.rooms = rooms;
    }

    onRoomListUpdate(rooms: Photon.LoadBalancing.RoomInfo[]): void {
        this.rooms = rooms;
        const event = new CustomEvent("onRoomListUpdate");
        window.dispatchEvent(event);
    }

    getActorByNr(actorNr: number) {
        const actor = this.myRoomActorsArray().find(
            (actor) => actor.actorNr === actorNr,
        );
        return actor;
    }

    getActorNameByNr(actorNr: number) {
        const actor = this.getActorByNr(actorNr);
        if (actor) {
            return this.getActorName(actor);
        } else {
            throw new Error("getActorNameByNr(): actor not found");
        }
    }

    getActorName(actor: Photon.LoadBalancing.Actor) {
        if (actor) {
            if (!actor.name || actor.name === "") {
                return actor.getCustomProperties().displayName;
            } else {
                return actor.name;
            }
        }
    }

    getAvatarsManager() {
        return this.avatarsManager;
    }

    onTeleportStart(target: Photon.LoadBalancing.Actor): void {
        console.log("teleport started: " + target.actorNr);
        this.avatarsManager?.disableCamera();

        const teleportData: NetworkTeleportData = {
            targetActorNumber: target.actorNr,
        };

        this.usersInTeleport.add(this.myActor().actorNr);
        //this.usersInTeleport.add(target.actorNr);

        const packedTeleportData = packNetworkTeleportData(teleportData);
        this.raiseEvent(EVENT_CODES.TELEPORT, packedTeleportData);

        if (this.avatarsManager) {
            const remoteAvatar = this.avatarsManager.getRemoteAvatar(
                target.actorNr,
            );
            const targetAvatar = this.avatarsManager.hasAvatar(target.actorNr);
            if (!remoteAvatar || !targetAvatar) {
                throw new Error("no avatar found for teleporting");
            }
            const targetPosition = remoteAvatar.position;

            if (targetAvatar) {
                targetAvatar.isVisible = false;
            }

            const currentTimestamp = Date.now();
            this.avatarsManager.getLocalTeleportEffect(currentTimestamp);

            setTimeout(() => {
                this.avatarsManager?.updateCameraPosition(targetPosition);
                if (!this.isLaserPointerModeActive) {
                    this.avatarsManager?.enableCamera();
                }
                this.sendPlayerData();
                this.usersInTeleport.clear();
                if (targetAvatar) {
                    targetAvatar.isVisible = true;
                }
            }, 1000);
        }
    }

    isUserInTeleport(actorNr: number) {
        return this.usersInTeleport.has(actorNr);
    }

    activateLaserPointerMode() {
        if (!this.avatarsManager || this.isLaserPointerModeActive) {
            return;
        }
        this.isLaserPointerModeActive = true;
        this.pinningSession?.setPinsSelectable(false);
        this.avatarsManager.activateLaserPointer();
        this.objectManager?.activateLaserPointerMode();
    }

    deactivateLaserPointerMode() {
        if (!this.avatarsManager || !this.isLaserPointerModeActive) {
            return;
        }
        this.isLaserPointerModeActive = false;
        this.pinningSession?.setPinsSelectable(true);
        this.avatarsManager.deactivateLaserPointer();
        this.objectManager?.deactivateLaserPointerMode();
    }

    toggleGizmoSelectionMode(isActive: boolean, selectionMode: ObjectMode) {
        this.isGizmoSelectionActive = isActive;
        this.objectSelectionMode = selectionMode;
        if (this.objectManager?.isGizmoEnabled) {
            this.objectManager?.switchGizmoSelectionMode(selectionMode);
        }
    }

    queueResetAssetToast(userName: string) {
        console.log("reset: ", userName);
        ToastQueue.info(
            `${this.t("toast.review.userResetObject", { userName })}`,
            {
                timeout: 5000,
            },
        );
    }

    onDuplicateUserDetected() {
        if (!this.isJoinedToRoom()) return;
        const sendDupeMessage = () => {
            window.postMessage({ type: "duplicateUserDetected" }, "*");
        };
        sendDupeMessage();
    }
}
