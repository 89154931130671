/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { MessagingContextProvider } from "@shared/client";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./lib/i18n";
import "./lib/new-relic/externals";
import { App } from "./App";
import { ROUTER_BASE_PATH } from "./config";
import { AcpContextProvider } from "./contexts/AcpContext";
import { HI5UserProvider } from "./contexts/HI5UserProvider";
import { IngestContextProvider } from "./contexts/IngestContext";
import { LocaleProvider } from "./contexts/LocaleContext";
import { NetworkProvider } from "./contexts/NetworkContext";
import { PostMessageProvider } from "./contexts/PostMessageContext";
import { ThemeProvider } from "./contexts/ThemeContext";

document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("app-root");
    if (container) {
        const root = ReactDOM.createRoot(container);
        root.render(
            <BrowserRouter basename={ROUTER_BASE_PATH}>
                <ThemeProvider>
                    <LocaleProvider>
                        <NetworkProvider>
                            <HI5UserProvider>
                                <PostMessageProvider>
                                    <AcpContextProvider>
                                        <MessagingContextProvider>
                                            <IngestContextProvider>
                                                <App />
                                            </IngestContextProvider>
                                        </MessagingContextProvider>
                                    </AcpContextProvider>
                                </PostMessageProvider>
                            </HI5UserProvider>
                        </NetworkProvider>
                    </LocaleProvider>
                </ThemeProvider>
            </BrowserRouter>,
        );
    }
});
