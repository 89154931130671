/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Vector3 } from "@babylonjs/core/Maths/math.vector";

import { Pin, PinState } from "./mesh/Pin";

export interface PinGroup {
  id: string;
  hostPin: Pin;
  members: Pin[];
  groupPin?: Pin;
}

export class PinGroupManager {
  pinDistanceMap: Record<string, Record<string, number>> = {};
  lastGroups: Record<string, PinGroup> = {};
  pinGroups: Record<string, PinGroup> = {};
  pins: Record<string, Pin> = {};
  pinToGroupMap: Record<string, PinGroup> = {};
  ungroupedPins: Record<string, Pin> = {};

  lastDistance = 0;

  constructor(private groupPinBuilder: (count: number) => Pin) {}


  buildDistanceMap(pins: Pin[]) {
    this.pinDistanceMap = {};

    // only for visible pins
    const visiblePins = pins.filter(pin => pin.visible);

    this.pins = visiblePins.reduce((acc, pin) => {
      if (!pin.pinData) {
        console.error("Group manager given pin with no data");
        throw new Error("Pin data required in all pins");
      }

      acc[pin.pinData?.id] = pin;
      return acc;
    }, {} as Record<string, Pin>);
    this.ungroupedPins = { ...this.pins };

    for (const hostPin of visiblePins) {
      this.updateDistanceMapForPin(hostPin, visiblePins);
    }
  }

  isGroupPin(pin: Pin) {
    return Object.values(this.pinGroups).some(group => group.groupPin === pin);
  }

  updateDistanceMapForPin(hostPin: Pin, pins: Pin[]) {
    if (!hostPin.pinData) throw new Error("Pin data required in all pins");

    if (!this.pinDistanceMap[hostPin.pinData.id])
      this.pinDistanceMap[hostPin.pinData.id] = {};

    for (const neighborPin of pins) {
      if (hostPin === neighborPin) continue;
      if (!neighborPin.pinData)
        throw new Error("Pin data required in all pins");

      this.pinDistanceMap[hostPin.pinData.id][neighborPin.pinData.id] =
        Vector3.Distance(hostPin.head.position, neighborPin.head.position);
    }
  }

  updateDistance(
    distanceUpdate: number,
    animate: boolean,
) {
    this.lastDistance = distanceUpdate;
    this.lastGroups = { ...this.pinGroups };
    this.pinGroups = {};
    this.pinToGroupMap = {};
    this.ungroupedPins = { ...this.pins };

    this.groupUngroupedPins();
    this.updatePinVisuals(animate);
}

  groupUngroupedPins() {
    for (const leftPin of Object.values(this.pins)) {
      if (!leftPin.isEnabled) {
        delete this.ungroupedPins[leftPin.pinData!.id];
        continue;
      }
      for (const rightPin of Object.values(this.ungroupedPins)) {
        if (!rightPin.isEnabled) {
          delete this.ungroupedPins[rightPin.pinData!.id];
          continue;
        }
        if (leftPin === rightPin || !this.ungroupedPins[leftPin.pinData!.id])
          continue;
        if (
          this.pinDistanceMap[leftPin.pinData!.id][rightPin.pinData!.id] <
          this.lastDistance
        ) {
          if (this.pinGroups[leftPin.pinData!.id]) {
            this.pinGroups[leftPin.pinData!.id].members.push(rightPin);
          } else {
            this.pinGroups[leftPin.pinData!.id] = {
              hostPin: leftPin,
              id: leftPin.pinData!.id,
              members: [rightPin],
            };
          }
          this.pinToGroupMap[rightPin.pinData!.id] =
            this.pinGroups[leftPin.pinData!.id];
          delete this.ungroupedPins[rightPin.pinData!.id];
        }
      }
      if (this.pinGroups[leftPin.pinData!.id]) {
        delete this.ungroupedPins[leftPin.pinData!.id];
      }
    }
  }

  updatePinVisuals(animate: boolean) {
    for (const pinGroup of Object.values(this.pinGroups)) {
      const count = pinGroup.members.length + 1;
      const spriteIndex = Math.min(count - 2, 8);

      const existingGroupPin = this.lastGroups[pinGroup.id]?.groupPin;
      if (existingGroupPin) {
        pinGroup.groupPin = existingGroupPin;
        pinGroup.groupPin.head.cellIndex = pinGroup.groupPin.spriteIndex =
          spriteIndex;
        delete this.lastGroups[pinGroup.id];
      } else if (!pinGroup.groupPin) {
        pinGroup.groupPin = this.groupPinBuilder(spriteIndex);
      }

      let occludedCount = 0;
      const averagePosition = [...pinGroup.members, pinGroup.hostPin]
        .reduce((vector, pin) => {
          if (pin.state === PinState.occluded) {
            occludedCount++;
          }

          pin.setVisible(false, animate);

          return vector.add(pin.head.position);
        }, new Vector3())
        .scale(1 / count);

      pinGroup.groupPin.updatePinLocation(
        averagePosition,
        new Vector3(1, 0, 0)
      );
      pinGroup.groupPin.clampPinSize();
      pinGroup.groupPin.setInitialState(
        occludedCount === count ? PinState.occluded : PinState.normal
      );
    }

    for (const pin of Object.values(this.ungroupedPins)) {
      pin.setVisible(true, animate);
    }
    Object.values(this.lastGroups).forEach((pinGroup) => {
      if (pinGroup.groupPin) {
        pinGroup.groupPin.setVisible(false, animate, () => {
          pinGroup.groupPin?.dispose();
        });
      }
    });
  }

  destroy() {
    for (const pinGroup of Object.values(this.pinGroups)) {
      if (pinGroup.groupPin) {
        pinGroup.groupPin.dispose();
      }
      pinGroup.hostPin.setVisible(true);
      pinGroup.members.forEach((pin) => {
        pin.setVisible(true);
      });
    }
  }
}
