/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, ButtonGroup, View } from "@adobe/react-spectrum";
import { Popover } from "@react-spectrum/overlays";
import { Placement } from "@react-types/overlays";
import { useEffect, useState, CSSProperties, useRef, Key } from "react";
import { useTranslation } from "react-i18next";

import "@spectrum-css/tokens";
import "@spectrum-css/popover";
import "@spectrum-css/coachmark";

import { UI_SCALE } from "@src/config";
import { useOnboardingContext } from "@src/contexts/OnboardingContext";
import { useThemeContext } from "@src/contexts/ThemeContext";

interface Props {
    title: string;
    content: string;
    currentStep: number;
    totalStepCount: number;
    setCurrentStep: (nextStep: number) => void;
    skipTour: () => void;
    anchorElementRef: HTMLElement | null | undefined;
    placement: Placement;
    offset: number;
    crossOffset: number;
}

interface CoachMarkCSSProperties extends CSSProperties {
    "--mod-coachmark-min-width": string;
    "--mod-coachmark-max-width": string;
    "--mod-coachmark-width": string;
    "--spectrum-popover-height": string;
    "--spectrum-popover-width": string;
}

function CoachMark({
    title,
    content,
    currentStep,
    setCurrentStep,
    totalStepCount,
    skipTour,
    anchorElementRef,
    placement,
    offset,
    crossOffset,
}: Props) {
    const { isDarkMode } = useThemeContext();
    const { t } = useTranslation("web");
    const isLastStep = currentStep === totalStepCount - 1;
    const shouldShow = currentStep < totalStepCount;

    const cssProps: CSSProperties = {};
    if (anchorElementRef) {
        const rect = anchorElementRef.getBoundingClientRect();
        const [horz, vert] = placement.split(" ");

        if (horz === "left") {
            cssProps["right"] = `${window.innerWidth - rect.left + offset}px`;
        } else if (horz === "right") {
            cssProps["left"] = `${rect.right + offset}px`;
        } else if (horz === "start") {
            cssProps["left"] = `${rect.left + offset}px`;
        } else if (horz === "end") {
            cssProps["right"] = `${window.innerWidth - rect.right + offset}px`;
        }
        if (vert === "top") {
            cssProps["top"] = `${rect.top + crossOffset}px`;
        } else if (vert === "bottom") {
            cssProps["top"] = `${rect.bottom + crossOffset}px`;
        }
    }

    return (
        shouldShow &&
        anchorElementRef && (
            <View
                UNSAFE_className={`spectrum spectrum--${UI_SCALE} spectrum--${isDarkMode ? "dark" : "light"} spectrum-CoachMark`}
                UNSAFE_style={
                    {
                        "--mod-coachmark-min-width": "0px",
                        "--mod-coachmark-max-width": "0px",
                        "--mod-coachmark-width": "0px",
                        "--spectrum-popover-height": "142px",
                        "--spectrum-popover-width": "50px",
                    } as CoachMarkCSSProperties
                }>
                <View
                    UNSAFE_style={{
                        position: "relative",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        inlineSize: "var(--spectrum-popover-width)",
                    }}>
                    <View
                        UNSAFE_className={`spectrum-Popover is-open spectrum-Popover--sizeM spectrum-CoachMark-popover`}
                        UNSAFE_style={{
                            ...{
                                position: "fixed",
                                inlineSize: "var(--spectrum-coach-mark-width)",
                                zIndex: 1000,
                            },
                            ...cssProps,
                        }}>
                        <View
                            UNSAFE_style={{
                                zIndex: 1,
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                alignItems: "stretch",
                            }}>
                            <View
                                UNSAFE_className={`spectrum-CoachMark-header`}>
                                <View
                                    UNSAFE_className={`spectrum-CoachMark-title`}>
                                    {t(`${title}`)}
                                </View>
                            </View>
                            <View
                                UNSAFE_className={`spectrum-CoachMark-content`}>
                                {t(`${content}`)}
                            </View>
                            <View
                                UNSAFE_className={`spectrum-CoachMark-footer`}>
                                <View
                                    UNSAFE_className={`spectrum-CoachMark-step`}>
                                    <bdo dir="ltr">{`${currentStep + 1} of ${totalStepCount}`}</bdo>
                                </View>
                                <ButtonGroup
                                    UNSAFE_className={`spectrum-CoachMark-buttongroup`}
                                    UNSAFE_style={{
                                        justifyContent: "flex-end",
                                    }}>
                                    <Button
                                        variant="secondary"
                                        onPress={skipTour}>
                                        Skip tour
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onPress={() => {
                                            setCurrentStep(currentStep + 1);
                                        }}>
                                        {isLastStep ? "Done" : "Next"}
                                    </Button>
                                </ButtonGroup>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    );
}

export function OnboardingCoachMark() {
    const {
        state,
        currentStep,
        setCurrentStep,
        currentStepSpec,
        totalStepCount,
        anchorElementRef,
        finishOnboarding,
    } = useOnboardingContext();
    const { isOpen } = state;
    const anchorElement = useRef<HTMLElement>(anchorElementRef || null);
    const [key, setKey] = useState<Key>();
    useEffect(() => {
        // Changing key to force re-render of coachmark popover so that it is interactable
        // when the anchorElementRef is in a popover itself
        setKey(anchorElementRef?.id + "-mark");
    }, [anchorElementRef]);

    return (
        isOpen &&
        currentStepSpec && (
            <>
                <style>
                    {`
                    .outline-highlight {
                        outline: 2px solid var(--spectrum-alias-focus-color) !important;
                        z-index: 10;
                    }

                    .border-highlight {
                        border: 2px solid var(--spectrum-alias-focus-color) !important;
                        z-index: 10;
                    }

                    .background-highlight {
                        --spectrum-high-contrast-highlight-text: white;
                        background-color: var(--spectrum-alias-focus-color) !important;
                        border-color: var(--spectrum-alias-focus-color) !important;
                        color: var(--spectrum-global-color-gray-900)!important;
                    }
                `}
                </style>
                <Popover
                    UNSAFE_style={{
                        width: "100vw",
                        position: "fixed",
                        top: "0px",
                        height: "100vh",
                        visibility: "hidden",
                    }}
                    key={key}
                    state={state}
                    triggerRef={anchorElement}
                    hideArrow>
                    <CoachMark
                        title={currentStepSpec.title}
                        content={currentStepSpec.content}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        skipTour={finishOnboarding}
                        totalStepCount={totalStepCount}
                        anchorElementRef={anchorElementRef}
                        placement={currentStepSpec.placement || ""}
                        offset={currentStepSpec.offset || 0}
                        crossOffset={currentStepSpec.crossOffset || 0}
                    />
                </Popover>
            </>
        )
    );
}
