/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    DialogTrigger,
    Dialog,
    Content,
    Flex,
    ActionButton,
    View,
    RadioGroup,
    Radio,
    Tooltip,
    TooltipTrigger,
    Heading,
    Text
} from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import { useTranslation } from "react-i18next";

import { MicMuted, MicUnmuted } from "@src/components/custom-icons/MicIcon";
import { useIsHover } from "@src/hooks/useIsHover";

interface MangeMicState {
    currentAudioDeviceId: string;
    audioDevices: MediaDeviceInfo[];
    muted: boolean;
    authorizeMic: boolean;
    setMuted: (data: boolean) => void;
    setDevice: (deviceId: string) => void;
    isSolo: boolean;
}

export function MicrophoneInput({
    currentAudioDeviceId,
    audioDevices,
    muted,
    authorizeMic,
    setMuted,
    setDevice,
    isSolo,
}: MangeMicState) {
    const { t } = useTranslation("web");
    const { isHovered, setIsHoveredRef } = useIsHover();

    function renderMicPermissionDeniedUI() {
        return (
            <Dialog>
                <Heading>{t("audio.permissionDenied.header")}</Heading>
                <Content marginTop="size-100">
                    <Text>{t("audio.permissionDenied.details")}</Text>
                </Content>
            </Dialog>
        )
    }

    let tooltipText;
    if (isSolo) {
        tooltipText = t("nap.tooltip.mute.solo");
    } else if (muted) {
        tooltipText = t("nav.tooltip.unmute");
    } else {
        tooltipText = t("nav.tooltip.mute");
    }

    return (
        <View>
            <Flex
                direction="row"
                alignItems="center"
                UNSAFE_style={{
                    top: "5px",
                }}>
                <TooltipTrigger isOpen={isSolo ? isHovered : undefined}>
                    <ActionButton
                        ref={(ref) => {setIsHoveredRef(ref?.UNSAFE_getDOMNode())}}
                        isDisabled={isSolo || !authorizeMic}
                        isQuiet
                        onPress={() => {
                            //set mic is on
                            setMuted(muted);
                        }}>
                        {muted ? <MicMuted /> : <MicUnmuted />}
                    </ActionButton>
                    <Tooltip>{tooltipText}</Tooltip>
                </TooltipTrigger>

                <DialogTrigger
                    type="popover"
                    placement="bottom"
                    hideArrow={true}>
                    <ActionButton
                        isDisabled={isSolo}
                        isQuiet
                        UNSAFE_style={{
                            right: "10px",
                        }}>
                        <ChevronDown />
                    </ActionButton>
                    {!authorizeMic ? renderMicPermissionDeniedUI() : 
                        <Dialog>
                            <Content>
                                {
                                    <RadioGroup
                                        label={t("audio.micInput")}
                                        name={t("audio.micInput")}
                                        value={currentAudioDeviceId}
                                        onChange={setDevice}>
                                        {audioDevices.map((device) => (
                                            <Radio
                                                key={device.deviceId}
                                                value={device.deviceId}>
                                                {device.label}
                                            </Radio>
                                        ))}
                                    </RadioGroup>
                                }
                            </Content>
                        </Dialog>
                    }
                </DialogTrigger>
            </Flex>
        </View>
    );
}
