/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { JanusRoutes as Routes } from "@shared/common/src/routes/JanusRoutes";
import { generatePath } from "react-router-dom";


export function getLandingPath() {
    return generatePath(Routes.index.path);
}

export function getHomePath() {
    return generatePath(Routes.home.path);
}

export function getReviewPath(assetUrn: string) {
    return generatePath(Routes.homeModal.path , {
        modal: "enter",
        assetId: assetUrn
    });
}

export function getReviewPagePath(assetUrn: string) {
    return generatePath(Routes.twoUp.path) + '?asset-id=' + assetUrn;
}

export function getMetaVRAppRedirectPath() {
    return generatePath(Routes.metaVrAppRedirect.path);
}

export type HomeModal = "ingest" | "preview" | "environment" | "share" | "enter";

export function getHomeModalPath(modal: HomeModal, assetId: string) {
    return generatePath(Routes.homeModal.path, {
        modal,
        assetId,
    });
}
