/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PHOTON_MAX_PLAYERS } from "@src/config";

import type { TFunction } from "i18next";

export function getTrimmedString(defaultString: string, maxLength = 20) {
    if (defaultString.length > maxLength) {
        return `${defaultString.substring(0, maxLength)}...`;
    }
    return defaultString;
}

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
    { unit: "year", ms: 31536000000 },
    { unit: "month", ms: 2628000000 },
    { unit: "day", ms: 86400000 },
    { unit: "hour", ms: 3600000 },
    { unit: "minute", ms: 60000 },
    { unit: "second", ms: 1000 },
];

export function getRelativeFormattedTime(modifyDate: string, locale: string) {
    if (!modifyDate) return "";
    const rtf = new Intl.RelativeTimeFormat(locale, { style: "long" });
    const elapsed = Date.parse(modifyDate) - Date.now();
    for (const { unit, ms } of units) {
        if (Math.abs(elapsed) >= ms || unit === "second") {
            return rtf.format(Math.round(elapsed / ms), unit);
        }
    }
    return "";
}

export function getFormattedDate(date: string, locale: string) {
    if (!date) return "";
    return new Intl.DateTimeFormat(locale, {
        dateStyle: "long",
        timeStyle: "long",
        timeZone: "UTC",
    }).format(Date.parse(date));
}
/**
 * Returns the lowercase file extension for the given name. If `name` does not have an
 * extension, the defaultExtension is returned.
 */
export function fileExtension(name: string, defaultExtension: string): string {
    return (
        name
            ?.match(/\.[^/.]+$/)
            ?.toString()
            .toLowerCase() || defaultExtension.toLowerCase()
    );
}

export function getCurrentTime(locale: string) {
    if (!locale)
        return new Intl.DateTimeFormat("en-US", { timeStyle: "short" }).format(
            Date.now(),
        );

    const shortTime = new Intl.DateTimeFormat(locale, {
        timeStyle: "short",
    }).format(Date.now());
    return shortTime;
}

export function isValidEmailFormat(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

export type Units = "mm" | "cm" | "m";

export function getFormattedPhysicalSize(sizeArray: number[]) {
    const physicalSizeCm = sizeArray.map((size: number) => size * 100);
    let physicalSizeUnit: Units = "cm";
    let formattedPhysicalSize;
    if (
        physicalSizeCm.find((size: number) => size > 1000) ||
        physicalSizeCm.every((size: number) => size > 100)
    ) {
        formattedPhysicalSize = sizeArray.map((size: number) =>
            size.toFixed(1),
        );
        physicalSizeUnit = "m";
    } else {
        formattedPhysicalSize = physicalSizeCm.map((size: number) =>
            size.toFixed(0),
        );
    }
    return { formattedPhysicalSize, physicalSizeUnit };
}

export function getSizeString(modelSize: number[] | undefined, t: TFunction) {
    if (modelSize) {
        const { formattedPhysicalSize, physicalSizeUnit } =
            getFormattedPhysicalSize(modelSize);
        return t("web:infoPanel.displayName.physicalSize.displayValue", {
            val1: formattedPhysicalSize[0],
            val2: formattedPhysicalSize[1],
            val3: formattedPhysicalSize[2],
            physicalSizeUnit: physicalSizeUnit,
        });
    }
    return "";
}

export function getSizeStrings(modelSize: number[] | undefined, t: TFunction) {
    if (modelSize) {
        const { formattedPhysicalSize, physicalSizeUnit } =
            getFormattedPhysicalSize(modelSize);
        return formattedPhysicalSize.map((size, i) => ({
            string: t(
                "infoPanel.displayName.physicalSize.displayValue.individual",
                {
                    val: size,
                    physicalSizeUnit: physicalSizeUnit,
                },
            ),
            value: modelSize[i],
        }));
    }
    return [];
}

export function fileNameToExtension(fileName: string) {
    return `.${fileName.split(".").pop()}`;
}

export function getUniqueFileName(
    newFileName: string,
    existingFileNames: string[],
): string {
    const extension = newFileName.split(".").pop();
    const baseName = newFileName.replace(`.${extension}`, "");
    const nameSet = new Set(existingFileNames);

    let count = 1;
    let safeName = baseName;
    while(nameSet.has(safeName)) {
        safeName = `${baseName} (${count})`;
        count++;
    }

    return `${safeName}.${extension}`;
}

export function getPlayerCountString(playerCount: number, t: TFunction) {
    if (playerCount != 1) {
        return t("enterReview.playerCount.plural", {
            val: playerCount,
            max: PHOTON_MAX_PLAYERS,
        });
    } else {
        return t("enterReview.playerCount.singular", {
            val: playerCount,
            max: PHOTON_MAX_PLAYERS,
        });
    }
}

export function isValidAssetName(assetName: string) {
    return !(assetName.length > 200 ||
        assetName.endsWith(".") ||
        assetName.trim().length === 0 ||
        assetName.match(/[\\/*?"|:<>]/))
}
