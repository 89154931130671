/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useMessagingContext } from "@shared/client";
import { useEffect } from "react";

import { useAcpContext } from "@src/contexts/AcpContext";
import { isAutomationUser } from "@src/contexts/HI5UserProvider";
import CreateSampleAsset from "@src/pages/dialogs/CreateSampleAsset";

export function useCreateSampleDialog() {
    const { useMyReviews } = useAcpContext();
    const { showModal } = useMessagingContext();

    const { data: myReviews, isFetched } = useMyReviews();

    useEffect(() => {
        const disabled = isAutomationUser();
        if (!disabled  && isFetched && myReviews?.length === 0) {
            showModal(<CreateSampleAsset />);
        }
    }, [myReviews, isFetched]);
}
