/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export function debounce<T extends unknown[]>(
    fn: (...args: T) => void,
    durationInMilliseconds: number,
) {
    let timer: number | undefined;
    return (...args: T) => {
        clearTimeout(timer);
        timer = window.setTimeout(() => {
            fn.apply(null, args);
        }, durationInMilliseconds);
    };
}
