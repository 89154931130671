/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { JobClient, JobsApiConfig } from "@shared/client";
import { JobGraph } from "@shared/common/src/jobs/graph";
import { useState } from "react";

import { ASSET_APIS, HI5_API_KEY } from "@src/config";
import { QualityKey, useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

const apiConfig: JobsApiConfig = {
    url: ASSET_APIS.substance3d,
    key: HI5_API_KEY,
};

const AllKeys: QualityKey[] = ["low", "medium", "high"];

export function getComponentQualityPath(quality: QualityKey) {
    return `renditions/compactedModel_${quality}.glb`;
}

export function useOptimizeAsset(assetId: string) {
    const { accessToken } = useHi5UserContext();
    const { acpClient, queryClient } = useAcpContext();

    const [progress, setProgress] = useState(0);
    const [running, setRunning] = useState(false);
    const [complete, setComplete] = useState(false);
    const [cancelOptimize, setCancelOptimize] = useState(() => () => {});
    const [error, setError] = useState("");

    const optimize = async () => {
        if (!accessToken) throw new Error("No accessToken");
        try {
            const model = await acpClient.getGlbComponent(assetId);
            if (!model)
                throw new Error("Failed to get GLB component for model");
            const getSpec = (quality: QualityKey) =>
                new JobGraph((job) => {
                    job.httpsAcpComponentDownload(
                        {
                            imsToken: accessToken,
                            assetId,
                            componentId: model.id,
                            version: model.version!,
                        },
                        "model/.glb",
                    )
                        .modelCompact({
                            quality,
                            textureFormat: "jpg-png",
                        }, "model/.glb")
                        .httpsAcpComponentUpload({
                            imsToken: accessToken,
                            assetId,
                            componentPath: getComponentQualityPath(quality),
                            componentName: `compactedModel_${quality}`,
                        });
                });

            const updateProgress = () => {
                const progress =
                    jobs.reduce((acc, val) => acc + val.progress, 0) /
                    jobs.length;
                setProgress(progress);
            };

            const jobs: JobClient[] = AllKeys.map((quality) => {
                const spec = getSpec(quality);
                const job = new JobClient(
                    accessToken,
                    apiConfig,
                    spec.body.spec,
                );

                job.on("progress", updateProgress);

                return job;
            });
            setRunning(true);
            setCancelOptimize(() => () => {
                jobs.forEach((job) => {
                    job.removeAllListeners();
                    job.cancel();
                });
                setRunning(false);
                setComplete(false);
                setProgress(0);
            });
            window.onbeforeunload = () =>
                "You are still optimizing assets would you like to cancel?";

            Promise.all(jobs.map((job) => job.jobCompletePromise))
                .then(() => {
                    setComplete(true);
                    setRunning(false);
                })
                .catch(() => {
                    setError("Conversion jobs failed");
                    setRunning(false);
                    setComplete(false);
                })
                .finally(() => {
                    window.onbeforeunload = null;
                    queryClient.invalidateQueries({
                        queryKey: ["glbOptimizedUrls", assetId],
                    });
                });
        } catch (e) {
            setError("Failed to create conversion jobs");
            setRunning(false);
            setComplete(false);
        }
    };

    return {
        progress,
        running,
        complete,
        error,
        optimize,
        cancelOptimize,
    };
}
