/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { PropsWithChildren } from "react";

export function IngestDialogWrapper({ children }: PropsWithChildren) {
    return (
        <View
            id="ingest-modal"
            width="90vw"
            height="90vh"
            backgroundColor="gray-200"
            padding="size-300"
            UNSAFE_style={{ boxSizing: "border-box" }}>
            <Flex direction="row" width="100%" height="100%">
                {children}
            </Flex>
        </View>
    );
}
