/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Placement } from "@react-types/overlays";

import { isAutomationUser } from "@src/contexts/HI5UserProvider";

interface VisualEffect {
    elementId: string;
    effect: string;
}

export interface OnboardingStepSpec {
    title: string;
    content: string;
    placement: Placement;
    offset?: number;
    crossOffset?: number;
    anchorElementId: string;
    visualEffects: Array<VisualEffect>;
}

const OFFSET = 10;

export const ONBOARDING_COACH_MARKS_SPECS: Array<OnboardingStepSpec> = [
    {
        title: "onboarding.intro.title",
        content: "onboarding.intro.content",
        placement: "right top",
        offset: OFFSET,
        anchorElementId: "orbit",
        visualEffects: [{ elementId: "orbit", effect: "background-highlight" }],
    },
    {
        title: "onboarding.cameraControls.title",
        content: "onboarding.cameraControls.content",
        placement: "right top",
        offset: OFFSET,
        anchorElementId: "cameraControl",
        visualEffects: [
            { elementId: "orbit", effect: "background-highlight" },
            { elementId: "cameraControl", effect: "outline-highlight" },
        ],
    },
    {
        title: "onboarding.laserPointer.title",
        content: "onboarding.laserPointer.content",
        placement: "right top",
        offset: OFFSET,
        anchorElementId: "pointer",
        visualEffects: [
            { elementId: "pointer", effect: "background-highlight" },
        ],
    },
    {
        title: "onboarding.objectManipulation.title",
        content: "onboarding.objectManipulation.content",
        placement: "right top",
        offset: OFFSET,
        anchorElementId: "objectPanelButtons",
        visualEffects: [
            { elementId: "select", effect: "background-highlight" },
            { elementId: "objectPanelButtons", effect: "border-highlight" },
            {
                elementId: "objectPanelButtons-select",
                effect: "background-highlight",
            },
        ],
    },
    {
        title: "onboarding.comments.title",
        content: "onboarding.comments.content",
        placement: "left top",
        offset: OFFSET,
        anchorElementId: "commentPanel",
        visualEffects: [
            { elementId: "commentPanel", effect: "outline-highlight" },
        ],
    },
    {
        title: "onboarding.share.title",
        content: "onboarding.share.content",
        placement: "end bottom",
        crossOffset: OFFSET,
        anchorElementId: "shareButton",
        visualEffects: [
            { elementId: "shareButton", effect: "outline-highlight" },
        ],
    },
    {
        title: "onboarding.openInVr.title",
        content: "onboarding.openInVr.content",
        placement: "end bottom",
        crossOffset: OFFSET,
        anchorElementId: "openInVRButton",
        visualEffects: [
            { elementId: "openInVRButton", effect: "outline-highlight" },
        ],
    },
    {
        title: "onboarding.refresher.title",
        content: "onboarding.refresher.content",
        placement: "left top",
        offset: OFFSET,
        anchorElementId: "orientation",
        visualEffects: [
            { elementId: "orientation", effect: "outline-highlight" },
        ],
    },
];

const ONBOARDING_SESSION_ID = "onboardingSessionId";

//keeping track of users who have gone through the tutorial in the current session
export function addUserHasCompletedOnboarding(userId: string) {
    const currentMap = getUserHasCompletedOnboardingMap();
    if (userId) {
        localStorage.setItem(
            ONBOARDING_SESSION_ID,
            JSON.stringify({ ...currentMap, [userId]: true }),
        );
    }
}

export function getUserHasCompletedOnboardingMap() {
    const onboardedUsers = localStorage.getItem(ONBOARDING_SESSION_ID);
    return onboardedUsers ? JSON.parse(onboardedUsers) : {};
}

export function hasUserCompletedOnboarding(userId: string) {
    if (isAutomationUser()) {
        return true;
    }
    const currentMap = getUserHasCompletedOnboardingMap();

    return userId in currentMap;
}
