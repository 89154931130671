/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View, Flex, Header, Image, Text, Button, Divider, Link as LinkSpectrum } from "@adobe/react-spectrum";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useLocaleContext } from "../../contexts/LocaleContext";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import landingImage from "@src/assets/images/rw_landing.png";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function SignedOut() {
    const { t } = useTranslation(["common", "vr"]);
    const { locale, changeLocale } = useLocaleContext();
    const { logIn, userProfile, imsReady, authState } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const useSUSI = searchParams.get("use-susi") || undefined;

    function resizeWindowAndLogIn() {
        if (!postMessageBusApi) return;
        postMessageBusApi.resizeWindow("log-in-required-susi");
        logIn();
    }

    useEffect(() => {
        changeLocale(locale);
    }, [])

    function quitAppVR() {
        if (postMessageBusApi) postMessageBusApi.quitApp();
    }

    function openOculusBrowser() {
        const url = window.location.href;
        const lastSlash =  window.location.href.lastIndexOf("/");
        const domain = url.substring(0, lastSlash);

        let metaSsso = domain + "/meta-sso";
        if (postMessageBusApi) {
            postMessageBusApi.interceptLink(
                metaSsso,
            );
        }
    }

    return (
        <View padding="20px">
            <Flex 
                direction="column" 
                gap="20px"
                justifyContent="start"
                alignItems="center">
                    <Header
                        UNSAFE_style={{
                            fontSize: "2.0em",
                            fontWeight: "bold",
                        }}>
                        {t("web:home.welcome")}
                    </Header>
                    <Image
                        width="80%"
                        minWidth="100px"
                        minHeight="56px"
                        alt={t("common:header.appIcon")}
                        src={landingImage}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                    <Flex 
                        direction="column" 
                        justifyContent="center"
                        alignItems="center"
                        marginTop="1vh"
                        width="80%">
                        <Text
                            alignSelf="flex-start"
                            UNSAFE_style={{
                                fontSize: "1.35em",
                                fontWeight: "bold",
                            }}>
                            {t("vr:signedOut.logInOnWeb")}
                        </Text>
                        <Divider size="M"/>
                        <Text
                            alignSelf="flex-start"
                            UNSAFE_style={{
                                fontSize: "1.2em",
                            }}>
                            {t("vr:signedOut.webInstructions.1")}
                            <u>{t("vr:signedOut.webInstructions.2")}</u>
                            {t("vr:signedOut.webInstructions.3")}
                        </Text>
                    </Flex>
                <Flex
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="80%"
                    marginTop="2vh"
                    gap="size-200">
                    <Flex direction="row" gap="0.3em">
                        <Text
                            UNSAFE_style={{
                                fontSize: "1em",
                                fontWeight: "bold",
                                color: "var(--spectrum-global-color-gray-400)"
                            }}>
                            {t("vr:signedOut.alternateSignIn.1")}
                        </Text>
                        <LinkSpectrum
                            onPress={openOculusBrowser}
                            UNSAFE_style={{
                                fontWeight: "bold",
                                color: "var(--spectrum-global-color-gray-400)"
                            }}
                            variant="secondary">
                            {t("vr:signedOut.alternateSignIn.2")}
                        </LinkSpectrum>
                    </Flex>
                    <Flex>
                        <Button
                            onPress={quitAppVR}
                            width="auto"
                            variant="cta"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.0em",
                                }}>
                                {t("vr:signedOut.quitReviewer.button")}
                            </Text>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </View>
    );
}
