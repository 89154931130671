/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionGroup,
    Item,
    Flex,
    Tabs,
    TabList,
    TabPanels,
    Text,
} from "@adobe/react-spectrum";
import ClassicGridView from "@spectrum-icons/workflow/ClassicGridView";
import ViewList from "@spectrum-icons/workflow/ViewList";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SortAndFilterView } from "./SortAndFilterView";
import { useAcpContext } from "@src/contexts/AcpContext";
import {
    ContentTab,
    ContentTabsKeys,
    useContentListContext,
} from "@src/contexts/ContentListContext";
import { useIsVRPage } from "@src/hooks/useIsVRPage";

export type ContentLayout = "grid" | "list";

export function AssetTabsView() {
    const { t } = useTranslation("common");
    const { selectedTab, changeTab, updateLoadedLists } =
        useContentListContext();

    const { useMyReviews, useSharedWithMeReviews } = useAcpContext();

    const { data: yourAssets, isFetched: assetsFetched } = useMyReviews();
    const { data: sharedWithYouAssets, isFetched: sharedFetched } =
        useSharedWithMeReviews();

    const [layout, setLayout] = useState<ContentLayout>("grid");
    const defaultTab: ContentTab = ContentTabsKeys[0];

    const { isVRPage } = useIsVRPage();

    // this useEffect sets the default tab based on whether the user has uploaded assets, when assets have loaded
    useEffect(() => {
        if (!assetsFetched || !sharedFetched) return;
        if (!(yourAssets && sharedWithYouAssets)) return;
        if (yourAssets.length === 0 && sharedWithYouAssets.length > 0) {
            changeTab(ContentTabsKeys[1]);
        }
    }, [yourAssets, sharedWithYouAssets, assetsFetched, sharedFetched]);

    useEffect(() => {
        if (assetsFetched) updateLoadedLists("yourReviews");
        if (sharedFetched) updateLoadedLists("sharedWithYou");
    }, [assetsFetched, sharedFetched]);

    return (
        <Tabs
            selectedKey={selectedTab || defaultTab}
            onSelectionChange={changeTab}>
            <Flex direction="row" justifyContent="space-between">
                <TabList minWidth={isVRPage ? "288px" : ""}>
                    <Item
                        key={ContentTabsKeys[0]}
                        aria-label={t("tabs.yourReviews")}>
                        <Text
                            UNSAFE_style={{
                                fontSize: isVRPage ? "1.6em" : "",
                            }}>
                            {t("tabs.yourReviews")}
                        </Text>
                    </Item>
                    <Item
                        key={ContentTabsKeys[1]}
                        aria-label={t("tabs.shared")}>
                        <Text
                            UNSAFE_style={{
                                fontSize: isVRPage ? "1.6em" : "",
                            }}>
                            {t("tabs.shared")}
                        </Text>
                    </Item>
                </TabList>
                <ActionGroup
                    selectionMode="single"
                    selectedKeys={new Set(layout)}
                    // We don't need to worry about  "all" if no multi select
                    onSelectionChange={(key) =>
                        setLayout([...key][0] as ContentLayout)
                    }
                    disallowEmptySelection={true}
                    isQuiet
                    alignSelf="center">
                    <Item
                        key="list"
                        aria-label={t("accessibility.tabs.toggleList")}>
                        <ViewList />
                    </Item>
                    <Item
                        key="grid"
                        aria-label={t("accessibility.tabs.toggleGrid")}>
                        <ClassicGridView />
                    </Item>
                </ActionGroup>
            </Flex>
            <TabPanels>
                <Item
                    key={ContentTabsKeys[0]}
                    aria-label={t("tabs.yourReviews")}>
                    <SortAndFilterView
                        assets={yourAssets}
                        layout={layout}
                    />
                </Item>
                <Item key={ContentTabsKeys[1]} aria-label={t("tabs.shared")}>
                    <SortAndFilterView
                        assets={sharedWithYouAssets}
                        layout={layout}
                    />
                </Item>
            </TabPanels>
        </Tabs>
    );
}
