/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import { ToastQueue } from "@react-spectrum/toast";
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

import { EnvironmentBuilder } from "@src/lib/babylon/EnvironmentBuilder";
import { type PinningSession } from "@src/lib/babylon/PinningSession";
import { NetworkManager } from "@src/lib/photon/NetworkManager";
import { NetworkVoiceManager } from "@src/lib/photon/NetworkVoiceManager";

export type JoinReviewOptions = {
    isSolo: boolean;
    pinningSession: PinningSession | undefined;
    assetId: string;
    viewer: AdobeViewer | undefined;
    displayName: string;
    avatarUrl: string;
    envBuilder: EnvironmentBuilder | undefined;
};

export interface NetworkContextValue {
    connected: boolean;
    networkManager: NetworkManager | null;
    voiceManager: NetworkVoiceManager | null;
}

export const NetworkContext = createContext<NetworkContextValue>(
    {} as NetworkContextValue,
);

export function useNetworkContext() {
    const context = useContext(NetworkContext);
    if (!context) {
        throw new Error(
            "useNetworkContext must be used within a NetworkContext.Provider",
        );
    }
    return context;
}

export function NetworkProvider({ children }: PropsWithChildren) {
    const { t } = useTranslation("web");
    const [connected, setConnected] = useState(true);
    const lastDisconnected = useRef(0);
    const networkManager = useMemo(() => new NetworkManager(t), []);
    const voiceManager = useMemo(() => new NetworkVoiceManager(), []);

    useEffect(() => {
        // Use this when debugging locally
        // const url = "https://stage.3dshare.substance3d.adobe.com/" //
        const url = "/";
        let timer = 0;
        function poll() {
            timer = window.setTimeout(() => {
                fetch(url, { method: "HEAD" })
                    .then(() => {
                        setConnected(true);
                    })
                    .catch(() => {
                        setConnected(false);
                    })
                    .finally(() => {
                        poll();
                    });
            }, 3_000);
        }
        poll();
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (connected && lastDisconnected.current) {
            ToastQueue.positive(t("toast.network.connectionRestored"), {
                timeout: 5000,
            });
        }
        if (!connected) {
            lastDisconnected.current = Date.now();
            ToastQueue.negative(t("toast.network.connectionInterrupted"), {
                timeout: 5000,
            });
        }
    }, [connected]);

    return (
        <NetworkContext.Provider
            value={{ connected, networkManager, voiceManager }}>
            {children}
        </NetworkContext.Provider>
    );
}
