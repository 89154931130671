/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export type SerializedVector3 = [number, number, number];

export interface SerializedPin {
  id: string;
  cameraPosition: SerializedVector3;
  pinWorldPosition: SerializedVector3;
  pinNormal: SerializedVector3;
  modelPath?: string[];
  pinModelLocalPosition?: SerializedVector3;
  pinUnscaledModelRootPosition?: SerializedVector3;
  meta?: { [key: string]: unknown };
}

export enum PinEvents {
  pinManagerInitialized = "pinManagerInitialized",
  pinSelected = "pinSelected",
  pinDeselected = "pinDeselected",
  pinHoverStart = "pinHoverStart",
  pinHoverEnd = "pinHoverEnd",
  cameraMove = "cameraMove",
}

export interface PinEvent {
  pointer?: {
    x: number;
    y: number;
  };
  boundingBox: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  pinData: SerializedPin;
}
