/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useOpenInVR } from "../hooks/useOpenInVR";
import { getHomePath, getReviewPagePath } from "../util/PathUtils";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { clearMetaUserIdCache } from "@src/hooks/useMetaLinkedAccount"
import { useMetaLinkedAccount } from "@src/hooks/useMetaLinkedAccount"
import {useIsMetaBrowser} from "@src/hooks/useIsMetaBrowser";
import { useRedirects } from "@src/hooks/useRedirects";

export function MetaSSO() {
    const location = useLocation();
    const navigate = useNavigate();
    const hashString = location.hash && location.hash.substring(1);
    const { accessToken } = useHi5UserContext();
    const { getMetaId } = useOpenInVR(accessToken);
    const { acpClient } = useAcpContext();
    const { homeRedirect, metaVRAppRedirect } = useRedirects();

    const isMeta = useIsMetaBrowser();
    useEffect(() => {
        if (!accessToken) return;
        console.log("MetaSSO: isMeta", isMeta);
        if (accessToken === undefined) {
            // not signed in, redirect to Home
            console.log("MetaSSO: not signed in, redirect to Home");
            homeRedirect();
            return;
        }

        try {
            const decoded = atob(hashString);
            const parsedData = JSON.parse(decoded);

            if (parsedData?.org_scoped_id) {
              saveMetaUserData(parsedData.org_scoped_id);
            } else {
              console.warn("org_scoped_id is missing in parsed data");
            }
          } catch (error) {
            console.error("Failed to decode or parse hashString:", error);
          }

        if (isMeta) {
            // if user is in Meta browser, redirect to Meta deeplink
            metaVRAppRedirect();
        } else {
            // if user is not in Meta browser, redirect to Home
            homeRedirect();
        }

    }, [accessToken, isMeta]);

    async function saveMetaUserData(orgScopedId: string) {
        const cloudDir = await acpClient.getDirectory("cloud-content");
        const cloudDirAssetId = cloudDir["repo:assetId"];

        if (!(cloudDirAssetId && orgScopedId)) return;
        const metaUserData = await getMetaId(orgScopedId);
        if (!metaUserData || !metaUserData.userId) {
            // error getting userId from Meta, redirect to Home
            console.error("invalid Meta userId");
            homeRedirect();
            return;
        }
        const { userId, userIdAlias } = metaUserData;

        // save id and alias into cloudContentApplicationMetadataUrl so we don't have to prompt user to link Meta account again
        await acpClient.setApplicationMeta(
            cloudDirAssetId,
            {
                "highfive:metaUserId": userId,
                "highfive:metaUserIdAlias": userIdAlias,
            }
        );

        clearMetaUserIdCache();
        useMetaLinkedAccount();
    }

    async function getMetaUserData(orgScopedId: string) {
        saveMetaUserData(orgScopedId);
        // success, navigate back to review that the SSO was initiated from
        const savedReviewUrn = localStorage.getItem("savedReviewUrn");
        if (savedReviewUrn)
            navigate(`${getReviewPagePath(savedReviewUrn)}&accountLinked=true`);
    }

    return <div />;
}
