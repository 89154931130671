/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    TooltipTrigger,
    Tooltip,
    ToggleButton,
    Divider,
} from "@adobe/react-spectrum";
import { FocusableRefValue } from "@react-types/shared";
import { useTranslation } from "react-i18next";

import { LaserPointerTooltipView } from "./LaserPointerTooltipView";
import { useIsHover } from "@src/hooks/useIsHover";
import { NavigationMode, ObjectMode, PanelButton } from "@src/util/PanelUtils";

interface NavigationPanelSectionProps {
    sectionName: string;
    buttons: PanelButton[];
    temporaryNavigationMode: NavigationMode | undefined;
    selectedNavigationKey: NavigationMode;
    setSelectedNavigationKey: (navigationKey: NavigationMode) => void;
    includeDivider?: boolean;
}

export function NavigationPanelSection({
    sectionName,
    buttons,
    temporaryNavigationMode,
    selectedNavigationKey,
    setSelectedNavigationKey,
    includeDivider = false,
}: NavigationPanelSectionProps) {
    const { t } = useTranslation("web");
    const { isHovered, hoveredRef, setIsHoveredRef } = useIsHover();

    const temporaryNavigationKey = !!temporaryNavigationMode
        ? temporaryNavigationMode
        : undefined;

    function setRefs(
        ref: FocusableRefValue<HTMLButtonElement, HTMLButtonElement>,
        buttonKey: ObjectMode,
    ) {
        if (buttonKey === ("pointer" as ObjectMode)) {
            setIsHoveredRef(ref?.UNSAFE_getDOMNode());
        }
    }

    return (
        <div id={`Coachmark-${sectionName}`}>
            {buttons.map((button: PanelButton) => {
                return (
                    <div key={button.key}>
                        {hoveredRef && (
                            <LaserPointerTooltipView
                                hoveredRef={hoveredRef}
                                isHovered={isHovered}
                            />
                        )}
                        <TooltipTrigger
                            placement="end"
                            key={button.key}
                            isDisabled={button.key === "pointer"}>
                            <ToggleButton
                                id={`Coachmark-${button.key}`}
                                ref={ (ref) => {ref && setRefs(ref, button.key as ObjectMode)} }
                                isQuiet
                                isSelected={
                                    temporaryNavigationKey === button.key ||
                                    (selectedNavigationKey === button.key &&
                                        !temporaryNavigationKey)
                                }
                                isDisabled={
                                    temporaryNavigationKey &&
                                    temporaryNavigationKey !== button.key
                                }
                                onPress={() => {
                                    setSelectedNavigationKey(
                                        button.key as NavigationMode,
                                    );
                                }}
                                aria-label={t(button.label)}
                                UNSAFE_style={{
                                    gap: "14px",
                                    margin: "5px 0px",
                                }}>
                                {button.icon}
                            </ToggleButton>
                            <Tooltip>{t(button.tooltip)}</Tooltip>
                        </TooltipTrigger>
                    </div>
                );
            })}
            {includeDivider && <Divider size="S" />}
        </div>
    );
}
