/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

import { useCameraControlsToggle } from "../hooks/useCameraControlsToggle";
import { DEFAULT_TOGGLE_MODE } from "../scene/PanningArcRotateCameraPointersInput";
import { DollySvg } from "../svg/camera-dolly";
import { CameraOrbit } from "../svg/camera-orbit";
import { CameraPan } from "../svg/camera-pan";

import type { ToggleMode } from "../scene/PanningArcRotateCameraPointersInput";
import type { SceneManager } from "../scene/SceneManager";

export const dropShadowStyle = "drop-shadow(2px 2px 2px gray)";
export interface CameraControlsProps {
    sceneManager?: SceneManager;
    strings: {
        cameraDolly: string;
        cameraPan: string;
        cameraOrbit: string;
    };
}

export function CameraControls({ strings, sceneManager }: CameraControlsProps) {
    const { mode, setMode } = useCameraControlsToggle(sceneManager);

    const toggleMode = (buttonMode: ToggleMode) => {
        return () => {
            if (mode !== buttonMode) {
                setMode(buttonMode);
            } else {
                setMode(DEFAULT_TOGGLE_MODE);
            }
        };
    };

    return (
        <>
            <TooltipTrigger placement="right">
                <Button
                    UNSAFE_style={{
                        pointerEvents: "auto",
                        filter: dropShadowStyle,
                    }}
                    style="fill"
                    variant="accent"
                    staticColor={mode === "rotate" ? undefined : "white"}
                    onPress={toggleMode("rotate")}>
                    <CameraOrbit />
                </Button>
                <Tooltip>{strings.cameraOrbit}</Tooltip>
            </TooltipTrigger>
            <TooltipTrigger placement="right">
                <Button
                    UNSAFE_style={{
                        pointerEvents: "auto",
                        filter: dropShadowStyle,
                    }}
                    style="fill"
                    variant="accent"
                    staticColor={mode === "dolly" ? undefined : "white"}
                    onPress={toggleMode("dolly")}>
                    <DollySvg />
                </Button>
                <Tooltip>{strings.cameraDolly}</Tooltip>
            </TooltipTrigger>
            <TooltipTrigger placement="right">
                <Button
                    UNSAFE_style={{
                        pointerEvents: "auto",
                        filter: dropShadowStyle,
                    }}
                    style="fill"
                    variant="accent"
                    staticColor={mode === "pan" ? undefined : "white"}
                    onPress={toggleMode("pan")}>
                    <CameraPan />
                </Button>
                <Tooltip>{strings.cameraPan}</Tooltip>
            </TooltipTrigger>
        </>
    );
}
