/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Image,
    View,
    Link as LinkSpectrum,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import logo from "../../assets/images/adobe_logo_splash.png";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { AboutContent, AboutHeader } from "@src/components/About";

export function AboutHighFive() {
    const { t } = useTranslation("common");
    const { postMessageBusApi } = usePostMessageContext();

    return (
        <View
            paddingY="size-200"
            paddingX="size-500"
            UNSAFE_style={{ overflow: "hidden" }}>
            <View paddingBottom="size-500">
                <Text
                    UNSAFE_style={{
                        fontSize: "2em",
                        fontWeight: "bold",
                    }}>
                    {t("preferences.about")}
                </Text>
            </View>
            <AboutHeader isForVr={true} />
            <Flex direction="column" gap="size-200">
                <AboutContent isForVr={true} />

                <Text UNSAFE_style={{ fontSize: "1.6em" }}>
                    {t("about.h5.third.party")}{" "}
                    <LinkSpectrum
                        onPress={() => {
                            if (postMessageBusApi) {
                                postMessageBusApi.interceptLink(
                                    "https://www.adobe.com/go/thirdparty",
                                );
                            }
                        }}>
                        {"https://www.adobe.com/go/thirdparty"}
                    </LinkSpectrum>
                </Text>
                <Flex direction="row" gap="size-200">
                    <Text UNSAFE_style={{ fontSize: "1.6em" }}>
                        {
                            <LinkSpectrum
                                onPress={() => {
                                    if (postMessageBusApi) {
                                        postMessageBusApi.interceptLink(
                                            "https://www.adobe.com/legal/terms.html",
                                        );
                                    }
                                }}>
                                {t("about.h5.general.terms")}
                            </LinkSpectrum>
                        }
                    </Text>
                    <Text UNSAFE_style={{ fontSize: "1.6em" }}>
                        {
                            <LinkSpectrum
                                onPress={() => {
                                    if (postMessageBusApi) {
                                        postMessageBusApi.interceptLink(
                                            "https://www.adobe.com/privacy.html",
                                        );
                                    }
                                }}>
                                {t("about.h5.privacy.policy")}
                            </LinkSpectrum>
                        }
                    </Text>
                </Flex>
                <Flex direction="row" justifyContent="center">
                    <Image
                        width="130px"
                        height="130px"
                        alt={t("accessibility.adobeLogo")}
                        src={logo}
                    />
                </Flex>
            </Flex>
        </View>
    );
}
