/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// Client for ACP Changelog Service API
// https://developers.corp.adobe.com/ons/Changelog/changelogApiSwagger.json

import { ADOBE_IMS_CONFIG } from "@src/config";

type Change = {
    'repo:assetId': string,
    'ons:subscribedDirectoryAssetId'?: string,
}

type ChangeRes = {
    changes: Array<Change>,
    nextUrl: string,
}

export async function changes(
    id: string,
    url: string,
    accessToken: string
): Promise<ChangeRes> {
    const headers = {
        'x-api-key': ADOBE_IMS_CONFIG.client_id,
        'authorization': `Bearer ${accessToken}`,
    }
    const res = await fetch(url, { headers });
    if (!res.ok) {
        throw new Error('ons/getChanges - fetch res not ok' + res.statusText);
    }
    const body = await res.json();
    const nextUrl: string = (body as any)._links.next.href;
    const events: any = body['ons:changedSubscriptions'].filter((x: any) => x['ons:subscriptionId'] === id);
    const changes = (events.length > 0 && events[0]['ons:changes'].length > 0) ? events[0]['ons:changes'] : [];
    return {
        changes,
        nextUrl,
    };
}
