/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Material } from "@babylonjs/core/Materials/material";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { CreatePlane } from "@babylonjs/core/Meshes/Builders/planeBuilder";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Node } from "@babylonjs/core/node";
import { GridMaterial } from "@babylonjs/materials/grid/gridMaterial";

import environmentCourtyardIblUrl from "@src/assets/ibl/reviewer-env-courtyard.env";
import environmentGarageIblUrl from "@src/assets/ibl/reviewer-env-garage.env";
import environmentStudioIblUrl from "@src/assets/ibl/reviewer-env-studio.env";
import environmentDefaultIblUrl from "@src/assets/ibl/studio_black_soft_light_02.env";
import pedestalLargeUrl from "@src/assets/models/pedestal_minimal_large.glb";
import pedestalMediumeUrl from "@src/assets/models/pedestal_minimal_medium.glb";
import pedestalSmallUrl from "@src/assets/models/pedestal_minimal_small.glb";
import environmentCourtyardUrl from "@src/assets/models/reviewer-env-courtyard.glb";
import environmentGarageUrl from "@src/assets/models/reviewer-env-garage.glb";
import environmentStudioUrl from "@src/assets/models/reviewer-env-studio.glb";
import { throttleAnimation } from "@src/util/AnimationThrottleUtils";

import type { Camera } from "@babylonjs/core/Cameras/camera";
import type { SceneManager } from "@components/studio/src/scene/SceneManager";

import "@babylonjs/materials/grid/grid.fragment";
import "@babylonjs/materials/grid/grid.vertex";
import "@babylonjs/core/Layers/effectLayerSceneComponent";

export type Environment =
    | "default"
    | "courtyard"
    | "garage"
    | "studio"
    | "none";
export type Pedestal = "small" | "large" | "medium" | "none";
export type Grounding = "grounded" | "ungrounded";
export type Centering = "centered" | "uncentered";
export type UpAxis = "y+" | "y-" | "x+" | "x-" | "z+" | "z-";

export type EnvironmentState = {
    size: [number, number, number];
    scaling: number;
    pedestal: Pedestal;
    environmentModel: Environment;
    groundModel: Grounding;
    centerModel: Centering;
    upAxis: UpAxis;
};

const envUrlMap: Record<Environment, string> = {
    none: "",
    default: environmentStudioUrl,
    studio: environmentStudioUrl,
    garage: environmentGarageUrl,
    courtyard: environmentCourtyardUrl,
};

const envIblUrlMap: Record<Environment, string> = {
    none: environmentDefaultIblUrl,
    default: environmentDefaultIblUrl,
    garage: environmentGarageIblUrl,
    studio: environmentStudioIblUrl,
    courtyard: environmentCourtyardIblUrl,
};

interface CameraLimits {
    min: Vector3;
    max: Vector3;
    // used to detect asset collision
    // some objects go below 0 but if we set min below 0 the camera might pan below the ground 
    minEnvCollision?: Vector3;
}

export const envCameraLimitsMap: Record<Environment, CameraLimits> = {
    none: {
        min: new Vector3(-10, -10, -10),
        max: new Vector3(10, 10, 10),
    },
    default: {
        min: new Vector3(-6.5, 0, -6.5),
        max: new Vector3(6.5, 3, 6.5),
    },
    garage: {
        min: new Vector3(-6.2, 0, -6.2),
        max: new Vector3(6.2, 3, 6.2),
    },
    studio: {
        min: new Vector3(-5.9, 0.05, -4.1),
        max: new Vector3(3.8, 3, 4.15),
    },
    courtyard: {
        min: new Vector3(-6.2, 0.1, -10.5),
        max: new Vector3(7, 6.6, 10.4),
        minEnvCollision: new Vector3(-6.2, -0.05, -10.5),
    },
};

const envBackgroundColorMap: Record<
    Environment,
    [number, number, number, number]
> = {
    none: [0.2, 0.2, 0.2, 1],
    default: [1, 1, 1, 1],
    studio: [1, 1, 1, 1],
    garage: [1, 1, 1, 1],
    courtyard: [1, 1, 1, 1],
};

const pedUrlMap: Record<Pedestal, string> = {
    large: pedestalLargeUrl,
    medium: pedestalMediumeUrl,
    small: pedestalSmallUrl,
    none: "",
};

export const pedHeightMap: Record<Pedestal, number> = {
    large: 1,
    medium: 1,
    small: 1,
    none: 0,
};

export function getEnvUrl(env: Environment) {
    return envUrlMap[env];
}

export function getPedestalUrl(ped: Pedestal) {
    return pedUrlMap[ped];
}

export const DefaultEnvironmentState: () => EnvironmentState = () => ({
    pedestal: "none",
    environmentModel: "none",
    size: [1, 1, 1],
    scaling: 1,
    centerModel: "uncentered",
    groundModel: "ungrounded",
    upAxis: "y+",
});

export function isOversize(
    environmentState: Pick<EnvironmentState, "scaling" | "size">,
) {
    const { size, scaling } = environmentState;
    return (
        size[0] * scaling > 10 ||
        size[1] * scaling > 4 ||
        size[2] * scaling > 12
    );
}

export class EnvironmentBuilder {
    defaultPosition: Vector3 = new Vector3();
    defaultRotation: Vector3 = new Vector3();
    pedestal: Pedestal = "none";

    envLoaded: boolean = false;
    onEnvironmentLoadedObservable: Observable<void> = new Observable();

    constructor(
        private sceneManager: SceneManager,
        private currentEnvironment: EnvironmentState,
        private randomizeCameraAlpha: boolean = false,
    ) {
        if (this.sceneManager.viewer.modelInitialized) {
            this.updateEnvironment();
        } else {
            this.sceneManager.viewer.onModelLoaded.add(() => {
                this.updateEnvironment();
            });
        }
        throttleAnimation(
            this.sceneManager.scene.onAfterRenderObservable,
            () => {
                if (this.sceneManager.camera) {
                    this.sceneManager.camera.onViewMatrixChangedObservable.add(
                        this.clampCamera,
                    );
                }
            },
        );
    }

    isOversizedAsset() {
        const { size, scaling } = this.currentEnvironment;
        return isOversize({ size, scaling });
    }

    async updateEnvironment(oldEnvironment?: EnvironmentState) {
        if (this.isOversizedAsset()) {
            this.currentEnvironment.environmentModel = "none";
        }

        if (oldEnvironment?.scaling !== this.currentEnvironment.scaling) {
            await this.updateScaling();
        }
        if (
            oldEnvironment?.environmentModel !==
            this.currentEnvironment.environmentModel
        ) {
            await this.updateEnvironmentModel();
        }
        if (oldEnvironment?.pedestal !== this.currentEnvironment.pedestal) {
            this.pedestal = this.currentEnvironment.pedestal;
            await this.updatePedestal();
        }
        if (oldEnvironment?.upAxis !== this.currentEnvironment.upAxis) {
            this.updateOrientation();
        }
        if (
            oldEnvironment?.centerModel !== this.currentEnvironment.centerModel
        ) {
            this.updateCentering();
        }
        if (
            oldEnvironment?.groundModel !==
                this.currentEnvironment.groundModel ||
            oldEnvironment?.pedestal !== this.currentEnvironment.pedestal
        ) {
            this.updateGrounding();
        }

        if (this.randomizeCameraAlpha && this.sceneManager.viewer.camera) {
            this.sceneManager.viewer.camera.alpha = Math.random() * Math.PI * 2;
        }

        this.sceneManager.viewer.camera.storeState();
        this.updateIblShadowPipeline(oldEnvironment);

        this.envLoaded = true;
        this.onEnvironmentLoadedObservable.notifyObservers();
        this.sceneManager.viewer.renderLoop.toggle(100);
    }

    set environment(environment: EnvironmentState) {
        const oldEnvironment = this.currentEnvironment;
        this.currentEnvironment = environment;
        this.updateEnvironment(oldEnvironment);
    }

    get environment() {
        return this.currentEnvironment;
    }

    private updateScaling() {
        const rootModel = this.sceneManager.viewer.model;

        if (rootModel) {
            // eslint-disable-next-line prefer-const
            let { scaling, size } = this.currentEnvironment;
            if (this.isOversizedAsset()) {
                scaling = 1 / Math.max(...size);
            }
            rootModel.scaling.setAll(scaling);
            this.updateBounds();
            this.updateGrounding();
            this.updateCentering();
            this.reframe();
        }
    }

    private async updatePedestal() {
        this.sceneManager.viewer.scene.getNodes().forEach((node) => {
            if (node.name.includes("pedestal")) {
                node.dispose();
            }
        });

        const model = this.sceneManager.viewer.model;

        if (pedUrlMap[this.currentEnvironment.pedestal]) {
            await this.sceneManager.appendAsync(
                pedUrlMap[this.currentEnvironment.pedestal],
            );
        }

        if (this.sceneManager.viewer.shadowPipeline) {
            this.sceneManager.viewer.scene.getNodes().forEach((node) => {
                if (node.name.includes("pedestal")) {
                    this.addIblMats(node);
                }
            });
        }

        if (model) {
            this.updateGrounding();
        }
        this.updateBackground();
        this.reframe();
    }

    private getNodeMaterials(node: Node) {
        const childMats = node
            .getChildMeshes()
            .map((mesh) => mesh.material)
            .filter((material) => !!material);
        const parentMat = (node as Mesh).material;
        if (parentMat) {
            childMats.push(parentMat);
        }
        return childMats;
    }

    private addIblMats(node: Node) {
        if (this.sceneManager.viewer.shadowPipeline) {
            const materials = this.getNodeMaterials(node) as Material[];
            this.sceneManager.viewer.shadowPipeline.addShadowReceivingMaterial(
                materials,
            );
        }
    }
    private removeIblTextures(node: Node) {
        if (this.sceneManager.viewer.shadowPipeline) {
            const materials = this.getNodeMaterials(node) as Material[];
            this.sceneManager.viewer.shadowPipeline.removeShadowReceivingMaterial(
                materials,
            );
        }
    }

    private async updateEnvironmentModel() {
        this.sceneManager.viewer.scene.getNodes().forEach((node) => {
            if (node.name.includes("environment")) {
                this.removeIblTextures(node);
                node.dispose();
            }
        });

        if (envUrlMap[this.currentEnvironment.environmentModel]) {
            await this.sceneManager.appendAsync(
                envUrlMap[this.currentEnvironment.environmentModel],
            );
        }

        if (this.currentEnvironment.environmentModel === "none") {
            window.requestAnimationFrame(() => {
                const ground = CreatePlane(
                    "reviewer_environment_grid",
                    {
                        sideOrientation: 2,
                        width: 40,
                        height: 40,
                    },
                    this.sceneManager.scene,
                );
                ground.rotation.x = Math.PI / 2;
                const grid = new GridMaterial(
                    "grid_mat",
                    this.sceneManager.scene,
                );
                grid.majorUnitFrequency = 10;
                grid.minorUnitVisibility = 0.3;
                grid.gridRatio = 0.1;
                grid.backFaceCulling = false;
                grid.mainColor = new Color3(1, 1, 1);
                grid.lineColor = new Color3(1, 1, 1);
                grid.opacityTexture = new Texture(
                    "https://assets.babylonjs.com/environments/backgroundGround.png",
                    this.sceneManager.scene,
                );
                grid.opacity = 0.56;
                ground.material = grid;
            });
        }
        if (this.sceneManager.viewer.shadowPipeline) {
            this.sceneManager.viewer.scene.getNodes().forEach((node) => {
                if (node.name.includes("environment")) {
                    this.addIblMats(node);
                }
            });
        }

        this.updateIbl();
        this.updateBackground();
        this.reframe();
    }

    private updateOrientation() {
        const model = this.sceneManager.viewer.model;
        if (model) {
            model.position.setAll(0);
            model.rotation.setAll(0);
            const rotation = Math.PI / 2;
            switch (this.currentEnvironment.upAxis) {
                case "y-":
                    model.rotation.y = 2 * rotation;
                    break;
                case "z+":
                    model.rotation.z = rotation;
                    break;
                case "z-":
                    model.rotation.z = -rotation;
                    break;
                case "x+":
                    model.rotation.x = rotation;
                    break;
                case "x-":
                    model.rotation.x = -rotation;
                    break;
            }

            this.updateGrounding();
            this.updateCentering();

            this.defaultRotation = model.rotation.clone();
        }
    }

    private updateGrounding() {
        const { groundModel, pedestal } = this.currentEnvironment;
        const model = this.sceneManager.viewer.model;
        if (model) {
            if (groundModel === "grounded") {
                model.position.y = 0;
                this.updateBounds();
                const bounds = this.sceneManager.modelBounds;
                model.position.y = pedHeightMap[pedestal] + (0 - bounds.min.y);
            } else {
                model.position.y = pedHeightMap[pedestal];
            }

            this.updateBounds();
            this.reframe();

            this.defaultPosition = model.position.clone();
        }
    }

    private updateCentering() {
        const { centerModel } = this.currentEnvironment;
        const model = this.sceneManager.viewer.model;
        if (model) {
            if (centerModel === "centered") {
                model.position.x = model.position.z = 0;
                const bounds = model.getHierarchyBoundingVectors();
                const center = Vector3.Center(bounds.min, bounds.max);
                model.position.x = -center.x;
                model.position.z = -center.z;
            } else {
                model.position.x = model.position.z = 0;
            }

            this.updateBounds();
            this.reframe();

            this.defaultPosition = model.position.clone();
        }
    }

    updateBackground() {
        const color =
            envBackgroundColorMap[this.currentEnvironment.environmentModel];
        this.sceneManager.viewer.scene.clearColor = new Color4(...color);
    }

    clampCamera = (camera: Camera) => {
        camera.position = this.clampPosition(camera.position);
    };

    clampPosition = (position: Vector3) => {
        const env = this.currentEnvironment.environmentModel;

        return Vector3.Clamp(position, envCameraLimitsMap[env].min, envCameraLimitsMap[env].max);
    }

    updateIbl() {
        const hdrTexture = CubeTexture.CreateFromPrefilteredData(
            envIblUrlMap[this.currentEnvironment.environmentModel],
            this.sceneManager.scene,
        );
        hdrTexture.rotationY = Math.PI;
        this.sceneManager.scene.environmentTexture = hdrTexture;

        this.sceneManager.scene.materials.forEach((mat: any) => {
            if (mat.name.includes("AdvancedDynamicTextureMaterial")) {
                // NOTE: All GUI materials in Babylon use an AdvancedDynamicTexture.
                // This is to disable the environment map for GUI materials, otherwise
                // they would show reflections from the environment as well when
                // they should be rendered as unlit 2D elements with no lighting effect.
                // Setting reflectionTexture to null removes any environment reflections
                // from these materials.
                mat.reflectionTexture = null;
            } else {
                mat.reflectionTexture = hdrTexture;
                mat.enableSpecularAntiAliasing = false;
            }
        });
        if (this.sceneManager.viewer.shadowPipeline) {
            this.sceneManager.viewer.shadowPipeline.updateVoxelization();
        }
    }

    updateBounds() {}

    updateIblShadowPipeline(oldEnv: EnvironmentState | undefined) {
        if (!this.sceneManager.viewer.shadowPipeline) return;

        const env = this.environment;
        const requiresShadowUpdate =
            env.centerModel !== oldEnv?.centerModel ||
            env.environmentModel !== oldEnv?.environmentModel ||
            env.groundModel !== oldEnv?.groundModel ||
            env.environmentModel !== oldEnv?.environmentModel ||
            env.pedestal !== oldEnv?.pedestal ||
            env.scaling !== oldEnv?.scaling ||
            env.upAxis !== oldEnv?.upAxis;

        if (requiresShadowUpdate) {
            this.sceneManager.viewer.updateIblShadows();
        }
    }

    reframe() {
        if (this.sceneManager.camera) {
            this.sceneManager.camera.beta = Math.PI * 0.45;
        }
        this.sceneManager.frame();
    }
}
