/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View, Flex, Text, Button, Image } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";
import Welcome from "@src/assets/images/tutorial-welcome.png";

export function WelcomePage({
    setCurrentPage,
    onCloseTutorial,
}: {
    setCurrentPage: (value: number) => void;
    onCloseTutorial: () => void;
}) {
    const { t } = useTranslation(["web", "vr"]);

    return (
        <View
            width="1320px"
            height="1120px"
            UNSAFE_style={{
                overflow: "hidden",
            }}>
            <Flex
                direction="column"
                gap="size-350"
                justifyContent="space-between">
                <Image src={Welcome} alt={t("web:home.welcome")} />
                <Flex
                    direction="column"
                    gap="size-350"
                    UNSAFE_style={{ padding: "100px", marginTop: "-190px" }}>
                    <Text
                        UNSAFE_style={{ fontSize: "54px", fontWeight: "bold" }}>
                        {t("web:home.welcome")}
                    </Text>
                    <Text UNSAFE_style={{ fontSize: "33px" }}>
                        {t("vr:tutorial.welcome")}
                    </Text>
                    <Flex
                        direction="row"
                        justifyContent="space-between"
                        gap="size-500"
                        marginTop="2vh">
                        <Button
                            marginTop="1vh"
                            width="100%"
                            height="75px"
                            variant="primary"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}
                            onPress={onCloseTutorial}>
                            <Flex alignItems="center" gap="size-50">
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.75em",
                                    }}>
                                    {t("vr:tutorial.button.skipTour")}
                                </Text>
                            </Flex>
                        </Button>
                        <Button
                            marginTop="1vh"
                            width="100%"
                            height="75px"
                            variant="cta"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}
                            onPress={() => setCurrentPage(1)}>
                            <Flex alignItems="center" gap="size-50">
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.75em",
                                    }}>
                                    {t("vr:tutorial.button.begin")}
                                </Text>
                            </Flex>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </View>
    );
}
