/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Item,
    ActionButton,
    MenuTrigger,
    Menu,
    TooltipTrigger,
    Tooltip,
} from "@adobe/react-spectrum";
import { useCameraControlsToggle } from "@components/studio/src/hooks/useCameraControlsToggle";
import { ToggleMode } from "@components/studio/src/scene/PanningArcRotateCameraPointersInput";
import { SceneManager } from "@components/studio/src/scene/SceneManager";
import Dolly from "@spectrum-icons/workflow/Dolly";
import Orbit from "@spectrum-icons/workflow/Orbit";
import Pan from "@spectrum-icons/workflow/Pan";
import Select from "@spectrum-icons/workflow/Select";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { NavigationPanelSection } from "./NavigationPanelSection";
import { LaserPointerMode } from "@src/components/custom-icons/LaserPointerMode";
import { SelectAndMove } from "@src/components/custom-icons/SelectAndMove";
import { SelectAndRotate } from "@src/components/custom-icons/SelectAndRotate";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import { useOnboardingContext } from "@src/contexts/OnboardingContext";
import {
    NavigationMode,
    ObjectMode,
    PanelButton,
    NavigationPanelSections,
} from "@src/util/PanelUtils";

const SELECT_CLASS = "Dniwja_is-selected";

// TODO: Determine best way to inject a divider in between sections
const navigationPanelSections = [
    {
        key: "top",
        buttons: [
            {
                key: "pointer" as NavigationMode,
                label: "nav.label.pointer",
                icon: <LaserPointerMode size="S" />,
                tooltip: "nav.tooltip.pointer",
                description: "",
            },
        ],
    },
    {
        key: "cameraControl",
        buttons: [
            {
                key: "orbit" as NavigationMode,
                label: "nav.label.orbit",
                icon: <Orbit size="S" />,
                tooltip: "nav.tooltip.orbit",
                description: "",
            },
            {
                key: "dolly" as NavigationMode,
                label: "nav.label.dolly",
                icon: <Dolly size="S" />,
                tooltip: "nav.tooltip.dolly",
                description: "",
            },
            {
                key: "pan" as NavigationMode,
                label: "nav.label.pan",
                icon: <Pan size="S" />,
                tooltip: "nav.tooltip.pan",
                description: "",
            },
        ],
    },
];

const objectPanelButtons = [
    {
        key: "select" as ObjectMode,
        label: "nav.label.select",
        icon: <Select size="S" />,
        tooltip: "nav.tooltip.select",
        description: "",
    },
    {
        key: "move" as ObjectMode,
        label: "nav.label.move",
        icon: <SelectAndMove size="S" />,
        tooltip: "",
        description: "",
    },
    {
        key: "rotate" as ObjectMode,
        label: "nav.label.rotate",
        icon: <SelectAndRotate size="S" />,
        tooltip: "nav.object.tooltip.rotate",
        description: "",
    },
];

interface NavigationPanelProps {
    sceneManager: SceneManager | undefined;
    // Used to shift to another navigation mode while a key is pressed
    // and then return back to the previous mode once unpressed
    temporaryNavigationMode: NavigationMode | undefined;
}

export function NavigationPanel({
    sceneManager,
    temporaryNavigationMode,
}: NavigationPanelProps) {
    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState<boolean>(false);
    const [selectedNavigationKey, setSelectedNavigationKey] =
        useState<NavigationMode>("none");
    const [selectedObjectKey, setSelectedObjectKey] =
        useState<ObjectMode>("select");
    const [selectedObjectIcon, setSelectedObjectIcon] = useState<JSX.Element>(
        <Select size="S" />,
    );
    const [prevSelectedObjectKey, setPrevSelectedObjectKey] =
        useState<ObjectMode>("select");

    const [isLockedOut, setIsLockedOut] = useState<boolean>(false);

    const { mode, setMode } = useCameraControlsToggle(sceneManager);

    const { networkManager } = useNetworkContext();
    const { t } = useTranslation("web");
    const { shouldForceSelectMenuOpen } = useOnboardingContext();

    useEffect(() => {
        // need to manually add class to the long press menu
        // trigger icon to make it visually selectable
        const longPressSVG = document.querySelector(
            ".Dniwja_spectrum-ActionButton-hold",
        );
        if (longPressSVG) {
            longPressSVG.classList.add("Dniwja_spectrum-Icon");
        }
    }, []);

    useEffect(() => {
        if (!sceneManager) return;
    }, [sceneManager]);

    const toggleMode = (buttonMode: ToggleMode) => {
        if (mode !== buttonMode) {
            setMode(buttonMode);
        } else {
            setMode("rotate");
        }
    };

    useEffect(() => {
        if (!networkManager || !networkManager.objectManager) return;
        const objectManager = networkManager.objectManager;

        objectManager.on("isAssetLocked", (isLocked) => {
            setIsLockedOut(isLocked);
        });

        return () => {
            networkManager?.objectManager?.removeAllListeners();
        };
    }, [networkManager, networkManager?.objectManager]);

    const hasTemporaryNavigationMode = !!temporaryNavigationMode;

    useEffect(() => {
        // Toggle back to selectedNavigationKey once temporaryNavigationMode is no longer set
        if (!hasTemporaryNavigationMode) {
            if (selectedNavigationKey !== "none") {
                networkManager?.toggleGizmoSelectionMode(false, "none");
                setSelectedObjectKey("none");
            }
            toggleMode(selectedNavigationKey as ToggleMode);
            if (selectedNavigationKey === "pointer") {
                networkManager?.activateLaserPointerMode();
            } else {
                networkManager?.deactivateLaserPointerMode();
            }
        }
    }, [hasTemporaryNavigationMode, selectedNavigationKey]);

    useEffect(() => {
        if (selectedObjectKey === null || selectedObjectKey === "none") {
            networkManager?.toggleGizmoSelectionMode(false, selectedObjectKey);
            networkManager?.objectManager?.disableGizmos();
        } else {
            setPrevSelectedObjectKey(selectedObjectKey);
            setSelectedNavigationKey("none");
            networkManager?.toggleGizmoSelectionMode(true, selectedObjectKey);
            const selectedIcon = objectPanelButtons.find(
                (button: PanelButton) => button.key === selectedObjectKey,
            );
            setSelectedObjectIcon(selectedIcon?.icon as JSX.Element);
        }
    }, [selectedObjectKey]);

    return (
        <Flex height="100%" direction="column" gap="7px">
            <MenuTrigger
                trigger="longPress"
                isOpen={shouldForceSelectMenuOpen || isSelectMenuOpen}
                direction="end"
                align="end"
                onOpenChange={setIsSelectMenuOpen}>
                <TooltipTrigger placement="end">
                    <ActionButton
                        id={`Coachmark-select`}
                        aria-label="Select"
                        isQuiet
                        isDisabled={isLockedOut}
                        UNSAFE_className={
                            selectedObjectKey === "none" ? "" : SELECT_CLASS
                        }
                        onPress={() => {
                            selectedObjectKey === "none"
                                ? setSelectedObjectKey(prevSelectedObjectKey)
                                : setSelectedObjectKey("none");
                        }}>
                        {selectedObjectIcon}
                    </ActionButton>
                    <Tooltip>
                        {selectedObjectKey === "move" ||
                        selectedObjectKey === "rotate"
                            ? t(`nav.tooltip.${selectedObjectKey}`)
                            : t("nav.tooltip.select")}
                    </Tooltip>
                </TooltipTrigger>
                <Menu
                    id={`Coachmark-objectPanelButtons`}
                    UNSAFE_style={{
                        display: "flex",
                        height: "45px",
                        width: "120px",
                        padding: "6px",
                        gap: "14px",
                    }}>
                    {objectPanelButtons.map((button: PanelButton) => {
                        return (
                            <Item key={button.key} textValue={button.label}>
                                <ActionButton
                                    isQuiet
                                    id={`Coachmark-objectPanelButtons-${button.key}`}
                                    onPress={() => {
                                        setSelectedObjectKey(
                                            button.key as ObjectMode,
                                        );
                                    }}
                                    UNSAFE_className={
                                        selectedObjectKey ===
                                        (button.key as ObjectMode)
                                            ? SELECT_CLASS
                                            : ""
                                    }>
                                    {button.icon}
                                </ActionButton>
                            </Item>
                        );
                    })}
                </Menu>
            </MenuTrigger>
            <div>
                {navigationPanelSections.map(
                    (section: NavigationPanelSections, i) => {
                        return (
                            <NavigationPanelSection
                                key={i}
                                sectionName={section.key}
                                buttons={section.buttons}
                                temporaryNavigationMode={
                                    temporaryNavigationMode
                                }
                                selectedNavigationKey={selectedNavigationKey}
                                setSelectedNavigationKey={
                                    setSelectedNavigationKey
                                }
                                includeDivider={
                                    i < navigationPanelSections.length - 1
                                }
                            />
                        );
                    },
                )}
            </div>
        </Flex>
    );
}
