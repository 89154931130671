type SearchParams = Record<string, string>;

export function initRouteConfigs(baseURI: string) {
    const baseUrl = baseURI;
    const basePath = new URL(baseUrl).pathname;
    // Note: dev server doesn't like trailing '/', but CDN works with it,
    // so local testing is insufficient.
    // React Router also doesn't want trailing '/'s in their base path, so remove it if it exists.
    const basePathEndIdx = basePath[basePath.length - 1] === '/' ? basePath.length - 1 : basePath.length;
    const ROUTER_BASE_PATH = basePath.slice(0, basePathEndIdx);
    const getAppPath = (currPath = window.location.pathname): string => currPath.slice(ROUTER_BASE_PATH.length);

    const getHref = (appPath: string, params?: SearchParams): string => {
        // React Router routes _should_ always start with '/', but just in
        // case they don't They're supposed to be relative to basename
        // regardless though, so they should be treated as relative even
        // though they appear absolute.
        const prefix = appPath[0] === '/' ? '.' : '';
        const url = new URL(prefix + appPath, baseUrl);
        if (params) {
            for (let key in params) {
                url.searchParams.set(key, params[key]);
            }
        }
        return url.toString();
    }

    return {
        baseUrl,
        basePath,
        ROUTER_BASE_PATH,
        getAppPath,
        getHref,
    }
}
