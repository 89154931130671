/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, View, ActionButton, Divider } from "@adobe/react-spectrum";
import GlobeGrid from "@spectrum-icons/workflow/GlobeGrid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Preferences } from "./AppSettingsTabsView";
import {
    PreferencesOptionType,
    VRPreferencesOption,
} from "./VRPreferencesOption";
import { useLocaleContext } from "../contexts/LocaleContext";
import { usePostMessageContext } from "../contexts/PostMessageContext";
import {
    presets,
    customResolutions,
    uiSizes,
    controllers,
    snapTurns,
    HEADER_FONT_SIZE,
    BODY_FONT_SIZE,
    HEADER_COLOR,
} from "@src/util/AppSettingsUtils";

import type { Key } from "@react-types/shared";

const PICKER_CLASS = "cm4dRG_spectrum-Dropdown";

type PreferencesSection = {
    title: string;
    options: PreferencesOptionType[];
};
interface PreferencesProps {
    preferences: Preferences;
    setPreferences: (preferences: Preferences) => void;
}

export function VRPreferences({
    preferences,
    setPreferences,
}: PreferencesProps) {
    const { t } = useTranslation("common");

    const { availableLocales, changeLocale } = useLocaleContext();

    const { postMessageBusApi } = usePostMessageContext();

    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab") || "";

    useEffect(() => {
        const pickers = document.getElementsByClassName(PICKER_CLASS);
        for (let i = 0; i < pickers.length; i++) {
            const button = pickers[i].getElementsByTagName("button")[0];
            button.style.height = "3.4em";
        }
    }, [preferences.preset]);

    const openAboutHighFive = () => {
        if (postMessageBusApi) {
            postMessageBusApi.openAboutHighFive();
        }
    };

    const postLocaleChange = (value: Key) => {
        setPreferences({ ...preferences, lang: value as string });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("language", value);
        }
        changeLocale(value as string);
    };

    const postUiSizeChange = (value: Key) => {
        setPreferences({ ...preferences, uiSize: value as string });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("uiSize", value as string);
        }
    };

    const postControllerChange = (value: Key) => {
        setPreferences({ ...preferences, controller: value as string });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("controller", value as string);
        }
    };

    const postSnapTurnChange = (value: Key) => {
        setPreferences({ ...preferences, snapTurn: value as string });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("snapTurn", value as string);
        }
    };

    const postHapticsChange = (value: boolean) => {
        setPreferences({ ...preferences, haptics: value });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("haptics", value);
        }
    };

    const postLocomotionChange = (value: boolean) => {
        setPreferences({ ...preferences, locomotion: value });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("locomotion", value);
        }
    };

    const postAutoOrientChange = (value: boolean) => {
        setPreferences({ ...preferences, autoOrient: value });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("autoOrient", value);
        }
    };

    const postResolutionChange = (value: boolean) => {
        setPreferences({ ...preferences, resolution: value });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("resolution", value);
        }
    };

    const postSpaceWarpChange = (value: boolean) => {
        setPreferences({ ...preferences, spaceWarp: value });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("spaceWarp", value);
        }
    };

    const postPresetChange = (value: Key) => {
        setPreferences({ ...preferences, preset: value as string });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("preset", value);
        }
    };

    const postCustomResolutionChange = (value: Key) => {
        setPreferences({ ...preferences, customResolution: value as string });
        if (postMessageBusApi) {
            postMessageBusApi.appSettingChanged("customResolution", value);
        }
    };

    useEffect(() => {
        if (preferences.preset === "Custom") {
            if (tab == "preferences")
                postMessageBusApi?.resizeWindow(
                    "app-settings-preferences-customGP",
                );
            else
                postMessageBusApi?.resizeWindow(
                    "app-settings-preferences-customGP-ext",
                );
        } else {
            if (tab == "preferences")
                postMessageBusApi?.resizeWindow("app-settings-preferences");
            else
                postMessageBusApi?.resizeWindow("app-settings-preferences-ext");
        }
    }, [preferences.preset]);

    const preferencesSections: PreferencesSection[] = [
        {
            title: "preferences.general",
            options: [
                {
                    isPicker: true,
                    label: "preferences.language",
                    selection: preferences.lang,
                    items: availableLocales,
                    onChange: (key: Key) => postLocaleChange(key),
                },
                {
                    isPicker: true,
                    label: "preferences.uiSize",
                    selection: preferences.uiSize,
                    items: uiSizes,
                    onChange: (key: Key) => postUiSizeChange(key),
                },
            ],
        },
        {
            title: "preferences.input",
            options: [
                {
                    isPicker: true,
                    label: "preferences.input.controller",
                    selection: preferences.controller,
                    items: controllers,
                    onChange: (key: Key) => postControllerChange(key),
                },
                {
                    isPicker: true,
                    label: "preferences.input.snapTurn",
                    selection: preferences.snapTurn,
                    items: snapTurns,
                    onChange: (key: Key) => postSnapTurnChange(key),
                },
            ],
        },
        {
            title: "preferences.accessibility",
            options: [
                {
                    isPicker: false,
                    label: "preferences.accessibility.haptics",
                    selection: preferences.haptics,
                    onChange: (isSelected: boolean) =>
                        postHapticsChange(isSelected),
                },
                {
                    isPicker: false,
                    label: "preferences.accessibility.locomotion",
                    selection: preferences.locomotion,
                    onChange: (isSelected: boolean) =>
                        postLocomotionChange(isSelected),
                    contextualHelp: "preferences.accessibility.locomotion.help",
                },
                {
                    isPicker: false,
                    label: "preferences.accessibility.autoOrient",
                    selection: preferences.autoOrient,
                    onChange: (isSelected: boolean) =>
                        postAutoOrientChange(isSelected),
                    contextualHelp: "preferences.accessibility.autoOrient.help",
                },
            ],
        },
        {
            title: "preferences.graphics",
            options: [
                {
                    isPicker: true,
                    label: "preferences.graphics.preset",
                    selection: preferences.preset,
                    items: presets,
                    onChange: (key: Key) => postPresetChange(key),
                },
                {
                    isPicker: false,
                    label: "preferences.graphics.dynamicResolution",
                    selection: preferences.resolution,
                    onChange: () =>
                        postResolutionChange(!preferences.resolution),
                    contextualHelp:
                        "preferences.graphics.dynamicResolution.help",
                    isCustomPreset: true,
                },
                {
                    isPicker: true,
                    label: "preferences.graphics.resolution",
                    selection: preferences.customResolution,
                    items: customResolutions,
                    onChange: (key: Key) => postCustomResolutionChange(key),
                    isCustomPreset: true,
                },
                {
                    isPicker: false,
                    label: "preferences.graphics.spaceWarp",
                    selection: preferences.spaceWarp,
                    onChange: () => postSpaceWarpChange(!preferences.spaceWarp),
                    contextualHelp: "preferences.graphics.spaceWarp.help",
                    isCustomPreset: true,
                },
            ],
        },
    ];

    return (
        <View marginTop="15px">
            <Flex direction="column" gap="15px">
                {preferencesSections.map((section) => {
                    return (
                        <>
                            <Text
                                UNSAFE_style={{
                                    color: HEADER_COLOR,
                                    fontSize: HEADER_FONT_SIZE,
                                }}>
                                {t(section.title)}
                            </Text>
                            {section.options
                                .filter((option) => !option.isCustomPreset)
                                .map((option, i) => {
                                    return (
                                        <VRPreferencesOption
                                            key={i}
                                            option={option}
                                        />
                                    );
                                })}
                            {section.title === "preferences.graphics" &&
                                preferences.preset === "Custom" &&
                                section.options
                                    .filter((option) => option.isCustomPreset)
                                    .map((option, i) => {
                                        return (
                                            <VRPreferencesOption
                                                key={i}
                                                option={option}
                                            />
                                        );
                                    })}
                            <Divider size="M" />
                        </>
                    );
                })}
                <View>
                    <Text
                        UNSAFE_style={{
                            paddingTop: "10px",
                            color: HEADER_COLOR,
                            fontSize: HEADER_FONT_SIZE,
                        }}>
                        {t("preferences.about")}
                    </Text>
                    <Flex
                        direction="column"
                        gap="25px"
                        marginTop="15px"
                        alignContent="center">
                        <ActionButton
                            height="3.5em"
                            onPress={() => {
                                if (postMessageBusApi)
                                    postMessageBusApi.showLegalNotice();
                            }}
                            UNSAFE_style={{
                                paddingLeft: "1em",
                                paddingRight: "1em",
                            }}>
                            <Flex alignItems="center" gap="size-50">
                                <GlobeGrid />
                                <Text
                                    UNSAFE_style={{
                                        fontSize: BODY_FONT_SIZE,
                                    }}>
                                    {t("preferences.legal.notice")}
                                </Text>
                            </Flex>
                        </ActionButton>
                        <ActionButton
                            height="3.5em"
                            onPress={openAboutHighFive}
                            UNSAFE_style={{
                                paddingLeft: "1em",
                                paddingRight: "1em",
                            }}>
                            <Text UNSAFE_style={{ fontSize: BODY_FONT_SIZE }}>
                                {t("preferences.legal.about")}
                            </Text>
                        </ActionButton>
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
