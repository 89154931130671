/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export class ActivationTimer {
    defaultDelay: number;
    private armHandler: () => void;
    private disarmHandler: () => void;
    private timerId = 0;
    private lastActivationTime = 0;
    private lastActivationDuration = 0;

    constructor(
        armHandler: () => void,
        disarmHandler: () => void,
        defaultDelay = 0,
    ) {
        this.defaultDelay = defaultDelay;
        this.armHandler = armHandler;
        this.disarmHandler = disarmHandler;
    }

    private clearTimer() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    }

    activate() {
        this.clearTimer();
        this.lastActivationTime = Date.now();
        this.armHandler();
    }

    deactivate(delayOverride?: number) {
        const now = Date.now();
        let delay =
            delayOverride !== undefined ? delayOverride : this.defaultDelay;

        const existingDelay =
            this.lastActivationTime - now + this.lastActivationDuration;
        // ensure a longer existing delay isn't cut short by a request for a shorter delay
        if (existingDelay > delay) {
            delay = existingDelay;
        }
        this.clearTimer();
        this.timerId = window.setTimeout(() => {
            this.disarmHandler();
        }, delay);
        this.lastActivationDuration = delay;
    }

    /**
     * Activates and then auto deactivates after a set period of time
     * convenience method instead of calling both methods at the same time
     * @param delayOverride
     */
    toggle(delayOverride?: number) {
        this.armHandler();
        this.deactivate(delayOverride);
        this.lastActivationTime = Date.now();
    }
}
