/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Composite,
    createAsset,
    Directory,
    DirectoryMediaType,
    fetchLinksIfMissing,
    File as DCXFile,
    getDirectory,
    getIndexDocument,
    getPresignedUrl,
    LinkRelation,
    resolveAsset,
    directoryTransformer,
} from "@dcx/assets";
import { ACPRepoMetadataResource } from "@dcx/assets/types/src/Asset";
import {
    createHTTPService,
    createRepoAPISession,
    newDCXComposite,
    pullCompositeManifestOnly,
    pushComposite,
    uploadComponent,
    uploadNewComponent,
} from "@dcx/dcx-js";
import { LRUCache } from "lru-cache";

import { retryCallbackWithJitter } from "../../util/RetryCallbackWithJitter";
import { SerializedLruCache } from "../../util/SerializedLruCache";

import type { AdobeAsset } from "@dcx/assets";
import type {
    AdobeDCXError,
    AdobeDCXComponent,
    AdobeRepoMetadata,
    AssetWithRepoAndPathOrId,
    ProgressCallback,
} from "@dcx/common-types";
import type {
    AdobeDCXBranch,
    AdobeHTTPService,
    AdobeRepoAPISession,
} from "@dcx/dcx-js";

const log = {
    warn: (d: any, m = "") => console.warn(m, d),
    info: (d: any, m = "") => console.log(m, d),
    error: (d: any, m = "") => console.error(m, d),
    debug: (d: any, m = "") => console.debug(m, d),
};

interface RepoInfo {
    repoId: string;
    rootDirId: string;
    rootPath: string;
}

// 4.5 minutes to ensure when we have stale react
// query cache there is an empty cache at this layer
// Tightly linked to ../../contexts/AcpContext.tsx stale time
const TTL_MAX_URL_MS = 4 * 60 * 60 * 1000;
const urlCache = new LRUCache<string, string>({
    max: 10_000,
    ttl: TTL_MAX_URL_MS,
});

const repoInfoCache = new SerializedLruCache<string, RepoInfo>(
    "acp-repo-info-cache",
    {
        max: 10_000,
        ttl: 1 * 60 * 60 * 1000, // 1 hour in milliseconds
    },
);

const RENDITION_URL_CACHE_PREFIX = "rendition-url-";

export type AcpPermissionType = "read" | "write" | "delete" | "ack";

let concurrentUrlGetRequests = 0;

const ACP_SLOW_REQUEST_MARGIN = 3_000;

export enum ACP_CLIENT_EVENTS {
    cachePropagation = "cachePropagation",
}

export interface CachePropagationEventMessage {
    id?: string;
    url?: string;
    ttl?: number;
}

// this can throw
export function getRemainingTtl(url: string): number {
    const params = new URL(url).searchParams;
    const date = params.get("X-Amz-Date");
    const expiry = params.get("X-Amz-Expires");
    if (!date || !expiry)
        throw new Error("No date value found in search params");

    // example value
    // 'X-Amz-Date' => '20240508T133243Z'
    // 20240611T234437Z
    const startMs = Date.UTC(
        Number(date.slice(0, 4)), // year
        Number(date.slice(4, 6)) - 1, // month
        Number(date.slice(6, 8)), // day
        Number(date.slice(9, 11)), // hour
        Number(date.slice(11, 13)), // minute
        Number(date.slice(13, 15)), // seconds
    );

    const ttlOrigMs = Number(expiry) * 1000;
    let remainingTtl = startMs + ttlOrigMs - Date.now();

    if (isNaN(remainingTtl)) throw new Error("Parse TTL failed");
    if (remainingTtl > TTL_MAX_URL_MS) remainingTtl = TTL_MAX_URL_MS;

    return remainingTtl;
}

function setCache(id: string, url: string) {
    try {
        const ttl = getRemainingTtl(url);
        urlCache.set(id, url, { ttl });
    } catch (err) {
        log.warn({ err }, "UrlSetCacheFail");
    }
}

export interface AdobeExtendedAsset extends AdobeAsset {
    collaboratorCount: number;
}

export class AcpClient {
    private acpService!: AdobeHTTPService;
    private session!: AdobeRepoAPISession;
    private acpEndpoint: string;

    private repoId: string | undefined;
    private rootDirId: string | undefined;
    private rootPath: string | undefined;

    private userId = "";
    private orgId = "";
    private authToken = "";

    private lastToken = "";
    private authInvalid?: () => any;

    initialized: Promise<void>;
    _onInitialized!: () => void;

    constructor(
        acpHost: string,
        private clientId: string,
    ) {
        this.acpEndpoint = acpHost;
        this.initialized = new Promise((res) => {
            this._onInitialized = res;
        });
    }

    initializeUser(userId: string, orgId: string, authToken: string, authInvalid?: () => any) {
        this.userId = userId;
        this.orgId = orgId;
        this.authToken = authToken;
        if (authInvalid) {
            this.authInvalid = authInvalid;
        }
        this.acpService = createHTTPService(
            (service) => {
                // ACP is probably calling us again with the same
                // token if it's expired, logged out
                if (this.lastToken && this.authToken === this.lastToken) {
                    console.debug('acpClient authHandler same-token');
                    if (this.authInvalid) this.authInvalid();
                } else {
                    if (service) {
                        service.setApiKey(this.clientId);
                        service.setAuthToken(this.authToken);
                        this.lastToken = this.authToken
                        this.acpService.isActive = true;
                    } else {
                        console.debug('acpClient authHandler why?');
                    }
                }
            },
            { maxOutstanding: 25 },
        );
        this.session = createRepoAPISession(this.acpService, this.acpEndpoint);
        this._onInitialized();

        this.getRepoConfig();
    }

    getKey(compositeId: string, componentId: string, version = "latest") {
        return `${this.orgId}:${compositeId}:${componentId}:${version}`;
    }

    private _fetchingIndexDocument: Promise<RepoInfo> | undefined;

    async getRepoConfig() {
        await this.initialized;
        const repoKey = `${this.orgId}:${this.userId}`;
        const cachedRepoInfo = repoInfoCache.get(repoKey);
        if (cachedRepoInfo) {
            this.repoId = cachedRepoInfo.repoId;
            this.rootDirId = cachedRepoInfo.rootDirId;
            this.rootPath = cachedRepoInfo.rootPath;
            return cachedRepoInfo;
        }

        if (!this._fetchingIndexDocument) {
            this._fetchingIndexDocument = new Promise((resolve, reject) => {
                getIndexDocument(this.acpService)
                    .then(({ result }) => {
                        this.repoId =
                            result.assignedDirectories?.[0]?.repositoryId;
                        this.rootDirId =
                            result.assignedDirectories?.[0]?.assetId;
                        this.rootPath = result.assignedDirectories?.[0]?.path;

                        if (!this.repoId || !this.rootDirId || !this.rootPath) {
                            throw new Error(
                                `Failed to retrieve repo for ${this.userId}`,
                            );
                        }

                        const repoInfo = {
                            repoId: this.repoId,
                            rootDirId: this.rootDirId,
                            rootPath: this.rootPath,
                        };

                        repoInfoCache.set(repoKey, repoInfo);
                        this._fetchingIndexDocument = undefined;
                        resolve(repoInfo);
                    })
                    .catch(reject);
            });
        }

        return this._fetchingIndexDocument;
    }

    async hasPermissions(
        compositeId: string,
        permissionType: AcpPermissionType,
        opName: string,
        userId: string,
        requestId: string,
    ) {
        const composite = await this.getCompositeById(compositeId);
        try {
            const privilege = await composite.checkACLPrivilege(
                permissionType,
                LinkRelation.PRIMARY,
            );
            return privilege.result;
        } catch {
            log.info({ requestId, userId, compositeId }, `${opName}Failed`);
            return false;
        }
    }

    async getComponentUrl(
        compositeId: string,
        componentId: string,
        revision?: string,
    ): Promise<string | undefined> {
        const { repoId } = await this.getRepoConfig();
        const key = this.getKey(compositeId, componentId);
        const cachedValue = urlCache.get(key);
        if (cachedValue) {
            return cachedValue;
        }

        log.info(
            { componentId, compositeId },
            "ACP cache miss fetching URL from ACP",
        );

        try {
            const start = Date.now();
            concurrentUrlGetRequests++;
            const { result } = await retryCallbackWithJitter(
                () =>
                    getPresignedUrl(
                        this.acpService,
                        {
                            repositoryId: repoId,
                            assetId: compositeId,
                        },
                        {
                            component_id: componentId,
                            reltype: LinkRelation.COMPONENT,
                            revision: revision,
                        },
                    ),
                3,
                1_000,
                2_000,
                log,
            );
            setCache(key, result);
            const requestDuration = Date.now() - start;
            if (requestDuration > ACP_SLOW_REQUEST_MARGIN) {
                log.warn(
                    {
                        componentId,
                        compositeId,
                        requestDuration,
                        concurrentUrlGetRequests,
                    },
                    "Slow URL request from ACP",
                );
            }
            return result;
        } catch (e) {
            const msg = "ACP error trying to get URL for component.";
            log.error({ err: e, compositeId, componentId }, msg);
            throw new Error(msg);
        } finally {
            concurrentUrlGetRequests--;
        }
    }

    async getRootDir() {
        const { repoId, rootDirId, rootPath } = await this.getRepoConfig();
        return new Directory(
            {
                repositoryId: repoId,
                assetId: rootDirId,
                path: rootPath,
            } as AdobeAsset,
            this.acpService,
        );
    }

    async createNewComposite(
        name: string,
        path: string,
        type: string,
        id = window.crypto.randomUUID(),
    ) {
        let asset: AdobeAsset | undefined = undefined;
        try {
            let rootMetaResult: AdobeRepoMetadata | undefined;
            let rootDir: Directory | undefined;
            try {
                rootDir = await this.getRootDir();
                ({ result: rootMetaResult } = await rootDir.getRepoMetadata());
            } catch (err) {
                log.error({ err, path, id }, "Failed to get repo");
                throw new Error(`Failed to get repo for ${path}`);
            }

            const parentPath = path.substring(0, path.lastIndexOf("/"));
            await this.ensureDirectoryFromIndexRoot(parentPath);

            let result: AdobeAsset | undefined;
            try {
                ({ result } = await createAsset(
                    this.acpService,
                    rootMetaResult,
                    path,
                    true,
                    type,
                ));
            } catch (err: any) {
                //overwrite the existing asset, so resolve the existing asset
                const theError: AdobeDCXError = err as AdobeDCXError;
                if (theError.code === "ALREADY_EXISTS") {
                    log.warn(
                        { err, path },
                        "DCX composite ALREADY_EXISTS, attempting to resolve asset.",
                    );
                    const assetWithRepoIdAndPath2 = <AssetWithRepoAndPathOrId>{
                        repositoryId: rootDir.repositoryId,
                        path,
                    };

                    const { result: tempAsset } = await resolveAsset(
                        this.acpService,
                        assetWithRepoIdAndPath2,
                        "path",
                    );

                    result = tempAsset;
                } else throw err;
            }

            const newComposite = new Composite(result, this.acpService);
            await newComposite.updateManifest(
                {
                    name,
                    "manifest-format-version": 6,
                    id,
                    children: [],
                    state: "unmodified",
                    type,
                },
                false,
            );
            asset = result;
        } catch (err) {
            log.error({ err, path }, "Failed to create DCX composite");
            throw new Error(`Failed to create DCX composite ${path}`);
        }

        const composite = new Composite(asset, this.acpService);

        const links = await fetchLinksIfMissing(this.acpService, composite, [
            LinkRelation.COMPONENT,
            LinkRelation.BLOCK_UPLOAD_INIT,
            LinkRelation.RENDITION,
        ]);
        composite.setLinks(links);

        return composite;
    }

    async getCompositeById(compositeId: string) {
        const { repoId } = await this.getRepoConfig();
        return new Composite(
            {
                assetId: compositeId,
                repositoryId: repoId,
            },
            this.acpService,
        );
    }

    getAsFile(assetId: string, repositoryId?: string | undefined) {
        return new DCXFile(
            {
                assetId,
                repositoryId: repositoryId || this.repoId,
            },
            this.acpService,
        );
    }

    async getApplicationMeta(assetId: string) {
        const { repoId } = await this.getRepoConfig();
        const file = this.getAsFile(assetId, repoId);
        const res = await file.getAppMetadata();
        return res.result;
    }

    async setApplicationMeta(assetId: string, data: Record<string, string>) {
        const { repoId } = await this.getRepoConfig();
        const file = this.getAsFile(assetId, repoId);
        const patches = Object.entries(data).map(([key, value]) => ({
            op: "add",
            path: `/${key}`,
            value: value,
        }));

        return await file.patchAppMetadata(patches, "*");
    }

    async resolveComposite(assetId: string): Promise<AdobeRepoMetadata> {
        const composite = await this.getCompositeById(assetId);
        const { response, result } = await composite.getRepoMetadata();
        if (response.statusCode !== 200) {
            throw new Error("Failed to resolved assetId");
        }
        return result;
    }

    async discardComposite(assetId: string) {
        await this.getRepoConfig();
        return this.getAsFile(assetId).discard();
    }

    async restoreComposite(assetId: string) {
        await this.getRepoConfig();
        return this.getAsFile(assetId).restore();
    }

    async renameComposite(assetId: string, name: string) {
        await this.getRepoConfig();
        const composite = await this.resolveComposite(assetId);
        const oldName = composite.path.split("/").pop();
        if (!oldName) throw new Error("Failed to get name of composite");
        return await this.getAsFile(assetId).move(
            {
                path: composite.path.replace(oldName, name),
                repositoryId: composite.repositoryId,
            },
            false,
            false,
        );
    }

    getDcxCompositeFromComposite(composite: Composite) {
        return newDCXComposite(
            composite.assetId,
            composite.repositoryId,
            composite.name,
            composite.assetId,
            composite.type,
            composite.links,
        );
    }

    expireCachedThumbnailLink(assetId: string) {
        if (assetId) {
            urlCache.delete(RENDITION_URL_CACHE_PREFIX + assetId);
        }
    }

    /**
     * throws if NOT_FOUND
     */
    async getDirectory(path: string) {
        const rootDir = await this.getRootDir();
        const pathFromRoot = `${rootDir.path}/${path}`;
        const asset = {
            repositoryId: rootDir.repositoryId,
            path: pathFromRoot,
        };

        const dirRes = await getDirectory(this.acpService, asset);
        if (dirRes.response.statusCode !== 200) {
            throw new Error("Invalid directory response");
        }
        const data: ACPRepoMetadataResource = dirRes.result;

        return data;
    }

    async getAllChildrenInDirectory(
        path: string,
        type: string | undefined,
        orderBy = "repo:modifyDate",
    ) {
        const asset = await this.getDirectory(path);

        if (asset._page.count < 100) {
            try {
                const transformedDir = directoryTransformer(asset);
                const dir = transformedDir[1] as unknown as {
                    children: AdobeRepoMetadata[];
                };
                return dir.children.filter((asset) => asset.format === type);
            } catch (e) {
                console.error(e);
            }
        }

        const directory = new Directory(asset, this.acpService);

        const children: AdobeRepoMetadata[] = [];
        try {
            let page = await directory.getPagedChildren({ orderBy, type });
            do {
                children.push(...(page.paged.items as AdobeRepoMetadata[]));
            } while ((page = await page.paged.getNextPage()));
        } catch (e) {
            console.error(e);
        }

        return children;
    }

    /**
     * @param {string} path The path to ensure. Do NOT include a leading "/" as this will be handled for you.
     * @returns {Promise<DirectoryResult>}
     */
    async ensureDirectoryFromIndexRoot(path: string) {
        const getDirectoryOrCreate = async () => {
            try {
                const dir = await this.getDirectory(path);
                return dir;
            } catch (err) {
                if ((err as AdobeDCXError).code === "NOT_FOUND") {
                    log.info(
                        { err, path },
                        "Directory did not exist. Attempt to create.",
                    );
                } else {
                    throw err;
                }
            }

            try {
                const rootDir = await this.getRootDir();
                const { result: createResult, response } =
                    await rootDir.createAsset(path, true, DirectoryMediaType);

                if (createResult) {
                    return createResult;
                }
                log.error({
                    errorCode: "2017",
                    statusCode: response.statusCode,
                });
            } catch (e) {
                log.error({
                    errorCode: "2017",
                    statusCode: (e as AdobeDCXError)?.response?.statusCode,
                });
            }

            throw new Error(`Failed to find or create ${path} directory`);
        };

        return retryCallbackWithJitter(
            getDirectoryOrCreate,
            3,
            1_000,
            2_000,
            log,
        );
    }

    async pullComposite(compositeId: string) {
        const composite = await this.getCompositeById(compositeId);
        const dcxComposite = this.getDcxCompositeFromComposite(composite);
        const branch = await pullCompositeManifestOnly(
            this.session,
            dcxComposite,
        );
        dcxComposite.resolvePullWithBranch(branch);

        return dcxComposite;
    }

    async getMetadata(assetId: string): Promise<AdobeDCXBranch> {
        const composite = await this.pullComposite(assetId);

        return composite._current;
    }

    /**
     * @param assetId
     * @param keys keys to get
     * @param childName If not supplied the root node will be used
     */
    async getTypedChildMetadata<
        T extends readonly string[],
        R extends Record<T[number], string | undefined>,
    >(assetId: string, keys: T, childName?: string): Promise<R> {
        const result: R = {} as R;

        const manifest = await this.getMetadata(assetId);

        const child = childName
            ? manifest.getChildrenOf().find((child) => child.name === childName)
            : manifest.rootNode;

        if (!child) return result;

        keys.forEach((key: T[number]) => {
            result[key] = child.getValue(key); // Use type assertion to tell TypeScript that `key` is a valid key.
        });

        return result;
    }

    /**
     * @param assetId
     * @param data keys to set
     * @param childName If not supplied the root node will be used
     */
    setTypedChildMetadata(
        assetId: string,
        data: Record<string, string>,
        childName?: string,
    ): Promise<void> {
        return retryCallbackWithJitter(async () => {
            const composite = await this.pullComposite(assetId);

            let child = childName
                ? composite.current
                    .getChildrenOf()
                    .find((child) => child.name === childName)
                : composite.current.rootNode;

            if (!child) {
                child = composite.current.addChild(childName);
            }

            Object.entries(data).forEach(([key, value]) => {
                child?.setValue(key, value);
            });
            // don't force update composite
            await pushComposite(this.session, composite, false);
        }, 3, 2_000, 500, log);
    }

    async getComponentByMatcher(
        compositeId: string,
        matcher: (component: AdobeDCXComponent) => boolean,
    ) {
        const manifest = await this.getMetadata(compositeId);

        const component = manifest.allComponents().find(matcher);

        return component;
    }

    async getUrlForComponentByMatcher(
        compositeId: string,
        matcher: (component: AdobeDCXComponent) => boolean,
    ) {
        const component = await this.getComponentByMatcher(
            compositeId,
            matcher,
        );

        if (!component) {
            return;
        }

        const url = await this.getComponentUrl(
            compositeId,
            component.id,
            component.version,
        );

        return url;
    }

    async getThumbnailRenditionUrl(compositeId: string) {
        return this.getUrlForComponentByMatcher(compositeId, (component) => {
            return (
                component.name === "thumbnail" &&
                component.path.startsWith("renditions/")
            );
        });
    }

    async getSourceDocPath(compositeId: string) {
        const metadata = await this.getMetadata(compositeId);
        const untypedMeta = metadata.data as unknown as any;

        return untypedMeta["usdcx#source-document"] as string;
    }

    async getOptimizedDocPath(compositeId: string) {
        const metadata = await this.getMetadata(compositeId);
        const untypedMeta = metadata.data as unknown as any;

        return untypedMeta["usdcx#optimized-document"] as string;
    }

    async getGlbComponent(compositeId: string, useOptimized = true) {
        const sourceDocPath = await this.getSourceDocPath(compositeId);
        const optimizedDocPath = await this.getOptimizedDocPath(compositeId);

        if (useOptimized && optimizedDocPath) {
            return await this.getComponentByMatcher(
                compositeId,
                (composite) => composite.path === optimizedDocPath,
            );
        }

        if (sourceDocPath?.match(/glb$/)) {
            return await this.getComponentByMatcher(
                compositeId,
                (composite) => composite.path === sourceDocPath,
            );
        }
        return await this.getComponentByMatcher(
            compositeId,
            (composite) => composite.name === "convertedGlbModel",
        );
    }

    async getGlbUrl(compositeId: string, useOptimized = true) {
        const glbComponent = await this.getGlbComponent(
            compositeId,
            useOptimized,
        );

        if (!glbComponent) throw new Error("Couldn't get component");

        return await this.getComponentUrl(
            compositeId,
            glbComponent.id,
            glbComponent.version,
        );
    }

    async getUsdzUrl(compositeId: string) {
        return await this.getUrlForComponentByMatcher(
            compositeId,
            (component) => component.path.includes("convertedUsdzModel"),
        );
    }

    async getStorageQuota() {
        const dir = await this.getDirectory("");
        const quotaUrl =
            dir?._links["http://ns.adobe.com/adobecloud/rel/quota"]?.href;
        if (!quotaUrl) {
            throw new Error("Failed to fetch quota URL");
        }

        const { statusCode, response } = await this.acpService.invoke(
            "GET",
            quotaUrl,
        );
        if (statusCode !== 200) {
            throw new Error("Failed to get quota");
        }

        const bytesUsed = response["storage:bytesUsed"] as number;
        const bytesLimit = response["storage:bytesLimit"] as number;
        const limitType = response["storage:limitType"];
        return {
            bytesUsed,
            bytesLimit,
            limitType,
        };
    }

    public async uploadComponent(
        compositeId: string,
        file: File,
        path: string,
        componentName: string,
        extraMeta?: Record<string, string>,
        progressCallback?: ProgressCallback,
        componentId = crypto.randomUUID(),
    ): Promise<{
        uploadComponentPromise: Promise<AdobeDCXComponent>;
        cancel: () => void;
    }> {
        const dcxComposite = await this.pullComposite(compositeId);

        const current = dcxComposite.current;

        const arrBuffer = await file.arrayBuffer();

        const onSliceBuffer = (
            startBuf: number,
            endBuf: number,
        ): Promise<ArrayBuffer> => {
            const slice = arrBuffer.slice(startBuf, endBuf);
            return new Promise((resolve) => {
                resolve(slice);
            });
        };

        const findComponent = dcxComposite.current.getComponentWithAbsolutePath(
            "/" + path,
        );

        const uploadPromise =
            findComponent == undefined
                ? uploadNewComponent(
                      this.session,
                      dcxComposite,
                      onSliceBuffer,
                      "application/octet-stream",
                      componentId,
                      file.size,
                      undefined, // md5
                      progressCallback,
                  )
                : uploadComponent(
                      this.session,
                      dcxComposite,
                      findComponent,
                      onSliceBuffer,
                      file.size,
                      undefined, // md5
                      progressCallback,
                  );

        const cancel = uploadPromise.cancel.bind(uploadPromise);
        const uploadComponentPromise = (async () => {
            const uploadResults = await uploadPromise;
            const component =
                findComponent == undefined
                    ? current.addComponentWithUploadResults(
                          file.name,
                          componentName,
                          path,
                          undefined,
                          uploadResults,
                      )
                    : current.updateComponentWithUploadResults(
                          findComponent,
                          uploadResults,
                      );

            if (extraMeta) {
                Object.entries(extraMeta).forEach(([key, val]) => {
                    dcxComposite.current.rootNode.setValue(key, val);
                });
            }

            try {
                await pushComposite(this.session, dcxComposite, true);
            } catch (e) {
                log.error({
                    errorCode: "2000",
                    resources: [{ compositeId: dcxComposite.id }],
                    statusCode: (e as AdobeDCXError)?.response?.statusCode,
                });
                throw new Error(
                    "[dcx-js] Failed to push component to composite.",
                );
            }
            return component;
        })();

        return { uploadComponentPromise, cancel };
    }
}
