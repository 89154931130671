/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { StudioTool } from "@components/studio";
import {
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

import { StudioView } from "./StudioView";
import { useEnvironmentBuilder } from "@src/hooks/useEnvironmentBuilder";
import {
    DefaultEnvironmentState,
    EnvironmentBuilder,
    EnvironmentState,
} from "@src/lib/babylon/EnvironmentBuilder";
import { getPhysicalSize } from "@src/util/HealthCheckUtils";

import type { SceneManager } from "@components/studio/src/scene/SceneManager";

type Props = {
    viewerUrl: string;
    comparisonUrl: string;
    compare: boolean;
    sceneManager?: SceneManager;
    comparisonSceneManager?: SceneManager;
    setSceneManager: (manager: SceneManager) => void;
    setComparisonSceneManager: (manager: SceneManager) => void;
};

function SlidingWindow({
    children,
    side,
    percent,
}: PropsWithChildren<{ side: "left" | "right"; percent: number }>) {
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                clipPath:
                    side === "left"
                        ? `xywh(0 0 ${percent}% 100%)`
                        : `xywh(${100 - percent}% 0 100% 100%)`,
            }}>
            {children}
        </div>
    );
}

function linkCameras(
    sourceScene: SceneManager,
    targetScene: SceneManager,
    envBuilder: EnvironmentBuilder,
) {
    const sourceViewer = sourceScene.viewer;
    const targetViewer = targetScene.viewer;
    if (sourceViewer.camera) {
        sourceViewer.camera.onViewMatrixChangedObservable.add(() => {
            if (targetViewer.camera && sourceViewer.camera) {
                targetViewer.camera.position = envBuilder.clampPosition(
                    sourceViewer.camera.position,
                );
                targetViewer.camera.target.set(
                    ...sourceViewer.camera.target.asArray(),
                );
                targetViewer.renderLoop.toggle();
            }
        });
    }

    sourceScene.viewer.onModelLoaded.add(() => {
        targetScene.frame();
        sourceScene.frame();
    });
}

function useLinkedCameras(
    linkEnabled: boolean,
    sourceScene?: SceneManager,
    targetScene?: SceneManager,
    envBuilder?: EnvironmentBuilder,
) {
    const [linked, setLinked] = useState(false);

    useEffect(() => {
        if (
            !linked &&
            linkEnabled &&
            sourceScene &&
            targetScene &&
            envBuilder
        ) {
            linkCameras(sourceScene, targetScene, envBuilder);
            linkCameras(targetScene, sourceScene, envBuilder);
            setLinked(true);
        }
    }, [linked, linkEnabled, sourceScene, targetScene, envBuilder]);
}

interface HandelProps {
    containerRef?: HTMLDivElement;
    onPositionChange: (percent: number) => void;
}
function SlidingHandle({ containerRef, onPositionChange }: HandelProps) {
    const { t } = useTranslation("web");
    const handleRef = useRef<HTMLDivElement>(null);
    const draggingRef = useRef(false);
    const [offset, setOffset] = useState(0);
    const [containerRect, setContainerRect] = useState<DOMRect>();

    function updateOffset(mouseX: number, rect: DOMRect) {
        if (draggingRef.current) {
            const relativePos = mouseX - rect.x;
            setOffset(Math.max(Math.min(relativePos, rect.width), 0));
        }
    }

    useEffect(() => {
        function updateRect() {
            if (containerRef) {
                setContainerRect(containerRef.getBoundingClientRect());
            }
        }
        updateRect();
        window.addEventListener("resize", updateRect);
        return () => {
            window.removeEventListener("resize", updateRect);
        };
    }, [containerRef]);

    useEffect(() => {
        if (handleRef.current && containerRect) {
            setOffset(containerRect.width / 2);

            handleRef.current.addEventListener(
                "mousedown",
                () => (draggingRef.current = true),
            );
            handleRef.current.addEventListener(
                "mouseup",
                () => (draggingRef.current = false),
            );
            document.body.addEventListener("mousemove", (e) =>
                updateOffset(e.clientX, containerRect),
            );
        }
    }, [handleRef.current, containerRect]);

    useEffect(() => {
        if (containerRect) {
            onPositionChange((offset / containerRect.width) * 100);
        }
    }, [offset, containerRect]);

    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: `${offset}px`,
                zIndex: 2,
                pointerEvents: "none",
                userSelect: "none",
                // This was originally designed to be over white and I'm feeling lazy
                filter: "invert()",
            }}>
            <div
                style={{
                    position: "relative",
                    left: "-50%",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "end",
                    color: "#323232",
                    fontSize: "14px",
                    lineHeight: "30px",
                    fontWeight: "700",
                }}>
                <div style={{ width: "40px" }}>{t("infoPanel.original")}</div>
                <div
                    ref={handleRef}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "end",
                        height: "100%",
                        fontSize: "20px",
                        lineHeight: "34px",
                        gap: "5px",
                        cursor: "ew-resize",
                        pointerEvents: "all",
                    }}>
                    <div>&lt;</div>
                    <div
                        style={{
                            height: "100%",
                            border: "1px solid gray",
                        }}
                    />
                    <div>&gt;</div>
                </div>
                <div style={{ width: "40px" }}>{t("infoPanel.optimized")}</div>
            </div>
        </div>
    );
}

export function ViewerComparison({
    viewerUrl,
    comparisonUrl,
    compare,

    sceneManager,
    setSceneManager,
    comparisonSceneManager,
    setComparisonSceneManager,
}: Props) {
    const [position, setPosition] = useState(100);
    const containerRef = useRef<HTMLDivElement>(null);

    const [evnState, setEnvironmentState] = useState<
        EnvironmentState | undefined
    >();
    const { envBuilder } = useEnvironmentBuilder(sceneManager, evnState);
    const { envBuilder: compEnvBuilder } = useEnvironmentBuilder(
        comparisonSceneManager,
        evnState,
    );

    useLinkedCameras(!!comparisonUrl, sceneManager, comparisonSceneManager, envBuilder);

    useEffect(() => {
        const manager = sceneManager || comparisonSceneManager;
        if (manager && !evnState) {
            manager.viewer.onModelLoaded.addOnce(() => {
                const size = getPhysicalSize(manager.viewer);
                setEnvironmentState({
                    ...DefaultEnvironmentState(),
                    size,
                });
            });
        }
    }, [sceneManager, comparisonSceneManager]);

    useEffect(() => {
        if (compare) {
            if (sceneManager && viewerUrl) {
                sceneManager.changeModel(viewerUrl);
            }
            if (comparisonSceneManager && comparisonUrl) {
                comparisonSceneManager
                    .changeModel(comparisonUrl)
                    .then(() => {
                        compEnvBuilder?.updateEnvironment();
                    })
                    .catch((e) => console.warn(e));
            }
        } else {
            if (sceneManager && viewerUrl) {
                sceneManager
                    .changeModel(viewerUrl)
                    .then(() => {
                        envBuilder?.updateEnvironment();
                    })
                    .catch((e) => console.warn(e));
            }
        }
    }, [
        sceneManager,
        comparisonSceneManager,
        viewerUrl,
        comparisonUrl,
        compare,
    ]);

    useEffect(() => {
        if (compare) {
            setPosition(50);
        } else {
            setPosition(100);
        }
    }, [compare]);

    return (
        <div
            ref={containerRef}
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
            }}>
            {compare && (
                <SlidingHandle
                    onPositionChange={setPosition}
                    containerRef={containerRef.current || undefined}
                />
            )}
            <SlidingWindow side="left" percent={position}>
                <StudioView
                    modelUrl={viewerUrl}
                    editorTools={[
                        StudioTool.cameraControls,
                        StudioTool.frameButton,
                    ]}
                    setSceneManager={setSceneManager}
                />
            </SlidingWindow>
            <SlidingWindow side="right" percent={100 - position}>
                <StudioView
                    modelUrl={comparisonUrl}
                    editorTools={[
                        StudioTool.cameraControls,
                        StudioTool.frameButton,
                    ]}
                    setSceneManager={setComparisonSceneManager}
                />
            </SlidingWindow>
        </div>
    );
}
