/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    Flex,
    Heading,
    Image,
    Item,
    Picker,
    Switch,
    Text,
    View,
    ProgressCircle
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import Alert from "@spectrum-icons/workflow/Alert";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PHOTON_MAX_PLAYERS } from "@src/config";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import { MicUnmuted } from "@src/components/custom-icons/MicIcon";
import { useAccessCheck } from "@src/hooks/useAccessCheck";
import { useAudioControls } from "@src/hooks/useAudioControls";
import { useRedirects } from "@src/hooks/useRedirects";
import { getPlayerCountString } from "@src/util/StringUtils";

interface Props {
    assetId?: string;
    assetName: string;
}

export function EnterReviewDialog({ assetId, assetName }: Props) {
    const { t } = useTranslation(["common"]);
    useAccessCheck(assetId || "");

    const { closeModal } = useMessagingContext();
    const { networkManager } = useNetworkContext();
    const { useReviewThumbnailUrl } = useAcpContext();
    const { homeRedirect, reviewRedirect } = useRedirects();
    const { avatarUrl, userProfile } = useHi5UserContext();

    const { data: modelThumbnail } = useReviewThumbnailUrl(assetId || "");

    const [playerCount, setPlayerCount] = useState(0);
    const [joinedLobby, setJoinedLobby] = useState(false);

    const {
        audioDevices,
        currentAudioDeviceId,
        setCurrentAudioDeviceId,
        muted,
        setMuted,
    } = useAudioControls();

    useEffect(() => {
        if (!networkManager || !assetId) return;

        const initRealtimeConnection = async () => {
            try {
                await networkManager.startConnection(assetId);
                if (networkManager.isJoinedLobby()) {
                    setJoinedLobby(true);
                }
            } catch (error) {
                console.error("Error initializing realtime:", error);
            }
        };

        initRealtimeConnection();
        setPlayerCount(networkManager.getTotalPlayerCountInRoom(assetId));
    }, [networkManager]);

    useEffect(() => {
        const handleOnRoomListUpdate = () => {
            if (networkManager && assetId) {
                setPlayerCount(
                    networkManager.getTotalPlayerCountInRoom(assetId),
                );
            }
        };

        window.addEventListener("onRoomListUpdate", handleOnRoomListUpdate);

        return () => {
            window.removeEventListener(
                "onRoomListUpdate",
                handleOnRoomListUpdate,
            );
        };
    }, []);

    return (
        <View
            id="modal-enter-review"
            maxWidth="850px"
            UNSAFE_style={{ height: "min-content" }}
            backgroundColor="gray-200"
            borderRadius="large"
            padding="size-400">
            <Flex direction="column" width="100%" height="100%">
                <Heading level={1} margin={0} marginBottom="size-125">
                    {assetName} &nbsp;
                </Heading>
                <Flex gap="size-400">
                    <View
                        minWidth="360px"
                        height="360px"
                        borderRadius="large"
                        backgroundColor="gray-200">
                        <Image
                            src={modelThumbnail || ""}
                            isHidden={!modelThumbnail}
                        />
                    </View>
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        width="350px">
                        <View>
                            {playerCount > 0 &&
                                playerCount < PHOTON_MAX_PLAYERS && (
                                    <View
                                        backgroundColor="gray-400"
                                        padding="size-100"
                                        borderRadius="regular"
                                        marginBottom="size-100">
                                        <Text>
                                            {getPlayerCountString(
                                                playerCount,
                                                t,
                                            )}
                                        </Text>
                                    </View>
                                )}
                            {playerCount >= PHOTON_MAX_PLAYERS && (
                                <View
                                    backgroundColor="red-400"
                                    padding="size-100"
                                    borderRadius="regular"
                                    marginBottom="size-100">
                                    <Flex direction="row">
                                        <View padding="size-50">
                                            <Alert size="M" />
                                        </View>
                                        <Text
                                            UNSAFE_style={{
                                                paddingLeft:
                                                    "var(--spectrum-global-dimension-size-150, var(--spectrum-alias-size-150))",
                                            }}>
                                            {t("enterReview.playerCount.max", {
                                                val: PHOTON_MAX_PLAYERS,
                                            })}
                                        </Text>
                                    </Flex>
                                </View>
                            )}
                            <Flex direction="column" gap="size-250">
                                <Picker
                                    isDisabled={playerCount >= PHOTON_MAX_PLAYERS}
                                    width="100%"
                                    label="Select microphone"
                                    selectedKey={currentAudioDeviceId}
                                    onSelectionChange={(deviceId) =>
                                        setCurrentAudioDeviceId(
                                            deviceId as string,
                                        )
                                    }>
                                    {audioDevices.map((device) => (
                                        <Item key={device.deviceId}>
                                            {device.label}
                                        </Item>
                                    ))}
                                </Picker>
                                <Flex gap="size-125" alignItems="center">
                                    <View
                                        UNSAFE_style={{
                                            color: `${playerCount >= PHOTON_MAX_PLAYERS && "gray"}`,
                                        }}>
                                        <MicUnmuted size="S" />
                                    </View>
                                    <Switch
                                        isDisabled={
                                            playerCount >= PHOTON_MAX_PLAYERS
                                        }
                                        isSelected={!muted}
                                        onChange={(selected) =>
                                            setMuted(!selected)
                                        }
                                    />
                                </Flex>
                            </Flex>
                        </View>
                        <Flex direction="column" gap="size-300">
                            <Flex gap="size-150">
                                <Image
                                    width="48px"
                                    height="48px"
                                    src={avatarUrl}
                                />
                                <Flex direction="column">
                                    <Heading level={2} margin={0}>
                                        {userProfile?.displayName ||
                                            `${userProfile?.first_name} ${userProfile?.last_name}`}
                                    </Heading>
                                    <Text
                                        UNSAFE_style={{
                                            textOverflow: "ellipsis",
                                            overflowX: "hidden",
                                            maxWidth: "100%",
                                            opacity: 0.7,
                                        }}>
                                        {userProfile?.email}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex justifyContent="space-between" gap="size-100">
                                <Button
                                    flex={1}
                                    variant="secondary"
                                    onPress={() => {
                                        homeRedirect();
                                    }}>
                                    {t("navigation.goHome")}
                                </Button>
                                <Button
                                    flex={1}
                                    variant={
                                        playerCount >= PHOTON_MAX_PLAYERS
                                            ? "cta"
                                            : "secondary"
                                    }
                                    isDisabled={!joinedLobby}
                                    onPress={() => {
                                        closeModal();
                                        reviewRedirect(assetId!, true);
                                    }}>
                                    <Text>{t("navigation.soloMode")}</Text>
                                </Button>
                                <Button
                                    flex={1}
                                    variant="cta"
                                    onPress={() => {
                                        closeModal();
                                        reviewRedirect(assetId!, false);
                                    }}
                                    isDisabled={
                                        playerCount >= PHOTON_MAX_PLAYERS ||
                                        !joinedLobby
                                    }>
                                        <Flex direction="row" alignItems="center" gap="size-100" UNSAFE_style={{whiteSpace: "nowrap"}}>
                                            {t("navigation.enterReview")}
                                            {(!joinedLobby) && (
                                                <ProgressCircle aria-label="Loading…" isIndeterminate size="S" />
                                            )}
                                        </Flex>
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </View>
    );
}
