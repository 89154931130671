/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
//@ts-ignore
import { validateBytes } from "gltf-validator";

export const AssetTechInfoFields = [
    "physicalSize",
    "originalFormat",
    "fileFormat",
    "fileSize",
    "polyCount",
    "drawCalls",
    "meshes",
    "materials",
    "textures",
] as const;
export type ReviewerAssetTechInfo = Partial<
    Record<(typeof AssetTechInfoFields)[number], string>
>;

interface NumericValueField {
    displayName: string;
    value: number;
    recommendedLimit: number;
    displayValue?: string;
    displayRecommendedLimit?: string;
}

interface PhysicalSizeField {
    displayName: string;
    value: [number, number, number];
    displayValue?: string;
    recommendedRange: [number, number];
}

export interface AssetTechInfo extends GltfReportInfo {
    physicalSize: PhysicalSizeField;
    originalFormat?: Record<string, string>;
}

export interface GltfReportInfo {
    fileFormat: Record<string, string>;
    fileSize: NumericValueField;
    polycount: NumericValueField;
    drawCalls: NumericValueField;
    meshes: NumericValueField;
    materials: NumericValueField;
    textures: NumericValueField;
}

export async function fetchReportInfo(url: string) {
    const res = await fetch(url);
    const buffer = await res.arrayBuffer();
    const report  = await validateBytes(new Uint8Array(buffer));
    return getReportTechInfo(report, buffer.byteLength);
}

export function getReportTechInfo(report: any, size: number) {
    const info = getEmptyGltfReportInfo();

    info.fileSize.value = size;
    info.polycount.value = report.info.totalTriangleCount;
    info.meshes.value = info.drawCalls.value = report.info.drawCallCount;
    info.materials.value = report.info.materialCount;
    info.textures.value = report.info.resources.filter((res: any) =>
        res.mimeType.startsWith("image/"),
    ).length;

    return info;
}

export function getEmptyAssetTechInfo(
    reportInfo?: GltfReportInfo,
): AssetTechInfo {
    const info = reportInfo || getEmptyGltfReportInfo();
    return {
        physicalSize: {
            displayName: "Physical size",
            value: [0, 0, 0],
            recommendedRange: [0.005, 12],
        },
        ...info,
    };
}

export function isEmptyAssetTechInfo(info: AssetTechInfo) {
    return Math.max(...info.physicalSize.value) === 0;
}

export function hydrateAssetTechInfo(meta: ReviewerAssetTechInfo) {

    const info = getEmptyAssetTechInfo();
    if (meta.originalFormat) {
        const originalFormat = JSON.parse(meta.originalFormat);
        if (Object.keys(originalFormat).length !== 0) info.originalFormat = originalFormat;
    }
    info.fileFormat = JSON.parse(meta.fileFormat ?? "{}");
    info.fileSize.value = Number(meta.fileSize);
    info.polycount.value = Number(meta.polyCount);
    info.drawCalls.value = Number(meta.drawCalls);
    info.meshes.value = Number(meta.meshes);
    info.materials.value = Number(meta.materials);
    info.textures.value = Number(meta.textures);
    info.physicalSize.value = JSON.parse(
        meta.physicalSize ?? "[]",
    ) as [number, number, number];
    return info;
}

export function serializeAssetTechInfo(info: AssetTechInfo) {
    const serializedInfo: ReviewerAssetTechInfo = {
        physicalSize: JSON.stringify(info.physicalSize.value),
        fileFormat: JSON.stringify(info.fileFormat),
        fileSize: info.fileSize.value.toString(),
        polyCount: info.polycount.value.toString(),
        drawCalls: info.drawCalls.value.toString(),
        meshes: info.meshes.value.toString(),
        materials: info.materials.value.toString(),
        textures: info.textures.value.toString(),
    };

    if (info.originalFormat && Object.keys(info.originalFormat).length !== 0) {
        serializedInfo.originalFormat = JSON.stringify(info.originalFormat);
    }

    return serializedInfo;
}

export function getEmptyGltfReportInfo(): GltfReportInfo {
    return {
        fileFormat: { displayName: "Format", value: "GLB" },
        fileSize: {
            displayName: "File size",
            value: 0, // in KB
            recommendedLimit: 10000000, // in KB
        },
        polycount: {
            displayName: "Polycount",
            value: 0,
            recommendedLimit: 500000,
        },
        drawCalls: {
            displayName: "Draw calls",
            value: 0,
            recommendedLimit: 300,
        },
        meshes: { displayName: "Meshes", value: 0, recommendedLimit: 10 },
        materials: {
            displayName: "Materials",
            value: 0,
            recommendedLimit: 8,
        },
        textures: {
            displayName: "Textures",
            value: 0,
            recommendedLimit: 10,
        },
    };
}

export function addOriginalFileFormat(
    info: AssetTechInfo,
    originalFormat?: string,
): AssetTechInfo {
    if (originalFormat && originalFormat !== "glb") {
        info.originalFormat = {
            displayName: "Original format",
            value: originalFormat?.toUpperCase() || "",
        };
        const orderedInfo = {
            physicalSize: null,
            originalFormat: null,
            fileFormat: null
        };
        return Object.assign(orderedInfo, info)
    }
    return info;
}

export function addPhysicalSizeFromViewer(
    viewer: AdobeViewer,
    info: Pick<AssetTechInfo, "physicalSize">,
) {
    const size = getPhysicalSize(viewer)
    info.physicalSize.value = size;
}

export function getPhysicalSize(viewer: AdobeViewer) {
    if (!viewer.model) throw new Error("Model must exist to get physical size")
    const bounds = viewer.model?.getHierarchyBoundingVectors();
    const diff = bounds.max.subtract(bounds.min);

    return diff.asArray().map(val => Math.abs(val) / viewer.modelScale) as [number, number, number];
}

export function shouldOptimizeModel(techInfo: AssetTechInfo | undefined) {
    if (!techInfo) return false;
    return !![
        techInfo.drawCalls,
        techInfo.fileSize,
        techInfo.materials,
        techInfo.meshes,
        techInfo.polycount,
        techInfo.textures,
    ].find((info) => info.value > info.recommendedLimit);
}
