/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

export type PinContextValue = ReturnType<typeof usePinContextValue>;

export const PinContext = createContext<PinContextValue>({} as PinContextValue);

export function usePinContext() {
    const context = useContext(PinContext);
    if (!context.toggleAnnotationButton) {
        throw new Error(
            "usePinContext must be used within a PinContext.Provider",
        );
    }
    return context;
}

function usePinContextValue() {
    const toggleAnnotationButton = useRef<HTMLElement | null>(null);
    const [pinsVisible, setPinsVisible] = useState<boolean>(true);

    useEffect(() => {
        console.log("toggleAnnotationButton", toggleAnnotationButton.current);
        if (!toggleAnnotationButton.current) {
            const ccCommentsList =
                document.getElementsByTagName("cc-comments-list")[0];
            if (ccCommentsList) {
                const buttonContainer =
                    ccCommentsList.shadowRoot?.querySelector(
                        ".toggle-right-container",
                    );
                const button = buttonContainer?.querySelector<HTMLElement>(
                    "#ccx-comments-hide-annotations-button",
                );

                if (button) {
                    toggleAnnotationButton.current = button;
                }
            }
        }
    });

    return {
        toggleAnnotationButton,
        pinsVisible,
        setPinsVisible,
    };
}

export function PinContextProvider({ children }: PropsWithChildren) {
    const contextValue = usePinContextValue();

    return (
        <PinContext.Provider
            value={contextValue}>
            {children}
        </PinContext.Provider>
    );
}
