/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import { ActivationTimer } from "@components/studio/src/utils/ActivationTimer";

import type { Pin } from "./mesh/Pin";
import type { PickingInfo } from "@babylonjs/core/Collisions/pickingInfo";
import type { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import type { Observer } from "@babylonjs/core/Misc/observable";
import type { Scene } from "@babylonjs/core/scene";
import type { Nullable } from "@babylonjs/core/types";

import "@babylonjs/core/Sprites/spriteSceneComponent";

interface RegisteredPin {
    pin: Pin;
    clickHandler: (pick: PickingInfo, pin: Pin) => void;
    moveHandler: (over: boolean) => void;
}

export class PointerEventManager {
    private viewer: AdobeViewer;
    private canvas: HTMLElement;
    private scene: Scene;

    private pointerObservableHandle: Nullable<Observer<PointerInfo>>;
    private currentHoveredPin?: RegisteredPin;

    interactionDisabled = false;

    registeredPins: RegisteredPin[] = [];

    clickOutsideHandler: () => void;

    constructor(viewer: AdobeViewer, clickOutsideHandler: () => void) {
        this.viewer = viewer;
        this.canvas = viewer.canvas as HTMLElement;
        this.scene = viewer.scene;
        this.clickOutsideHandler = clickOutsideHandler;
        this.pointerObservableHandle = this.scene.onPointerObservable.add(
            (e) => {
                if (!this.interactionDisabled) {
                    switch (e.type) {
                        case PointerEventTypes.POINTERMOVE:
                            this.onMove();
                            break;
                        case PointerEventTypes.POINTERTAP:
                            (e.event as PointerEvent).stopPropagation();
                            this.onClick();
                            break;
                    }
                }
            },
        );
    }

    attachPinHandler(registeredPin: RegisteredPin) {
        this.registeredPins.push(registeredPin);
    }

    removePinHandlerById(pinId: string) {
        this.registeredPins = this.registeredPins.filter(
            (regPin) => regPin.pin.pinData?.id !== pinId,
        );
    }

    removePinHandler(pin: Pin) {
        this.registeredPins = this.registeredPins.filter(
            (regPin) => regPin.pin !== pin,
        );
    }

    removeAllHandlers() {
        this.currentHoveredPin = undefined;
        this.registeredPins = [];
    }

    dispose() {
        this.scene.onPointerObservable.remove(this.pointerObservableHandle);
        this.canvas.style.cursor = "";
    }

    private onMove() {
        const pickResult = this.getPick();
        if (
            pickResult?.hit &&
            pickResult?.pickedSprite &&
            !this.currentHoveredPin
        ) {
            const matchedRegPin = this.registeredPins.find(
                (regPin) => regPin.pin.head === pickResult.pickedSprite,
            );
            if (matchedRegPin) {
                matchedRegPin.moveHandler(true);
                this.currentHoveredPin = matchedRegPin;
                this.pointerActive = true;
            }
        }
        if (!pickResult?.hit && this.currentHoveredPin) {
            this.currentHoveredPin.moveHandler(false);
            this.currentHoveredPin = undefined;
            this.pointerActive = false;
        }

        this.viewer.renderLoop.toggle();
    }

    private onClick() {
        const pickResult = this.getPick();
        if (pickResult?.hit && pickResult?.pickedSprite) {
            const matchedRegPin = this.registeredPins.find(
                (regPin) => regPin.pin.head === pickResult.pickedSprite,
            );
            if (matchedRegPin) {
                matchedRegPin.clickHandler(pickResult, matchedRegPin.pin);
            }
        } else {
            this.clickOutsideHandler();
        }
    }

    private getPick() {
        return this.scene.pickSprite(this.scene.pointerX, this.scene.pointerY);
    }

    private _pointerActive = false;

    set pointerActive(isActive: boolean) {
        this.canvas.style.cursor = isActive ? "pointer" : "";
        this._pointerActive = isActive;
    }

    get pointerActive() {
        return this._pointerActive;
    }

    disableInteractionToggle = new ActivationTimer(
        () => (this.interactionDisabled = true),
        () => (this.interactionDisabled = false),
        100,
    );
}
