/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useNavigate } from "react-router-dom";

import {
    getLandingPath,
    getHomePath,
    getReviewPagePath,
    getHomeModalPath,
    type HomeModal,
    getReviewPath,
    getMetaVRAppRedirectPath,
} from "@src/util/PathUtils";

export function useRedirects() {
    const navigate = useNavigate();

    function landingRedirect(replace = false) {
        navigate(getLandingPath(), { replace });
    }

    function homeRedirect(replace = false) {
        navigate(getHomePath(), { replace });
    }

    function homeModalRedirect(modal: HomeModal, assetId: string, replace = true) {
        navigate(getHomeModalPath(modal, assetId), { replace });
    }

    function reviewModalRedirect(assetId: string, replace = true) {
        navigate(getReviewPath(assetId), { replace });
    }

    function reviewRedirect(assetId: string, isSolo: boolean, replace = false) {
        navigate(getReviewPagePath(assetId), { replace, state: { isSolo } });
    }

    function metaVRAppRedirect(replace = false) {
        navigate(getMetaVRAppRedirectPath(), { replace });
    }

    return {
        landingRedirect,
        homeRedirect,
        homeModalRedirect,
        reviewModalRedirect,
        reviewRedirect,
        metaVRAppRedirect,
    };
}
