/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex } from "@adobe/react-spectrum";
// import { ToastQueue } from "@react-spectrum/toast";
// import { useTranslation } from "react-i18next";

import { AssetGridView } from "./AssetGridView";
import { AssetListView } from "./AssetListView";
import { useContentListContext } from "@src/contexts/ContentListContext";
import { useIsVRPage } from "@src/hooks/useIsVRPage";
import { ReviewListItem } from "@src/lib/acp/AcpClientModels";

interface AssetsViewProps {
    assets: ReviewListItem[] | undefined;
    gridLayoutEnabled: boolean;
}

export function AssetsView({ assets, gridLayoutEnabled }: AssetsViewProps) {
    const { assetClickHandler } = useContentListContext();
    const { isVRPage } = useIsVRPage();

    return (
        <Flex
            alignItems={gridLayoutEnabled ? "start" : "stretch"}
            alignContent="start"
            direction={gridLayoutEnabled ? "row" : "column"}
            wrap={gridLayoutEnabled}
            width="100%"
            height="100%"
            gap={gridLayoutEnabled ? (isVRPage ? "5em" : "1.5em") : "0"}>
            {assets &&
                assets.map((data) =>
                    gridLayoutEnabled ? (
                        <div
                            key={data.assetId}
                            onClick={() => assetClickHandler(data.assetId)}
                            aria-label={data.displayName}>
                            <AssetGridView assetId={data.assetId} />
                        </div>
                    ) : (
                        <div
                            key={data.assetId}
                            onClick={() => assetClickHandler(data.assetId)}
                            aria-label={data.displayName}>
                            <AssetListView
                                assetId={data.assetId}
                            />
                        </div>
                    ),
                )}
        </Flex>
    );
}
