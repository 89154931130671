/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    View,
    Button,
    Text,
    Header,
    Image,
    Switch,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import Info from "@spectrum-icons/workflow/Info";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import noThumbnail from "../../assets/images/no-thumbnail.svg";
import { UserProfileView } from "../../components/UserProfileView";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import useNotifyVRAuth from "../../hooks/useNotifyVRAuth";
import { MicPermissionDenied, MicUnmutedVR } from "@src/components/custom-icons/MicIcon";
import { PHOTON_MAX_PLAYERS } from "@src/config";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import {
    shouldOptimizeModel,
} from "@src/util/HealthCheckUtils";
import { getPlayerCountString } from "@src/util/StringUtils";

export function EnterReviewDialog() {
    const { t } = useTranslation(["common", "vr"]);
    const {
        useReviewListItem,
        useGlbUrl,
        useReviewThumbnailUrl,
        useEnvironmentMeta,
        useAssetTechInfo
    } = useAcpContext();

    const { userProfile, avatarUrl } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const { networkManager } = useNetworkContext();
    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("urn") || "";

    const { data: reviewItem } = useReviewListItem(assetId);
    const { data: envMeta } = useEnvironmentMeta(assetId);
    const { data: glbUrl } = useGlbUrl(assetId);
    const { data: thumbnailUrl } = useReviewThumbnailUrl(assetId);
    const { data: assetTechInfo } = useAssetTechInfo(assetId);

    useNotifyVRAuth();

    const [playerCount, setPlayerCount] = useState(0);
    const [muted, setMuted] = useState(false);
    const [hasMicPermission, setHasMicPermission] = useState();

    const [isOptimized, setIsOptimized ] = useState(true);
    useEffect(() => {
        if (!assetTechInfo) return;
        setIsOptimized(!shouldOptimizeModel(assetTechInfo));
    }, [assetTechInfo]);

    useEffect(() => {
        if (!networkManager) return;

        if (!networkManager.state()) {
            const initRealtimeConnection = async () => {
                try {
                    await networkManager.startConnection(assetId);
                    setPlayerCount(
                        networkManager.getTotalPlayerCountInRoom(assetId),
                    );
                } catch (error) {
                    console.error("Error initializing realtime:", error);
                }
            };

            initRealtimeConnection();
        }
    }, []);

    useEffect(() => {
        const handleOnRoomListUpdate = () => {
            if (networkManager && assetId) {
                setPlayerCount(
                    networkManager.getTotalPlayerCountInRoom(assetId),
                );
            }
        };

        window.addEventListener("onRoomListUpdate", handleOnRoomListUpdate);

        return () => {
            window.removeEventListener(
                "onRoomListUpdate",
                handleOnRoomListUpdate,
            );
        };
    }, []);

    useEffect(() => {
        if (!postMessageBusApi) return;
        postMessageBusApi.addEventListener("mic-permission", (data: any) => {
            setHasMicPermission(data.detail.permission);
            console.log(`"mic-permission updated: ${hasMicPermission}"`);
        });
    }, [postMessageBusApi]);

    function thumbnail() {
        if (thumbnailUrl) {
            return (
                <Flex direction="column">
                    {playerCount > 0 && playerCount < PHOTON_MAX_PLAYERS && (
                        <View
                            position="absolute"
                            top={isOptimized ? "470px" : "430px"}
                            right="95px"
                            width="76%"
                            backgroundColor="gray-400"
                            paddingY="size-100"
                            borderRadius="regular">
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                    paddingLeft:
                                        "var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200))",
                                }}>
                                {getPlayerCountString(playerCount, t)}
                            </Text>
                        </View>
                    )}
                    {playerCount >= PHOTON_MAX_PLAYERS && (
                        <View
                            position="absolute"
                            top="435px"
                            right="95px"
                            width="76%"
                            backgroundColor="red-400"
                            paddingY="size-100"
                            borderRadius="regular">
                            <Flex direction="row">
                                <View padding="size-100">
                                    <Alert size="M" />
                                </View>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.5em",
                                        paddingLeft:
                                            "var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200))",
                                    }}>
                                    {t("common:enterReview.playerCount.max", {
                                        val: PHOTON_MAX_PLAYERS,
                                    })}
                                </Text>
                            </Flex>
                        </View>
                    )}
                    <Image
                        width="400px"
                        alt={t("common:accessibility.asset.thumbnail")}
                        src={thumbnailUrl}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                </Flex>
            );
        } else {
            return (
                <Image
                    width={"size-2000"}
                    height={"size-2000"}
                    alt={t("common:accessibility.asset.thumbnail")}
                    src={noThumbnail}
                />
            );
        }
    }

    function goHome() {
        if (postMessageBusApi)
            postMessageBusApi.showDialog("log-in-check", "", false);
    }

    function ReturnVRMicIcon()
    {

        if (!hasMicPermission)
        {
            return (
                <MicPermissionDenied />
            );
        }
        else
        {
            return (
                <MicUnmutedVR />
            );
        }
    }

    return (
        <View
            paddingTop={isOptimized ? "size-500" : "size-300"}
            paddingBottom={isOptimized ? "size-500" : "size-400"}
            paddingX="size-700"
            height="100%"
            UNSAFE_style={{
                boxSizing: "border-box",
            }}>
            <Flex
                direction="column"
                height="100%"
                justifyContent="space-between">
                <View >
                    <Header
                        UNSAFE_style={{
                            fontSize: "2.2em",
                        }}>
                        {reviewItem?.displayName || "I'm a review"}
                    </Header>
                </View>
                <View
                    backgroundColor="gray-200"
                    height="400px"
                    UNSAFE_style={{
                        borderRadius: "8px",
                    }}>
                    <Flex
                        direction="row"
                        width="100%"
                        height="400px"
                        alignItems="center"
                        justifyContent="center">
                        {thumbnail()}
                    </Flex>
                </View>
                {!isOptimized && (
                    <View
                        padding="size-200"
                        borderWidth="thick"
                        borderRadius="medium"
                        UNSAFE_style={{
                            borderColor:
                                "var(--spectrum-informative-visual-color)",
                        }}>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("vr:enterReview.notOptimized")}
                            </Text>
                            <Info size="M" color="informative"/>
                        </Flex>
                    </View>
                )}
                <View>
                    <Flex direction="row">
                        <UserProfileView
                            userProfile={userProfile}
                            avatarUrl={avatarUrl}
                            isForVr={true}
                        />
                        <Flex gap="size-125">
                            <View>
                                <Flex
                                    UNSAFE_style={{
                                        paddingTop: "3px",
                                    }}>
                                    {ReturnVRMicIcon()}
                                </Flex>
                            </View>
                            <Switch
                                width="size-200"
                                isSelected={muted}
                                onChange={(selected) => setMuted(selected)}
                                isDisabled={
                                    playerCount >= PHOTON_MAX_PLAYERS ||
                                    hasMicPermission === false
                                }
                            />
                        </Flex>
                    </Flex>
                </View>
                <View>
                    <Flex
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        gap="2%">
                        <Button
                            onPress={goHome}
                            width="100%"
                            variant="secondary"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("common:navigation.goHome")}
                            </Text>
                        </Button>
                        <Button
                            isDisabled={!glbUrl || !envMeta}
                            variant={
                                playerCount >= PHOTON_MAX_PLAYERS
                                    ? "cta"
                                    : "secondary"
                            }
                            style="fill"
                            width="100%"
                            onPress={() => {
                                postMessageBusApi?.enterReview(
                                    assetId,
                                    glbUrl!,
                                    muted,
                                    envMeta,
                                    true,
                                );
                            }}
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("common:navigation.soloMode")}
                            </Text>
                        </Button>
                        <Button
                            isDisabled={
                                !glbUrl ||
                                playerCount >= PHOTON_MAX_PLAYERS ||
                                !envMeta
                            }
                            onPress={() => {
                                postMessageBusApi?.enterReview(
                                    assetId,
                                    glbUrl!,
                                    muted,
                                    envMeta,
                                );
                            }}
                            width="100%"
                            variant="cta"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("common:navigation.enterReview")}
                            </Text>
                        </Button>
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
