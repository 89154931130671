/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import { IconProps } from "../../util/IconUtils";

export const SelectAndMove = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg>
                <path
                    className="st1"
                    d="M17.8,10.8C17.8,10.8,17.9,10.8,17.8,10.8c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1
			l-2.3-1.9c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2v1.3h-3.2V6.9h1.3
			c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2L11.3,4c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1L9,6.2C9,6.3,9,6.4,8.9,6.4c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1
			h1.3v3.2H7.4V8.8c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1l-2.3,1.9
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1l2.3,1.9c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0
			c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2v-1.3h3.2v3.2H9.3c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2s0,0.1,0,0.2
			C9,14.9,9,14.9,9,15l1.9,2.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0s0.1,0,0.1-0.1l1.9-2.3c0-0.1,0.1-0.1,0.1-0.2
			c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1h-1.3v-3.2h3.2v1.3c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1
			c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1L17.8,10.8z"
                />
                <path
                    className="st1"
                    d="M0.1,0.1C0.1,0,0.1,0,0.2,0s0.1,0,0.1,0.1l6,6c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1
			c0,0-0.1,0-0.1,0H2.7L0.3,9.2c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1V0.2C0,0.2,0,0.1,0.1,0.1
			C0,0.1,0,0.1,0.1,0.1z"
                />
                <path className="st2" d="M11.3,5.9" />
            </svg>
        </Icon>
    );
};
