/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Avatar, Flex, Heading, Text, View } from "@adobe/react-spectrum";
import { useAvatar } from "@shared/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetTechInfoView } from "../components/AssetTechInfoView";
import { ADOBE_IMS_CONFIG, IMS_ENV } from "../config";
import { useLocaleContext } from "../contexts/LocaleContext";
import {
    getFormattedDate,
    getFormattedPhysicalSize,
    getTrimmedString,
} from "../util/StringUtils";

import type { AssetTechInfo } from "../util/HealthCheckUtils";
import type { PPSProfile } from "@shared/client";

interface AssetInfoPanelProps {
    accessToken?: string;
    ownerId: string;
    modifyDate?: string;
    techInfo: AssetTechInfo;
}

export function AssetInfoPanel({
    accessToken,
    ownerId,
    modifyDate,
    techInfo,
}: AssetInfoPanelProps) {
    const { getPPSProfile } = useAvatar(
        ADOBE_IMS_CONFIG.client_id,
        IMS_ENV === "prod",
    );
    const { locale } = useLocaleContext();
    const { t } = useTranslation("web");

    const [ownerProfile, setOwnerProfile] = useState<PPSProfile | undefined>();

    async function fetchOwnerProfile() {
        if (!(ownerId && accessToken)) return;
        const profile = await getPPSProfile(ownerId, accessToken);
        setOwnerProfile(profile);
    }

    useEffect(() => {
        if (!ownerId) return;
        fetchOwnerProfile();
    }, [ownerId]);

    const [physicalWidth, setPhysicalWidth] = useState<string | undefined>("");
    const [physicalHeight, setPhysicalHeight] = useState<string | undefined>(
        "",
    );
    const [physicalDepth, setPhysicalDepth] = useState<string | undefined>("");
    useEffect(() => {
        if (!techInfo) return;
        const { formattedPhysicalSize, physicalSizeUnit } =
            getFormattedPhysicalSize(techInfo.physicalSize.value);
        setPhysicalWidth(
            t("infoPanel.displayName.physicalSize.displayValue.individual", {
                val: formattedPhysicalSize[0],
                physicalSizeUnit: physicalSizeUnit,
            }),
        );
        setPhysicalHeight(
            t("infoPanel.displayName.physicalSize.displayValue.individual", {
                val: formattedPhysicalSize[1],
                physicalSizeUnit: physicalSizeUnit,
            }),
        );
        setPhysicalDepth(
            t("infoPanel.displayName.physicalSize.displayValue.individual", {
                val: formattedPhysicalSize[2],
                physicalSizeUnit: physicalSizeUnit,
            }),
        );
    }, [techInfo]);

    function avatarUrl() {
        return (ownerProfile && ownerProfile.avatarUrl) || "";
    }

    function displayName() {
        return ownerProfile && ownerProfile.displayName
            ? getTrimmedString(ownerProfile.displayName)
            : "";
    }

    function dateModified() {
        return modifyDate && getFormattedDate(modifyDate, locale);
    }

    return (
        <Flex
            direction="column"
            justifyContent="start"
            marginX="size-250"
            marginTop="size-250"
            gap="size-250">
            <View>
                <Heading level={4} marginY="size-100">
                    {t("infoPanel.displayName.physicalSize")}
                </Heading>
                <View>
                    <Text>{`${t(
                        "infoPanel.displayName.physicalSize.width",
                    )} ${physicalWidth}`}</Text>
                </View>
                <View>
                    <Text>{`${t(
                        "infoPanel.displayName.physicalSize.height",
                    )} ${physicalHeight}`}</Text>
                </View>
                <View>
                    <Text>{`${t(
                        "infoPanel.displayName.physicalSize.depth",
                    )} ${physicalDepth}`}</Text>
                </View>
            </View>
            <View>
                <Heading level={4} marginY="size-100">
                    {t("infoPanel.createdBy")}
                </Heading>
                <View
                    backgroundColor="gray-200"
                    borderWidth="thin"
                    borderColor="mid"
                    borderRadius="regular"
                    padding="size-75"
                    UNSAFE_style={{
                        width: "fit-content",
                    }}>
                    <Flex
                        direction="row"
                        justifyContent="start"
                        alignItems="center">
                        {avatarUrl() && (
                            <Avatar
                                alt={t(
                                    "accessibility.assetOwner.profilePicture",
                                )}
                                src={avatarUrl()}
                                size={"avatar-size-100"}
                            />
                        )}
                        {displayName() && (
                            <Text
                                marginStart={"size-100"}
                                UNSAFE_style={{
                                    fontSize: "0.9em",
                                }}>
                                {displayName()}
                            </Text>
                        )}
                    </Flex>
                </View>
            </View>
            <View>
                <Heading level={4} marginY="size-100">
                    {t("infoPanel.dateModfied")}
                </Heading>
                <Text>{dateModified()}</Text>
            </View>
            <View>
                <Heading level={4} marginY="size-100">
                    {t("infoPanel.technicalInformation")}
                </Heading>
                <AssetTechInfoView
                    techInfo={techInfo}
                    isAssetInfoPanelChild={true}
                />
            </View>
        </Flex>
    );
}
