/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionMenu,
    Button,
    Content,
    Dialog,
    Flex,
    Footer,
    Heading,
    Item,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { UserProfileView } from "./UserProfileView";
import { getHref } from "@src/config";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { requestAccess } from "@src/lib/invitation/Access";
import { getReviewPath } from "@src/util/PathUtils";

interface AccessRequiredViewProps {
    assetId?: string;
    onGoHome: () => void;
    onSuccess: () => void;
    onFailure: (statusCode: string) => void;
    onNotFound: () => void;
    onSwitchAccounts?: () => void;
    isForVr?: boolean;
}

export function AccessRequiredView({
    assetId,
    onGoHome,
    onSuccess,
    onFailure,
    onNotFound,
    onSwitchAccounts,
    isForVr = false,
}: AccessRequiredViewProps) {
    const { t } = useTranslation("common");
    const { logOut, userProfile, avatarUrl, accessToken } = useHi5UserContext();

    function moreMenuAction(key: any) {
        if (key == "switchAccounts") {
            if (onSwitchAccounts) onSwitchAccounts();
            logOut();
        }
    }

    async function onRequestAccess() {
        if (!accessToken || !assetId) return;

        const responseStatus = await requestAccess(
            accessToken,
            assetId,
            getHref(getReviewPath(assetId), { share: 'true' }),
        );

        if (!responseStatus) {
            console.error("unexpected error from requestAccess");
            return;
        }
        if (responseStatus >= 200 && responseStatus < 300) {
            onSuccess();
        } else if (responseStatus === 404) {
            // asset does not exist
            onNotFound();
        } else {
            onFailure(responseStatus.toString());
        }
    }

    return (
        <Dialog
            width={isForVr ? "800px" : "400px"}
            height={isForVr ? "100%" : ""}>
            <Heading
                UNSAFE_style={{
                    fontSize: isForVr ? "2.2em" : "",
                }}>
                {t("access.denied.title")}
            </Heading>
            <Content>
                <View marginTop="size-250" height={isForVr ? "130px" : "112px"}>
                    <Text
                        UNSAFE_style={{
                            fontSize: isForVr ? "1.75em" : "",
                        }}>
                        {t("access.denied.description")}
                    </Text>
                </View>
            </Content>
            <Footer>
                <View paddingBottom="2em" width="100%">
                    <Flex
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <UserProfileView
                            userProfile={userProfile}
                            avatarUrl={avatarUrl}
                            trimStrings={true}
                            isForVr={isForVr}
                        />
                        <ActionMenu
                            onAction={moreMenuAction}
                            align="end"
                            isQuiet>
                            <Item key="switchAccounts">
                                <Text
                                    UNSAFE_style={{
                                        fontSize: isForVr ? "1.5em" : "",
                                    }}>
                                    {t("account.switch")}
                                </Text>
                            </Item>
                        </ActionMenu>
                    </Flex>
                </View>
                <View paddingBottom="2em" width="100%">
                    <Flex
                        direction="row"
                        width="100%"
                        gap="size-150"
                        justifyContent="space-between">
                        <Button
                            onPress={onGoHome}
                            width="50%"
                            variant="secondary"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: isForVr ? "100px" : "",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.5em" : "",
                                }}>
                                {t("navigation.goHome")}
                            </Text>
                        </Button>
                        <Button
                            onPress={onRequestAccess}
                            width="50%"
                            variant="cta"
                            UNSAFE_style={{
                                borderRadius: isForVr ? "100px" : "",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.5em" : "",
                                }}>
                                {t("access.requestAccess")}
                            </Text>
                        </Button>
                    </Flex>
                </View>
            </Footer>
        </Dialog>
    );
}
