/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export const DEFAULT_IBL_URL = "/assets/ibl/studio_white_soft_light-1k_180_front_comp.env";

// Constants for the webviewer
export const DEFAULT_ASPECT_RATIO = 1;

// Required: This object contains all optional RenderSetting properties
export const DEFAULT_RENDER_SETTINGS = {
    backgroundColor: [0.819, 0.819, 0.819, 1],
    groundPlaneEnabled: true,
    domeLightRotation: 0.0,
    domeLightIntensity: 1.0,
    directionalLightVector: [0.5, -1, 0.5],
    shadowIntensity: 0.75,
};
