/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import { useEffect, useState } from "react";

import { EventType, useAnalytics } from "./useAnalytics";
import headUrl from "../assets/images/pin-default.png";
import headTemporaryUrl from "../assets/images/pin-disabled.png";
import groupHoverSheetUrl from "../assets/images/pin-group-sheet-hover.png";
import groupOccludedSheetUrl from "../assets/images/pin-group-sheet-occluded.png";
import groupSheetUrl from "../assets/images/pin-group-sheet.png";
import headHoverUrl from "../assets/images/pin-hover.png";
import headOccludedUrl from "../assets/images/pin-outline.png";
import headEditUrl from "../assets/images/pin-pending.png";
import headSelectedUrl from "../assets/images/pin-selected.png";
import { getAppPath } from "@src/config";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { PinningSession } from "@src/lib/babylon/PinningSession";
import {
    PinManager,
    PinManagerOptions,
} from "@src/lib/babylon/pins/PinManager";
import { CommentsApi } from "@src/lib/bus-api/CommentsApi";

export function usePinningSession(
    commentsApi: CommentsApi,
    viewerInstance: AdobeViewer | undefined,
    assetId: string,
) {
    const { accessToken } = useHi5UserContext();
    const { sendLaunchAnalytics } = useAnalytics();
    const [pinningSession, setPinningSession] = useState<PinningSession>();

    useEffect(() => {
        const authToken = accessToken;
        if (authToken && assetId && viewerInstance && !pinningSession) {
            viewerInstance.onModelLoaded.addOnce(() => {
                try {
                    const pinOptions: PinManagerOptions = {
                        pinDisplayConfig: {
                            spriteSize: 351,
                            headUrl,
                            headHoverUrl,
                            headSelectedUrl,
                            headEditUrl,
                            headOccludedUrl,
                            headTemporaryUrl,
                        },
                        modelBounds:
                            viewerInstance.model?.getHierarchyBoundingVectors(),
                        grouping: {
                            enabled: true,
                            groupScreenRatio: 0.2,
                            minGroupPinCount: 3,
                            groupPinScalingFactor: 1.6,
                            groupPinHeadUrl: groupSheetUrl,
                            groupPinHeadOccludedUrl: groupOccludedSheetUrl,
                            groupPinHoverUrl: groupHoverSheetUrl,
                        },
                        pinStyle: {
                            headDistanceScale: 0.5,
                        },
                    };
                    const pinManager = new PinManager(
                        viewerInstance,
                        pinOptions,
                    );

                    viewerInstance.plugins.pins = pinManager;

                    const pinSession = new PinningSession(
                        pinManager,
                        commentsApi,
                        viewerInstance,
                    );

                    setPinningSession(pinSession);

                    sendLaunchAnalytics(EventType.success, getAppPath());
                } catch (e) {
                    sendLaunchAnalytics(EventType.error, getAppPath());
                    console.log("Error creating PinningSession", e);
                }
            });
        }
    }, [accessToken, assetId, viewerInstance]);

    return pinningSession;
}
