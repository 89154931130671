/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import { IconProps } from "../../util/IconUtils";

export const SelectAndRotate = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg>
                <path
                    className="st0"
                    d="M6.3,6.3C6.3,6.3,6.3,6.3,6.3,6.3c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l-6-6
	c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1V9c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1-0.1l2.3-2.8h3.5C6.2,6.4,6.2,6.4,6.3,6.3C6.3,6.3,6.3,6.3,6.3,6.3z"
                />
                <path
                    className="st0"
                    d="M15.7,13l-0.5-0.2c-0.3,0.9-0.9,1.6-1.7,2.1c-0.8,0.5-1.7,0.8-2.6,0.7c-0.9,0-1.8-0.3-2.5-0.9
	c-0.7-0.5-1.3-1.3-1.6-2.2c-0.3-0.9-0.3-1.8,0-2.7C7.1,9,7.6,8.2,8.4,7.6c0.7-0.6,1.6-0.9,2.5-0.9c0.9,0,1.8,0.2,2.6,0.7l-0.8,1
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.2C12.8,9,12.9,9,13,9l3,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
	c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1l-0.5-2.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0
	c-0.1,0-0.1,0.1-0.2,0.1l-0.8,1l0,0c-0.9-0.6-2.1-1-3.2-0.9c-1.1,0-2.2,0.4-3.2,1.1C6.9,7.4,6.2,8.4,5.9,9.5c-0.4,1.1-0.4,2.2,0,3.3
	c0.3,1.1,1,2,1.9,2.7c0.9,0.7,2,1.1,3.1,1.1c1.1,0,2.3-0.3,3.2-0.9c1-0.6,1.7-1.5,2.1-2.6L15.7,13z"
                />
            </svg>
        </Icon>
    );
};
