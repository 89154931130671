/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import {
    useQuery,
    useQueryClient,
    QueryClient,
    useMutation,
} from "@tanstack/react-query";
import {
    PersistQueryClientProvider,
    PersistedClient,
} from "@tanstack/react-query-persist-client";
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useMemo,
} from "react";

import { useHi5UserContext } from "./HI5UserProvider";
import { ASSET_APIS, HI5_API_KEY } from "@src/config";
import { getComponentQualityPath } from "@src/hooks/useOptimizeAsset";
import { AcpClient, getRemainingTtl } from "@src/lib/acp/AcpClient";
import {
    ReviewItem,
    ReviewListItem,
    dcxToReviewListItem,
} from "@src/lib/acp/AcpClientModels";
import { AnsClient } from "@src/lib/ans";
import { getCollaboratorsCount } from "@src/lib/invitation/Collaborators";
import { OnsClient } from "@src/lib/ons";
import { getSharedAssets } from "@src/lib/search/SearchSharedAssets";
import {
    AssetTechInfo,
    AssetTechInfoFields,
    hydrateAssetTechInfo,
    serializeAssetTechInfo,
} from "@src/util/HealthCheckUtils";

export const EnvironmentMetaFields = [
    "metaFormatVersion",
    "units",
    "environment",
    "scaling",
    "physicalSize",
    "pedestal",
    "upAxis",
    "grounding",
    "centering",
] as const;

export type ReviewerEnvMeta = Partial<
    Record<(typeof EnvironmentMetaFields)[number], string>
>;

export type QualityKey = "low" | "medium" | "high";

export const acpQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            refetchOnMount: false,
            networkMode: "online",
        },
    },
});

const NO_TOKEN = "no-token";

// URLs have to have 1 hour cache time remaining
const MIN_REMAINING_URL_CACHE_TIME = 60 * 60 * 1000;
const persister = createSyncStoragePersister({
    storage: window.localStorage,
    throttleTime: 2_000,
    deserialize(cachedString) {
        const data = JSON.parse(cachedString) as PersistedClient;
        if (data.buster === NO_TOKEN) {
            data.clientState.queries = [];
        }
        data.clientState.queries = data.clientState.queries.filter((query) => {
            if (
                typeof query.state.data === "string" &&
                query.state.data.startsWith("https://")
            ) {
                try {
                    const ttl = getRemainingTtl(query.state.data);
                    if (ttl < MIN_REMAINING_URL_CACHE_TIME) {
                        console.log(
                            "Expiring url that expires in less than an hour",
                            JSON.stringify(query),
                        );
                        return false;
                    }
                } catch (e) {
                    console.error("Failed to parse query", {
                        query,
                        error: e,
                    });
                    return false;
                }
            }
            return true;
        });
        return data;
    },
});

export type AcpContextValue = ReturnType<typeof useAcpContextValue>;

export const AcpContext = createContext<AcpContextValue>({} as AcpContextValue);

export function useAcpContext() {
    const acpContext = useContext(AcpContext);
    if (!acpContext.acpClient) {
        throw new Error("Acp Context used out of provider scope.");
    }
    return acpContext;
}

async function validateGetUrl(url: string) {
    if (url) {
        const controller = new AbortController();
        const res = await fetch(url, {
            cache: "no-cache",
            signal: controller.signal,
        });
        controller.abort();

        if (!res.ok) {
            const e = new Error(
                `Bad status on validateGetUrl ${url} code: ${res.status}`,
            );
            console.error(e);
            throw e;
        }
    }
}

function useAcpClient() {
    const { imsReady, userId, userProfile, accessToken, authInvalid } =
        useHi5UserContext();

    const acpClient = useMemo(
        () => new AcpClient(ASSET_APIS.hostAcp, HI5_API_KEY),
        [],
    );

    const onsClient = useMemo(() => new OnsClient(), []);
    const ansClient = useMemo(() => new AnsClient(), []);

    useEffect(() => {
        onsClient.accessToken = accessToken;
        ansClient.accessToken = accessToken;
    }, [accessToken]);

    useEffect(() => {
        if (imsReady && userId && userProfile && accessToken && authInvalid) {
            acpClient.initializeUser(
                userId,
                userProfile?.ownerOrg || userId,
                accessToken,
                authInvalid,
            );
            // @ts-ignore Leak to window for debugging
            window.acpClient = acpClient;
        }
    }, [imsReady, userId, userProfile, accessToken, authInvalid]);

    return { acpClient, onsClient, ansClient };
}

function useQueryInvalidations(
    acpClient: AcpClient,
    ansClient: AnsClient,
    onsClient: OnsClient,
) {
    const queryClient = useQueryClient();

    useEffect(() => {
        setTimeout(() => {
            // ensure these queries are fresh after hydration
            queryClient.invalidateQueries({ queryKey: ["myReviews"] });
            queryClient.invalidateQueries({
                queryKey: ["sharedWithMeReviews"],
            });
        }, 5_000);
    }, []);

    useEffect(() => {
        onsClient.on("reviewListChanged", () => {
            queryClient.invalidateQueries({ queryKey: ["myReviews"] });
        });

        onsClient.on("changedAsset", (assetId) => {
            queryClient.invalidateQueries({
                queryKey: ["environmentalMeta", assetId],
            });
            queryClient.invalidateQueries({ queryKey: ["glbUrls", assetId] });
            queryClient.invalidateQueries({
                queryKey: ["reviewItem", assetId],
            });
            queryClient.invalidateQueries({
                queryKey: ["thumbnailUrls", assetId],
            });
            queryClient.invalidateQueries({ queryKey: ["usdzUrls", assetId] });
            queryClient.invalidateQueries({ queryKey: ["myReviews"] });
            queryClient.invalidateQueries({
                queryKey: ["sharedWithMeReviews"],
            });
        });

        ansClient.on("sharedListChanged", () => {
            queryClient.invalidateQueries({
                queryKey: ["sharedWithMeReviews"],
            });
        });

        acpClient.getDirectory("cloud-content").then((dirInfo) => {
            onsClient.watchAsset({
                id: dirInfo["repo:assetId"]!,
                type: "asset",
                directoryLevel: "shallow",
                includeResources: [
                    { reltype: "api:annotation" },
                    { reltype: "api:metadata/repository" },
                    { reltype: "api:metadata/application" },
                    { reltype: "api:ac/policy" },
                ],
            });
            ansClient.listen();
        });
    }, [ansClient, onsClient]);
}

function useAcpContextValue(acpClient: AcpClient, onsClient: OnsClient) {
    const { accessToken } = useHi5UserContext();
    const queryClient = useQueryClient();

    const useStorageQuota = () =>
        useQuery({
            queryKey: ["storageQuota"],
            async queryFn() {
                const { bytesLimit, bytesUsed } =
                    await acpClient.getStorageQuota();
                return {
                    bytesUsed,
                    bytesLimit,
                    percent: bytesUsed / bytesLimit,
                };
            },
        });

    const useMyReviews = () =>
        useQuery<ReviewListItem[]>({
            queryKey: ["myReviews"],
            async queryFn() {
                const children = await acpClient.getAllChildrenInDirectory(
                    "cloud-content",
                    "application/vnd.adobe.usdcx+dcx",
                );
                const items = children.map(dcxToReviewListItem);
                items.forEach((review) => {
                    queryClient.setQueryData(
                        ["reviewItem", review.assetId],
                        review,
                    );
                });
                return items.map(({ assetId, displayName, modifyDate }) => ({
                    assetId,
                    displayName,
                    modifyDate,
                }));
            },
        });

    const useSharedWithMeReviews = () =>
        useQuery<ReviewListItem[] | undefined>({
            queryKey: ["sharedWithMeReviews"],
            async queryFn() {
                if (!accessToken) return undefined;
                const items = await getSharedAssets(accessToken);
                if (!items) return undefined;
                items.forEach((asset) => {
                    onsClient.watchAsset({
                        id: asset.assetId,
                        type: "asset",
                        includeResources: [
                            { reltype: "api:annotation" },
                            { reltype: "api:metadata/repository" },
                            { reltype: "api:metadata/application" },
                            { reltype: "api:ac/policy" },
                        ],
                    });
                });
                items.forEach((review) => {
                    queryClient.setQueryData(
                        ["reviewItem", review.assetId],
                        review,
                    );
                });
                return items.map(({ assetId, displayName, modifyDate }) => ({
                    assetId,
                    displayName,
                    modifyDate,
                }));
            },
        });

    const useReviewListItem = (assetId: string) =>
        useQuery<ReviewItem>({
            queryKey: ["reviewItem", assetId],
            async queryFn() {
                return dcxToReviewListItem(
                    await acpClient.resolveComposite(assetId),
                );
            },
        });

    const useCollaboratorCount = (assetId: string, enabled = true) =>
        useQuery<number>({
            queryKey: ["collaboratorCount", assetId],
            async queryFn() {
                if (!accessToken) return 0;
                const collaboratorCount = await getCollaboratorsCount(
                    accessToken,
                    assetId,
                );
                return collaboratorCount;
            },
            enabled,
        });

    const useReviewThumbnailUrl = (assetId: string, enabled = true) =>
        useQuery({
            queryKey: ["thumbnailUrls", assetId],
            async queryFn() {
                const url =
                    (await acpClient.getThumbnailRenditionUrl(assetId)) || "";
                await validateGetUrl(url);
                return url;
            },
            enabled,
        });

    const useGlbUrl = (assetId: string, useOptimized = true) =>
        useQuery({
            queryKey: ["glbUrls", assetId, useOptimized],
            async queryFn() {
                const url =
                    (await acpClient.getGlbUrl(assetId, useOptimized)) || "";
                await validateGetUrl(url);
                return url;
            },
        });

    const useOptimizedGlbUrl = (assetId: string, quality: QualityKey) =>
        useQuery({
            queryKey: ["glbOptimizedUrls", assetId, quality],
            async queryFn() {
                const url = await acpClient.getUrlForComponentByMatcher(
                    assetId,
                    (component) =>
                        component.path === getComponentQualityPath(quality),
                );
                return url || "";
            },
        });

    const useUsdzUrl = (assetId: string) =>
        useQuery({
            queryKey: ["usdzUrls", assetId],
            async queryFn() {
                return (await acpClient.getUsdzUrl(assetId)) || "";
            },
        });

    const getEnvironmentMeta = (assetId: string) =>
        acpClient.getTypedChildMetadata(
            assetId,
            EnvironmentMetaFields,
            "reviewer-meta",
        );

    const useEnvironmentMeta = (assetId: string) =>
        useQuery({
            queryKey: ["environmentalMeta", assetId],
            async queryFn() {
                const meta = await getEnvironmentMeta(assetId);
                return meta;
            },
            refetchOnMount: true,
        });

    const useMutateEnvironmentMeta = (assetId: string) =>
        useMutation({
            async mutationFn(
                data: Partial<
                    Record<(typeof EnvironmentMetaFields)[number], string>
                >,
            ) {
                await acpClient.setTypedChildMetadata(
                    assetId,
                    data,
                    "reviewer-meta",
                );
            },
            onSuccess() {
                queryClient.invalidateQueries({
                    queryKey: ["environmentalMeta", assetId],
                });
            },
        });

    const useOriginalFileFormat = (assetId: string) =>
        useQuery({
            queryKey: ["originalFileFormat", assetId],
            async queryFn() {
                return (
                    (await acpClient.getSourceDocPath(assetId))
                        .split(".")
                        .pop() || "unknown"
                );
            },
        });

    const useRenameReviewMutation = () =>
        useMutation({
            async mutationFn({
                assetId,
                name,
            }: {
                assetId: string;
                name: string;
            }) {
                await acpClient.renameComposite(assetId, name);
                return assetId;
            },
            onSuccess(assetId) {
                queryClient.invalidateQueries({ queryKey: ["myReviews"] });
                queryClient.invalidateQueries({
                    queryKey: ["reviewItem", assetId],
                });
            },
        });

    const useDiscardReviewMutation = () =>
        useMutation({
            async mutationFn(assetId: string) {
                await acpClient.discardComposite(assetId);
                return assetId;
            },
            onSuccess(assetId) {
                queryClient.invalidateQueries({ queryKey: ["myReviews"] });
                queryClient.invalidateQueries({
                    queryKey: ["reviewItem", assetId],
                });
            },
        });

    const useRestoreReviewMutation = () => 
        useMutation({
            async mutationFn(assetId: string) {
                await acpClient.restoreComposite(assetId);
                return assetId;
            },
            onSuccess(assetId) {
                queryClient.invalidateQueries({ queryKey: ["myReviews"] });
                queryClient.invalidateQueries({
                    queryKey: ["reviewItem", assetId],
                });
            },
        })

    const useAssetTechInfo = (assetId: string) =>
        useQuery({
            queryKey: ["assetTechInfo", assetId],
            async queryFn() {
                const meta = await acpClient.getTypedChildMetadata(
                    assetId,
                    AssetTechInfoFields,
                    "reviewer-meta",
                );
                const info = hydrateAssetTechInfo(meta);
                return info;
            },
            refetchOnMount: true,
        });

    const useMutateAssetTechInfo = (assetId: string) =>
        useMutation({
            async mutationFn(assetTechInfo: AssetTechInfo) {
                const data = serializeAssetTechInfo(assetTechInfo);
                await acpClient.setTypedChildMetadata(
                    assetId,
                    data,
                    "reviewer-meta",
                );
            },
            onSuccess() {
                queryClient.invalidateQueries({
                    queryKey: ["assetTechInfo", assetId],
                });
                queryClient.invalidateQueries({
                    queryKey: ["environmentalMeta", assetId],
                });
            },
        });

    return {
        acpClient,
        queryClient,
        // queries
        useStorageQuota,
        useMyReviews,
        useSharedWithMeReviews,
        useReviewListItem,
        useCollaboratorCount,
        useReviewThumbnailUrl,
        useGlbUrl,
        useOptimizedGlbUrl,
        useUsdzUrl,
        getEnvironmentMeta,
        useEnvironmentMeta,
        useOriginalFileFormat,
        useAssetTechInfo,
        // mutations
        useMutateEnvironmentMeta,
        useRenameReviewMutation,
        useDiscardReviewMutation,
        useMutateAssetTechInfo,
        useRestoreReviewMutation
    };
}

function AcpClientProvider({ children }: PropsWithChildren) {
    const { acpClient, ansClient, onsClient } = useAcpClient();

    useQueryInvalidations(acpClient, ansClient, onsClient);
    const contextValue = useAcpContextValue(acpClient, onsClient);

    return (
        <AcpContext.Provider value={contextValue}>
            {children}
        </AcpContext.Provider>
    );
}

export function AcpContextProvider({ children }: PropsWithChildren) {
    const { accessToken } = useHi5UserContext();
    const previousAccessToken =
        localStorage.getItem("previousAccessToken") || NO_TOKEN;

    useEffect(() => {
        localStorage.setItem("previousAccessToken", accessToken || NO_TOKEN);
    }, [accessToken]);

    // block data access until we know who you are
    return (
        <PersistQueryClientProvider
            client={acpQueryClient}
            persistOptions={{
                persister,
                buster: accessToken || previousAccessToken,
            }}>
            <AcpClientProvider>{children}</AcpClientProvider>
        </PersistQueryClientProvider>
    );
}
