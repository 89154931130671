/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Text, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

export function LegalNotice() {
    const { i18n, t } = useTranslation("common");

    const IframeHeights: Record<string, number> = {
        "en-US": 23400,
        "fr-FR": 26420,
        "de-DE": 27980,
        "ja-JP": 29100,
    };

    return (
        <View>
            <View paddingBottom="size-500">
                <Text
                    UNSAFE_style={{
                        fontSize: "2em",
                        fontWeight: "bold",
                        padding:
                            "var(--spectrum-global-dimension-size-200) var(--spectrum-global-dimension-size-500)",
                    }}>
                    {t("preferences.legal.notice")}
                </Text>
            </View>
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    overflowY: "scroll",
                }}>
                <iframe
                    src={t("preferences.legal.notice.link.url")}
                    style={{
                        pointerEvents: "none",
                    }}
                    width="100%"
                    scrolling="no"
                    frameBorder="0"
                    height={`${IframeHeights[i18n.language]}px`}
                />
            </div>
        </View>
    );
}
