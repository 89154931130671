/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    Content,
    Dialog,
    Flex,
    Footer,
    Heading,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { useTranslation } from "react-i18next";

interface AssetNotFoundViewProps {
    onGoHome: () => void;
    isForVr?: boolean;
    isProcessing?: boolean;
}

export function AssetNotFoundView({
    onGoHome,
    isForVr = false
}: AssetNotFoundViewProps) {
    const { t } = useTranslation("common");
    const {closeModal} = useMessagingContext();

    return (
        <Dialog width={isForVr ? "600px" : "400px"} height="100%">
            <Heading
                UNSAFE_style={{
                    fontSize: isForVr ? "2.2em" : "",
                }}>
                {t("review.notFound.title")}
            </Heading>
            <Content>
                <View marginTop="size-250" height="29px">
                    <Text
                        UNSAFE_style={{
                            fontSize: isForVr ? "1.75em" : "",
                        }}>
                        {t("review.notFound.description")}
                    </Text>
                </View>
            </Content>
            <Footer>
                <View paddingBottom="1.5em" width="100%">
                    <Flex direction="row" width="100%" justifyContent="center">
                        <Button
                            onPress={() => {
                                closeModal();
                                onGoHome();
                            }}
                            width="100%"
                            variant="cta"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: isForVr ? "100px" : "",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: isForVr ? "1.5em" : "",
                                }}>
                                {t("navigation.goHome")}
                            </Text>
                        </Button>
                    </Flex>
                </View>
            </Footer>
        </Dialog>
    );
}
