/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Provider,
    defaultTheme as SpectrumTheme,
    View,
    Flex,
    ButtonGroup,
    Button,
} from "@adobe/react-spectrum";
import { DEFAULT_RENDER_SETTINGS, DEFAULT_IBL_URL } from "@shared/common/src/render-defaults";
import { useState } from "react";
import ReactDOM from "react-dom/client";

import { AdobeCanvasViewerOptions } from "./scene/AdobeViewer";
import { RenderSettings } from "./scene/SceneManager";
import { StudioTool, Studio } from "./Studio";

import type { StudioStrings } from "./Studio";

export * from "./Studio";

const ENGLISH_EDITOR_STRINGS: StudioStrings = {
    cameraOrbit: "Rotate camera",
    cameraPan: "Pan camera",
    cameraDolly: "Zoom camera",

    frameButton: "Frame object",
};

const viewerConfig: AdobeCanvasViewerOptions = {
    autoSuspendRendering: true,
    skybox: false,
    lighting: true,
    useIblShadows: true,
};

const ViewerDemoApp = () => {
    const [mode, setMode] = useState<"viewer" | "editor">("viewer");

    return (
        <Provider theme={SpectrumTheme} colorScheme="dark" scale="medium">
            <Flex direction="column" height="100vh">
                <View margin="size-150">
                    <ButtonGroup>
                        <Button
                            isDisabled={mode === "viewer"}
                            variant={
                                mode === "viewer" ? "primary" : "secondary"
                            }
                            onPress={() => setMode("viewer")}>
                            Viewer
                        </Button>
                        <Button
                            isDisabled={mode === "editor"}
                            variant={
                                mode === "editor" ? "primary" : "secondary"
                            }
                            onPress={() => setMode("editor")}>
                            Editor
                        </Button>
                    </ButtonGroup>
                </View>
                <View flex="1" overflow="hidden">
                    {mode === "viewer" && (
                        <Studio
                            enableLimitedZoom={true}
                            modelUrl="/assets/models/ShoeRemake_web.glb"
                            iblUrl={DEFAULT_IBL_URL}
                            strings={ENGLISH_EDITOR_STRINGS}
                            tools={[
                                StudioTool.cameraControls,
                                StudioTool.frameButton,
                            ]}
                            renderSettings={DEFAULT_RENDER_SETTINGS}
                            viewerConfig={viewerConfig}
                        />
                    )}
                    {mode === "editor" && (
                        <Studio
                            enableLimitedZoom={true}
                            modelUrl="/assets/models/ShoeRemake_web.glb"
                            iblUrl={DEFAULT_IBL_URL}
                            strings={ENGLISH_EDITOR_STRINGS}
                            tools={[
                                StudioTool.cameraControls,
                                StudioTool.frameButton,
                            ]}
                            renderSettings={DEFAULT_RENDER_SETTINGS}
                            viewerConfig={viewerConfig}
                        />
                    )}
                </View>
            </Flex>
        </Provider>
    );
};

/**
 * @param renderSettingsUrl The url of the render settings config json file, if one exists
 *
 * @returns The render settings extracted from the given url. If no file was given, the default settings are returned
 */
const getRenderSettings = async (
    renderSettingsUrl: string | null,
): Promise<RenderSettings> => {
    if (renderSettingsUrl) {
        try {
            const response = await fetch(renderSettingsUrl);
            if (!response.ok) {
                throw `loading JSON failed with status ${response.status}`;
            }
            const renderSettings: RenderSettings = await response.json();

            // If any requried settings are added to RenderSettings, add checks here to validate the parsed object,
            // use the hotSpotsRequiredProps validation as a model of what to do here

            return renderSettings;
        } catch (e) {
            console.error("ERROR parsing render settings:", e);
        }
    }
    return DEFAULT_RENDER_SETTINGS;
};

/**
 * Renders the webviewer, for the viewer or editor html files
 *
 * @param selector Identifies the div where the webviewer will go. This should be the div clas name, with prefixed
 *                 with ".". This should either be ".sunrise-viewer" or ".sunrise-editor"
 * @param tools An array of the StudioTools to be used by the webviewer
 */
const render = (selector: string, tools: StudioTool[]) => {
    try {
        document.querySelectorAll(selector).forEach(async (el) => {
            const modelUrl = el.getAttribute("data-model-url");
            const renderSettingsUrl = el.getAttribute("data-config-url");
            const iblUrl = el.getAttribute("data-ibl-url");

            if (modelUrl && iblUrl) {
                const renderSettings =
                    await getRenderSettings(renderSettingsUrl);
                const container = ReactDOM.createRoot(el);
                container.render(
                    <Provider
                        theme={SpectrumTheme}
                        colorScheme="dark"
                        scale="medium">
                        <Studio
                            enableLimitedZoom={true}
                            modelUrl={modelUrl}
                            iblUrl={iblUrl}
                            strings={ENGLISH_EDITOR_STRINGS}
                            tools={tools}
                            renderSettings={renderSettings}
                        />
                    </Provider>,
                );
            }
            if (!modelUrl) {
                console.error("ERROR finding data-model-url parameter");
            }
            if (!iblUrl) {
                console.error("ERROR finding data-ibl-url parameter");
            }
        });
    } catch (e) {
        console.error(`ERROR rendering component ${selector}:`, e);
    }
};

document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("viewer-demo-app");
    if (container) {
        const root = ReactDOM.createRoot(container); // createRoot(container!) if you use TypeScript
        root.render(<ViewerDemoApp />);
    }

    render(".sunrise-viewer", [
        StudioTool.cameraControls,
        StudioTool.frameButton,
    ]);

    render(".sunrise-editor", [
        StudioTool.cameraControls,
        StudioTool.frameButton,
    ]);
});
