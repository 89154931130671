/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export const HEADER_COLOR = "rgb(150,150,150)";
export const HEADER_FONT_SIZE = "1.1em";
export const BODY_FONT_SIZE = "1.4em";

export const uiSizes = [
    { key: "Small", name: "preferences.uiSize.small" },
    { key: "Medium", name: "preferences.uiSize.medium" },
    { key: "Large", name: "preferences.uiSize.large" },
];

export const controllers = [
    { key: "Right", name: "preferences.controller.right" },
    { key: "Left", name: "preferences.controller.left" },
];

export const snapTurns = [
    { key: "Ten", name: "preferences.input.snapTurn.10" },
    { key: "Fifteen", name: "preferences.input.snapTurn.15" },
    { key: "Thirty", name: "preferences.input.snapTurn.30" },
    { key: "FortyFive", name: "preferences.input.snapTurn.45" },
];

export const presets = [
    {
        key: "Automatic",
        name: "preferences.graphics.preset.automatic",
    },
    { key: "High", name: "preferences.graphics.preset.high" },
    { key: "Balanced", name: "preferences.graphics.preset.balanced" },
    {
        key: "Performance",
        name: "preferences.graphics.preset.performance",
    },
    { key: "Custom", name: "preferences.graphics.preset.custom" },
];

export const customResolutions = [
    {
        key: "SuperSample",
        name: "preferences.graphics.resolution.superSample",
    },
    {
        key: "Balanced",
        name: "preferences.graphics.resolution.balanced",
    },
    {
        key: "Performance",
        name: "preferences.graphics.resolution.performance",
    },
];
