/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// Implement client for Adobe Notification System
// Onboarding ticket ANS-4197326
// https://wiki.corp.adobe.com/display/ANS/ANS+API+Reference#ANSAPIReference-NotificationAPIs
// Note: longpoll is on an allow-list basis, we have to poll

import EventEmitter from "events";

import { ADOBE_IMS_CONFIG, ASSET_APIS } from "@src/config";

export interface EventMap {
    sharedListChanged: [],
}

type Notification = {
    'app-id': Array<string>,
    'sub-type': string,
    'payload': string,
}

const AnsAppId = 'substance-3d-reviewer';

export class AnsClient extends EventEmitter<EventMap> {
    public accessToken: string | undefined;
    private nextUrl: string | undefined;
    private nextPollTimer: Timer | undefined;
    private failCtr = 0;
    private pollDelay = 5_000;
    // since we cannot know when a user has accepted an invitation, we
    // trip up our detection and just emit the changed event every
    // pollDelay interval
    private pollTrip = false;
    constructor() {
        super();
    }
    public async listen() {
        if (!this.accessToken) {
            console.debug('ans/listen - no-token');
            return;
        }
        if (this.nextPollTimer) {
            console.debug('ans/listen - already-scheduled');
            return;
        }
        const headers = {
            'accept': 'application/json',
            'cache-control': 'no-cache, no-store, must-revalidate',
            'x-adobe-app-id': AnsAppId,
            'x-api-key': ADOBE_IMS_CONFIG.client_id,
            'x-user-token': `Bearer ${this.accessToken}`,
        };
        try {
            if (this.pollTrip) {
                this.emit("sharedListChanged");
                return;
            }
            // for first request, since we load shared assets anyway, we
            // only care about events from this point onwards
            const url = this.nextUrl
                ? this.nextUrl
                : `${ASSET_APIS.ans}/ans/v1/notifications?from=${Date.now()}`;

            const res = await fetch(url, {
                headers,
            });
            if (!res.ok) {
                throw new Error('res-not-ok-' + res.statusText);
            }
            const body = await res.json();
            // should we let this throw, or is going back to a
            // fresh notifications a reasonable way to continue?
            this.nextUrl = body.paging.next;
            const x = body?.notifications?.notification;
            if (x && Array.isArray(x) && x.length > 0 && this.shouldEmit(x)) {
                this.pollTrip = true;
                console.log('ans/listen - emit');
                this.emit("sharedListChanged");
            }
            this.failCtr = 0;
        } catch (err) {
            // what is failure recovery here?
            this.failCtr++;
            if (this.failCtr > 3) {
                this.pollTrip = true;
                this.pollDelay = 60_000;
            } else {
                this.pollDelay = 15_000;
            }
        } finally {
            console.log('ans/listen - schedule');
            this.nextPollTimer = setTimeout(() => {
                this.nextPollTimer = undefined;
                this.listen();
            }, this.pollDelay);
        }
    }

    private shouldEmit(xs: Array<Notification>): boolean {
        // TODO is there a better check?
        return xs.some(x => x.payload.includes('substance3d'));
    }
}
