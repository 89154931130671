/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// CLient for ACP ONS Subscription API
// https://developers.corp.adobe.com/ons/PONS/subscriptionApiSwagger.json

import { ADOBE_IMS_CONFIG } from "@src/config";

import type { SubscriptionConfig, SubscriptionState } from "./index";


type Objects = Array<SubscriptionConfig>;

type CreateRes = Pick<Extract<SubscriptionState, { subscribed: true }>, 'id' | 'assets' | 'expires' | 'url'>;
export async function create(
    url: string,
    objects: Objects,
    deviceId: string,
    accessToken: string
): Promise<CreateRes> {
        const headers = {
            'x-api-key': ADOBE_IMS_CONFIG.client_id,
            'x-user-token': `Bearer ${accessToken}`,
        }

    // https://developers.corp.adobe.com/ons/PONS/subscriptionApiSwagger.json
    const subscriptionRes = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            objects,
            device: { id: deviceId },
        }),
        headers: {
            ...headers,
            'content-type': 'application/vnd.adobecloud.subscription+json',
        },
    });
    if (!subscriptionRes.ok) {
        throw new Error('subscription-res-not-ok-' + subscriptionRes.statusText);
    }
    const subscription = await subscriptionRes.json();
    return {
        id: subscription.id,
        assets: makeAssetsFromObjectRes(subscription.objects),
        expires: subscription.expiresAt,
        url: subscription?._links?.self?.href,
    }
}

type UpdateRes = Pick<Extract<SubscriptionState, { subscribed: true }>, 'assets' | 'expires'>;
export async function update(
    url: string,
    objects: Objects,
    accessToken: string
): Promise<UpdateRes> {
    const headers = {
        'content-type': 'application/json-patch+json',
        'x-api-key': ADOBE_IMS_CONFIG.client_id,
        'x-user-token': `Bearer ${accessToken}`,
    }
    const res = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(objects.map(value =>
            { return {
                op: 'add',
                path: '/objects/-',
                value,
            }})),
        headers,
    });
    if (!res.ok) {
        throw new Error('patch-res-not-ok-' + res.statusText);
    }
    const body = await res.json();
    return {
        expires: body.expiresAt,
        assets: makeAssetsFromObjectRes(body.objects),
    }
}

export function makeAssetsFromObjectRes(arr: Array<any>): Record<string, any> {
    const ret: Record<string, any> = {};
    for (const idx in arr) {
        const obj = arr[idx];
        ret[obj.id as unknown as string] = obj;
    }
    return ret;
}
