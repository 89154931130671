/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

import { nrConfig } from "./new-relic-config";
import { ENV } from "@src/config";

switch (ENV) {
    case "local":
        break;
    case "feature":
        break;
    case "dev":
        break;
    default:
        break;

    case "stage":
        new BrowserAgent(nrConfig["stage"])
        break;
    case "prod":
        new BrowserAgent(nrConfig["prod"])
        break;

}
