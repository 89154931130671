/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {useEffect } from "react";
import { usePostMessageContext } from "@src/contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function useNotifyVRAuth(){
    const { postMessageBusApi } = usePostMessageContext();
    const { accessToken, userProfile, avatarUrl} = useHi5UserContext();

    useEffect(() => {
        if (userProfile && postMessageBusApi && accessToken) {
            
            console.log("User is authorized");
            //for testing till in production
            import("@src/dev-config").then(({config}) => {
                console.log("DEV Post User Auth", config.tokenOverride);
                postMessageBusApi.userHasAuth(config.tokenOverride);
            }).catch(() => {
                console.log("PROD Post User Auth", accessToken);
                postMessageBusApi.userHasAuth(accessToken);
            })
        }
        
        if (userProfile && avatarUrl && postMessageBusApi) {
            console.log(
                "Post User Profile",
                userProfile.displayName,
                avatarUrl,
            );
            postMessageBusApi.adobeUser(
                userProfile.displayName ||
                userProfile.first_name + " " + userProfile.last_name,
                avatarUrl,
            );
        }
        
    }, [postMessageBusApi, accessToken, userProfile, avatarUrl]); 
}

export default useNotifyVRAuth;