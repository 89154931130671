/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

export function useIsVisible() {
    const [isVisible, setIsVisible] = useState(false);
    const [ref, setRef] = useState<HTMLElement | null | undefined>()

    useEffect(() => {
        if (!ref) return;
      const observer = new IntersectionObserver(([entry]) =>
        setIsVisible(entry.isIntersecting)
      );

      observer.observe(ref);
      return () => {
        observer.disconnect();
      };
    }, [ref]);

    return {isVisible, setRef};
}
