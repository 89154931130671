/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { v4 } from "uuid";

import { CommentsApiEvent } from "./CommentsApi.js";
import { SerializedPin } from "../babylon/pins/PinDataTypes.js";
import { PostMessageSharedApi } from "@src/lib/bus-api/PostMessageSharedApi.js";

import type { CommentsApi, PinnedComment } from "./CommentsApi.js";
import type { PostMessageBus } from "./PostMessageBus.js";
import type {
    AnnotationEvent,
    Comment,
    CommentInteractionEvent,
    CommentUpdateDelta,
} from "@ccx-public/ccx-comments";

export enum CommentsOutGoingMessageType {
    webReady = "webReady",
    loginFailed = "loginFailed",
    assetAccessFailure = "assetAccessFailure",
    sessionStart = "sessionStart",
    commentsUpdated = "commentsUpdated",
    commentUpdated = "commentUpdated",
    commentSelected = "commentSelected",
    commentHovered = "commentHovered",
    initiatePinning = "initiatePinning",
    cancelPinning = "cancelPinning",
    pinSubmitted = "pinSubmitted",
    error = "error",
    commentsFiltered = "commentsFiltered",
    annotationToggled = "annotationToggled",
    inputFieldBoundingRect = "inputFieldBoundingRect",
}

export enum CommentsIncomingMessageType {
    getComments = "getComments",
    pinningStarted = "pinningStarted",
    completePinning = "completePinning",
    cancelPinning = "cancelPinning",
    pinHovered = "pinHovered",
    pinSelected = "pinSelected",
    pinDeselected = "pinDeselected",
    createComment = "createComment", // currently unused
    updateComment = "updateComment", // currently unused
    removeComment = "removeComment", // currently unused
    focusCommentTextArea = "focusCommentTextArea",
    clearTextFocus = "clearTextFocus",
    vrKeyboardEnter = "vrKeyboardEnter",
    vrAnnotationToggled = "vrAnnotationToggled",
}

/**
 * Outgoing Message Types
 */
export type WebReadyMessage = {
    type: CommentsOutGoingMessageType.webReady;
    data: {
        version?: string;
        userId?: string;
        visitorId?: string;
    };
};
export type LoginFailedMessage = {
    type: CommentsOutGoingMessageType.loginFailed;
};
export type AssetAccessFailureMessage = {
    type: CommentsOutGoingMessageType.assetAccessFailure;
};
export type SessionStartMessage = {
    type: CommentsOutGoingMessageType.sessionStart;
    data: {
        sessionId: string;
    };
};
export type CommentsUpdatedMessage = {
    type: CommentsOutGoingMessageType.commentsUpdated;
    data: {
        comments: PinnedComment[];
    };
};
export type CommentUpdatedMessage = {
    type: CommentsOutGoingMessageType.commentUpdated;
    data: {
        eventType: "created" | "updated" | "deleted";
        comment: PinnedComment;
    };
};
export type CommentSelectedMessage = {
    type: CommentsOutGoingMessageType.commentSelected;
    data: {
        commentId: string;
    };
};
export type CommentHoveredMessage = {
    type: CommentsOutGoingMessageType.commentHovered;
    data: {
        commentId: string;
        status: number;
    };
};
export type InitiatePinningMessage = {
    type: CommentsOutGoingMessageType.initiatePinning;
    data: {
        commentId: string;
    };
};
export type PinSubmittedMessage = {
    type: CommentsOutGoingMessageType.pinSubmitted;
    data: {
        commentId: string;
    };
};
export type ErrorMessage = {
    type: CommentsOutGoingMessageType.error;
    message: string;
};
export type CommentsFilteredMessage = {
    type: CommentsOutGoingMessageType.commentsFiltered;
    data: {
        commentIds: string[];
    };
};
export type AnnotationToggledMessage = {
    type: CommentsOutGoingMessageType.annotationToggled;
};

export type InputBoundsMessage = {
    type: CommentsOutGoingMessageType.inputFieldBoundingRect;
    data: {
        bottom: number;
    };
};

/**
 * Shared message types
 */
export type CancelPinningMessage = {
    type:
        | CommentsOutGoingMessageType.cancelPinning
        | CommentsIncomingMessageType.cancelPinning;
    data: {
        commentId: string;
    };
};

/**
 * Incoming Message types
 */
export type GetCommentsMessage = {
    type: CommentsIncomingMessageType.getComments;
};
export type PinningStartedMessage = {
    type: CommentsIncomingMessageType.pinningStarted;
};
export type CompletePinningMessage = {
    type: CommentsIncomingMessageType.completePinning;
    data: {
        commentId: string;
        pinData: SerializedPin;
    };
};
export type PinHoveredMessage = {
    type: CommentsIncomingMessageType.pinHovered;
    data: {
        commentId: string;
        status: number;
    };
};
export type PinSelectedMessage = {
    type: CommentsIncomingMessageType.pinSelected;
    data: {
        commentId: string;
    };
};
export type PinDeselectedMessage = {
    type: CommentsIncomingMessageType.pinDeselected;
};
export type CreateCommentMessage = {
    type: CommentsIncomingMessageType.createComment;
    data: {
        temporaryId: string;
        comment: PinnedComment;
    };
};
export type UpdateCommentMessage = {
    type: CommentsIncomingMessageType.updateComment;
    data: {
        commentId: string;
        comment: PinnedComment;
    };
};
export type RemoveCommentMessage = {
    type: CommentsIncomingMessageType.removeComment;
    data: {
        commentId: string;
    };
};
export type FocusCommentTextAreaMessage = {
    type: CommentsIncomingMessageType.focusCommentTextArea;
};
export type ClearTextFocusMessage = {
    type: CommentsIncomingMessageType.clearTextFocus;
};
export type VRKeyboardEnterMessage = {
    type: CommentsIncomingMessageType.vrKeyboardEnter;
};
export type VRAnnotationToggledMessage = {
    type: CommentsIncomingMessageType.vrAnnotationToggled;
};

export type CommentsOutGoingMessage =
    | WebReadyMessage
    | LoginFailedMessage
    | AssetAccessFailureMessage
    | SessionStartMessage
    | CommentsUpdatedMessage
    | CommentUpdatedMessage
    | CommentSelectedMessage
    | CommentHoveredMessage
    | InitiatePinningMessage
    | CancelPinningMessage
    | PinSubmittedMessage
    | ErrorMessage
    | CommentsFilteredMessage
    | AnnotationToggledMessage
    | InputBoundsMessage;

export type CommentsIncomingMessage =
    | GetCommentsMessage
    | PinningStartedMessage
    | CompletePinningMessage
    | CancelPinningMessage
    | PinHoveredMessage
    | PinSelectedMessage
    | PinDeselectedMessage
    | CreateCommentMessage
    | UpdateCommentMessage
    | RemoveCommentMessage
    | FocusCommentTextAreaMessage
    | ClearTextFocusMessage
    | VRKeyboardEnterMessage
    | VRAnnotationToggledMessage;

interface VuplexIncomingMessage {
    type?: string;
    data: string;
}

export type CommentsMessageBus = PostMessageBus<
    VuplexIncomingMessage,
    CommentsOutGoingMessage
>;

export class PostMessageCommentsApi extends PostMessageSharedApi<CommentsMessageBus> {
    private temporaryCommentId: string | undefined;
    private sessionId: string;

    constructor(
        messageBus: CommentsMessageBus = window,
        private commentsApi?: CommentsApi,
    ) {
        super(messageBus);
        this.sessionId = v4();
        messageBus.addEventListener("message", this.messageHandler);
        if (commentsApi) {
            if (commentsApi.initialized) {
                this.initializeCommentsApi(commentsApi);
            } else {
                commentsApi.once(CommentsApiEvent.initialized, () => {
                    this.initializeCommentsApi(commentsApi);
                });
            }
        }
    }

    initializeCommentsApi(commentsApi: CommentsApi) {
        this.commentsApi = commentsApi;
        this.attachCommentsEvents();
        this.postCommentsList();
        this.postSessionStart();
    }

    webReady = (version?: string, userId?: string, visitorId?: string) => {
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.webReady,
            data: {
                version,
                userId,
                visitorId,
            },
        });
    };

    loginFailure() {
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.loginFailed,
        });
    }

    assetAccessFailure() {
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.assetAccessFailure,
        });
    }

    inputFieldBoundingRect(recBottom: number) {
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.inputFieldBoundingRect,
            data: {
                bottom: recBottom,
            },
        });
    }

    private postCommentUpdate(
        eventType: CommentUpdatedMessage["data"]["eventType"],
        comment: Comment,
    ) {
        const pinData = this.commentsApi?.getPinDataForComment(comment.id);
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.commentUpdated,
            data: {
                comment: {
                    ...comment,
                    pinData,
                },
                eventType,
            },
        });
    }

    private postSessionStart() {
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.sessionStart,
            data: {
                sessionId: this.sessionId,
            },
        });
    }

    private postCommentsList() {
        const comments = this.commentsApi?.getCommentsWithPinData();
        comments?.forEach((comment) =>
            this.postCommentUpdate("created", comment),
        );
    }

    private postCommentsFiltered(visibleComments: Comment[]) {
        const commentIds: string[] = [];
        visibleComments.forEach((comment) => commentIds.push(comment.id));

        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.commentsFiltered,
            data: {
                commentIds,
            },
        });
    }

    private postAnnotationToggled() {
        this.messageBus.postMessage({
            type: CommentsOutGoingMessageType.annotationToggled,
        });
    }

    private attachCommentsEvents() {
        if (!this.commentsApi) {
            return;
        }
        this.commentsApi
            // TODO find out if the external apps need to be wired to this event
            // .on(CommentsApiEvent.commentsSuccess, (data) => {})
            .on(
                CommentsApiEvent.commentsUpdate,
                ({ delta }: { delta: CommentUpdateDelta }) => {
                    if (delta.data.new) {
                        delta.data.new.forEach((comment) =>
                            this.postCommentUpdate("created", comment),
                        );
                    }
                    if (delta.data.modified) {
                        delta.data.modified.forEach((comment) =>
                            this.postCommentUpdate("updated", comment),
                        );
                    }
                    if (delta.data.deleted) {
                        delta.data.deleted.forEach((comment) =>
                            this.postCommentUpdate("deleted", comment),
                        );
                    }
                    if (delta.data.visibleComments) {
                        delta.data.visibleComments.forEach((comment) =>
                            this.postCommentUpdate("updated", comment),
                        );
                    }
                },
            )
            // TODO find out if the external apps need to be wired to this event
            // .on(CommentsApiEvent.temporaryCommentConverted, () => {})
            .on(
                CommentsApiEvent.annotationChanged,
                (event: AnnotationEvent) => {
                    switch (event.trigger) {
                        case "start":
                            this.temporaryCommentId = v4();
                            this.messageBus.postMessage({
                                type: CommentsOutGoingMessageType.initiatePinning,
                                data: {
                                    commentId: this.temporaryCommentId,
                                },
                            });
                            break;
                        case "cancel":
                            if (this.temporaryCommentId) {
                                this.messageBus.postMessage({
                                    type: CommentsOutGoingMessageType.cancelPinning,
                                    data: {
                                        commentId: this.temporaryCommentId,
                                    },
                                });
                                this.temporaryCommentId = undefined;
                            }
                            break;
                        case "submit":
                            if (this.temporaryCommentId) {
                                this.messageBus.postMessage({
                                    type: CommentsOutGoingMessageType.pinSubmitted,
                                    data: {
                                        commentId: this.temporaryCommentId,
                                    },
                                });
                                this.temporaryCommentId = undefined;
                            }
                            break;
                    }
                },
            )
            .on(
                CommentsApiEvent.commentInteraction,
                (event: CommentInteractionEvent) => {
                    if (event.trigger === "select") {
                        this.messageBus.postMessage({
                            type: CommentsOutGoingMessageType.commentSelected,
                            data: {
                                commentId: event.id,
                            },
                        });
                    } else if (
                        event.trigger === "hover" ||
                        event.trigger === "unhover"
                    ) {
                        this.messageBus.postMessage({
                            type: CommentsOutGoingMessageType.commentHovered,
                            data: {
                                commentId: event.id,
                                status: event.trigger === "hover" ? 1 : 0,
                            },
                        });
                    }
                },
            )
            .on(CommentsApiEvent.commentsFiltered, async (data) => {
                this.postCommentsFiltered(data);
            })
            .on(CommentsApiEvent.annotationToggle, () =>
                this.postAnnotationToggled(),
            );
    }

    private activatePinIcon() {
        this.dispatchEvent(
            new CustomEvent("set-pin-icon-status", {
                detail: { isActive: true },
            }),
        );
    }

    messageHandler = (payload: VuplexIncomingMessage) => {
        if (
            !(
                this.commentsApi &&
                payload &&
                payload.data &&
                typeof payload.data == "string"
            )
        )
            return;
        try {
            const message: CommentsIncomingMessage = JSON.parse(payload.data);
            switch (message.type) {
                case CommentsIncomingMessageType.getComments:
                    this.postCommentsList();
                    break;
                case CommentsIncomingMessageType.pinningStarted:
                    this.commentsApi.disableComments();
                    this.activatePinIcon();
                    break;
                case CommentsIncomingMessageType.completePinning:
                    if (this.temporaryCommentId === message.data.commentId) {
                        this.commentsApi.setPendingPin(message.data.pinData);
                    }
                    this.commentsApi.enableComments();
                    break;
                case CommentsIncomingMessageType.cancelPinning:
                    if (message.data.commentId === this.temporaryCommentId) {
                        this.temporaryCommentId = undefined;
                    }
                    this.dispatchEvent(new CustomEvent("cancel-pinning"));
                    this.commentsApi.enableComments();
                    break;
                case CommentsIncomingMessageType.pinHovered:
                    if (message.data.status === 1) {
                        this.commentsApi.highlightComment(
                            message.data.commentId,
                        );
                    } else {
                        this.commentsApi.unhighlightComment();
                    }
                    break;
                case CommentsIncomingMessageType.pinSelected:
                    this.commentsApi.showComment(message.data.commentId);
                    break;
                case CommentsIncomingMessageType.pinDeselected:
                    this.commentsApi.showComment();
                    break;
                case CommentsIncomingMessageType.createComment:
                    this.messageBus.postMessage({
                        type: CommentsOutGoingMessageType.error,
                        message: "createComment is unimplemented",
                    });
                    break;
                case CommentsIncomingMessageType.updateComment:
                    this.messageBus.postMessage({
                        type: CommentsOutGoingMessageType.error,
                        message: "updateComment is unimplemented",
                    });
                    break;
                case CommentsIncomingMessageType.removeComment:
                    this.messageBus.postMessage({
                        type: CommentsOutGoingMessageType.error,
                        message: "removeComment is unimplemented",
                    });
                    break;
                case CommentsIncomingMessageType.focusCommentTextArea:
                    this.dispatchEvent(
                        new CustomEvent("focus-comment-text-area"),
                    );
                    break;
                case CommentsIncomingMessageType.clearTextFocus:
                    this.dispatchEvent(new CustomEvent("clear-text-focus"));
                    break;
                case CommentsIncomingMessageType.vrKeyboardEnter:
                    this.dispatchEvent(new CustomEvent("vr-keyboard-enter"));
                    break;
                case CommentsIncomingMessageType.vrAnnotationToggled:
                    this.dispatchEvent(
                        new CustomEvent("vr-annotation-toggled"),
                    );
                    break;
            }
        } catch (err) {
            console.error("Received invalid message", payload);
        }
    };

    dispose() {
        this.messageBus.removeEventListener("message", this.messageHandler);
    }
}
