/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import { IconProps } from "../../util/IconUtils";

export function MicMuted(props: IconProps) {
    return (
        <Icon {...props}>
            <svg>
            <rect className="cls-1" x="7.4" y="-2.95" width="1.23" height="21.93" rx=".41" ry=".41" transform="translate(-3.32 8.02) rotate(-45)"/>
                <path
                    className="cls-1"
                    d="M12.89,10.61c.08-.36.13-.72.13-1.09v-2.25c0-.14-.11-.25-.25-.25h-.5c-.14,0-.25.11-.25.25h0v2.25c0,.07-.02.13-.02.2l.89.89Z"
                />
                <path
                    className="cls-1"
                    d="M11.77,16.02h-3.75v-1.03c1.22-.07,2.36-.61,3.2-1.49l-.67-.67c-1.82,1.68-4.66,1.57-6.34-.25-.77-.83-1.19-1.93-1.19-3.06v-2.25c0-.14-.11-.25-.25-.25h-.5c-.14,0-.25.11-.25.25h0v2.25c0,2.86,2.68,5.5,5,5.5v1h-3.75c-.14,0-.25.11-.25.25h0v.5c0,.14.11.25.25.25h8.5c.14,0,.25-.11.25-.25h0v-.5c0-.14-.11-.25-.25-.25h0Z"
                />
                <path
                    className="cls-1"
                    d="M10.92,8.63V3.62c0-1.87-1.51-3.4-3.39-3.4-1.32,0-2.52.76-3.08,1.95l6.47,6.47Z"
                />
                <path
                    className="cls-1"
                    d="M4.12,6.4v3.02c0,1.87,1.5,3.39,3.37,3.4.82,0,1.61-.29,2.23-.82l-5.59-5.59Z"
                />
            </svg>
        </Icon>
    );
}

export function MicUnmuted(props: IconProps) {
    return (
        <Icon {...props}>
            <svg>
                <path
                    className="cls-1"
                    d="M8.9,3.4c0-1.88-1.52-3.4-3.4-3.4s-3.4,1.52-3.4,3.4v5.8c0,1.88,1.52,3.4,3.4,3.4s3.4-1.52,3.4-3.4V3.4Z"
                />
                <path
                    className="cls-1"
                    d="M11,9.3v-2.25c0-.14-.11-.25-.25-.25h-.5c-.14,0-.25.11-.25.25v2.25c0,2.49-2.01,4.5-4.5,4.5S1,11.79,1,9.3v-2.25c0-.14-.11-.25-.25-.25H.25c-.14,0-.25.11-.25.25v2.25c0,2.86,2.68,5.5,5,5.5v1H1.25c-.14,0-.25.11-.25.25v.5c0,.14.11.25.25.25h8.5c.14,0,.25-.11.25-.25v-.5c0-.14-.11-.25-.25-.25h-3.75v-1.03c2.32,0,5-2.61,5-5.47Z"
                />
            </svg>
        </Icon>
    );
}

export function MicUnmutedVR(props: IconProps) {
    return (
        <Icon {...props}>
            <svg viewBox="0 0 20 20">
                <path
                    className="cls-1"
                    d="M8.9,3.4c0-1.88-1.52-3.4-3.4-3.4s-3.4,1.52-3.4,3.4v5.8c0,1.88,1.52,3.4,3.4,3.4s3.4-1.52,3.4-3.4V3.4Z"
                />
                <path
                    className="cls-1"
                    d="M11,9.3v-2.25c0-.14-.11-.25-.25-.25h-.5c-.14,0-.25.11-.25.25v2.25c0,2.49-2.01,4.5-4.5,4.5S1,11.79,1,9.3v-2.25c0-.14-.11-.25-.25-.25H.25c-.14,0-.25.11-.25.25v2.25c0,2.86,2.68,5.5,5,5.5v1H1.25c-.14,0-.25.11-.25.25v.5c0,.14.11.25.25.25h8.5c.14,0,.25-.11.25-.25v-.5c0-.14-.11-.25-.25-.25h-3.75v-1.03c2.32,0,5-2.61,5-5.47Z"
                />
            </svg>
        </Icon>
    );
}

export function MicPermissionDenied(props: IconProps) {
    return (
        <Icon {...props}>
            <svg viewBox="0 0 20 20">
            <rect className="cls-1"  fill="#3F3F3F" x="7.4" y="-2.95" width="1.23" height="21.93" rx=".41" ry=".41" transform="translate(-3.32 8.02) rotate(-45)"/>
                <path
                    className="cls-1"
                    d="M12.89,10.61c.08-.36.13-.72.13-1.09v-2.25c0-.14-.11-.25-.25-.25h-.5c-.14,0-.25.11-.25.25h0v2.25c0,.07-.02.13-.02.2l.89.89Z"
                    fill="#3F3F3F"
                />
                <path
                    className="cls-1"
                    d="M11.77,16.02h-3.75v-1.03c1.22-.07,2.36-.61,3.2-1.49l-.67-.67c-1.82,1.68-4.66,1.57-6.34-.25-.77-.83-1.19-1.93-1.19-3.06v-2.25c0-.14-.11-.25-.25-.25h-.5c-.14,0-.25.11-.25.25h0v2.25c0,2.86,2.68,5.5,5,5.5v1h-3.75c-.14,0-.25.11-.25.25h0v.5c0,.14.11.25.25.25h8.5c.14,0,.25-.11.25-.25h0v-.5c0-.14-.11-.25-.25-.25h0Z"
                    fill="#3F3F3F"
                />
                <path
                    className="cls-1"
                    d="M10.92,8.63V3.62c0-1.87-1.51-3.4-3.39-3.4-1.32,0-2.52.76-3.08,1.95l6.47,6.47Z"
                    fill="#3F3F3F"
                />
                <path
                    className="cls-1"
                    d="M4.12,6.4v3.02c0,1.87,1.5,3.39,3.37,3.4.82,0,1.61-.29,2.23-.82l-5.59-5.59Z"
                    fill="#3F3F3F"
                />
            </svg>
        </Icon>
    );
}

