/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    View,
    Flex,
    ActionButton,
    TooltipTrigger,
    Tooltip,
    DialogTrigger,
    Dialog,
    Content,
    Text,
    Switch,
} from "@adobe/react-spectrum";
import Visibility from "@spectrum-icons/workflow/Visibility";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useNetworkContext } from "@src/contexts/NetworkContext";
import { usePinContext } from "@src/contexts/PinContext";
import { CommentsApi, CommentsApiEvent } from "@src/lib/bus-api/CommentsApi";

interface VisibilityOptionsProps {
    isSolo: boolean;
    commentsApi: CommentsApi;
}

export function VisibilityOptions({
    isSolo,
    commentsApi,
}: VisibilityOptionsProps) {
    const { t } = useTranslation("web");
    const { networkManager } = useNetworkContext();
    const { toggleAnnotationButton, pinsVisible } = usePinContext();

    const [userCount, setUserCount] = useState(
        networkManager?.myRoomActorCount() || 1,
    );
    const [pinCount, setPinCount] = useState(0);
    const [nameplatesVisible, setNameplatesVisible] = useState(
        !(isSolo || userCount == 1),
    );

    const handleNameplateToggle = () => {
        networkManager?.avatarsManager?.toggleNameplateVisibility();
        setNameplatesVisible((prevState) => !prevState);
    };

    useEffect(() => {
        if (userCount > 1) {
            setNameplatesVisible(true);
        }
    }, [userCount]);

    useEffect(() => {
        commentsApi.on(
            CommentsApiEvent.commentsUpdate,
            ({ context, delta }) => {
                const pins = delta.data.visibleComments.filter(
                    (comment: any) => comment.annotationType === "note",
                ).length;
                setPinCount(pins);
            },
        );
    }, [commentsApi]);

    useEffect(() => {
        if (!networkManager || !networkManager.avatarsManager) return;
        const avatarsManager = networkManager.avatarsManager;

        setUserCount(networkManager?.myRoomActorCount());

        avatarsManager.on("join", () => {
            setUserCount(networkManager?.myRoomActorCount());
        });

        avatarsManager.on("leave", () => {
            setUserCount(networkManager?.myRoomActorCount());
        });

        return () => {
            networkManager.avatarsManager?.removeAllListeners();
        };
    }, [networkManager, networkManager?.avatarsManager]);

    return (
        <View>
            <DialogTrigger type="popover" placement="bottom" hideArrow={true}>
                <TooltipTrigger>
                    <ActionButton isQuiet>
                        <Visibility />
                    </ActionButton>
                    <Tooltip>{t("nav.tooltip.visibility")}</Tooltip>
                </TooltipTrigger>
                <Dialog
                    UNSAFE_style={{
                        display: "flex",
                        marginTop: "-30px",
                        marginBottom: "-30px",
                        marginLeft: "-25px",
                        marginRight: "-35px",
                        width: "var(--spectrum-global-dimension-size-1600)",
                    }}>
                    <Content>
                        <Flex direction="column" justifyContent="center">
                            <Flex
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Text>{t("visibility.nameplates")}</Text>
                                <Switch
                                    isEmphasized
                                    isSelected={nameplatesVisible}
                                    isDisabled={isSolo || userCount <= 1}
                                    onChange={() => {
                                        handleNameplateToggle();
                                    }}
                                />
                            </Flex>
                            <Flex
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Text>{t("visibility.pins")}</Text>
                                <Switch
                                    isEmphasized
                                    isSelected={
                                        pinCount === 0 ? false : pinsVisible
                                    }
                                    isDisabled={pinCount === 0}
                                    onChange={() => {
                                        toggleAnnotationButton.current?.click();
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </Content>
                </Dialog>
            </DialogTrigger>
        </View>
    );
}
