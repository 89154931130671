/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    ContextualHelp,
    Divider,
    Flex,
    Radio,
    RadioGroup,
    Switch,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IngestDialogWrapper } from "./IngestDialogWrapper";
import { AssetTechInfoView } from "../../components/AssetTechInfoView";
import { OptimizeAsset } from "../../components/OptimizeAsset";
import { ViewerComparison } from "../../components/ViewerComparison";
import { AssetRenameTextField } from "@src/components/AssetRenameTextField";
import { QualityKey, useAcpContext } from "@src/contexts/AcpContext";
import { useGlbInfo } from "@src/hooks/useGlbInfo";
import {
    getComponentQualityPath,
    useOptimizeAsset,
} from "@src/hooks/useOptimizeAsset";
import { useRedirects } from "@src/hooks/useRedirects";
import { isEmptyAssetTechInfo, shouldOptimizeModel } from "@src/util/HealthCheckUtils";

import type { SceneManager } from "@components/studio/src/scene/SceneManager";

interface UploadPreviewDialogProps {
    assetId: string;
    confirmHandler: () => void;
}

const qualityMap = { low: 0, medium: 1, high: 2 };
function getQualityUrl(quality: QualityKey, urls: (string | undefined)[]) {
    return urls[qualityMap[quality]];
}

export function PreviewDialog({
    assetId,
    confirmHandler,
}: UploadPreviewDialogProps) {
    const { t } = useTranslation(["web", "common"]);
    const { homeRedirect } = useRedirects();
    const {
        acpClient,
        queryClient,
        useGlbUrl,
        useOptimizedGlbUrl,
        useMutateEnvironmentMeta,
        useOriginalFileFormat,
        useMutateAssetTechInfo,
    } = useAcpContext();

    const { data: glbUrl, isFetched: fetchedGlb } = useGlbUrl(assetId, false);
    const { data: glbLowUrl, isFetched: fetchedGlbLow } = useOptimizedGlbUrl(
        assetId,
        "low",
    );
    const { data: glbMediumUrl, isFetched: fetchedGlbMedium } =
        useOptimizedGlbUrl(assetId, "medium");
    const { data: glbHighUrl, isFetched: fetchedGlbHigh } = useOptimizedGlbUrl(
        assetId,
        "high",
    );

    const { mutateAsync: mutateEnvMeta } = useMutateEnvironmentMeta(assetId);
    const { data: originalFormat } = useOriginalFileFormat(assetId);
    const { mutateAsync: mutateAssetInfo } = useMutateAssetTechInfo(assetId);

    const hasAllModelUrls =
        fetchedGlb && fetchedGlbLow && fetchedGlbMedium && fetchedGlbHigh;
    const hasOptimizedModels = !!glbLowUrl && !!glbMediumUrl && !!glbHighUrl;
    const [compareModels, setCompareModels] = useState(false);

    const [viewerUrl, setViewerUrl] = useState("");
    const [comparisonViewerUrl, setComparisonViewerUrl] = useState("");
    const [assetInfoSaved, setAssetInfoSaved] = useState(false);

    const [sceneManager, setSceneManager] = useState<SceneManager>();
    const [comparisonSceneManager, setComparisonSceneManager] =
        useState<SceneManager>();

    const { assetTechInfo } = useGlbInfo(
        viewerUrl,
        sceneManager?.viewer,
        originalFormat,
    );
    const { assetTechInfo: comparisonTechInfo } = useGlbInfo(
        comparisonViewerUrl,
        comparisonSceneManager?.viewer,
        originalFormat,
    );

    const [selectedModel, setSelectedModel] = useState<QualityKey | "original">(
        "original",
    );

    const shouldOptimize =
        shouldOptimizeModel(assetTechInfo) && !hasOptimizedModels;

    const { progress, running, optimize, cancelOptimize } =
        useOptimizeAsset(assetId);

    useEffect(() => {
        if (selectedModel === "original" && !isEmptyAssetTechInfo(assetTechInfo)) {
            mutateAssetInfo(assetTechInfo).then(() => setAssetInfoSaved(true));
        } else {
            if (!isEmptyAssetTechInfo(comparisonTechInfo)) {
                mutateAssetInfo(comparisonTechInfo).then(() => setAssetInfoSaved(true));
            }
        }
    }, [selectedModel, assetTechInfo, comparisonTechInfo]);

    useEffect(() => {
        if (
            assetTechInfo.physicalSize.value.reduce(
                (total, val) => total + val,
                0,
            ) > 0
        ) {
            mutateEnvMeta({
                physicalSize: JSON.stringify(assetTechInfo.physicalSize.value),
            });
        }
    }, [assetTechInfo]);

    function goBack() {
        mutateEnvMeta({
            physicalSize: undefined,
            environment: undefined,
            pedestal: undefined,
        });
        queryClient.invalidateQueries({ queryKey: ["glbUrls", assetId] });
        homeRedirect(true);
    }

    function confirm() {
        queryClient.invalidateQueries({ queryKey: ["glbUrls", assetId] });
        confirmHandler();
    }

    function setModel(model: string, compare: boolean) {
        setSelectedModel(model as QualityKey | "original");
        acpClient.getSourceDocPath(assetId).then((docPath) => {
            acpClient.setTypedChildMetadata(assetId, {
                "usdcx#optimized-document":
                    model === "original"
                        ? docPath
                        : getComponentQualityPath(model as QualityKey),
            });
        });
        const url =
            getQualityUrl(model as QualityKey, [
                glbLowUrl,
                glbMediumUrl,
                glbHighUrl,
            ]) || glbUrl;
        if (url) {
            if (compare) {
                setComparisonViewerUrl(url);
            } else {
                setViewerUrl(url);
            }
        }
    }
    function toggleComparison(on: boolean) {
        if (on && glbUrl) {
            if (comparisonViewerUrl && viewerUrl) {
                setComparisonViewerUrl(viewerUrl);
            }
            setViewerUrl(glbUrl);
        }
        if (!on) {
            if (comparisonViewerUrl) {
                setViewerUrl(comparisonViewerUrl);
            }
        }
        setCompareModels(on);
    }

    useEffect(() => {
        if (hasAllModelUrls) {
            if (hasOptimizedModels) {
                setCompareModels(true);
                setModel("medium", true);
            }
            setViewerUrl(glbUrl!);
        }
    }, [hasAllModelUrls, hasOptimizedModels]);

    return (
        <IngestDialogWrapper>
            <View width="65%" position="relative">
                {running && (
                    <View
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        UNSAFE_style={{
                            backgroundColor: "rgba(0,0,0,0.5)",
                            zIndex: 4,
                        }}
                    />
                )}
                <ViewerComparison
                    compare={compareModels}
                    viewerUrl={viewerUrl}
                    comparisonUrl={comparisonViewerUrl}
                    sceneManager={sceneManager}
                    setSceneManager={setSceneManager}
                    comparisonSceneManager={comparisonSceneManager}
                    setComparisonSceneManager={setComparisonSceneManager}
                />
            </View>
            <Flex
                width="35%"
                direction="column"
                UNSAFE_style={{
                    padding: "var(--spectrum-global-dimension-size-250)",
                    paddingTop: 0,
                    paddingBottom: 0,
                    overflow: "auto",
                }}>
                <Flex
                    direction="column"
                    justifyContent="space-between"
                    height="100%">
                    <View>
                        <AssetRenameTextField assetId={assetId} />
                        {shouldOptimize && (
                            <OptimizeAsset
                                optimize={optimize}
                                progress={progress}
                                running={running}
                                cancelOptimize={cancelOptimize}
                            />
                        )}
                        {hasOptimizedModels && (
                            <View
                                backgroundColor="gray-100"
                                padding="size-200"
                                marginTop="size-200">
                                <RadioGroup
                                    onChange={(model) =>
                                        setModel(model, compareModels)
                                    }
                                    label={t(
                                        "previewPanel.optimization.quality.label",
                                    )}
                                    contextualHelp={
                                        <ContextualHelp variant="info">
                                            <Content
                                                UNSAFE_style={{
                                                    marginTop: 0,
                                                }}>
                                                <Text>
                                                    {t(
                                                        "previewPanel.optimization.quality.info",
                                                    )}
                                                </Text>
                                            </Content>
                                        </ContextualHelp>
                                    }
                                    defaultValue="medium">
                                    <Radio value="original">
                                        {t(
                                            "previewPanel.optimization.quality-original",
                                        )}
                                    </Radio>
                                    <Radio value="low">
                                        {t(
                                            "previewPanel.optimization.quality-low",
                                        )}
                                    </Radio>
                                    <Radio value="medium">
                                        {t(
                                            "previewPanel.optimization.quality-medium",
                                        )}
                                    </Radio>
                                    <Radio value="high">
                                        {t(
                                            "previewPanel.optimization.quality-high",
                                        )}
                                    </Radio>
                                </RadioGroup>
                                <Divider size="M" />
                                <Flex alignItems={"center"}>
                                    <Switch
                                        isSelected={compareModels}
                                        onChange={toggleComparison}
                                        UNSAFE_style={{ marginRight: 0 }}>
                                        <Text>
                                            {t(
                                                "previewPanel.optimization.quality.compare-switch",
                                            )}
                                        </Text>
                                    </Switch>
                                    <ContextualHelp
                                        variant="info"
                                        UNSAFE_style={{
                                            marginLeft: "-5px",
                                        }}>
                                        <Content
                                            UNSAFE_style={{
                                                marginTop: 0,
                                            }}>
                                            <Text>
                                                {t(
                                                    "previewPanel.optimization.quality.compare-info",
                                                )}
                                            </Text>
                                        </Content>
                                    </ContextualHelp>
                                </Flex>
                            </View>
                        )}
                        <AssetTechInfoView
                            compare={compareModels}
                            compareTechInfo={comparisonTechInfo}
                            techInfo={assetTechInfo}
                        />
                    </View>
                    <Flex
                        direction="column"
                        justifyContent="end"
                        alignItems="end">
                        <ButtonGroup marginBottom="size-200">
                            <Button
                                isDisabled={running}
                                variant="secondary"
                                onPress={goBack}>
                                {t("common:actions.cancel")}
                            </Button>
                            <Button
                                isDisabled={running || !assetInfoSaved}
                                variant="accent"
                                onPress={confirm}
                                autoFocus>
                                {t("actions.next")}
                            </Button>
                        </ButtonGroup>
                    </Flex>
                </Flex>
            </Flex>
        </IngestDialogWrapper>
    );
}
