/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, ProgressCircle, View } from "@adobe/react-spectrum";
import { ToastContainer } from "@react-spectrum/toast";
import { CSSProperties, useEffect, useRef, useState } from "react";

import { use3dSceneManager } from "./hooks/use3dSceneManager";
import { AdobeCanvasViewerOptions } from "./scene/AdobeViewer";
import { CameraControls } from "./tool-components/CameraControls";
import { FrameButton } from "./tool-components/FrameButton";

import type { RenderSettings, SceneManager } from "./scene/SceneManager";
import type { CameraControlsProps } from "./tool-components/CameraControls";
import type { FrameButtonProps } from "./tool-components/FrameButton";

export enum StudioTool {
    cameraControls = "cameraControls",
    frameButton = "frameButton",
    none = "none",
}

export type StudioStrings = CameraControlsProps["strings"] &
    FrameButtonProps["strings"];

const cameraControlsTools: Partial<Record<StudioTool, boolean>> = {
    [StudioTool.cameraControls]: true,
};

const frameButtonTools: Partial<Record<StudioTool, boolean>> = {
    [StudioTool.frameButton]: true,
};

export interface OptimizerSettings {
    optimizerFps?: number;
    optimizerFreq?: number;
}

export interface SceneEditorProps {
    enableLimitedZoom?: boolean;
    modelUrl: string;
    iblUrl?: string;
    cameraName?: string;
    tools?: StudioTool[];
    strings: StudioStrings;
    wideFraming?: boolean;
    renderSettings?: RenderSettings;
    optimizerSettings?: OptimizerSettings;
    onViewLoaded?: () => void;
    onModelLoadError?: () => void;
    setSceneManager?: (sceneManager: SceneManager) => void;
    viewerConfig?: AdobeCanvasViewerOptions;
}

function getOverlayStyles() {
    return {
        pointerEvents: "none" as any,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        height: "100%",
    } as CSSProperties;
}

export function Studio({
    enableLimitedZoom,
    modelUrl,
    iblUrl,
    cameraName,
    tools = [StudioTool.cameraControls],
    strings,
    renderSettings,
    setSceneManager,
    viewerConfig,
}: SceneEditorProps) {
    const [viewerRef, setViewerRef] = useState<HTMLElement | null>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const [showLoader, setShowLoader] = useState(true);

    const { sceneManager, modelLoadProgress } = use3dSceneManager(
        viewerRef,
        modelUrl,
        iblUrl,
        enableLimitedZoom,
        cameraName,
        renderSettings,
        {
            clearColor: [1, 1, 1, 1],
            ...viewerConfig,
        },
    );

    useEffect(() => {
        if (sceneManager && setSceneManager) {
            setSceneManager(sceneManager);
        }
    }, [sceneManager]);

    useEffect(() => {
        const logData = {
            sceneManager: !!sceneManager,
            modelUrl: !!modelUrl,
            showLoader,
            modelLoadProgress,
        };
        if (!sceneManager) return;
        if (!modelUrl || (modelLoadProgress && !showLoader)) {
            console.log("Showing Loader", logData);
            setShowLoader(true);
        }
        if (modelUrl && showLoader && !modelLoadProgress) {
            const timer = window.setTimeout(() => {
                console.log("Hiding Loader", logData);
                setShowLoader(false);
            }, 500);
            return () => {
                window.clearTimeout(timer);
            };
        }
        return () => {};
    }, [modelUrl, sceneManager, showLoader, modelLoadProgress]);

    const hasCameraControlsTools = tools.find(
        (tool) => !!cameraControlsTools[tool],
    );
    const hasFrameButtonTools = tools.find((tool) => !!frameButtonTools[tool]);

    const overlayStyles = getOverlayStyles();

    return (
        <View data-uia="studio" backgroundColor="gray-100">
            {/* Display FPS, for debugging. Update the startOptimizer call with the div id below, and uncomment the
                following div, to display the FPS. Displaying it may slow down the program slightly, though. */}
            {/* <div id="fps">NONE</div> */}
            <ToastContainer />
            <Flex justifyContent="center" height="100%">
                <View position="relative" width="100%" height="100%">
                    <div
                        data-uia="scene-overlay-portal"
                        style={overlayStyles}
                        ref={overlayRef}
                    />
                    <div
                        data-uia="scene-overlay-tools"
                        style={{
                            ...overlayStyles,
                            opacity: !!sceneManager ? "1" : "0",
                            transition: "opacity 1s",
                        }}>
                        <Flex
                            position="relative"
                            height="100%"
                            width="100%"
                            justifyContent="space-between">
                            <Flex
                                direction="column"
                                justifyContent="end"
                                gap="size-125"
                                margin="size-150">
                                {hasCameraControlsTools && (
                                    <CameraControls
                                        sceneManager={sceneManager}
                                        strings={{
                                            cameraDolly: strings.cameraDolly,
                                            cameraOrbit: strings.cameraOrbit,
                                            cameraPan: strings.cameraPan,
                                        }}
                                    />
                                )}
                            </Flex>
                            <Flex
                                direction="column"
                                justifyContent="end"
                                margin="size-150">
                                {hasFrameButtonTools && (
                                    <FrameButton
                                        sceneManager={sceneManager}
                                        strings={{
                                            frameButton: strings.frameButton,
                                        }}
                                    />
                                )}
                            </Flex>
                        </Flex>
                    </div>
                    <View
                        isHidden={!showLoader}
                        backgroundColor="gray-100"
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={4}
                        data-uia="loading-screen">
                        <Flex
                            width="100%"
                            height="100%"
                            justifyContent="center"
                            alignItems="center">
                            <ProgressCircle
                                aria-label="Model loading"
                                isIndeterminate
                            />
                        </Flex>
                    </View>
                    <div
                        onFocus={() => sceneManager?.attachWindowListeners()}
                        onBlur={() => sceneManager?.detachWindowListeners()}
                        data-uia="scene-viewer"
                        style={{
                            ...overlayStyles,
                            pointerEvents: "all",
                            zIndex: 1,
                        }}
                        ref={(el) => setViewerRef(el)}
                    />
                </View>
            </Flex>
        </View>
    );
}
