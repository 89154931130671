/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { useState, useEffect } from "react";
import { ActionButton, Divider, Flex, Text, View } from "@adobe/react-spectrum";
import Info from "@spectrum-icons/workflow/Info";
import Close from "@spectrum-icons/workflow/Close";
import { useTranslation } from "react-i18next";

interface SoloModeBannerProps {
    userId: string | undefined;
}

const BANNER_CLOSED_SESSION_ID = "bannerClosedSessionId";

export function SoloModeBanner({
    userId,
}: SoloModeBannerProps) {
    const { t } = useTranslation("web");
    const [showBanner, setShowBanner] = useState(false);


    function markBannerAsClosed(userId?: string) {
        if (!userId) return;
        const closedMap = getBannerClosedMap();
        if (!(userId in closedMap)) {
            closedMap[userId] = true; 
            localStorage.setItem(BANNER_CLOSED_SESSION_ID, JSON.stringify(closedMap));
        }
    }

    function hasBannerBeenClosed(userId?: string): boolean {
        const closedMap = getBannerClosedMap();
        return userId ? !!closedMap[userId] : false;
    }

    function getBannerClosedMap(): Record<string, boolean> {
        const closedData = localStorage.getItem(BANNER_CLOSED_SESSION_ID);
        return closedData ? JSON.parse(closedData) : {};
    }

    useEffect(() => {
        if (userId) {
            setShowBanner(!hasBannerBeenClosed(userId));
        }
    }, [userId]);

    const handleCloseBanner = () => {
        if (userId) {
            markBannerAsClosed(userId);
        }
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
                <View position="relative">
                    <View
                        position="absolute"
                        width="100%"
                        backgroundColor="blue-400"
                        paddingY="size-100"
                        // paddingX="size-200"
                        zIndex={10}>
                        <Flex
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between">
                            <Flex
                                alignItems="center"
                                gap="size-150"
                                UNSAFE_style={{ marginLeft: "20px" }}>
                                <Info size="S" />
                                <Text>{t("banner.soloMode")}</Text>
                            </Flex>
                            <Flex
                                alignItems="center"
                                gap="size-100"
                                right="size-10">
                                <Divider
                                    orientation="vertical"
                                    size="S"
                                    UNSAFE_style={{
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        height: "32px",
                                    }}
                                />
                                <ActionButton
                                    isQuiet
                                    staticColor="white"
                                    onPress={() => handleCloseBanner()}
                                    UNSAFE_style={{ marginRight: "10px" }}>
                                    <Close size="XXS" />
                                </ActionButton>
                            </Flex>
                        </Flex>
                    </View>
                </View>
            )}
        </>
    );
}
