/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { AcpClient } from "@src/lib/acp/AcpClient";

let cache: Record<string, ReturnType<typeof checkLinkedMetaUserId>> = {};

export function clearMetaUserIdCache() {
    cache = {};
}

async function checkLinkedMetaUserId(
    acpClient: AcpClient,
): Promise<{ id: string; alias: string }> {
    const cloudDir = await acpClient.getDirectory("cloud-content");
    const cloudDirAssetId = cloudDir["repo:assetId"];

    if (!cloudDirAssetId) {
        const e = new Error("cloudDirAssetId not found");
        console.error(e);
        throw e;
    }
    const cloudAppMeta = await acpClient.getApplicationMeta(cloudDirAssetId);

    if (!cloudAppMeta) {
        const e = new Error("error getting Cloud Content application metadata");
        console.error(e);
        throw e;
    }
    const id = cloudAppMeta?.["highfive:metaUserId"] || "";
    const alias = cloudAppMeta?.["highfive:metaUserIdAlias"] || "";
    return { id, alias };
}

export function useMetaLinkedAccount() {
    const [metaUserId, setMetaUserId] = useState("");
    const [metaUserIdAlias, setMetaUserIdAlias] = useState("");
    const { acpClient } = useAcpContext();
    const { accessToken } = useHi5UserContext();

    // call checkLinkedMetaUserId if user/access token changes
    useEffect(() => {
        if (!accessToken) return;
        let meta = cache[accessToken];
        if (!meta) {
            console.log("cache miss")
            meta = checkLinkedMetaUserId(acpClient);
            cache[accessToken] = meta;
        }
        meta.then(({ id, alias }) => {
            setMetaUserId(id);
            setMetaUserIdAlias(alias);
        });
    }, [accessToken]);

    return {
        metaUserId,
        metaUserIdAlias,
    };
}
