/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Heading,
    ButtonGroup,
    Button,
    View,
    Text,
    Image,
    Flex,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useMessagingContext } from "@shared/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import meetMattThumbImage from "@src/assets/images/meet-mat-thumb.png";
import meetMattImage from "@src/assets/images/meet-matt.jpg";
import meetMattGlb from "@src/assets/models/meet-matt.glb";
import { HI5_API_KEY } from "@src/config";
import { useAcpContext } from "@src/contexts/AcpContext";

const Name = "Matt";

const MattMeta = {
    centering: "uncentered",
    drawCalls: "3",
    environment: "studio",
    fileFormat: '{"displayName":"Format","value":"GLB"}',
    fileSize: "3557092",
    grounding: "ungrounded",
    id: "d11ff865-67fd-4675-bab0-31dbc109a27e",
    materials: "3",
    meshes: "3",
    name: "reviewer-meta",
    pedestal: "small",
    physicalSize: "[0.17095669358968735,0.299762954363187,0.19682049751281738]",
    polyCount: "56424",
    scaling: "1",
    textures: "12",
    upAxis: "y+",
} as const;

function CreateSampleAsset() {
    const { t } = useTranslation("web");
    const { closeModal } = useMessagingContext();
    const { acpClient, queryClient } = useAcpContext();

    const [submitting, setSubmitting] = useState(false);

    async function handleSubmit() {
        ToastQueue.info(t("createSampleAsset.creatingAsset"), {
            timeout: 5_000,
        });
        setSubmitting(true);
        const file = await fetch(meetMattGlb);
        const blob = await file.blob();

        try {
            const compositeName = `${Name}.usdcx`;
            const composite = await acpClient.createNewComposite(
                compositeName,
                `cloud-content/${compositeName}`,
                "application/vnd.adobe.usdcx+dcx",
            );

            if (!composite.assetId)
                throw new Error("Error generating composite");
            closeModal();

            const fileName = `${Name}.glb`;
            const { uploadComponentPromise } = await acpClient.uploadComponent(
                composite.assetId,
                new File([blob], fileName),
                fileName,
                "original",
                {
                    "usdcx#version": "0",
                    "usdcx#product-id": HI5_API_KEY,
                    "usdcx#source-document": fileName,
                },
            );

            await uploadComponentPromise;

            const thumbnail = await fetch(meetMattThumbImage);
            const thumbnailBlob = await thumbnail.blob();

            const { uploadComponentPromise: thumbnailUploadPromise } =
                await acpClient.uploadComponent(
                    composite.assetId,
                    new File([thumbnailBlob], "thumbnail"),
                    "renditions/image-256-256.png",
                    "rendition",
                );

            await thumbnailUploadPromise;

            await acpClient.setTypedChildMetadata(
                composite.assetId,
                MattMeta,
                "reviewer-meta",
            );

            queryClient.invalidateQueries({
                queryKey: ["reviewItem", composite.assetId],
            });
            queryClient.invalidateQueries({
                queryKey: ["thumbnailUrls", composite.assetId],
            });
            queryClient.invalidateQueries({
                queryKey: ["glbUrls", composite.assetId],
            });
        } catch (error) {
            ToastQueue.negative(t("createSampleAsset.errorCreatingAsset"), {
                timeout: 10_000,
            });
            setSubmitting(false);
            console.error(error);
        }
    }

    return (
        <View
            id="create-sample-asset-dialog"
            width="296px"
            borderColor={"gray-400"}
            borderRadius="small"
            borderWidth="thin"
            overflow="hidden">
            <Image
                width="296px"
                height="161px"
                src={meetMattImage}
                alt={t("createSampleAsset.meetMattAlt")}
            />
            <View margin="size-250">
                <Heading>{t("createSampleAsset.no3DAsset")}</Heading>
                <Text>{t("createSampleAsset.getStarted")}</Text>
                <Flex justifyContent="end" marginTop="size-250">
                    <ButtonGroup>
                        <Button
                            isDisabled={submitting}
                            variant="secondary"
                            onPress={closeModal}>
                            {t("createSampleAsset.skip")}
                        </Button>
                        <Button
                            isDisabled={submitting}
                            variant="cta"
                            onPress={handleSubmit}>
                            {t("createSampleAsset.tryItNow")}
                        </Button>
                    </ButtonGroup>
                </Flex>
            </View>
        </View>
    );
}

export default CreateSampleAsset;
