/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View, Button, Flex, ProgressBar } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

interface Props {
    progress: number;
    running: boolean;
    optimize: () => void;
    cancelOptimize?: () => void;
}

export function OptimizeAsset({
    progress,
    running,
    optimize,
    cancelOptimize,
}: Props) {
    const {t} = useTranslation(["web", "common"]);
    return (
        <View
            marginTop="size-200"
            backgroundColor="gray-100"
            padding="size-200"
            borderWidth="thick"
            borderRadius="small"
            UNSAFE_style={{
                borderColor: "var(--spectrum-notice-visual-color)",
            }}>
            {!running && (
                <>
                    <p style={{ marginTop: "0" }}>
                        {t("previewPanel.optimize.note")}
                    </p>
                    <Button onPress={optimize} variant="primary">
                        {t("previewPanel.optimize.button")}
                    </Button>
                </>
            )}
            {running && (
                <Flex
                    justifyContent="space-between"
                    gap="size-150"
                    margin="size-150">
                    <ProgressBar
                        width="100%"
                        label="Optimizing..."
                        value={progress * 100}
                    />
                    <Button
                        variant="secondary"
                        onPress={() => cancelOptimize?.()}>
                        {t("common:actions.cancel")}
                    </Button>
                </Flex>
            )}
        </View>
    );
}
