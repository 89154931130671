/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ToastQueue } from "@react-spectrum/toast";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DetectSpeech } from "@src/util/DetectSpeech";

export interface AudioPreferences {
    muted: boolean;
    currentAudioDeviceId?: string;
}

const storageKey = "reviewer-audio-preferences";
const defaultPreferences: AudioPreferences = {
    muted: false,
};

function writeAudioPreferences(preferences: AudioPreferences) {
    window.localStorage.setItem(storageKey, JSON.stringify(preferences));
}

function readAudioPreferences() {
    try {
        const savedValue = window.localStorage.getItem(storageKey);
        if (savedValue) {
            return JSON.parse(savedValue) as AudioPreferences;
        }
    } catch (e) {
        console.error("Error getting audio preferences", e);
    }
    return defaultPreferences;
}

export function useAudioControls(
    isSolo?: boolean,
    mutedOverride?: boolean,
    currentDeviceIdOverride?: string,
) {
    const { t } = useTranslation("web");
    const savedPreferences = readAudioPreferences();
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [currentAudioDeviceId, setCurrentAudioDeviceId] = useState(
        currentDeviceIdOverride || savedPreferences.currentAudioDeviceId || "",
    );
    const defaultMuteState = useMemo(
        () => mutedOverride || savedPreferences.muted,
        [],
    );
    const [muteChanged, setMuteChanged] = useState(false);
    const [muted, setMuted] = useState(defaultMuteState);
    const [mediaStream, setMediaStream] = useState<MediaStream>();
    const [authorizeMic, setAuthorizeMic] = useState(false);
    const [isMutedToastManuallyClosed, setIsMutedToastManuallyClosed] = useState(false);

    useEffect(() => {
        writeAudioPreferences({
            currentAudioDeviceId,
            muted,
        });
    }, [currentAudioDeviceId, muted]);

    useEffect(() => {
        if (isSolo) return;

        let mediaStream: MediaStream | undefined;
        (async () => {
            try {
                mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                setMediaStream(mediaStream);
                setAuthorizeMic(true);

                const devices = await navigator.mediaDevices.enumerateDevices();
                //get only input devices
                const inputDevices = devices.filter(
                    (device) => device.kind === "audioinput",
                );
                if (inputDevices.length > 0) {
                    setAudioDevices(inputDevices);
                    setCurrentAudioDeviceId((currentId) => {
                        if (!currentId) {
                            return inputDevices[0].deviceId;
                        }
                        return currentId;
                    });
                }
                // console.log("Mic being used");
            } catch (error) {
                console.error("Error getting devices", error);
                setAuthorizeMic(false);
            }
        })();
        return () => {
            if (mediaStream) {
                mediaStream?.getAudioTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    // Warn muted users their mic is muted but they are talking
    useEffect(() => {
        if (!muted || !mediaStream || isSolo) return;

        const speechDetector = new DetectSpeech(mediaStream);
        let lastWarned = 0;
        let warnTimer = 0;
        speechDetector.on("SPEAKING", (isSpeaking) => {
            if (isSpeaking) {
                warnTimer = window.setTimeout(() => {
                    const now = Date.now();
                    if (now - lastWarned > 5_000 && !isMutedToastManuallyClosed) {
                        ToastQueue.neutral(
                            t("toast.audio.detectedSpeakingWhenMuted"),
                            { timeout: 1000 },
                        );
                        lastWarned = now;
                    }
                }, 1_000);
            }
            if (!isSpeaking) {
                clearTimeout(warnTimer);
            }
        });
        return () => {
            speechDetector.removeAllListeners("SPEAKING");
            speechDetector.dispose();
        };
    }, [muted, mediaStream, isSolo, isMutedToastManuallyClosed]);

    useEffect(() => {
        if (!muteChanged && muted !== defaultMuteState) {
            setMuteChanged(true);
        }

    }, [muteChanged, muted]);

    useEffect(() => {
        const mKeyMuteHandler = (e: KeyboardEvent) => {
            if (e.key === "m") {
                setMuted((currentMute) => !currentMute)
            }
        }
        window.addEventListener("keyup", mKeyMuteHandler)

        return () => {
            window.removeEventListener("keyup", mKeyMuteHandler)
        }
    }, [])

    // ------- handling toasts --------
    useEffect(() => {
        const clickHandler = (e: MouseEvent) => {
            // prints propagation path of event
            // console.log(e.composedPath())
            if (e.target instanceof HTMLElement) {
                const clickedElem = e.target.closest("div"); // get div parent of close button
                const clickedElemParent = clickedElem?.parentElement; // get div for toast
                const toastText = clickedElemParent?.innerText; // text on toast
                if (clickedElem?.className.includes("spectrum-Toast-buttons") && toastText === t("toast.audio.detectedSpeakingWhenMuted")) {
                    setIsMutedToastManuallyClosed(true);
                }
            }
        }

        document.body.addEventListener("click", clickHandler)
        return () => {
            document.body.removeEventListener("click", clickHandler);
        }
    }, []);

    useEffect(() => {
        // reset toast behavior if mute/unmute
        if (muted) setIsMutedToastManuallyClosed(false);
    }, [muted]);


    return {
        audioDevices,
        mediaStream,
        currentAudioDeviceId,
        setCurrentAudioDeviceId,
        muted,
        setMuted,
        authorizeMic
    };
}
