/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { BoundingBox } from "@babylonjs/core/Culling/boundingBox";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Scene } from "@babylonjs/core/scene";
import { Sprite } from "@babylonjs/core/Sprites/sprite";

export function calculateScaleForPosition(
    position: Vector3,
    pixelSize: number,
    heightInWorldUnits: number,
    minSize: number,
    maxSize: number,
    scene: Scene,
) {
    const camera = scene.activeCamera;
    if (!camera) {
        throw new Error("Camera not found");
    }

    const distanceToCamera = Vector3.Distance(camera.position, position);
    const targetSize = (pixelSize * heightInWorldUnits) / distanceToCamera;

    let scale = 1;
    if (targetSize > maxSize) {
        scale = getScaleForSize(
            maxSize,
            distanceToCamera,
            pixelSize,
            heightInWorldUnits,
        );
    } else if (targetSize < minSize) {
        scale = getScaleForSize(
            minSize,
            distanceToCamera,
            pixelSize,
            heightInWorldUnits,
        );
    } else {
        scale = getScaleForSize(
            targetSize,
            distanceToCamera,
            pixelSize,
            heightInWorldUnits,
        );
    }
    return scale;
}

export function scaleMesh(
    mesh: Mesh,
    pixelSize: number,
    heightInWorldUnits: number,
    minSize: number,
    maxSize: number,
    scene: Scene,
    applyScaling: boolean = true,
) {
    const scale = calculateScaleForPosition(
        mesh.position,
        pixelSize,
        heightInWorldUnits,
        minSize,
        maxSize,
        scene,
    );
    if (applyScaling) {
        mesh.scaling = Vector3.One().multiplyByFloats(scale, scale, scale);
    }
    return scale;
}

function getScaleForSize(
    size: number,
    distanceToCamera: number,
    pixelSize: number,
    height: number,
) {
    return ((size / pixelSize) * distanceToCamera) / height;
}

export function scaleMeshToPixelSize(
    mesh: Mesh,
    pixelSize: number,
    desiredPixelSize: number,
    heightInWorldUnits: number,
    applyScaling: boolean = true,
    scene: Scene,
) {
    const camera = scene.activeCamera;
    if (!camera) {
        throw new Error("Camera not found");
    }

    const distanceToCamera = Vector3.Distance(camera.position, mesh.position);
    const scale = getScaleForSize(
        desiredPixelSize,
        distanceToCamera,
        pixelSize,
        heightInWorldUnits,
    );

    // 3. apply scale factor to mesh by multiplying current scale by the calculated factor
    if (scale > Number.EPSILON) {
        if (applyScaling) {
            mesh.scaling = Vector3.One().multiplyByFloats(scale, scale, scale);
        }
        return scale;
    } else {
        return 1;
    }
}

export function scaleSprite(
    sprite: Sprite,
    minSizeDistance: number,
    maxSizeDistance: number,
    minSize: number,
    maxSize: number,
    scene: Scene,
) {
    const camera = scene.activeCamera;
    if (!camera) {
        throw new Error("Camera not found");
    }
    const height = sprite.height;
    const distance = Vector3.Distance(camera.position, sprite.position);
    let scale = 1;
    if (height == 0) return;
    if (distance > minSizeDistance) {
        scale = minSize;
    } else if (distance < maxSizeDistance) {
        scale = maxSize;
    } else {
        // LERP based on distance from camera; scale distance to 0 to 1 for LERP parameter
        const t =
            (distance - minSizeDistance) / (maxSizeDistance - minSizeDistance);
        const size = lerp(minSize, maxSize, t);
        scale = size;
    }

    sprite.width = scale;
    sprite.height = scale;
}

export function lerp(a: number, b: number, t: number) {
    return a + t * (b - a);
}

export function worldToScreen(vector: Vector3, scene: Scene) {
    const camera = scene.activeCamera;
    if (!camera) {
        throw new Error("Camera not found");
    }
    const viewport = camera.viewport.toGlobal(
        scene.getEngine().getRenderWidth(),
        scene.getEngine().getRenderHeight(),
    );
    return Vector3.Project(
        vector, // vector to project
        Matrix.Identity(), // world matrix
        scene.getTransformMatrix(), // transform matrix
        viewport, // viewport
    );
}

export function screenToWorld(vector: Vector3, scene: Scene) {
    return Vector3.Unproject(
        vector, // vector to unproject
        scene.getEngine().getRenderWidth(), // viewport width
        scene.getEngine().getRenderHeight(), // viewport height
        Matrix.Identity(), // world matrix
        scene.getViewMatrix(), // view matrix
        scene.getProjectionMatrix(), // projection matrix
    );
}

export function getScreenSpaceSize(
    boundingInfo: BoundingBox,
    scene: Scene,
): {
    width: number;
    height: number;
} {
    let minX = Number.POSITIVE_INFINITY,
        minY = Number.POSITIVE_INFINITY,
        maxX = Number.NEGATIVE_INFINITY,
        maxY = Number.NEGATIVE_INFINITY;
    for (const vertex of boundingInfo.vectorsWorld) {
        const temp = worldToScreen(vertex, scene);
        if (minX > temp.x) minX = temp.x;
        if (maxX < temp.x) maxX = temp.x;
        if (minY > temp.y) minY = temp.y;
        if (maxY < temp.y) maxY = temp.y;
    }

    return { width: maxX - minX, height: maxY - minY };
}
