/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { detect } from "detect-browser";
import { useRef } from "react";
import { v4 } from "uuid";

import { IMS_ENV, HI5_API_KEY, INGEST_URL, VERSION } from "../config";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export const EventWorkflow = {
    APP: "APP",
    IMPORT: "IMPORT",
    SHARING: "SHARING",
    SUSI: "SUSI",
    REPLATFORM: "REPLATFORM",
    COMMENTING: "COMMENTING",
} as const;
export type EventWorkflow = keyof typeof EventWorkflow;

export const EventSubcategory = {
    Launch: "Launch",
    LoadModel: "LoadModel",
    HealthCheck: "HealthCheck",
    Author: "Author",
    Upload: "Upload",
    Invite: "Invite",
    OpenReview: "OpenReview",
    Reshare: "Reshare",
    Shutdown: "Shutdown",
} as const;
export type EventSubcategory = keyof typeof EventSubcategory;

export const EventType = {
    success: "success",
    error: "error",
    continue: "continue",
    start: "start",
    open: "open",
} as const;
export type EventType = keyof typeof EventType;

export enum SourceData {
    clientId = "source.client_id",
    name = "source.name",
    version = "source.version",
    referrer = "source.referrer",
}

export enum EventData {
    category = "event.category",
    platform = "event.platform",
    value = "event.value",
    error_code = "event.error_code",
    idp = "event.idp",
    url = "event.url",
    user_agent = "event.user_agent",
    user_guid = "event.user_guid",
    session_guid = "event.session_guid",
    guid = "event.guid",
    device_guid = "event.device_guid",
    visitor_guid = "event.visitor_guid",
    workflow = "event.workflow",
    subcategory = "event.subcategory",
    type = "event.type",
}

export enum ContentData {
    access_mode = "content.access_mode",
    invitee_count = "content.invitee_count",
    invitees = "content.invitees",
    mimetype = "content.mimetype",
    name = "content.name",
    review_type = "content.review_type",
    size_in_mb = "content.size",
    urn = "content.urn",
}

export enum DimensionsData {
    gltf_extension_sheen = "gltf_extension_sheen",
    gltf_extension_metallic = "gltf_extension_metallic",
    comment_id = "comment_id",
    pin_relative_xyz_mm = "pin_relative_xyz_mm",
}

export enum MeasuresData {
    bounding_width_mm = "bounding_width_mm",
    bouding_depth_mm = "bouding_depth_mm",
    bouding_height_mm = "bouding_height_mm",
    import_time_ms = "import_time_ms",
    hc_polygon_count_failed_with = "hc_polygon_count_failed_with",
    hc_mesh_count_failed_with = "hc_mesh_count_failed_with",
    hc_file_size_failed_with = "hc_file_size_failed_with",
    hc_texture_size_failed_with = "hc_texture_size_failed_with",
    comment_panel_lifetime_s = "comment_panel_lifetime_s",
    comment_reply_count = "comment_reply_count",
}

export const isSessionStart = "isSessionStart";
sessionStorage.setItem(isSessionStart, "true");

export const sessionIdKey = "sessionId";
sessionStorage.setItem(
    sessionIdKey,
    localStorage.getItem(sessionIdKey) ?? v4(),
);

const sessionId = {
    current: sessionStorage.getItem(sessionIdKey) || v4(),
};

export function saveSessionGuid() {
    localStorage.setItem(sessionIdKey, sessionId.current ?? v4());
    const currIsSessionStart = sessionStorage.getItem(isSessionStart);
    if (currIsSessionStart)
        localStorage.setItem(isSessionStart, currIsSessionStart);
}

export const useAnalytics = () => {
    const vrSessionId = useRef<string | undefined>(null);
    const vrDeviceId = useRef<string | undefined>(null);

    const { accessToken, userProfile } = useHi5UserContext();

    const PROJECT = "hi5-web-service" as const;
    const INGEST_TYPE = "dunamis" as const;

    const sourceData = useRef<{
        [key in SourceData]?: string;
    }>({
        [SourceData.clientId]: HI5_API_KEY,
        [SourceData.name]: "High Five",
        [SourceData.version]: VERSION,
    });
    const eventData = useRef<{
        [key in EventData]?: string;
    }>({
        [EventData.category]: "Web",
        [EventData.platform]: "Web",
        [EventData.user_agent]: detect()?.name,
        [EventData.user_guid]: userProfile?.userId ?? "",
        [EventData.session_guid]: sessionId.current,
        [EventData.guid]: v4(),
    });
    const contentData = useRef<{
        [key in ContentData]?: string;
    }>({});
    const measuresData = useRef<{
        [key in MeasuresData]?: string;
    }>({});
    const dimensionsData = useRef<{
        [key in DimensionsData]?: string;
    }>({});

    function restoreSessionGuid() {
        const prevGuid = localStorage.getItem(sessionIdKey);
        if (prevGuid) sessionId.current = prevGuid;
        localStorage.removeItem(sessionIdKey);
        addEventData(EventData.session_guid, sessionId.current);

        const prevIsSessionStart = localStorage.getItem(isSessionStart);
        if (prevIsSessionStart)
            sessionStorage.setItem(isSessionStart, prevIsSessionStart);
        localStorage.removeItem(isSessionStart);
    }

    function addSourceData(key: SourceData, value: string) {
        sourceData.current[key] = value;
    }

    function addEventData(key: EventData, value: string) {
        eventData.current[key] = value;
    }

    function addContentData(key: ContentData, value: string) {
        contentData.current[key] = value;
    }

    function addMeasuresData(key: MeasuresData, value: string) {
        measuresData.current[key] = value;
    }

    function addDimensionsData(key: DimensionsData, value: string) {
        dimensionsData.current[key] = value;
    }

    async function sendAnalyticsEvent(
        workflow: EventWorkflow,
        subcategory: EventSubcategory,
        type: EventType,
    ) {
        addEventData(EventData.workflow, workflow);
        addEventData(EventData.subcategory, subcategory);
        addEventData(EventData.type, type);

        let contentDataObj;
        if (Object.keys(contentData.current).length != 0) {
            contentDataObj = contentData.current;
        }

        let measuresDataObj;
        if (Object.keys(measuresData.current).length != 0) {
            measuresDataObj = { "3di.measures": { ...measuresData.current } };
        }

        let dimensionsDataObj;
        if (Object.keys(dimensionsData.current).length != 0) {
            dimensionsDataObj = {
                "event.value": { ...dimensionsData.current },
            };
        }

        const body = {
            events: [
                {
                    time: new Date().toISOString(),
                    project: PROJECT,
                    environment: IMS_ENV === "prod" ? "prod" : "stage",
                    ingesttype: INGEST_TYPE,
                    data: {
                        ...sourceData.current,
                        ...eventData.current,
                        ...contentDataObj,
                        ...measuresDataObj,
                        ...dimensionsDataObj,
                    },
                },
            ],
        };

        console.log(JSON.stringify(body));

        await fetch(INGEST_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": PROJECT,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    }

    function sendLaunchAnalytics(type: EventType, url: string) {
        restoreSessionGuid();
        if (sessionStorage.getItem(isSessionStart) == "true") {
            addEventData(EventData.user_guid, userProfile?.userId ?? "");
            addEventData(EventData.url, url);
            sendAnalyticsEvent(
                EventWorkflow.APP,
                EventSubcategory.Launch,
                type,
            );
            sessionStorage.setItem(isSessionStart, "false");
        }
    }

    return {
        vrSessionId,
        vrDeviceId,
        saveSessionGuid,
        restoreSessionGuid,
        addSourceData,
        addEventData,
        addContentData,
        addMeasuresData,
        addDimensionsData,
        sendLaunchAnalytics,
    };
};
