/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import {
    AdobeCanvasViewerOptions,
    createAdobeViewerForCanvas,
} from "../scene/AdobeViewer";
import { RenderSettings, SceneManager } from "../scene/SceneManager";

export function use3dSceneManager(
    ref: HTMLElement | null,
    modelUrl: string,
    iblUrl?: string,
    enableLimitedZoom = false,
    cameraName?: string,
    renderSettings?: RenderSettings,
    viewerConfig?: AdobeCanvasViewerOptions,
    wideFraming = false,
) {
    const [sceneManager, setSceneManager] = useState<SceneManager>();
    const [modelLoadProgress, setModelLoadProgress] = useState<boolean | number>(false);

    //on window re-focus (tab switch), sometimes the renderer stops without fully rendering the frame
    useEffect(() => {
        const renderToggle = () => {
            if (!document.hidden) sceneManager?.viewer?.renderLoop?.activate();
        };
        window.document.addEventListener("visibilitychange", renderToggle);
        return () => {
            window.document.removeEventListener(
                "visibilitychange",
                renderToggle,
            );
        };
    }, []);

    useEffect(() => {
        if (ref) {
            const canvas = document.createElement("canvas");
            canvas.setAttribute("style", "width: 100%; height: 100%; background: transparent;");
            ref.appendChild(canvas);

            createAdobeViewerForCanvas(
                canvas,
                viewerConfig,
            ).then((viewer) => {
                const newSceneManager = new SceneManager(viewer, {
                    ...viewerConfig,
                    modelUrl,
                    iblUrl,
                    enableLimitedZoom,
                    cameraName,
                    renderSettings,
                    wideFraming,
                    debugLayerEnabled: true,
                });
                newSceneManager.onLoadProgress.add(setModelLoadProgress);
                setSceneManager(newSceneManager);
                const {width, height} = ref.getBoundingClientRect();
                canvas.width = width;
                canvas.height = height;
            });
        }
        return () => {
            if (sceneManager) {
                sceneManager.dispose();
                setSceneManager(undefined);
            }
        };
    }, [ref]);


    return {
        sceneManager,
        modelLoadProgress,
    };
}
