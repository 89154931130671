/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Button,
    DialogContainer,
    Divider,
    Flex,
    Heading,
    Text,
    Tooltip,
    TooltipTrigger,
    View,
} from "@adobe/react-spectrum";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import { SceneManager } from "@components/studio/src/scene/SceneManager";
import { CameraFrame } from "@components/studio/src/svg/camera-frame";
import { ToastQueue } from "@react-spectrum/toast";
import { useMessagingContext } from "@shared/client";
import { JanusRoutes } from "@shared/common/src/routes/JanusRoutes";
import Home from "@spectrum-icons/workflow/Home";
import { useState, useEffect, createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, matchPath, useLocation } from "react-router-dom";

import { MicrophoneInput } from "../components/MicrophoneInput";
import { OpenInVR } from "../components/OpenInVR";
import { PresenceBubbles } from "../components/PresenceBubbles";
import { ShareSheetView } from "../components/ShareSheet";
import { StudioView } from "../components/StudioView";
import { UserProfileView } from "../components/UserProfileView";
import { usePinningSession } from "../hooks/usePinningSession";
import { AssetNotFoundView } from "@src/components/AssetNotFoundView";
import { CommentInfoPanel } from "@src/components/CommentInfoPanel";
import { NavigationPanel } from "@src/components/NavigationPanel";
import { OnboardingCoachMark } from "@src/components/OnboardingCoachMark";
import { SoloModeBanner } from "@src/components/SoloModeBanner";
import { VisibilityOptions } from "@src/components/VisibilityOptions";
import { getAppPath } from "@src/config";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import {
    useNetworkContext,
    type JoinReviewOptions,
} from "@src/contexts/NetworkContext";
import { OnboardingProvider } from "@src/contexts/OnboardingContext";
import { PinContextProvider } from "@src/contexts/PinContext";
import { useAccessCheck } from "@src/hooks/useAccessCheck";
import { useAudioControls } from "@src/hooks/useAudioControls";
import { useEnvironmentBuilder } from "@src/hooks/useEnvironmentBuilder";
import { useNetworking } from "@src/hooks/useNetworking";
import { useRedirects } from "@src/hooks/useRedirects";
import { useSceneEnvironment } from "@src/hooks/useSceneEnvironment";
import { CommentsApi } from "@src/lib/bus-api/CommentsApi";
import { EnterReviewDialog } from "@src/pages/dialogs/EnterReviewDialog";
import { NavigationMode } from "@src/util/PanelUtils";

interface SceneContextValue {
    sceneInstance: any;
}

export const SceneContext = createContext<SceneContextValue>(
    {} as SceneContextValue,
);

export function useSceneContext() {
    const context = useContext(SceneContext);
    if (!context) {
        throw new Error(
            "useSceneContext must be used within a SceneContext.Provider",
        );
    }
    return context;
}

export function TwoUp() {
    const { t } = useTranslation("web");
    const { reviewModalRedirect, homeRedirect } = useRedirects();
    const { showModal } = useMessagingContext();

    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("asset-id") || "";
    const accountLinked = searchParams.get("accountLinked") || "";
    const commentApi = useMemo(() => new CommentsApi(assetId || ""), [assetId]);
    const shouldShowShareSheet = searchParams.get("share") || undefined;

    const { accessToken, userProfile, avatarUrl, logIn, userId } =
        useHi5UserContext();
    const { useReviewListItem, useGlbUrl } = useAcpContext();
    const { isAccessIssue } = useAccessCheck(assetId);

    const { data: reviewItem, error: reviewItemError } =
        useReviewListItem(assetId);
    const { data: glbUrl } = useGlbUrl(assetId);

    const [temporaryNavigationMode, setTemporaryNavigationMode] =
        useState<NavigationMode>();

    const [sceneManager, setSceneManager] = useState<SceneManager>();
    const envState = useSceneEnvironment(assetId);
    const [sceneInstance, setSceneInstance] = useState<any>();
    const [viewerInstance, setViewerInstance] = useState<
        AdobeViewer | undefined
    >();
    const pinningSession = usePinningSession(
        commentApi,
        viewerInstance,
        assetId,
    );

    const networkContext = useNetworkContext();
    const { networkManager } = networkContext;
    const userReadyToJoin = !matchPath(JanusRoutes.twoUpModal, getAppPath());

    const { state } = useLocation();
    const isSolo = state?.isSolo || false;

    const { envBuilder } = useEnvironmentBuilder(
        sceneManager,
        envState,
        !isSolo,
    );

    useEffect(() => {
        if (!assetId || !reviewItem || !networkManager) return;
        if (!networkManager.isJoinedLobby() && accountLinked === "false") {
            reviewModalRedirect(assetId);
        }

        if (!userReadyToJoin) {
            showModal(
                <EnterReviewDialog
                    assetId={assetId}
                    assetName={reviewItem.displayName}
                />,
            );
        }
    }, [assetId, reviewItem, networkManager, userReadyToJoin, accountLinked]);

    useEffect(() => {
        if (isSolo && mediaStream) {
            mediaStream?.getAudioTracks().forEach((track) => track.stop());
        }
    }, [isSolo]);

    const joinReviewOptions: JoinReviewOptions = {
        isSolo,
        pinningSession,
        assetId,
        viewer: viewerInstance,
        displayName: userProfile?.displayName ?? "",
        avatarUrl,
        envBuilder,
    };

    useNetworking(joinReviewOptions, accessToken, userReadyToJoin, envBuilder);

    useEffect(() => {
        if (reviewItemError && !isAccessIssue) {
            showModal(
                <View height="256px">
                    <AssetNotFoundView onGoHome={back} />
                </View>,

                { isDismissable: false },
            );
        }
    }, [reviewItemError, isAccessIssue]);

    useEffect(() => {
        if (!networkManager) return;

        const handleDupeUserEvt = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;
            if (event.data.type === "duplicateUserDetected") {
                networkManager.leaveRoom();
                homeRedirect();
                ToastQueue.negative(`${t("web:toast.review.duplicateUser")}`, {
                    timeout: 5000,
                });
            }
        };

        window.addEventListener("message", handleDupeUserEvt);
        return () => {
            window.removeEventListener("message", handleDupeUserEvt);
        };
    }, [networkManager]);

    function back() {
        homeRedirect();
    }

    const [isShareSheetOpen, setIsShareSheetOpen] =
        useState<boolean>(!!shouldShowShareSheet);
    function share() {
        return (
            <>
                <Button
                    id={`Coachmark-shareButton`}
                    variant="cta"
                    isDisabled={!reviewItem}
                    onPress={() => setIsShareSheetOpen(true)}>
                    <Text>{t("actions.share")}</Text>
                </Button>
                <DialogContainer onDismiss={() => setIsShareSheetOpen(false)}>
                    {isShareSheetOpen && (
                        <View width="size-5000" maxHeight="size-6000">
                            {assetId && reviewItem && (
                                <ShareSheetView
                                    assetUrn={assetId}
                                    assetName={reviewItem?.displayName}
                                    closeShareViewFunction={() =>
                                        setIsShareSheetOpen(false)
                                    }
                                />
                            )}
                        </View>
                    )}
                </DialogContainer>
            </>
        );
    }

    function logInButton() {
        return (
            <Button variant="cta" onPress={logIn}>
                <Text>{t("actions.signIn")}</Text>
            </Button>
        );
    }

    function openInVr() {
        return (
            <OpenInVR
                assetUrn={assetId}
                joinReviewOptions={joinReviewOptions}
            />
        );
    }

    const {
        audioDevices,
        mediaStream,
        currentAudioDeviceId,
        muted,
        setCurrentAudioDeviceId,
        setMuted,
        authorizeMic,
    } = useAudioControls(isSolo);

    function frame() {
        return (
            <TooltipTrigger>
                <ActionButton
                    isQuiet
                    onPress={() => {
                        if (sceneManager) {
                            sceneManager.frame();
                        }
                    }}>
                    <CameraFrame
                        aria-label={t("accessibility.actions.frame")}
                        size="S"
                    />
                </ActionButton>
                <Tooltip>{t("nav.tooltip.frame")}</Tooltip>
            </TooltipTrigger>
        );
    }

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            switch (e.code) {
                case "Space":
                    setTemporaryNavigationMode("pointer");
                    networkManager?.activateLaserPointerMode();
                    networkManager?.turnOnLaserPointer();
            }
        };

        const handleKeyUp = (e: KeyboardEvent) => {
            switch (e.code) {
                case "Space":
                    setTemporaryNavigationMode(undefined);
                    networkManager?.deactivateLaserPointerMode();
                    networkManager?.turnOffLaserPointer();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    return (
        <PinContextProvider>
            <OnboardingProvider>
                <SceneContext.Provider value={{ sceneInstance }}>
                    <Flex direction="column" width="100%" height="100%">
                        <View
                            backgroundColor="gray-200"
                            borderBottomColor="gray-75"
                            borderBottomWidth="thick"
                            height="size-800"
                            paddingX="2vw">
                            <Flex
                                direction="row"
                                width="100%"
                                height="100%"
                                alignItems="center"
                                justifyContent="space-between">
                                <View>
                                    <Flex
                                        direction="row"
                                        justifyContent="start"
                                        alignItems="center"
                                        gap="size-300">
                                        <TooltipTrigger>
                                            <ActionButton
                                                onPress={back}
                                                isQuiet>
                                                <Home
                                                    aria-label="back"
                                                    size="S"
                                                />
                                            </ActionButton>
                                            <Tooltip>
                                                {t("nav.tooltip.home")}
                                            </Tooltip>
                                        </TooltipTrigger>
                                        <View>
                                            <Heading level={4}>
                                                {reviewItem?.displayName}
                                            </Heading>
                                        </View>
                                        <Divider
                                            orientation="vertical"
                                            size="M"
                                            UNSAFE_style={{
                                                height: "40px",
                                                marginTop: "10px",
                                            }}
                                        />
                                        <View>{frame()}</View>
                                        <VisibilityOptions
                                            isSolo={isSolo}
                                            commentsApi={commentApi}
                                        />
                                        <MicrophoneInput
                                            audioDevices={audioDevices}
                                            muted={muted}
                                            currentAudioDeviceId={
                                                currentAudioDeviceId
                                            }
                                            authorizeMic={authorizeMic}
                                            setMuted={(on) => setMuted(!on)}
                                            setDevice={setCurrentAudioDeviceId}
                                            isSolo={isSolo}
                                        />
                                    </Flex>
                                </View>
                                <View>
                                    <Flex gap="size-200">
                                        {!isSolo && (
                                            <PresenceBubbles
                                                muted={muted}
                                                device={currentAudioDeviceId}
                                                mediaStream={mediaStream}
                                            />
                                        )}
                                        {openInVr()}
                                        {userProfile ? share() : logInButton()}
                                        <UserProfileView
                                            userProfile={userProfile}
                                            avatarUrl={avatarUrl}
                                            trimStrings={true}
                                            isCompactView={true}
                                            showTourOption={true}
                                        />
                                    </Flex>
                                </View>
                            </Flex>
                        </View>
                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            height="100%">
                            <div
                                style={{
                                    width: "100%",
                                    height: "calc(100vh - 64px)",
                                }}>
                                <Flex
                                    direction="row"
                                    width="100%"
                                    height="100%">
                                    <View
                                        key="left-nav"
                                        backgroundColor="gray-100"
                                        height="100%"
                                        width="48px"
                                        borderStartColor="gray-75"
                                        borderStartWidth="thick"
                                        paddingTop="size-100"
                                        paddingX="size-75">
                                        {sceneManager && (
                                            <NavigationPanel
                                                sceneManager={sceneManager}
                                                temporaryNavigationMode={
                                                    temporaryNavigationMode
                                                }
                                            />
                                        )}
                                    </View>
                                    <div
                                        key="viewer"
                                        style={{
                                            flex: 1,
                                            height: "100%",
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                        }}>
                                        {isSolo && (
                                            <SoloModeBanner
                                                key="banner"
                                                userId={userId}
                                            />
                                        )}
                                        {envState && glbUrl && (
                                            <StudioView
                                                key="studio"
                                                modelUrl={glbUrl}
                                                setSceneInstance={
                                                    setSceneInstance
                                                }
                                                setViewerInstance={
                                                    setViewerInstance
                                                }
                                                setSceneManager={
                                                    setSceneManager
                                                }
                                            />
                                        )}
                                    </div>
                                    <CommentInfoPanel
                                        assetId={assetId}
                                        accessToken={accessToken}
                                        commentApi={commentApi}
                                        pinningSession={pinningSession}
                                    />
                                </Flex>
                            </div>
                            <OnboardingCoachMark />
                        </Flex>
                    </Flex>
                </SceneContext.Provider>
            </OnboardingProvider>
        </PinContextProvider>
    );
}
