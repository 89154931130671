/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ShareDialog, ShareSheetProvider } from "@ccx-public/ccx-share-sheet";
import { useMessagingContext } from "@shared/client";
import { useEffect, useState } from "react";

import { useLocaleContext } from "../contexts/LocaleContext";
import { useThemeContext } from "../contexts/ThemeContext";
import { ImsData, useShareSheet } from "../hooks/useShareSheet";
import {
    ASSET_INVITE_NOTIFICATION,
    DEFAULT_SHARE_INVITATION_DIALOG_DATA,
} from "../types/CCACData";
import { getReviewPath } from "../util/PathUtils";
import { ASSET_APIS, IMS_ENV, HI5_API_KEY, getHref } from "@src/config";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

import type {
    ShareSheetAssetData,
    ShareSheetInviteDialogData,
} from "../types/CCACData";
import type { FC, PropsWithChildren } from "react";

/**
 * This file provides access to the share/invite dialog.
 * See this integration guide for more detail:
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/integration.md
 */

/**
 * Invitation API versions.
 * Require V4 to update link settings directly in the share dialog.
 */
const INVITATION_API_VERSION4 = "V4";

/**
 * Default share sheet dialog configuration.
 */
const SHARE_SHEET_CONFIG = {
    invitationAPIVersion: INVITATION_API_VERSION4,
    analyticsEnabled: false,
    disablePublicAccess: false,
    availableRoles: ["VIEW"],
    showHeader: true,
    showCloseButton: true,
    showSettings: false,
    showLinks: true,
    enablePassword: true,
    enablePublicEditor: false, // Enables can edit/comment dropdown in Link Footer.
    showFullWidthCopyButton: false,
};

/**
 * This interface defines the properties passed to ShareSheetView.
 * dialogId is the dialog to open. This is either "invite" or "share" and
 * sets the openedDialog for ShareSheetProviderProps.
 * Note: using asset data id so we can query for its data.
 */
export interface ShareSheetViewProps {
    assetUrn: string;
    assetName: string;
    closeShareViewFunction?: VoidFunction;
    hideCloseButton?: boolean;
}

export const ShareSheetView: FC<PropsWithChildren<ShareSheetViewProps>> = ({
    assetUrn,
    assetName,
    closeShareViewFunction,
    hideCloseButton,
}) => {
    const { isDarkMode } = useThemeContext();
    const { locale } = useLocaleContext();
    const { accessToken, userProfile } = useHi5UserContext();
    const { queryClient } = useAcpContext();
    const { closeModal } = useMessagingContext();

    let imsData: ImsData | undefined = undefined;

    if (accessToken && userProfile) {
        imsData = {
            id: userProfile.userId,
            token: accessToken,
            email: userProfile.email,
            name: userProfile.displayName,
        };
    }

    const { analyticsData, shareAssetData, shareSheetDialogId } = useShareSheet(
        assetUrn,
        assetName,
        imsData,
    );

    const [updatedShareAssetData, setUpdatedShareAssetData] =
        useState<ShareSheetAssetData>();

    useEffect(() => {
        if (assetUrn && shareAssetData) {
            const reviewPath = getHref(getReviewPath(assetUrn));
            const updatedAssetData = {
                ...shareAssetData,
                // resourceId is the share for review resource. If null or undefined, createArtifact will be called to
                // generate the resource for share-for-review workflow.
                resourceId: `${ASSET_APIS.hostAcp}/content/storage/id/${assetUrn}/`,
                // resourceViewUrl is the custom link that take the invitees to the asset.
                // This defaults to asset web view.
                // resourceViewUrl: `https://assets-stage.adobecc.com/files?dialog=notifications`,
                resourceViewUrl: reviewPath,
            };
            setUpdatedShareAssetData(updatedAssetData);
        }
    }, [assetUrn, shareAssetData]);

    //TODO: update TwoUp to use showModal / closeModal and remove closeShareViewFunction
    const handleClose = () => {
        queryClient.invalidateQueries({
            queryKey: ["collaboratorCount", assetUrn],
        });
        closeShareViewFunction?.();
        closeModal();
    };

    const handleOpened = (data: any) => {
        const { openedDialog } = data;
        console.log(`ShareSheet dialog '${openedDialog}' opened.`);
    };

    const shareInviteTemplateData = DEFAULT_SHARE_INVITATION_DIALOG_DATA;
    shareInviteTemplateData.emailNotification.sharing = {
        viewer: ASSET_INVITE_NOTIFICATION,
        editor: ASSET_INVITE_NOTIFICATION,
    };
    const inviteDialogData: ShareSheetInviteDialogData = {
        ...shareInviteTemplateData,
        disableLinkAccess: !SHARE_SHEET_CONFIG.showLinks,
        disablePublicAccess: SHARE_SHEET_CONFIG.disablePublicAccess,
        availableRoles: SHARE_SHEET_CONFIG.availableRoles,
    };

    // The simple 'invite' dialog can be shown using the latest ShareDialog API.
    // The openedDialog is required only with "ShareSheetDialog" which will be deprecated.
    // To get a similar dialog to the old 'invite', set both link footer and show settings to false.
    // ShareDialog is used for "Invite to edit" and ArtifactDialog is used for "Share for Review".
    // The behanceClientId is needed in order to show the avatars.
    // The peopleGraphApiKey is needed in order to request collaborator suggestions from pgc server.
    // The reCaptchaSiteKey is needed to minimize spamming.
    return updatedShareAssetData?.id
        ? updatedShareAssetData && imsData && (
              <ShareSheetProvider
                  assetData={updatedShareAssetData}
                  analyticsData={analyticsData}
                  analyticsEnabled={SHARE_SHEET_CONFIG.analyticsEnabled}
                  environment={IMS_ENV === "prod" ? "prod" : "stage"}
                  idpClientId={HI5_API_KEY}
                  imsData={imsData}
                  invitationServiceApiKey={HI5_API_KEY}
                  storageRepositoryApiKey={HI5_API_KEY}
                  inviteApiVersion={SHARE_SHEET_CONFIG.invitationAPIVersion}
                  behanceClientId={HI5_API_KEY}
                  peopleGraphApiKey={HI5_API_KEY}
                  theme={isDarkMode ? "darkest" : "light"}
                  locale={locale}
                  enablePassword={SHARE_SHEET_CONFIG.enablePassword}
                  showFullWidthCopyButton={
                      SHARE_SHEET_CONFIG.showFullWidthCopyButton
                  }
                  enablePublicEditor={SHARE_SHEET_CONFIG.enablePublicEditor}
                  onDialogOpen={handleOpened}
                  openedDialog={shareSheetDialogId}>
                  <ShareDialog
                      showHeader={SHARE_SHEET_CONFIG.showHeader}
                      showSettingsButton={SHARE_SHEET_CONFIG.showSettings}
                      showCloseButton={
                          hideCloseButton
                              ? false
                              : SHARE_SHEET_CONFIG.showCloseButton
                      }
                      useNewLinkFooter={SHARE_SHEET_CONFIG.showLinks}
                      inviteDialogData={inviteDialogData}
                      onClose={handleClose}
                  />
              </ShareSheetProvider>
          )
        : null;
};
