/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Tabs,
    TabList,
    TabPanels,
    View,
    Button,
    Item,
} from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { VRPreferences } from "./VRPreferences";
import { UserProfileView } from "../components/UserProfileView";
import { usePostMessageContext } from "../contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

import type { Key } from "@react-types/shared";

export enum AppSettingsTabType {
    account = "account",
    preferences = "preferences",
    info = "info",
    home = "home",
}

export type Preferences = {
    lang: string;
    uiSize: string;
    controller: string;
    snapTurn: string;
    haptics: boolean;
    locomotion: boolean;
    autoOrient: boolean;
    preset: string;
    resolution: boolean;
    spaceWarp: boolean;
    customResolution: string;
};

interface AppSettingsTabsViewProps {
    selectedSettingsTab: Key;
    setSelectedSettingsTab: (tab: Key) => void;
}

export function AppSettingsTabsView({
    selectedSettingsTab,
    setSelectedSettingsTab,
}: AppSettingsTabsViewProps) {
    const { userProfile, avatarUrl } = useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();

    const { t } = useTranslation("common");
    const tab = searchParams.get("tab") || "";

    useEffect(() => {
        if (selectedSettingsTab == AppSettingsTabType.preferences) {
            postMessageBusApi?.resizeWindow("app-settings-preferences");
        } else if (selectedSettingsTab == AppSettingsTabType.account) {
            postMessageBusApi?.resizeWindow("app-settings-account");
        } else if (selectedSettingsTab == AppSettingsTabType.info) {
            //todo
        }
    }, [selectedSettingsTab]);

    function logOutAndClearCookies() {
        if (postMessageBusApi) {
            postMessageBusApi.signOutVR();
        }
    }

    const [preferences, setPreferences] = useState<Preferences>({
        lang: "",
        uiSize: "",
        controller: "",
        snapTurn: "",
        haptics: false,
        locomotion: false,
        autoOrient: false,
        preset: "",
        resolution: false,
        spaceWarp: false,
        customResolution: "",
    });

    if (postMessageBusApi) {
        postMessageBusApi.addEventListener(
            "init-app-settings",
            (data: any) => {
                if (data.detail.lang.key) {
                    console.log(
                        "Got language",
                        JSON.stringify(data.detail.lang.key),
                    );
                }

                console.log("initAppSettings", JSON.stringify(data.detail));

                setPreferences({
                    ...preferences,
                    lang: data.detail.lang.key,
                    uiSize: data.detail.uiSize.key,
                    controller: data.detail.controller.key,
                    snapTurn: data.detail.snapTurn.key,
                    haptics: data.detail.haptics.val == "On",
                    locomotion: data.detail.locomotion.val == "On",
                    autoOrient: data.detail.autoOrient.val == "On",
                    preset: data.detail.preset.key,
                    resolution: data.detail.resolution.val == "On",
                    spaceWarp: data.detail.spaceWarp.val == "On",
                    customResolution: data.detail.customResolution.key,
                });
            },
            false,
        );
    }

    function accountView() {
        return (
            <View paddingY="25px">
                <UserProfileView
                    userProfile={userProfile}
                    avatarUrl={avatarUrl}
                    isForVr={true}
                />
                <View paddingTop="30px">
                    <Button
                        height="4.5vh"
                        onPress={() => {
                            logOutAndClearCookies();
                        }}
                        variant="secondary"
                        UNSAFE_style={{
                            borderRadius: "100px",
                            padding: "0 45px",
                        }}>
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.5em",
                            }}>
                            {t("account.signOut")}
                        </Text>
                    </Button>
                </View>
            </View>
        );
    }

    function preferencesView() {
        return (
            <VRPreferences
                preferences={preferences}
                setPreferences={setPreferences}
            />
        );
    }

    return (
        <>
            {userProfile && tab == "" ? (
                <Tabs
                    selectedKey={selectedSettingsTab}
                    onSelectionChange={setSelectedSettingsTab}>
                    <Flex direction="row" alignItems="start" wrap>
                        <TabList>
                            <Item key={AppSettingsTabType.preferences}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.5em",
                                    }}>
                                    {t("account.preferences")}
                                </Text>
                            </Item>
                            <Item key={AppSettingsTabType.account}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "1.5em",
                                    }}>
                                    {t("account.account")}
                                </Text>
                            </Item>
                        </TabList>
                    </Flex>
                    <TabPanels>
                        <Item key={AppSettingsTabType.preferences}>
                            {preferencesView()}
                        </Item>
                        <Item key={AppSettingsTabType.info}>
                            <View>
                                <Text>Info</Text>
                            </View>
                        </Item>
                        <Item key={AppSettingsTabType.account}>
                            {accountView()}
                        </Item>
                    </TabPanels>
                </Tabs>
            ) : (
                preferencesView()
            )}
        </>
    );
}
