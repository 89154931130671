/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Text,
    Flex,
    Avatar,
    View,
    Badge,
    Item,
    ActionButton,
    Tooltip,
    TooltipTrigger,
    MenuTrigger,
    Menu,
} from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import Crosshairs from "@spectrum-icons/workflow/Crosshairs";
import More from "@spectrum-icons/workflow/More";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MicMuted } from "@src/components/custom-icons/MicIcon";
import { useNetworkContext } from "@src/contexts/NetworkContext";
import { AFK_COLOR } from "@src/util/AvatarUtils";
import { DetectSpeech } from "@src/util/DetectSpeech";

interface PresenceBubblesProps {
    muted: boolean;
    device: string;
    mediaStream: MediaStream | undefined;
}

const maxUsersDisplayed = 4;

export function PresenceBubbles({
    muted,
    device,
    mediaStream,
}: PresenceBubblesProps) {
    const { t } = useTranslation("common");
    const { networkManager, voiceManager } = useNetworkContext();

    const [colorsMap, setColorsMap] = useState<Record<number, string>>({});
    const [talkingFlagsMap, setTalkingFlagsMap] = useState<
        Record<number, boolean>
    >({}); // mapping of actors to their talking status
    const [idleFlagsMap, setIdleFlagsMap] = useState<Record<number, boolean>>(
        {},
    ); // mapping of actors to their talking status
    const [mutedFlagsMap, setMutedFlagsMap] = useState<Record<number, boolean>>(
        {},
    ); // mapping of actors to their muted status

    useEffect(() => {
        if (!networkManager || !networkManager.avatarsManager) return;
        const avatarsManager = networkManager.avatarsManager;

        avatarsManager.on("color", (data) => {
            const { user, color } = data;
            console.log("color assigned", user, color);
            if (color == null || color === "") {
                setColorsMap((prev) => {
                    const updated = { ...prev };
                    delete updated[user];
                    return updated;
                });
            } else {
                setColorsMap((prev) => ({
                    ...prev,
                    [user]: color,
                }));
            }
        });

        avatarsManager.on("isIdle", (data) => {
            const { user, isIdle } = data;
            setIdleFlagsMap((prev) => ({
                ...prev,
                [user]: isIdle,
            }));
        });

        avatarsManager.on("isMuted", (data) => {
            const { user, isMuted } = data;
            if (mutedFlagsMap[user] !== isMuted) {
                setMutedFlagsMap((prev) => ({
                    ...prev,
                    [user]: isMuted,
                }));
            }
        });

        avatarsManager.on("isTalking", (data) => {
            const { user, isTalking } = data;
            if (!mutedFlagsMap[user]) {
                setTalkingFlagsMap((prev) => ({
                    ...prev,
                    [user]: isTalking,
                }));
            }
        });

        return () => {
            networkManager.avatarsManager?.removeAllListeners();
        };
    }, [networkManager, networkManager?.avatarsManager]);

    // run local speech detection when microphone is on
    useEffect(() => {
        if (!networkManager || !mediaStream) return;
        const actor = networkManager.myActor().actorNr ?? 0;

        let speechDetector: DetectSpeech;
        if (mediaStream && !muted) {
            speechDetector = new DetectSpeech(mediaStream);
            speechDetector.on("SPEAKING", (isCurrentlySpeaking) => {
                setTalkingFlagsMap((prev) => ({
                    ...prev,
                    [actor]: isCurrentlySpeaking,
                }));
                networkManager?.setIsTalkingFlag(isCurrentlySpeaking);
            });
        } else {
            setTalkingFlagsMap((prev) => ({
                ...prev,
                [actor]: false,
            }));
            networkManager.setIsTalkingFlag(false);
        }

        return () => {
            if (speechDetector) {
                speechDetector.dispose();
            }
        };
    }, [muted, mediaStream, networkManager]);

    useEffect(() => {
        // <-- new way to toggle mic
        voiceManager?.toggleMic(!muted, device);

        networkManager?.setIsMutedFlag(muted);
    }, [muted]);

    function handleDropdownSelection(
        selection: string | number,
        actor: Photon.LoadBalancing.Actor,
    ) {
        switch (selection) {
            case "teleport":
                networkManager?.onTeleportStart(actor);
        }
    }

    function renderLimitedAvatarView(actors: Photon.LoadBalancing.Actor[]) {
        return (
            <View>
                <Flex direction="row">
                    {actors
                        .filter((actor) => {
                            const isCurrentUser =
                                actor.actorNr ===
                                networkManager?.myActor().actorNr;
                            return !isCurrentUser;
                        })
                        .map((actor) => {
                            return renderAvatarPresenceBubble(actor);
                        })}
                </Flex>
            </View>
        );
    }

    function renderAvatarPresenceBubble(actor: Photon.LoadBalancing.Actor) {
        const overlapOffset = "-8px";
        const { avatarUrl, platform } = actor.getCustomProperties();
        return (
            <View>
                <TooltipTrigger placement="bottom" delay={0}>
                    <ActionButton
                        isQuiet
                        UNSAFE_style={{
                            zIndex: 1,
                            marginRight: overlapOffset,
                            borderRadius: "50%",
                        }}>
                        <Avatar
                            key={actor.actorNr}
                            size="avatar-size-400"
                            alt={t("accessibility.account.profilePicture")}
                            src={avatarUrl}
                            UNSAFE_style={{
                                border: "2px solid #303030",
                                boxShadow: `0 0 0 2px ${talkingFlagsMap[actor.actorNr] ? `white` : idleFlagsMap[actor.actorNr] ? AFK_COLOR : colorsMap[actor.actorNr]}`,
                            }}
                        />
                    </ActionButton>
                    <Tooltip>
                        <Text>{actor.name}</Text>
                        {platform === "VR" && (
                            <Badge
                                variant="neutral"
                                UNSAFE_style={{
                                    left: 3,
                                    borderRadius: "30px",
                                    backgroundColor:
                                        "var(--spectrum-global-color-gray-500)",
                                    padding: "1px",
                                }}>
                                {"VR"}
                            </Badge>
                        )}
                    </Tooltip>
                </TooltipTrigger>
            </View>
        );
    }

    function renderAvatarDropDownItem(
        actor: Photon.LoadBalancing.Actor | undefined,
    ) {
        if (!actor) return;
        const { avatarUrl, platform } = actor.getCustomProperties();
        const currentUser = networkManager?.myActor().actorNr || 0;
        const isCurrentUser = actor.actorNr === currentUser;
        const isCurrentUserTeleporting =
            networkManager?.isUserInTeleport(currentUser);
        const isActorTeleporting = networkManager?.isUserInTeleport(
            actor.actorNr,
        );
        return (
            <div
                style={{
                    width: "100%",
                    justifyContent: "start",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: mutedFlagsMap[actor.actorNr]
                            ? "space-between"
                            : "start",
                        width: "100%",
                    }}
                    key={actor.actorNr}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            flexGrow: "1",
                        }}>
                        <Avatar
                            src={avatarUrl}
                            alt={t("accessibility.account.profilePicture")}
                        />
                        <Text
                            flexShrink={0}
                            UNSAFE_style={{
                                maxWidth: "fit-content",
                                paddingInlineEnd: "0px",
                                fontWeight: talkingFlagsMap[actor.actorNr]
                                    ? "bold"
                                    : "normal",
                            }}>
                            {actor.name}
                        </Text>
                        {isCurrentUser ? (
                            <Text
                                UNSAFE_style={{
                                    maxWidth: "fit-content",
                                    color: "var(--spectrum-global-color-gray-500)",
                                    order: 2,
                                }}>
                                You
                            </Text>
                        ) : (
                            platform === "VR" && (
                                <Badge
                                    variant="neutral"
                                    height={15}
                                    UNSAFE_style={{
                                        order: 2,
                                        borderRadius: "30px",
                                        padding: "3px",
                                    }}>
                                    <Text alignSelf={"center"}>{"VR"}</Text>
                                </Badge>
                            )
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                        {(isCurrentUser
                            ? muted
                            : mutedFlagsMap[actor.actorNr]) && (
                            <MicMuted size="XS" />
                        )}
                        {!isCurrentUser &&
                            !isCurrentUserTeleporting &&
                            !isActorTeleporting && (
                                // TODO: render if not current user and not "away"
                                <MenuTrigger align="start" direction="right">
                                    <ActionButton
                                        isQuiet
                                        onPress={() => {
                                            console.log(
                                                "Clicked on " + actor.name,
                                            );
                                            // networkManager?.onTeleportStart(actor);
                                        }}>
                                        <More />
                                    </ActionButton>
                                    <Menu
                                        onAction={(key) =>
                                            handleDropdownSelection(key, actor)
                                        }>
                                        <Item
                                            key="teleport"
                                            textValue={`teleport-${actor.actorNr}`}>
                                            <Crosshairs />
                                            <Text>{"Teleport To"}</Text>
                                        </Item>
                                    </Menu>
                                </MenuTrigger>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    function renderAvatarDropDownView() {
        const actors = Object.values(networkManager?.myRoomActors() || {});
        // show local actor on top
        const localActor = actors.find(
            (actor) => actor.actorNr === networkManager?.myActor().actorNr,
        );
        return (
            <Flex direction="row">
                <View>
                    {renderLimitedAvatarView(
                        actors.slice(0, maxUsersDisplayed),
                    )}
                </View>
                <View>
                    <MenuTrigger direction="bottom" closeOnSelect={false}>
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{
                                marginLeft: "12px",
                            }}>
                            <ChevronDown />
                        </ActionButton>
                        <Menu minWidth="300px">
                            {[
                                <Item
                                    key={`dropdown-item-${localActor?.actorNr}`}
                                    textValue={`dropdown-item-${localActor?.actorNr}`}>
                                    <Text>
                                        {renderAvatarDropDownItem(localActor)}
                                    </Text>
                                </Item>,
                                ...actors
                                    .filter((actor) => actor != localActor)
                                    .map((actor) => (
                                        <Item
                                            key={`dropdown-item-${actor.actorNr}`}
                                            textValue={`dropdown-item-${actor.actorNr}`}>
                                            <Text>
                                                {renderAvatarDropDownItem(
                                                    actor,
                                                )}
                                            </Text>
                                        </Item>
                                    )),
                            ]}
                        </Menu>
                    </MenuTrigger>
                </View>
            </Flex>
        );
    }

    const myRoomActorsArray = Object.values(
        networkManager?.myRoomActors() || {},
    );

    return (
        <View>
            <Flex
                direction="row"
                alignItems="center"
                position="relative"
                UNSAFE_style={{
                    marginRight: "12px",
                    marginTop: "2px",
                }}>
                {myRoomActorsArray &&
                    (myRoomActorsArray.length <= 1
                        ? renderLimitedAvatarView(myRoomActorsArray)
                        : renderAvatarDropDownView())}
            </Flex>
        </View>
    );
}
