/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { CubicEase } from "@babylonjs/core/Animations/easing";
import { Camera } from "@babylonjs/core/Cameras/camera";
import { AbstractEngine } from "@babylonjs/core/Engines";
import { Engine } from "@babylonjs/core/Engines/engine";
import { EngineStore } from "@babylonjs/core/Engines/engineStore";
import { Effect } from "@babylonjs/core/Materials/effect";
import { GreasedLineMaterialDefaults } from "@babylonjs/core/Materials/GreasedLine/greasedLineMaterialDefaults";
import { GreasedLineMeshColorMode } from "@babylonjs/core/Materials/GreasedLine/greasedLineMaterialInterfaces";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { RawTexture } from "@babylonjs/core/Materials/Textures/rawTexture";
import { Color3 } from "@babylonjs/core/Maths";
import { Vector3, Matrix } from "@babylonjs/core/Maths/math.vector";
import { GreasedLineBaseMesh } from "@babylonjs/core/Meshes";
import { CreateGreasedLine } from "@babylonjs/core/Meshes/Builders";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { PostProcess } from "@babylonjs/core/PostProcesses/postProcess";
import { Scene } from "@babylonjs/core/scene";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { LinearGradient } from "@babylonjs/gui/2D/controls/gradient/LinearGradient";
import { Line } from "@babylonjs/gui/2D/controls/line";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import EventEmitter from "events";

import DefaultAvatarGrayscale from "@src/assets/images/default-avatar-grayscale.png";
import DefaultAvatar from "@src/assets/images/default-avatar.png";
import {
    NAME_TAG_RATIO,
    NAME_TAG_HEIGHT,
    AFK_COLOR,
    DARK_FOREGROUND,
    renderNameplate,
    getBase64Images,
    lerpAvatarPosition,
    scaleAvatar,
    updateIdleVisual,
    updateMutedVisual,
    updateTalkingVisual,
    setAvatarDirectionArrowVisibility,
    getTexture,
    getNameTagContainer,
    removeMesh,
    calculateScaleForAvatarFinalPosition,
    DEFAULT_COLOR,
} from "@src/util/AvatarUtils";
import {
    lerp,
    scaleMesh,
    scaleMeshToPixelSize,
} from "@src/util/BabylonGUIUtils";

import "@babylonjs/core/Shaders/glowBlurPostProcess.fragment";
import "@babylonjs/core/Shaders/pass.fragment";

import { debounce } from "@src/util/DebounceUtils";

const LOCAL_LASER_POINTER_WIDTH = 6;
const NETWORK_LASER_POINTER_INITIAL_DIAMETER_SIZE = 0.02;
const NETWORK_LASER_POINTER_DOT_PIXEL_SIZE = 10;
const NETWORK_LASER_POINTER_WIDTH_ATTENUATED = 15;

const MOVEMENT_THRESHOLD = 0.0001;
const VELOCITY_THRESHOLD = 0.01;

const MAX_AVATARS_IN_GROUP = 3;
const MIN_AVATAR_GROUP_MESH_SIZE = 200;
const MAX_AVATAR_GROUP_MESH_SIZE = 300;

const LASER_POINTER_GRADIENT_INTERPOLATION_STEPS = 50;
const GRABBER_LASER_POINTER_OPACITY_POINTS = [
    { linePercentage: 0, opacity: 1 },
    { linePercentage: 0.4, opacity: 0.6 },
    { linePercentage: 0.8, opacity: 0.2 },
    { linePercentage: 1, opacity: 0 },
];
const LASER_POINTER_OPACITY_POINTS = [
    { linePercentage: 0, opacity: 0.75 },
    { linePercentage: 0.15, opacity: 0 },
    { linePercentage: 0.6, opacity: 0 },
    { linePercentage: 0.7, opacity: 0.75 },
    { linePercentage: 1, opacity: 0.75 },
];
const LASER_POINTER_LINE_DOT_GAP = 0.015;

type opacityTexturePointType = {
    linePercentage: number;
    opacity: number;
};

interface AvatarsManagerEvents {
    color: [{ user: number; color: string }];
    isIdle: [{ user: number; isIdle: boolean }];
    isMuted: [{ user: number; isMuted: boolean }];
    isTalking: [{ user: number; isTalking: boolean }];
    leave: [user: number];
    join: [user: number];
}

export class AvatarsManager extends EventEmitter<AvatarsManagerEvents> {
    private scene: Scene;
    private camera: Camera | null;
    private engine: AbstractEngine;
    private resizeObserver: ResizeObserver;
    private viewportWidth: number = 0;
    private viewportHeight: number = 0;
    private canvasWidth: number = 0;
    private canvasHeight: number = 0;
    private viewportPixelSize: number = 0;

    private avatarMap: Record<number, Mesh | undefined> = {}; // mapping of actors to their avatar representation
    private remoteAvatarMap: Record<number, Mesh | undefined> = {}; // mapping of actors to their ghost representation; this is not visible to the user and is used to keep track of the users actual 3D position in space
    private colorsMap: Record<number, string | undefined> = {}; // mapping of actors to their assigned colors
    private profilePicMap: Record<number, [string, string] | undefined> = {}; // mapping of actors to their base64 profile picture URLs
    private actorParentMap: Record<number, number | undefined> = {}; // mapping of actors to their parent / group lead to keep track of which group they are in (default is actor to self)
    private actorGroupMap: Record<number, Set<number> | undefined> = {}; //mapping of actors to a set of all actors in a group
    private avatarGroupMeshMap: Record<number, Mesh | undefined> = {}; // mapping of group of actors to their group avatar representation
    private lastUpdateTimeMap: Record<number, number | undefined> = {}; // mapping of actors to their last update time
    private waitingMap: Record<number, boolean | undefined> = {}; // used to throttle updates to avatar positions

    private localPointerLine: Line;
    private localPointerDot: Ellipse;
    localPointerTarget: Vector3 = Vector3.Zero();
    private pointerMeshMap: Record<number, GreasedLineBaseMesh | undefined> =
        {};
    private remotePointerDotMaterial: StandardMaterial;
    private grabbingAssetLaserPointer: GreasedLineBaseMesh | undefined;
    grabbingAssetSourceActorNr: number | undefined;

    private availableColors: string[] = [
        "#008CB8", // Dark Blue
        "#E34850", // Red
        "#EDCC00", // Yellow
        "#4BCCA2", // Turquoise
        "#F69500", // Orange
        "#B247C2", // Magenta
        "#00C7FF", // Light Blue
        "#7E4BF3", // Purple
        "#009112", // Green
        "#A0004D", // Dark Magenta
    ];

    // used for teleport animation
    private fadeLevel: number;

    isNameplateVisible: boolean = true;

    constructor(private viewer: AdobeViewer) {
        super();
        this.scene = viewer.scene;
        this.camera = this.scene.activeCamera;
        this.engine = this.scene.getEngine();

        // Fixes error thrown for invalid engine
        EngineStore._LastCreatedScene = this.scene;
        EngineStore.Instances.push(this.engine);

        this.setCanvasSize();

        // Currently necessary for use of GreasedLine due to
        // https://forum.babylonjs.com/t/greasedline-setpoints-broken/55033/7
        viewer.engine.getCaps().parallelShaderCompile = undefined;

        this.fadeLevel = 1;
        // defining shader for teleport effect
        if (!("fadePixelShader" in Effect.ShadersStore)) {
            Effect.ShadersStore["fadePixelShader"] =
                "precision highp float;" +
                "varying vec2 vUV;" +
                "uniform sampler2D textureSampler; " +
                "uniform float fadeLevel; " +
                "void main(void){" +
                "vec4 baseColor = texture2D(textureSampler, vUV) * fadeLevel;" +
                "baseColor.a = 1.0;" +
                "gl_FragColor = baseColor;" +
                "}";
        }
        const postProcess = new PostProcess(
            "Fade",
            "fade",
            ["fadeLevel"],
            null,
            1.0,
            this.camera,
        );
        postProcess.onApply = (effect) => {
            effect.setFloat("fadeLevel", this.fadeLevel);
        };

        const advancedTexture =
            AdvancedDynamicTexture.CreateFullscreenUI("localPointer");
        const line = new Line();
        line.isVisible = false;
        line.lineWidth = LOCAL_LASER_POINTER_WIDTH;

        line.onDirtyObservable.add(() => {
            const gradient = new LinearGradient(
                line._x1.value,
                line._y1.value,
                line._x2.value,
                line._y2.value,
            );
            gradient.addColorStop(0, "#1379F300");
            gradient.addColorStop(0.25, "#1379F300");
            gradient.addColorStop(0.5, "#1379F3BF");
            gradient.addColorStop(1, "#FFFFFFBF");
            line.gradient = gradient;
        });
        advancedTexture.addControl(line);

        const dot = new Ellipse("localPointerDot");
        dot.isVisible = false;
        dot.background = "#FFFFFFCC";
        dot.color = "#545454CC";
        dot.thickness = 3;
        dot.width = "15px";
        dot.height = "15px";

        advancedTexture.addControl(dot);
        this.localPointerLine = line;
        this.localPointerDot = dot;

        const pointerDotMaterial = new StandardMaterial(
            "remotePointerDotMaterial",
            this.scene,
        );
        pointerDotMaterial.alpha = 1;
        pointerDotMaterial.emissiveColor = new Color3(1, 1, 1);
        this.remotePointerDotMaterial = pointerDotMaterial;

        this.resizeObserver = new ResizeObserver(
            debounce(() => {
                window.requestAnimationFrame(() => {
                    this.setCanvasSize();
                });
            }, 50),
        );
        const canvas = this.engine.getRenderingCanvas();
        canvas && this.resizeObserver.observe(canvas);

        //global defaults for laser pointer attenuation
        GreasedLineMaterialDefaults.DEFAULT_WIDTH = 1;
        GreasedLineMaterialDefaults.DEFAULT_WIDTH_ATTENUATED =
            NETWORK_LASER_POINTER_WIDTH_ATTENUATED;
    }

    setCanvasSize() {
        this.viewportWidth = this.engine.getRenderWidth();
        this.viewportHeight = this.engine.getRenderHeight();
        const canvas = this.engine.getRenderingCanvas();
        this.canvasWidth = canvas?.clientWidth || this.viewportWidth;
        this.canvasHeight = canvas?.clientHeight || this.viewportHeight;
        this.viewportPixelSize = Math.sqrt(
            Math.pow(this.canvasWidth, 2) + Math.pow(this.canvasHeight, 2),
        );
    }

    dispose() {
        this.resizeObserver.disconnect();
    }

    /**
     * @summary creates a new mesh for the avatar representation of an actor in Babylon scene
     *
     * @param actorNr
     * @param displayName
     * @param avatarUrl
     * @param color
     */
    async spawnAvatar(
        actorNr: number,
        displayName: string,
        avatarUrl: string,
        color: string,
        isVR: boolean,
    ) {
        if (displayName === "") {
            console.warn("No display name provided for actor: " + actorNr);
        }
        console.log(
            `spawning avatar: ${actorNr}, ${displayName}, ${color}, ${isVR ? `VR` : `WEB`}`,
        );

        // profile picture
        // NOTE: BabylonJS throws a CORS error when loading the profile picture URL
        // so we convert it to base64 format to bypass the error
        let colorImg = DefaultAvatar;
        let grayscaleImg = DefaultAvatarGrayscale;
        try {
            [colorImg, grayscaleImg] = (await getBase64Images(avatarUrl)) as [
                string,
                string,
            ];
        } catch (error) {
            console.error("Error loading profile picture: ", error);
        }
        this.profilePicMap[actorNr] = [colorImg, grayscaleImg];

        const nameplate = renderNameplate(
            actorNr,
            displayName,
            colorImg,
            color ?? DEFAULT_COLOR,
            isVR,
        );

        this.avatarMap[actorNr] = nameplate;
        this.actorParentMap[actorNr] = actorNr;

        const remoteAvatar = nameplate.clone();
        remoteAvatar.isVisible = false;
        this.remoteAvatarMap[actorNr] = remoteAvatar;
        this.waitingMap[actorNr] = false;
        this.lastUpdateTimeMap[actorNr] = 0;

        scaleAvatar(this.scene, nameplate, this.viewportPixelSize);
        // this.calculateOffscreenVisual(plane);
        this.viewer.renderLoop.toggle(100);
    }

    toggleNameplateVisibility(isVisible?: boolean) {
        this.isNameplateVisible =
            isVisible === undefined ? !this.isNameplateVisible : isVisible;
        for (const actorNr in this.avatarMap) {
            const avatar = this.avatarMap[actorNr];
            if (avatar) {
                avatar.isVisible = this.isNameplateVisible;
            }
        }
        this.viewer.renderLoop.toggle();
    }

    getCameraPosition() {
        if (!this.camera) {
            throw new Error("Camera not found");
        }
        return this.camera.position;
    }

    renderExistingAvatarsAndScale() {
        // scale avatars
        const avatars: Record<number, any> = this.getAvatarMap() ?? [];
        for (const [groupNr, groupSet] of Object.entries(
            this.actorGroupMap ?? {},
        )) {
            const groupMesh = this.avatarGroupMeshMap[Number(groupNr)];
            if (groupMesh && groupSet) {
                const groupLength = Math.min(
                    groupSet.size,
                    MAX_AVATARS_IN_GROUP + 1,
                );
                const meshHeight = NAME_TAG_HEIGHT * (groupLength + 1);
                scaleMesh(
                    groupMesh,
                    this.viewportPixelSize,
                    meshHeight,
                    MIN_AVATAR_GROUP_MESH_SIZE,
                    MAX_AVATAR_GROUP_MESH_SIZE,
                    this.scene,
                    true,
                );
            }
        }
        for (const actorNr in avatars) {
            const avatar = avatars[actorNr];
            if (!avatar) {
                throw new Error(
                    "no avatar found for updating scaling on camera move",
                );
            }
            if (!this.scene) {
                throw new Error("no scene found for updating scaling");
            }
            const scaleFactor = scaleAvatar(
                this.scene,
                avatar,
                this.viewportPixelSize,
                false,
            );
            avatar.scaling = Vector3.One().multiplyByFloats(
                scaleFactor,
                scaleFactor,
                scaleFactor,
            );

            // TODO: temporarily disabling offscreen visual
            // calculate offscreen visual based on remote avatar position
            // this.avatarsManager.calculateOffscreenVisual(
            //     avatar,
            //     remoteAvatar.position,
            // );
            // }
        }
        // scale any visible network laser pointers
        this.scaleNetworkLaserPointers();
    }

    updateAvatarPosition(
        actorNr: number,
        position: number[],
        time?: number,
        objectPosition?: Vector3,
    ) {
        const avatar: Mesh | undefined = this.avatarMap[actorNr];
        const remoteAvatar: Mesh | undefined = this.remoteAvatarMap[actorNr];
        const newPosition = Vector3.FromArray(position);

        // if the avatar moves too little or too fast, don't show the update visually.
        // instead, update the remote avatar position to keep track of the user's actual position
        if (
            remoteAvatar &&
            !remoteAvatar.position.asArray().every((val) => val === 0)
        ) {
            const prevPosition = remoteAvatar.position.clone();

            // filter for small position updates
            const distanceMoved = Vector3.Distance(newPosition, prevPosition);
            if (distanceMoved < MOVEMENT_THRESHOLD && remoteAvatar) {
                remoteAvatar.position = newPosition.clone();
                this.checkAvatarProximity(actorNr);
                return;
            }

            // filter for high velocity movements
            if (time) {
                const velocity =
                    distanceMoved /
                    (time - (this.lastUpdateTimeMap[actorNr] || 0));
                if (velocity > VELOCITY_THRESHOLD && remoteAvatar) {
                    remoteAvatar.position = newPosition.clone();
                    return;
                }
            }
        }

        if (!this.waitingMap[actorNr]) {
            const updateLaserPointerObjectGrabber = () => {
                if (
                    this.grabbingAssetLaserPointer &&
                    this.grabbingAssetSourceActorNr &&
                    objectPosition
                ) {
                    this.displayNetworkLaserObjectGrabber(
                        this.grabbingAssetSourceActorNr,
                        objectPosition,
                    );
                }
            };
            window.requestAnimationFrame(() => {
                if (avatar && remoteAvatar) {
                    const observer = this.scene.onBeforeRenderObservable.add(
                        updateLaserPointerObjectGrabber,
                    );
                    remoteAvatar.position = newPosition.clone();
                    lerpAvatarPosition(
                        avatar,
                        newPosition.clone(),
                        this.scene,
                        this.isNameplateVisible,
                        undefined,
                        () => {
                            this.checkAvatarProximity(actorNr);
                            this.scene.onBeforeRenderObservable.remove(
                                observer,
                            );
                            this.waitingMap[actorNr] = false;
                        },
                    );
                }
            });
            this.waitingMap[actorNr] = true;
        }
    }

    checkAvatarProximity(actorNr: number) {
        for (const existingActorNr of Object.keys(this.remoteAvatarMap)) {
            if (parseInt(existingActorNr) !== actorNr) {
                this.updateAvatarGroups(parseInt(existingActorNr), actorNr);
            }
        }
    }

    isAvatarUpdating(actorNr: number) {
        return this.waitingMap[actorNr];
    }

    removeUserMeshes(actorNr: number) {
        const avatar = this.avatarMap[actorNr];
        removeMesh(avatar);
        delete this.avatarMap[actorNr];

        const remoteAvatar = this.remoteAvatarMap[actorNr];
        removeMesh(remoteAvatar);
        delete this.remoteAvatarMap[actorNr];

        //remove laser pointers
        if (this.grabbingAssetSourceActorNr === actorNr) {
            removeMesh(this.grabbingAssetLaserPointer as Mesh);
            this.grabbingAssetSourceActorNr = undefined;
        }
        const laserPointer = this.pointerMeshMap[actorNr];
        removeMesh(laserPointer as Mesh);
        delete this.pointerMeshMap[actorNr];

        this.viewer.renderLoop.toggle();
    }

    removeUser(actorNr: number) {
        if (this.isAvatarInGroup(actorNr)) {
            this.leaveAvatarGroup(actorNr);
        }

        this.removeUserMeshes(actorNr);
        this.releaseUserColor(actorNr);
    }

    /**
     * @summary assigns a random color to a user from the availableColors list.
     * if the user already has a color assigned, it returns the assigned color.
     * once a color is assigned it gets pushed to the back of the list so that colors are
     * reused after >8 players.
     *
     * @param actorNr
     * @returns color assigned to user
     */
    assignUserColor(actorNr: number) {
        // if user already has a color dont assign a new one
        const userColor = this.colorsMap[actorNr];
        if (userColor) {
            this.emit("color", { user: actorNr, color: userColor });
            return userColor;
        }

        const color = this.availableColors.shift();
        if (color) {
            this.availableColors.push(color);
            this.colorsMap[actorNr] = color;
            this.emit("color", { user: actorNr, color });
            return color;
        } else {
            console.warn(
                "No color assigned, using default color. Available colors: ",
                this.availableColors,
            );
            return undefined;
        }
    }

    /**
     * @summary called when the host assigns a color to a user to update user's local color map
     * @param actorNr actor number of the user whose color is being updated
     * @param color color assigned to the user
     */
    updateUserColor(actorNr: number, color: string) {
        if (color) {
            this.colorsMap[actorNr] = color;
            this.availableColors = this.availableColors.filter(
                (c) => c !== color,
            );
            this.availableColors.push(color);
            this.emit("color", { user: actorNr, color });
        }
    }

    /**
     * @summary called when a user leaves the room; frees up the color assigned to the user
     *
     * @param actorNr actor number of the user who left the room
     */
    releaseUserColor(actorNr: number) {
        const color = this.colorsMap[actorNr];
        if (color) {
            delete this.colorsMap[actorNr];

            this.emit("color", { user: actorNr, color: "" });
        }
    }

    updateIsIdleState(actorNr: number, isIdle: boolean) {
        this.emit("isIdle", { user: actorNr, isIdle });

        const avatar = this.avatarMap[actorNr];
        if (!avatar) {
            console.error("No avatar found for actor: " + actorNr);
            return;
        }

        if (!this.colorsMap[actorNr]) {
            console.error("No avatar color found for actor: " + actorNr);
            return;
        }

        if (!this.profilePicMap[actorNr]) {
            console.error("No avatar profile pic found for actor: " + actorNr);
            return;
        }

        updateIdleVisual(
            this.viewer,
            actorNr,
            avatar,
            isIdle,
            this.colorsMap[actorNr] ?? "",
            this.profilePicMap[actorNr] ?? ["", ""],
        );
    }

    updateIsMutedState(actorNr: number, isMuted: boolean, isVR: boolean) {
        this.emit("isMuted", { user: actorNr, isMuted });

        const avatar = this.avatarMap[actorNr];
        if (!avatar) {
            console.error("No avatar found for actor: " + actorNr);
            return;
        }

        updateMutedVisual(this.viewer, actorNr, avatar, isMuted, isVR);
        if (isMuted) {
            updateTalkingVisual(this.viewer, actorNr, avatar, false);
        }
    }

    /**
     * @summary updates name tag visual based on IsTalking state
     * renders white border when user is talking
     *
     * @param actorNr
     * @param isTalking
     */
    updateIsTalkingState(actorNr: number, isTalking: boolean) {
        this.emit("isTalking", { user: actorNr, isTalking });

        const avatar = this.avatarMap[actorNr];
        if (!avatar) {
            console.error("No avatar found for actor: " + actorNr);
            return;
        }

        updateTalkingVisual(this.viewer, actorNr, avatar, isTalking);
    }

    updateCameraPosition(position: Vector3) {
        if (!this.camera) {
            throw new Error("Camera not found");
        }
        this.camera.position = position;
    }

    /**
     * @summary checks whether an avatar exists for a given actor number
     *
     * @param actorNr
     * @returns boolean indicating whether an avatar exists for the actor number
     */
    hasAvatar(actorNr: number) {
        return this.avatarMap[actorNr];
    }

    getAvatarMap() {
        return this.avatarMap;
    }

    getRemoteAvatar(actorNr: number): Mesh | undefined {
        return this.remoteAvatarMap[actorNr];
    }

    getLocalTeleportEffect(startingTimestamp: number) {
        let elapsed_time = Date.now() - startingTimestamp;
        const easingOutFunc = new CubicEase();
        easingOutFunc.setEasingMode(CubicEase.EASINGMODE_EASEOUT);
        const duration = 500;
        const totalDuration = duration * 2;
        const extraTimeForRender = 100;

        const fade = () => {
            // First half of this animation is fade out, second half is fade in

            elapsed_time = Date.now() - startingTimestamp;
            const isInFadeOut = elapsed_time <= duration;
            this.fadeLevel = isInFadeOut
                ? 1 - easingOutFunc.ease(elapsed_time / duration)
                : easingOutFunc.ease((elapsed_time - duration) / duration);

            if (elapsed_time > totalDuration) {
                this.scene.unregisterAfterRender(fade);
            }
        };
        this.scene.registerAfterRender(fade);
        this.viewer.renderLoop.toggle(totalDuration + extraTimeForRender);
    }

    async getNetworkTeleportEffect(
        startNr: number,
        targetNr: number,
        myActorNr: number,
    ) {
        if (!this.hasAvatar(startNr)) {
            return;
        }
        if (this.isAvatarInGroup(startNr)) {
            window.requestAnimationFrame(() => {
                this.leaveAvatarGroup(startNr);
            });
        }

        console.log("getting network teleporting effect");

        // Setting this.waiting to true to ensure that we don't update the
        // avatar's position back to the original position or to the final position
        // before the animation is completed (due to the other client sending an updated
        // position)
        this.waitingMap[startNr] = true;

        const startAvatar = this.avatarMap[startNr];
        const targetAvatar = this.avatarMap[targetNr];
        const isTeleportingToMyActor = targetNr === myActorNr;

        if (startAvatar && (targetAvatar || isTeleportingToMyActor)) {
            const startPosition = startAvatar.position;

            //experimenting with the duration
            const duration = 2000;
            let updatedDuration = 0;
            const startTime = Date.now();
            const extraTimeForRender = 300;
            const updatePositionFunc = () => {
                const targetRemoteAvatar = this.remoteAvatarMap[targetNr];
                const baseTargetPosition = isTeleportingToMyActor
                    ? this.getCameraPosition()
                    : targetAvatar!.position;
                const baseTargetRemotePosition = isTeleportingToMyActor
                    ? this.getCameraPosition()
                    : targetRemoteAvatar?.position;
                const targetPosition = baseTargetPosition.clone();
                if (isTeleportingToMyActor) {
                    targetPosition.y += 0.25;
                }

                startAvatar.position = Vector3.Lerp(
                    startPosition,
                    targetPosition,
                    updatedDuration / duration,
                );
                if (!isTeleportingToMyActor && baseTargetRemotePosition) {
                    const targetScaling = calculateScaleForAvatarFinalPosition(
                        baseTargetRemotePosition,
                        this.viewportPixelSize,
                        this.scene,
                    );
                    startAvatar.scaling = Vector3.Lerp(
                        startAvatar.scaling,
                        Vector3.One().multiplyByFloats(
                            targetScaling,
                            targetScaling,
                            targetScaling,
                        ),
                        updatedDuration / duration,
                    );
                }

                updatedDuration = Date.now() - startTime;
                if (updatedDuration > duration) {
                    this.scene.unregisterBeforeRender(updatePositionFunc);
                    this.waitingMap[startNr] = false;
                    // temp fix - the avatar position update from the other client may come in during the
                    // time when this.waiting is set to true. Since avatar position updates will be paused
                    // during that time, ensure we set the local avatar position to the final position.
                    // TODO: figure out if there is a better order of events to ensure the final position update
                    // is done after the teleport animation
                    baseTargetRemotePosition &&
                        this.updateAvatarPosition(startNr, [
                            baseTargetRemotePosition.x,
                            baseTargetRemotePosition.y,
                            baseTargetRemotePosition.z,
                        ]);
                }
                this.viewer.renderLoop.toggle(100);
            };
            this.scene.registerBeforeRender(updatePositionFunc);
            this.viewer.renderLoop.toggle(duration + extraTimeForRender);
        }
    }

    updateAvatarGroups(actorA: number, actorB: number) {
        const threshold = 0.05;

        const avatarA = this.remoteAvatarMap[actorA];
        const avatarB = this.remoteAvatarMap[actorB];

        if (!avatarA || !avatarB) return;

        const distance = Vector3.Distance(avatarA.position, avatarB.position);
        const areAandBInGroup = this.areAvatarsInGroup(actorA, actorB);

        if (distance <= threshold && !areAandBInGroup) {
            console.log(`adding avatar ${avatarB.name} to ${avatarA.name}`);
            this.addAvatarToGroupAndCreateMesh(actorA, actorB);
        } else if (distance > threshold && areAandBInGroup) {
            this.leaveAvatarGroup(actorB);
        }
    }

    getAvatarGroupMeshes() {
        return this.avatarGroupMeshMap;
    }

    isAvatarInGroup(actor: number) {
        return actor in this.actorGroupMap || this.findGroup(actor) != actor;
    }

    areAvatarsInGroup(actorA: number, actorB: number) {
        return this.findGroup(actorA) == this.findGroup(actorB);
    }

    findGroup(actorNr: number): number {
        const actorParent = this.actorParentMap[actorNr];
        if (!actorParent) {
            this.actorParentMap[actorNr] = actorNr;
            return actorNr;
        }

        if (actorParent === actorNr) {
            return actorNr;
        }

        return this.findGroup(actorParent);
    }

    addAvatarToGroupAndCreateMesh(actorA: number, actorB: number) {
        if (!this.hasAvatar(actorA) || !this.hasAvatar(actorB)) {
            return;
        }
        const actorAParent = this.findGroup(actorA);
        const actorBParent = this.findGroup(actorB);

        this.actorParentMap[actorBParent] = actorAParent;
        if (actorAParent in this.actorGroupMap) {
            const actorAParentGroup = this.actorGroupMap[actorAParent];
            if (actorAParentGroup) {
                if (actorAParent !== actorBParent) {
                    actorAParentGroup.add(actorBParent);
                }
                if (!actorAParentGroup.has(actorAParent)) {
                    actorAParentGroup.add(actorAParent);
                }
            }
        } else {
            this.actorGroupMap[actorAParent] = new Set([actorA, actorB]);
        }
        this.createAvatarGroupMesh(actorAParent);
    }

    leaveAvatarGroup(actorNr: number) {
        console.log(`${actorNr} leaving the avatar group`);

        const previousParent = this.actorParentMap[actorNr];
        if (previousParent && previousParent in this.actorGroupMap) {
            const groupToLeave =
                this.actorGroupMap[previousParent] || new Set();
            if (groupToLeave.has(actorNr)) {
                groupToLeave.delete(actorNr);
                if (groupToLeave.size === 0) {
                    delete this.actorGroupMap[previousParent];
                    this.removeAvatarGroupMesh(previousParent);
                }
                //If there is one member left, remove them from the group since group should have more than one actor
                else if (groupToLeave.size === 1) {
                    const lastMember = groupToLeave.values().next().value;
                    if (lastMember) {
                        this.actorParentMap[lastMember] = lastMember;
                        const lastMemberAvatar = this.avatarMap[lastMember];
                        if (lastMemberAvatar) {
                            lastMemberAvatar.visibility = 1;
                        }
                        // setAvatarDirectionArrowVisibility(
                        //     this.avatarMap[lastMember],
                        //     true,
                        // );
                        delete this.actorGroupMap[previousParent];
                    }
                    this.removeAvatarGroupMesh(previousParent);
                } else if (
                    groupToLeave.size > 1 &&
                    previousParent === actorNr
                ) {
                    // Migrate other members of the group to a new parent
                    const [newParent] = groupToLeave; // default some group member
                    for (const groupMember of groupToLeave) {
                        this.actorParentMap[groupMember] = newParent;
                    }
                    this.actorGroupMap[newParent] = groupToLeave;
                    delete this.actorGroupMap[previousParent];

                    const groupMesh = this.avatarGroupMeshMap[previousParent];
                    this.avatarGroupMeshMap[newParent] = groupMesh;
                    delete this.avatarGroupMeshMap[previousParent];
                    this.removeAvatarGroupMesh(previousParent);
                    //re-render the group mesh for the updated group
                    this.createAvatarGroupMesh(newParent);
                } else {
                    this.createAvatarGroupMesh(previousParent);
                }
                const avatar = this.avatarMap[actorNr];
                if (avatar) {
                    avatar.visibility = 1;
                }
                // setAvatarDirectionArrowVisibility(
                //     this.avatarMap[actorNr],
                //     true,
                // );
            }
            console.log(
                "updated avatar group map is ",
                this.actorGroupMap[previousParent],
            );
        }
        this.actorParentMap[actorNr] = actorNr;
        this.viewer.renderLoop.toggle(500);
    }

    calculateYInAvatarGroupMesh(
        groupLength: number,
        i: number,
        nameTagHeight: number,
    ): number {
        const overlapAmount = nameTagHeight / 8;
        const middleIndex = (groupLength - 1) / 2;
        const isOddNumberOfTags = groupLength % 2 == 1;
        const numberOfOverlaps =
            i > middleIndex
                ? Math.floor(middleIndex) - i
                : Math.ceil(middleIndex) - i;
        return (
            (isOddNumberOfTags
                ? nameTagHeight * Math.floor(groupLength / 2)
                : nameTagHeight / 2 + nameTagHeight * (groupLength / 2 - 1)) -
            numberOfOverlaps *
                (Math.floor(middleIndex) === i || Math.ceil(middleIndex) === i
                    ? overlapAmount / 2
                    : overlapAmount) -
            i * nameTagHeight
        );
    }

    getLongestNameTagWidth(groupMembers: Set<number>) {
        let longestWidth = 0;
        groupMembers.forEach((groupMemberNr) => {
            const groupMemberAvatar = this.avatarMap[groupMemberNr];
            const advancedTexture =
                groupMemberAvatar && getTexture(groupMemberAvatar);
            const nameTagContainer =
                advancedTexture && getNameTagContainer(advancedTexture);
            const currentWidth = nameTagContainer
                ? nameTagContainer.widthInPixels
                : 0;
            //getting the longest avatar width to calculate the grouping mesh based on that
            longestWidth = Math.max(currentWidth, longestWidth);
        });

        return longestWidth;
    }

    createClonedAvatarForGroup(
        groupMember: Mesh,
        clonedMemberName: string,
        plane: Mesh,
        clonedMemberYPosition: number,
    ) {
        const clonedMember = groupMember.clone(clonedMemberName);
        clonedMember.isVisible = true;
        clonedMember.visibility = 1;
        clonedMember.billboardMode = Mesh.BILLBOARDMODE_NONE;

        //removing the offscreen arrows from showing in the group mesh
        setAvatarDirectionArrowVisibility(clonedMember, false);

        plane.addChild(clonedMember);
        clonedMember.position.x = 0;
        clonedMember.position.y = clonedMemberYPosition;
        clonedMember.position.z = 0;
        clonedMember.renderingGroupId = 3;

        return clonedMember;
    }

    /**
     * @summary creates a new mesh for the group representation of an actors in Babylon Scene
     *
     * @param groupNr
     */
    createAvatarGroupMesh(groupNr: number) {
        console.log("spawning avatar group: ", groupNr);
        console.log("members in the group are: ", this.actorGroupMap[groupNr]);

        //Removing existing group mesh if there is one for the group
        this.removeAvatarGroupMesh(groupNr);

        const groupMembers = this.actorGroupMap[groupNr] || new Set();

        //alphabetical ordering for grouping
        const groupMemberAvatars: Mesh[] = [];
        groupMembers.forEach((groupMemberNr) => {
            const groupMemberAvatar = this.avatarMap[groupMemberNr];
            groupMemberAvatar && groupMemberAvatars.push(groupMemberAvatar);
        });

        if (groupMembers && groupMemberAvatars) {
            const groupLength = Math.min(
                groupMembers.size,
                MAX_AVATARS_IN_GROUP + 1,
            );
            const planeHeight = NAME_TAG_HEIGHT * (groupLength + 1);

            const longestNameTagWidth =
                this.getLongestNameTagWidth(groupMembers);

            const planeWidth = 1;
            const plane = MeshBuilder.CreatePlane(groupNr.toString(), {
                width: planeWidth,
                height: planeHeight,
            });

            plane.isPickable = false;
            plane.billboardMode = Mesh.BILLBOARDMODE_ALL;

            const groupMemberNr = groupMembers.values().next().value;
            let nameTagScaleX = 0;
            let nameTagScaleY = 0;
            let nameTagHeightInPixels = 0;
            if (groupMemberNr) {
                const groupMember = this.avatarMap[groupMemberNr];
                if (groupMember) {
                    plane.position = groupMember.position;

                    const advancedTexture = getTexture(groupMember);
                    const nameTagContainer =
                        getNameTagContainer(advancedTexture);

                    nameTagScaleX = nameTagContainer.scaleX;
                    nameTagScaleY = nameTagContainer.scaleY;
                    nameTagHeightInPixels = nameTagContainer.heightInPixels;
                }
            }

            const planeAdvancedTexture = AdvancedDynamicTexture.CreateForMesh(
                plane,
                1024,
                1024 * planeHeight,
            );

            const imgContainer = new Rectangle("planeImgContainer");
            imgContainer.thickness = 0;
            imgContainer.background = DARK_FOREGROUND;
            imgContainer.cornerRadius = 90;

            imgContainer.widthInPixels =
                nameTagScaleX * (longestNameTagWidth + 25);
            const numberOfOverlaps = groupLength - 1;
            imgContainer.heightInPixels =
                nameTagScaleY *
                (nameTagHeightInPixels * groupLength -
                    (numberOfOverlaps * nameTagHeightInPixels) / 8 +
                    25);

            planeAdvancedTexture.addControl(imgContainer);

            groupMemberAvatars.sort((a, b) => a.name.localeCompare(b.name));

            groupMemberAvatars.forEach((groupMember, i) => {
                if (i < 3) {
                    const clonedMemberName = `${groupNr} - ${groupMemberNr}`;
                    const clonedMemberYPosition =
                        this.calculateYInAvatarGroupMesh(
                            groupLength,
                            i,
                            NAME_TAG_HEIGHT,
                        );
                    this.createClonedAvatarForGroup(
                        groupMember,
                        clonedMemberName,
                        plane,
                        clonedMemberYPosition,
                    );
                }
                groupMember.visibility = 0;
            });

            if (
                groupMemberAvatars.length > 3 &&
                plane.getChildren().length === 3
            ) {
                const overflowAvatar = MeshBuilder.CreatePlane(
                    `${groupNr} - more`,
                    {
                        width: 1,
                        height: 1 / NAME_TAG_RATIO,
                    },
                );
                overflowAvatar.isPickable = false;
                overflowAvatar.billboardMode = Mesh.BILLBOARDMODE_NONE;
                const advancedTexture = AdvancedDynamicTexture.CreateForMesh(
                    overflowAvatar,
                    1024,
                    1024 / NAME_TAG_RATIO,
                );

                const textContainer = new Rectangle("moreTagContainer");
                textContainer.thickness = 0;
                textContainer.background = AFK_COLOR;
                textContainer.cornerRadius = 20;
                textContainer.scaleX = 2.8;
                textContainer.scaleY = 2.8;

                const text = new TextBlock("moreMembers", "+ more");
                text.fontSizeInPixels = 20;
                text.setPadding(8, 30, 8, 30);

                textContainer.adaptHeightToChildren = true;
                textContainer.adaptWidthToChildren = true;
                text.resizeToFit = true;
                text.color = DARK_FOREGROUND;
                text.fontFamily = "adobe-clean";

                textContainer.addControl(text);
                advancedTexture.addControl(textContainer);

                plane.addChild(overflowAvatar);
                overflowAvatar.position.x = 0;
                overflowAvatar.position.y = this.calculateYInAvatarGroupMesh(
                    groupLength,
                    groupLength - 1,
                    NAME_TAG_HEIGHT,
                );
                overflowAvatar.position.z = 0;
                overflowAvatar.renderingGroupId = 3;
            }

            this.avatarGroupMeshMap[groupNr] = plane;

            scaleMesh(
                plane,
                this.viewportPixelSize,
                planeHeight,
                MIN_AVATAR_GROUP_MESH_SIZE,
                MAX_AVATAR_GROUP_MESH_SIZE,
                this.scene,
                true,
            );
            plane.renderingGroupId = 2;

            if (plane.material) {
                plane.material.disableDepthWrite = true;
            }
        }
        this.viewer.renderLoop.toggle();
    }

    removeAvatarGroupMesh(groupNr: number) {
        const groupMesh = this.avatarGroupMeshMap[groupNr];
        if (groupMesh) {
            groupMesh.geometry?.dispose();
            groupMesh.material?.dispose();
            groupMesh.dispose();
            delete this.avatarGroupMeshMap[groupNr];
        }
    }

    disableCamera() {
        if (!this.camera) {
            throw new Error("Camera not found");
        }
        this.camera.detachControl();
    }

    enableCamera() {
        if (!this.camera) {
            throw new Error("Camera not found");
        }
        this.camera.attachControl();
    }

    updateLaserPointerPosition() {
        const ray = this.scene.createPickingRay(
            this.scene.pointerX,
            this.scene.pointerY,
            Matrix.Identity(),
            this.camera,
        );
        const hit = this.scene.pickWithRay(ray);
        const endPosition = hit?.pickedPoint;

        if (!endPosition) {
            return;
        }
        const viewportToCanvasSizeRatio =
            this.viewportHeight / this.canvasHeight;
        this.localPointerTarget = endPosition;

        this.localPointerLine.x1 =
            Math.round(this.canvasWidth / 2) * viewportToCanvasSizeRatio;
        this.localPointerLine.y1 =
            Math.floor(this.canvasHeight) * viewportToCanvasSizeRatio;
        this.localPointerLine.x2 =
            this.scene.pointerX * viewportToCanvasSizeRatio; //endPositionScreenPosition.x;
        this.localPointerLine.y2 =
            this.scene.pointerY * viewportToCanvasSizeRatio; //endPositionScreenPosition.y;

        this.localPointerDot.left =
            (this.scene.pointerX - this.canvasWidth / 2) *
            viewportToCanvasSizeRatio;
        this.localPointerDot.top =
            (this.scene.pointerY - this.canvasHeight / 2) *
            viewportToCanvasSizeRatio;

        this.viewer.renderLoop.toggle();
    }

    activateLaserPointer() {
        if (!this.camera) {
            throw new Error("Camera not found");
        }
        this.camera.detachControl();
    }

    deactivateLaserPointer() {
        if (!this.camera) {
            throw new Error("Camera not found");
        }
        this.camera.attachControl();
        this.localPointerLine.isVisible = false;
        this.localPointerDot.isVisible = false;
    }

    getInterpolatedPoints(points: Array<Vector3>) {
        const interpolatedPoints = [];
        for (
            let segmentIndex = 0;
            segmentIndex < points.length - 1;
            segmentIndex++
        ) {
            const startPoint = points[segmentIndex];
            const endPoint = points[segmentIndex + 1];
            for (
                let stepIndex = 0;
                stepIndex < LASER_POINTER_GRADIENT_INTERPOLATION_STEPS;
                stepIndex++
            ) {
                const lerpAmount =
                    stepIndex /
                    (LASER_POINTER_GRADIENT_INTERPOLATION_STEPS - 1);
                interpolatedPoints.push(
                    Vector3.Lerp(startPoint, endPoint, lerpAmount),
                );
            }
        }
        return interpolatedPoints;
    }

    getInterpolatedColors(colors: Array<Color3>) {
        const interpolatedColors = [];
        for (
            let segmentIndex = 0;
            segmentIndex < colors.length - 1;
            segmentIndex++
        ) {
            const startColor = colors[segmentIndex];
            const endColor = colors[segmentIndex + 1];
            for (
                let stepIndex = 0;
                stepIndex < LASER_POINTER_GRADIENT_INTERPOLATION_STEPS;
                stepIndex++
            ) {
                const lerpAmount =
                    stepIndex /
                    (LASER_POINTER_GRADIENT_INTERPOLATION_STEPS - 1);
                interpolatedColors.push(
                    Color3.Lerp(startColor, endColor, lerpAmount),
                );
            }
        }
        return interpolatedColors;
    }

    getInterpolatedOpacity(
        interpolatedColors: Color3[],
        rawTextureColors: Uint8Array,
        opacityTexturePoints: opacityTexturePointType[],
    ) {
        let intervalStartPointIndex = 0;
        let intervalEndPointIndex = intervalStartPointIndex + 1;
        for (let i = 0; i < interpolatedColors.length; i++) {
            const percentageOfTotalColorCount =
                (i + 1) / interpolatedColors.length;

            // Shift interval that we are looking at as needed
            if (
                intervalStartPointIndex < opacityTexturePoints.length - 2 &&
                percentageOfTotalColorCount >=
                    opacityTexturePoints[intervalEndPointIndex].linePercentage
            ) {
                intervalStartPointIndex++;
                intervalEndPointIndex++;
            }
            const intervalStartPoint =
                opacityTexturePoints[intervalStartPointIndex];
            const intervalEndPoint =
                opacityTexturePoints[intervalEndPointIndex];
            // Calculate how far along into this interval the current color is
            const percentageOfInterval =
                (percentageOfTotalColorCount -
                    intervalStartPoint.linePercentage) /
                (intervalEndPoint.linePercentage -
                    intervalStartPoint.linePercentage);
            const alpha = lerp(
                intervalStartPoint.opacity,
                intervalEndPoint.opacity,
                percentageOfInterval,
            );
            rawTextureColors[4 * i + 3] = alpha * 255;
        }

        return rawTextureColors;
    }

    displayNetworkLaserObjectGrabber(
        sourceActorNr: number,
        endPosition: Vector3,
    ) {
        const avatar = this.avatarMap[sourceActorNr];
        if (avatar) {
            const avatarSize =
                avatar.getBoundingInfo().boundingBox.extendSizeWorld;
            const startPosition = new Vector3(
                avatar.position.x,
                avatar.position.y - avatarSize._y / 4,
                avatar.position.z,
            );
            const basePoints = [
                startPosition,
                Vector3.Lerp(startPosition, endPosition, 0.3),
                endPosition,
            ];
            const interpolatedPoints = this.getInterpolatedPoints(basePoints);
            //update
            if (this.grabbingAssetLaserPointer) {
                this.grabbingAssetLaserPointer?.setPoints(interpolatedPoints);
            } else {
                //create
                if (this.colorsMap[sourceActorNr]) {
                    const avatarColor = Color3.FromHexString(
                        this.colorsMap[sourceActorNr] ?? "",
                    );
                    const baseColors = [
                        avatarColor,
                        avatarColor,
                        Color3.FromHexString("#FFFFFF"),
                    ];

                    const laserPointerLine = this.createNetworkLaserPointerMesh(
                        sourceActorNr,
                        interpolatedPoints,
                        baseColors,
                        GRABBER_LASER_POINTER_OPACITY_POINTS,
                    );

                    this.grabbingAssetLaserPointer = laserPointerLine;
                    this.grabbingAssetSourceActorNr = sourceActorNr;
                }
            }
            this.viewer.renderLoop.toggle(100);
        }
    }

    displayNetworkLaserPointer(sourceActorNr: number, endPosition: Vector3) {
        const avatar = this.avatarMap[sourceActorNr];
        const remoteAvatar = this.remoteAvatarMap[sourceActorNr];
        if (!avatar || !remoteAvatar) {
            throw new Error(
                "avatar not found for displaying network laser pointer",
            );
        }
        const avatarSize = avatar.getBoundingInfo().boundingBox.extendSizeWorld;
        const startPosition = new Vector3(
            avatar.position.x,
            avatar.position.y - avatarSize._y / 4,
            avatar.position.z,
        );
        const distance = Vector3.Distance(startPosition, endPosition);
        const cameraDistanceToEnd = Vector3.Distance(
            this.getCameraPosition(),
            endPosition,
        );
        const lineEndPercentage =
            (distance - LASER_POINTER_LINE_DOT_GAP * cameraDistanceToEnd) /
            distance;
        const basePoints = [
            startPosition,
            Vector3.Lerp(startPosition, endPosition, 0.15),
            Vector3.Lerp(startPosition, endPosition, 0.7),
            Vector3.Lerp(startPosition, endPosition, 0.8),
            Vector3.Lerp(
                startPosition,
                endPosition,
                Math.max(lineEndPercentage, 0.8),
            ),
        ];
        const interpolatedPoints = this.getInterpolatedPoints(basePoints);
        //update
        const sourceActorPointerMesh = this.pointerMeshMap[sourceActorNr];
        if (sourceActorPointerMesh) {
            sourceActorPointerMesh.setPoints(interpolatedPoints);
            sourceActorPointerMesh.getChildMeshes()[0].position = endPosition;

            scaleMeshToPixelSize(
                sourceActorPointerMesh.getChildMeshes()[0] as Mesh,
                this.viewportPixelSize,
                NETWORK_LASER_POINTER_DOT_PIXEL_SIZE,
                NETWORK_LASER_POINTER_INITIAL_DIAMETER_SIZE,
                true,
                this.scene,
            );
        } else {
            //create
            const sourceActorColor = this.colorsMap[sourceActorNr];
            if (sourceActorColor) {
                const baseColors = [
                    Color3.FromHexString(sourceActorColor),
                    Color3.FromHexString(sourceActorColor),
                    Color3.FromHexString("#000000"),
                    Color3.FromHexString(sourceActorColor),
                    Color3.FromHexString("#FFFFFF"),
                ];

                const laserPointerLine = this.createNetworkLaserPointerMesh(
                    sourceActorNr,
                    interpolatedPoints,
                    baseColors,
                    LASER_POINTER_OPACITY_POINTS,
                );

                const laserPointerDot =
                    this.createNetworkLaserPointerDotMesh(sourceActorNr);
                if (!laserPointerDot) {
                    throw new Error("no laser pointer dot mesh");
                }
                laserPointerDot.position = endPosition;
                laserPointerDot.parent = laserPointerLine;
                this.pointerMeshMap[sourceActorNr] = laserPointerLine;
            }
        }
        this.viewer.renderLoop.toggle(100);
    }

    createNetworkLaserPointerMesh(
        sourceActorNr: number,
        interpolatedPoints: Vector3[],
        baseColors: Color3[],
        opacityTexturePoints: opacityTexturePointType[],
    ) {
        const greasedLine = CreateGreasedLine(
            `laserPointer${sourceActorNr}`,
            {
                points: interpolatedPoints,
            },
            {
                sizeAttenuation: true,
                colorMode: GreasedLineMeshColorMode.COLOR_MODE_MULTIPLY,
            },
            this.scene,
        );
        greasedLine.isPickable = false;

        if (greasedLine.material) {
            // list of colors we want to apply to the line
            const interpolatedColors = this.getInterpolatedColors(baseColors);

            //for each color3 in baseColors, we want to have 4 indexes representing r,g,b,a for raw texture
            const rawTextureColors = new Uint8Array(
                interpolatedColors.length * 4,
            );
            //color3 ranges from 0-1 in rgb but rawTextures require rgb ranging from 0-255
            interpolatedColors.forEach((color: Color3, index: number) => {
                rawTextureColors[4 * index] = color.r * 255;
                rawTextureColors[4 * index + 1] = color.g * 255;
                rawTextureColors[4 * index + 2] = color.b * 255;
            });
            //calculate the opacity of each colored section
            this.getInterpolatedOpacity(
                interpolatedColors,
                rawTextureColors,
                opacityTexturePoints,
            );

            const colorTexture = new RawTexture(
                rawTextureColors,
                rawTextureColors.length / 4,
                1,
                Engine.TEXTUREFORMAT_RGBA,
                this.scene,
                false,
                true,
                Engine.TEXTURE_NEAREST_NEAREST,
            );
            const greasedLineMaterial =
                greasedLine.material as StandardMaterial;
            greasedLineMaterial.emissiveTexture = colorTexture;
            greasedLineMaterial.opacityTexture = colorTexture;
            colorTexture.hasAlpha = true;
        }
        greasedLine.renderingGroupId = 2;
        return greasedLine;
    }

    createNetworkLaserPointerDotMesh(sourceActorNr: number) {
        const dot = MeshBuilder.CreateSphere(
            `laserPointer${sourceActorNr}-dot`,
            {
                diameter: NETWORK_LASER_POINTER_INITIAL_DIAMETER_SIZE,
                updatable: true,
            },
            this.scene,
        );

        dot.billboardMode = Mesh.BILLBOARDMODE_ALL;
        dot.renderOutline = true;
        dot.outlineWidth = NETWORK_LASER_POINTER_INITIAL_DIAMETER_SIZE / 5;
        dot.outlineColor = Color3.FromHexString("#545454");
        dot.material = this.remotePointerDotMaterial;
        dot.renderingGroupId = 2; // we don't want the dot to appear on the opposite side of mesh for v1

        //scale the dot so it's consistently sized regardless of camera perspective
        scaleMeshToPixelSize(
            dot,
            this.viewportPixelSize,
            NETWORK_LASER_POINTER_DOT_PIXEL_SIZE,
            NETWORK_LASER_POINTER_INITIAL_DIAMETER_SIZE,
            true,
            this.scene,
        );

        return dot;
    }

    removeNetworkLaserPointer(sourceActorNr?: number) {
        if (!sourceActorNr && !this.grabbingAssetLaserPointer) {
            return;
        }
        const mesh = sourceActorNr
            ? this.pointerMeshMap[sourceActorNr]
            : this.grabbingAssetLaserPointer;
        if (mesh) {
            const meshMaterial = mesh.material as StandardMaterial;
            if (meshMaterial && meshMaterial.emissiveTexture) {
                meshMaterial.emissiveTexture.dispose();
            }
            const childMeshes = mesh.getChildMeshes();
            // Clear re-scaling observable
            if (childMeshes.length > 0 && childMeshes[0].name.endsWith("dot")) {
                (childMeshes[0] as Mesh).onAfterRenderObservable.clear();
            }
            mesh.dispose();
            if (sourceActorNr) {
                delete this.pointerMeshMap[sourceActorNr];
            } else {
                delete this.grabbingAssetLaserPointer;
                this.grabbingAssetSourceActorNr = undefined;
            }
        }
        this.viewer.renderLoop.toggle();
    }

    scaleNetworkLaserPointers() {
        for (const mesh of Object.values(this.pointerMeshMap)) {
            if (mesh) {
                scaleMeshToPixelSize(
                    mesh.getChildMeshes()[0] as Mesh,
                    this.viewportPixelSize,
                    NETWORK_LASER_POINTER_DOT_PIXEL_SIZE,
                    NETWORK_LASER_POINTER_INITIAL_DIAMETER_SIZE,
                    true,
                    this.scene,
                );
            }
        }
    }

    handleLaserPointerMove() {
        this.updateLaserPointerPosition();
    }

    handleLaserPointerUp() {
        this.localPointerLine.isVisible = false;
        this.localPointerDot.isVisible = false;
        this.viewer.renderLoop.toggle();
    }

    handleLaserPointerDown() {
        this.localPointerLine.isVisible = true;
        this.localPointerDot.isVisible = true;
        this.updateLaserPointerPosition();
    }
}
