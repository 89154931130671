/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    Text,
    StatusLight,
    Tooltip,
    TooltipTrigger,
    View,
} from "@adobe/react-spectrum";
import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getFormattedPhysicalSize } from "../util/StringUtils";

import type { AssetTechInfo } from "../util/HealthCheckUtils";

interface DisplayInfo {
    displayName: string;
    value: number | [number, number, number];
    displayValue: string;
    recommendedLimit?: number;
    recommendedRange?: [number, number];
    displayRecommendedLimit?: string;
}

interface AssetTechInfoViewProps {
    techInfo?: AssetTechInfo;
    compareTechInfo?: AssetTechInfo;
    compare?: boolean;
    isAssetInfoPanelChild?: boolean;
}

function useFormattedTechInfo(techInfo?: AssetTechInfo) {
    const { t } = useTranslation("web");

    const displayInfo = useMemo(() => {
        if (!techInfo) return;
        const displayInfo: Record<string, DisplayInfo> = JSON.parse(
            JSON.stringify(techInfo),
        );

        const { formattedPhysicalSize, physicalSizeUnit } =
            getFormattedPhysicalSize(techInfo.physicalSize.value);
        displayInfo.physicalSize.displayName = t(
            "infoPanel.displayName.physicalSize",
        );
        displayInfo.physicalSize.displayValue = t(
            "infoPanel.displayName.physicalSize.displayValue",
            {
                val1: formattedPhysicalSize[0],
                val2: formattedPhysicalSize[1],
                val3: formattedPhysicalSize[2],
                physicalSizeUnit: physicalSizeUnit,
            },
        );

        displayInfo.fileFormat.displayName = t(
            "infoPanel.displayName.fileFormat",
        );
        const fileSizeMb = techInfo.fileSize.value / 1000000;
        displayInfo.fileSize.displayName = t("infoPanel.displayName.fileSize");
        displayInfo.fileSize.displayValue =
            fileSizeMb > 1
                ? t("infoPanel.fileSizeMb", { val: fileSizeMb.toFixed(1) })
                : t("infoPanel.fileSizeKb", { val: techInfo.fileSize.value });
        displayInfo.fileSize.displayRecommendedLimit = `${
            techInfo.fileSize.recommendedLimit / 1000000
        } MB`;

        if (techInfo.polycount.value === 1) {
            displayInfo.polycount.displayValue = `${1} ${t(
                "infoPanel.triangle",
            )}`;
        } else {
            const polycountK = techInfo.polycount.value / 1000;
            displayInfo.polycount.displayValue =
                polycountK > 1
                    ? t("infoPanel.polycountK", { val: polycountK.toFixed(0) })
                    : t("infoPanel.polycount", {
                          val: techInfo.polycount.value,
                      });
        }
        displayInfo.polycount.displayName = t(
            "infoPanel.displayName.polycount",
        );
        displayInfo.polycount.displayRecommendedLimit = `${
            techInfo.polycount.recommendedLimit / 1000
        }K ${t("infoPanel.triangles")}`;

        displayInfo.drawCalls.displayName = t(
            "infoPanel.displayName.drawCalls",
        );
        displayInfo.meshes.displayName = t("infoPanel.displayName.meshes");
        displayInfo.materials.displayName = t(
            "infoPanel.displayName.materials",
        );
        displayInfo.textures.displayName = t("infoPanel.displayName.textures");

        displayInfo.drawCalls.displayRecommendedLimit = `${
            techInfo.drawCalls.recommendedLimit
        } ${t("infoPanel.calls")}`;
        displayInfo.meshes.displayRecommendedLimit = `${
            techInfo.meshes.recommendedLimit
        } ${t("infoPanel.meshes")}`;
        displayInfo.materials.displayRecommendedLimit = `${
            techInfo.materials.recommendedLimit
        } ${t("infoPanel.materials")}`;
        displayInfo.textures.displayRecommendedLimit = `${
            techInfo.textures.recommendedLimit
        } ${t("infoPanel.textures")}`;

        return displayInfo;
    }, [techInfo]);

    return { displayInfo };
}

function shouldShowWarningStatus(field: DisplayInfo) {
    if (field.recommendedLimit && typeof field.value === "number") {
        return field.value > field.recommendedLimit;
    }
    if (field.recommendedRange && field.value instanceof Array) {
        const range = field.recommendedRange;
        for (let index = 0; index < field.value.length; index++) {
            if (
                field.value[index] &&
                (field.value[index] < range[0] || field.value[index] > range[1])
            ) {
                return true;
            }
        }
    }
    return false;
}

function TechInfoRowContent({
    field,
    compareRow,
}: {
    field: DisplayInfo;
    compareRow?: boolean;
}) {
    const { t } = useTranslation("web");

    return (
        <View
            height="100%"
            minWidth="50%"
            borderStartWidth={compareRow ? "thin" : "none"}
            borderStartColor={compareRow ? "mid" : undefined}>
            <Flex
                justifyContent="start"
                alignItems="center"
                gap="size-200"
                height="100%">
                <View width="size-250">
                    {shouldShowWarningStatus(field) && (
                        <TooltipTrigger>
                            <ActionButton isQuiet={true}>
                                <StatusLight
                                    aria-label="warning"
                                    role="status"
                                    variant="notice"
                                    UNSAFE_style={{
                                        alignItems: "center",
                                    }}
                                />
                            </ActionButton>
                            <Tooltip>
                                {field.recommendedRange
                                    ? t("infoPanel.physicalSize.tooltip")
                                    : t("infoPanel.tooltip", {
                                          recommendedLimit:
                                              field.displayRecommendedLimit,
                                      })}
                            </Tooltip>
                        </TooltipTrigger>
                    )}
                </View>
                <View>
                    <Text>{field.displayValue || field.value}</Text>
                </View>
            </Flex>
        </View>
    );
}

function RowWrapper({ name, children }: PropsWithChildren<{ name: string }>) {
    return (
        <View
            height="size-500"
            borderBottomWidth="thin"
            borderBottomColor="mid"
            backgroundColor="gray-100">
            <Flex
                direction="row"
                alignItems="center"
                alignContent="center"
                height="100%"
                justifyContent="start">
                <View
                    height="100%"
                    width="20%"
                    marginStart="size-100"
                    borderEndWidth="thin"
                    borderEndColor="mid"
                    UNSAFE_style={{ lineHeight: "38px" }}>
                    <Text>{name}</Text>
                </View>
                <View flexGrow={1}>
                    <Flex
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        height="100%">
                        {children}
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}

export function AssetTechInfoView({
    compare = false,
    techInfo,
    compareTechInfo,
    isAssetInfoPanelChild,
}: AssetTechInfoViewProps) {
    const { t } = useTranslation("web");
    const { displayInfo } = useFormattedTechInfo(techInfo);
    const { displayInfo: compareDisplayInfo } =
        useFormattedTechInfo(compareTechInfo);

    return (
        <Flex
            marginTop="size-300"
            direction="column"
            width="100%"
            UNSAFE_style={{
                fontSize: "0.95em",
            }}>
            {compare && (
                <RowWrapper name="">
                    <View width="50%" UNSAFE_style={{ textAlign: "center" }}>
                        {t("infoPanel.original")}
                    </View>
                    <View
                        width="50%"
                        height="100%"
                        borderStartWidth="thin"
                        borderStartColor="mid"
                        UNSAFE_style={{ textAlign: "center", lineHeight: "36px" }}>
                        {t("infoPanel.optimized")}
                    </View>
                </RowWrapper>
            )}
            {displayInfo &&
                Object.entries(displayInfo).map(([key, field]) => {
                    // physical size is displayed differently in AssetInfoPanel
                    if (
                        isAssetInfoPanelChild &&
                        field.displayName === "physicalSize"
                    )
                        return;
                    return (
                        <RowWrapper key={key} name={field.displayName}>
                            <TechInfoRowContent field={field} />
                            {compare &&
                                compareDisplayInfo &&
                                key !== "physicalSize" &&
                                key !== "originalFormat" &&
                                key !== "fileFormat" && (
                                    <TechInfoRowContent
                                        compareRow
                                        field={compareDisplayInfo[key]}
                                    />
                                )}
                        </RowWrapper>
                    );
                })}
        </Flex>
    );
}
