/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    Tooltip,
    TooltipTrigger,
    View,
    ToggleButton,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import Comment from "@spectrum-icons/workflow/Comment";
import Flag from "@spectrum-icons/workflow/Flag";
import Info from "@spectrum-icons/workflow/Info";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AssetInfoPanel } from "./AssetInfoPanel";
import { CommentsPanel } from "./CommentsPanel";
import { ReportAbuseView } from "./ReportAbuseView";
import { useAcpContext } from "@src/contexts/AcpContext";
import { usePinContext } from "@src/contexts/PinContext";
import { PinningSession } from "@src/lib/babylon/PinningSession";
import { CommentsApi, CommentsApiEvent } from "@src/lib/bus-api/CommentsApi";
import { InfoMode } from "@src/util/PanelUtils";

const sidePanelButtons = [
    {
        key: "comment" as InfoMode,
        label: "actions.comment",
        icon: <Comment size="S" />,
        hideTooltip: "detailPanel.comment.hide.tooltip",
        showTooltip: "detailPanel.comment.show.tooltip",
    },
    {
        key: "info" as InfoMode,
        label: "actions.info",
        icon: <Info size="S" />,
        hideTooltip: "detailPanel.info.hide.tooltip",
        showTooltip: "detailPanel.info.show.tooltip",
    },
] as const;

interface CommentInfoPanelProps {
    assetId: string;
    accessToken: string | undefined;
    commentApi: CommentsApi;
    pinningSession: PinningSession | undefined;
}

function InfoPanel({
    assetId,
    accessToken,
}: {
    assetId: string;
    accessToken: string | undefined;
}) {
    const { useAssetTechInfo, useReviewListItem } = useAcpContext();
    const { data: reviewItem } = useReviewListItem(assetId);
    const { data: assetTechInfo } = useAssetTechInfo(assetId);

    const isInfoPanelReady = accessToken && reviewItem && assetTechInfo;
    return (
        isInfoPanelReady && (
            <AssetInfoPanel
                accessToken={accessToken}
                ownerId={reviewItem.createdBy}
                modifyDate={reviewItem.modifyDate}
                techInfo={assetTechInfo}
            />
        )
    );
}

function ReportAbuse({ assetId }: { assetId: string }) {
    const { showModal } = useMessagingContext();
    const { t } = useTranslation("web");

    return (
        <TooltipTrigger>
            <ActionButton
                isQuiet
                onPress={() =>
                    showModal(<ReportAbuseView assetUrn={assetId} />)
                }>
                <Flag
                    aria-label={t("accessibility.actions.reportAbuse")}
                    size="S"
                />
            </ActionButton>
            <Tooltip>{t("detailPanel.reportAbuse.tooltip")}</Tooltip>
        </TooltipTrigger>
    );
}

export function CommentInfoPanel({
    assetId,
    accessToken,
    commentApi,
    pinningSession,
}: CommentInfoPanelProps) {
    const { t } = useTranslation("web");
    const { toggleAnnotationButton, setPinsVisible } = usePinContext();

    const [selectedPanelKey, setSelectedPanelKey] =
        useState<InfoMode>("comment");

    function isPanelHidden(key: InfoMode) {
        return selectedPanelKey !== key;
    }

    useEffect(() => {
        if (!pinningSession) return;
        if (selectedPanelKey !== "comment") {
            // hide pins when comment panel is not shown
            if (pinningSession?.pinsVisible) {
                toggleAnnotationButton.current?.click();
            }
        } else {
            if (!pinningSession?.pinsVisible) {
                toggleAnnotationButton.current?.click();
            }
        }
    }, [selectedPanelKey, pinningSession]);

    useEffect(() => {
        if (!pinningSession) return;

        pinningSession.on(CommentsApiEvent.annotationToggle, () => {
            setPinsVisible((prevState) => !prevState);
            if (!pinningSession?.pinsVisible) {
                setSelectedPanelKey("comment");
            }
        });
    }, [pinningSession]);

    return (
        <>
            <View
                id={`Coachmark-commentPanel`}
                key="right-nav"
                backgroundColor="gray-100"
                borderStartColor="gray-75"
                borderStartWidth="thick"
                width="size-4600"
                height="100%"
                overflow="hidden"
                isHidden={selectedPanelKey === "none"}>
                <CommentsPanel
                    visible={!isPanelHidden("comment")}
                    assetId={assetId}
                    commentsApi={commentApi}
                />
                <View width="100%" isHidden={isPanelHidden("info")}>
                    <InfoPanel assetId={assetId} accessToken={accessToken} />
                </View>
            </View>
            <View
                key="panels"
                backgroundColor="gray-100"
                height="100%"
                width="48px"
                borderStartColor="gray-75"
                borderStartWidth="thick"
                paddingTop="size-100"
                paddingX="size-75">
                <Flex
                    height="100%"
                    direction="column"
                    justifyContent="space-between">
                    <Flex direction="column">
                        {sidePanelButtons.map((button) => {
                            return (
                                <TooltipTrigger
                                    placement="end"
                                    key={button.key}>
                                    <ToggleButton
                                        isQuiet
                                        isSelected={
                                            selectedPanelKey === button.key
                                        }
                                        onPress={() => {
                                            if (
                                                selectedPanelKey === button.key
                                            ) {
                                                setSelectedPanelKey("none");
                                            } else {
                                                setSelectedPanelKey(
                                                    button.key as InfoMode,
                                                );
                                            }
                                        }}
                                        aria-label={t(button.label)}
                                        UNSAFE_style={{
                                            gap: "14px",
                                            margin: "5px 0px",
                                        }}>
                                        {button.icon}
                                    </ToggleButton>
                                    <Tooltip>
                                        {isPanelHidden(button.key)
                                            ? t(button.showTooltip)
                                            : t(button.hideTooltip)}
                                    </Tooltip>
                                </TooltipTrigger>
                            );
                        })}
                    </Flex>
                    <View paddingBottom="size-100">
                        <ReportAbuse assetId={assetId} />
                    </View>
                </Flex>
            </View>
        </>
    );
}
