/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// @ts-ignore
import "./photon-voice.js";

import {
    IMS_ENV,
    PHOTON_VOICE_DEVAPPID,
    PHOTON_CONFIG,
    PHOTON_VOICE_APPID,
} from "../../config.js";

var roomTTL = 0;
var playerTTL = 0;

export class NetworkVoiceManager extends Photon.Voice.LoadBalancingVoiceClient {
    private micSource!: Photon.Voice.AudioSource;
    private micVoice!: Photon.Voice.LocalVoiceAudio | null;

    private stateChangeListeners: Array<() => void> = [];

    private isMuteAllUsers: boolean | undefined;

    constructor() {
        let voiceAppId;
        if (IMS_ENV === "prod") {
            voiceAppId = PHOTON_VOICE_APPID;
        } else {
            voiceAppId = PHOTON_VOICE_DEVAPPID;
        }

        super(
            PHOTON_CONFIG["Wss"]
                ? Photon.ConnectionProtocol.Wss
                : Photon.ConnectionProtocol.Ws,
            voiceAppId,
            PHOTON_CONFIG.AppVersion,
        );
    }

    /**
    @summary Sets mute all users to true/false depending on whether it's toggled
    */
    setIsMuteAllUsers(on: boolean) {
        this.isMuteAllUsers = on;
    }

    /**
    @summary Returns is mute all users current val
    @returns Returns boolean
    */
    getIsMuteAllUsers() {
        return this.isMuteAllUsers;
    }

    /**
    @summary Updates state status (JoinedLobby, Joined, Disconnected, Error, etc).
    @returns Returns status in string.
    */
    updateStatus(): string | undefined {
        const LBC = Photon.LoadBalancing.LoadBalancingClient;
        var statusText = LBC.StateToName(this.state());
        return statusText;
    }

    /**
    @summary Returns whether user has joined voice room.
    @returns True if joined room, false otherwise.
    */
    isJoinedRoom(): boolean {
        const LBC = Photon.LoadBalancing.LoadBalancingClient;
        return this.state() == LBC.State.Joined;
    }

    /**
    @summary Returns whether user has joined lobby.
    @returns True if joined lobby, false otherwise.
    */
    isJoinedLobby(): boolean {
        const LBC = Photon.LoadBalancing.LoadBalancingClient;
        return this.state() == LBC.State.JoinedLobby;
    }

    /**
    @summary Returns whether there's an error on join.
    @returns True if joining throws and error, false otherwise.
    */
    isJoinedError(): boolean {
        const LBC = Photon.LoadBalancing.LoadBalancingClient;
        return this.state() == LBC.State.Error;
    }

    /**
    @summary Returns whether user is disconnected.
    @returns True if disconnected, false otherwise.
    */
    isJoinedDisconnected(): boolean {
        const LBC = Photon.LoadBalancing.LoadBalancingClient;
        return this.state() == LBC.State.Disconnected;
    }

    /**
    @summary Joins voice room.
    @param {string} voiceRoom Voice room name.
    */
    joinVoiceRoom(voiceRoom: string) {
        this.joinRoom(
            voiceRoom,
            { createIfNotExists: true },
            { roomTTL: roomTTL, playerTTL: playerTTL },
        );
        // return false;
    }

    /**
    @summary Triggers event listener when actor joins.
    @param {string} actor Actor.
    */
    onActorJoin(actor: Photon.LoadBalancing.Actor) {
        console.log("new voice player joined: " + actor.actorNr);
        console.log(actor);
    }

    /**
    @summary Triggers event listener when state changes.
    */
    onStateChange() {
        this.stateChangeListeners.forEach((listener) => listener());
    }

    addStateChangeListener(listener: () => void) {
        this.stateChangeListeners.push(listener);
    }

    removeStateChangeListener(listener: () => void) {
        this.stateChangeListeners = this.stateChangeListeners.filter(
            (l) => l !== listener,
        );
    }

    /**
    @summary Enables/disables microphone.
    @param {boolean} on True if user has toggled on microphone, false otherwise.
    @param {string} deviceID Device ID of microphone source
    */
    public toggleMic(on: boolean, deviceID: string) {
        if (on) {
            if (!this.micVoice) {
                const micInfo = Photon.Voice.createOpusVoiceInfo(
                    24000 /* voice (Opus) sample rate*/,
                    1 /* channels */,
                    20000 /* frameSize */,
                    20000 /* bitrate */,
                );
                this.micSource = Photon.Voice.createMicrophone(
                    deviceID,
                    micInfo,
                );
                this.micVoice =
                    this.voiceClient.createLocalVoiceAudioFromSource(
                        micInfo,
                        this.micSource,
                    );
            }
        } else {
            if (this.micVoice) {
                Photon.Voice.dispose(this.micSource);
                Photon.Voice.dispose(this.micVoice); // cleans up the js adapter
                this.micVoice = null;
            }
        }
    }

    /**
    @summary Debug mode for mic -- it will echo back the audio streams you send.
    @param {boolean} isMic True if microphone is on, false otherwise.
    @param {string} deviceID Device ID of microphone source
    */
    updateEchoDebugModeMic(isMic: boolean) {
        this.micVoice && this.micVoice.setDebugEchoMode(isMic);
    }

    /**
    @summary Resets microphone with newly selected device.
    @param {boolean} on True if user has toggled on microphone, false otherwise.
    @param {string} deviceID Device ID of microphone source
    */
    resetMic(on: boolean, deviceID: string) {
        if (on) {
            this.toggleMic(false, deviceID);
            this.toggleMic(true, deviceID);
        }
    }

    /**
    @summary Returns list of local audio devices.
    @returns {Promise<MediaDeviceInfo[]>} Returns list of local audio devices
    */
    async listDevices() {
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const audioDevices = devices.filter(
                    (device) => device.kind === "audioinput",
                );
                return audioDevices;
            } catch (error) {
                console.error("Error enumerating devices: ", error);
                throw error;
            }
        } else {
            throw new Error("Cannot get devices");
        }
    }

    /**
    @summary Processes and plays incoming streams for remote voices.
    */
    private remoteVoiceAction(
        playerId: number,
        voiceId: number,
        voiceInfo: Photon.Voice.VoiceInfo,
    ) {
        const player = Photon.Voice.createAudioPlayer(voiceInfo, 200);
        const playerTimer = setInterval(() => player.service(), 20);

        const speakingStatus = new Map();

        const speakingTimeout = 200;

        const decoder = Photon.Voice.createAudioDecoder((frame) => {
            if (!this.isMuteAllUsers || this.isMuteAllUsers === undefined) {
                player.input(frame);
                const now = Date.now();

                if (!speakingStatus.has(playerId)) {
                    speakingStatus.set(playerId, {
                        lastFrameTime: now,
                        speaking: false,
                    });
                }

                const status = speakingStatus.get(playerId);
                status.lastFrameTime = now;

                if (!status.speaking) {
                    status.speaking = true;
                }
            }
        });

        const checkSpeakingStatus = () => {
            const now = Date.now();

            speakingStatus.forEach((status) => {
                if (
                    status.speaking &&
                    now - status.lastFrameTime > speakingTimeout
                ) {
                    status.speaking = false;
                }
            });
        };

        const speakingCheckTimer = setInterval(
            checkSpeakingStatus,
            speakingTimeout,
        );
        const onRemove = () => {
            clearInterval(playerTimer);
            clearInterval(speakingCheckTimer);
            Photon.Voice.dispose(player);
        };

        return Photon.Voice.createRemoteVoiceOptions(decoder, onRemove);
    }

    /**
    @summary Sets custom actor properties.
    */
    setCustomProperties(
        avatarUrl: string,
        platform: string,
        isSpeaking: boolean,
    ) {
        const properties = {
            avatarUrl: avatarUrl,
            platform: platform,
            isSpeaking: isSpeaking,
        };
        this.myActor().setCustomProperties(properties);
    }

    /**
    @summary Connects to name server, region, and joins lobby. Promise is resolved when user is connected to lobby.
    */
    start() {
        return new Promise<void>((resolve, reject) => {
            if (PHOTON_CONFIG["NameServer"]) {
                this.setNameServerAddress(PHOTON_CONFIG["NameServer"]);
            }
            this.connectToRegionMaster(PHOTON_CONFIG["Region"]);

            this.remoteVoiceAction = this.remoteVoiceAction.bind(this);
            this.voiceClient.setOnRemoteVoiceInfoAction(this.remoteVoiceAction);

            const checkJoinedLobby = () => {
                if (this.isJoinedLobby()) {
                    clearInterval(checkInterval);
                    resolve();
                } else if (this.isJoinedError()) {
                    console.error("Running is joined error");
                    reject(new Error("Connection failed"));
                    clearInterval(checkInterval);
                }
            };

            const checkInterval = setInterval(checkJoinedLobby, 1000);
            setTimeout(() => {
                clearInterval(checkInterval);
                reject(new Error("Timeout while joining voice lobby"));
            }, 30000);
        });
    }

    /**
    @summary Waits for user to connect to lobby and then joins room.
    @param voiceRoom Name of voice room to connect to.
    */
    async initializeVoiceRoom(voiceRoom: string, displayName: string) {
        try {
            await this.start();

            this.myActor().name = displayName;
            this.joinVoiceRoom(voiceRoom);
        } catch (error) {
            console.error("Failed to initialize voice room: ", error);
        }
    }

    /**
    @summary Reconnects to voice room if connection becomes inactive or fails
    @param voiceRoom Name of voice room to connect to.
    */
    reconnectToRoom(displayName: string) {
        this.myActor().name = displayName;
        this.reconnectToMaster();
    }
}
