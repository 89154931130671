/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { Camera, TargetCamera } from "@babylonjs/core/Cameras";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { RenderingManager } from "@babylonjs/core/Rendering/renderingManager";
import { SpriteManager } from "@babylonjs/core/Sprites/spriteManager";
import { WithOptional } from "@shared/types/src/util";
import EventEmitter from "events";

import { NormalTracer } from "./mesh/NormalTracer";
import { PinConfig, PinState, PinTextures, Pin } from "./mesh/Pin";
import { PinEvents } from "./PinDataTypes";
import { PinGroupManager } from "./PinGroupManager";
import { PointerEventManager } from "./PointerEventManager";
import { createPinData, serializeVec3 } from "./utils/pinData";

import type { PinEvent, SerializedPin } from "./PinDataTypes";
import type { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import type { Viewport } from "@babylonjs/core/Maths/math.viewport";
import type { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import type { Scene } from "@babylonjs/core/scene";
import type { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";

type SerializedColor = [number, number, number];

export interface PinDisplayConfig {
    poleColor: SerializedColor;
    poleAlpha: number;
    spriteSize: number;
    headUrl: string;
    headOccludedUrl: string;
    headHoverUrl: string;
    pinScale: {
        multiplier: number;
        minScaleSize: number;
        maxScaleSize: number;
    };
    headSelectedUrl?: string;
    headEditUrl?: string;
    headTemporaryUrl?: string;
}

export interface PinGroupingConfig {
    enabled: boolean;
    groupScreenRatio: number;
    minGroupPinCount: number;
    groupPinHeadUrl: string;
    groupPinHeadOccludedUrl: string;
    groupPinHoverUrl: string;
    debugPinGrouping?: boolean;
    groupPinScalingFactor?: number;
}

type OptionalPinDisplayConfig = WithOptional<
    PinDisplayConfig,
    "poleColor" | "poleAlpha" | "pinScale"
>;

export type PinManagerOptions = WithOptional<
    Omit<PinConfig, "radius">,
    "pinTextures"
> & {
    modelBounds?: { min: Vector3; max: Vector3 };
    pinDisplayConfig: OptionalPinDisplayConfig;
    grouping?: PinGroupingConfig;
};

export const DEFAULT_PIN_COLOR_CONFIG: Omit<
    PinDisplayConfig,
    | "headUrl"
    | "headHoverUrl"
    | "headSelectedUrl"
    | "headEditUrl"
    | "headOccludedUrl"
    | "spriteSize"
> = {
    poleColor: [0.5, 0.5, 0.5],
    poleAlpha: 0,
    /**
     * multiplier: is based on the diagonal length of the model bounding box
     * minScaleSize: Is the minimum size the sprite can scale to of it's original size
     * maxScaleSize: Is the maximum size the sprite can scale to of it's original size
     */
    pinScale: {
        multiplier: 1 / 45,
        minScaleSize: 1,
        maxScaleSize: 1.5,
    },
};

export interface PinViewport {
    viewport: Viewport;
    viewportPixelSize: number;
    minSpriteSize: number;
    maxSpriteSize: number;
}

export class PinManager extends EventEmitter {
    private viewer: AdobeViewer;

    private scene: Scene;
    private rootMesh?: AbstractMesh;
    private playerRootMesh?: AbstractMesh;
    private camera?: TargetCamera;
    private tracer?: NormalTracer;
    private pinViewport?: PinViewport;
    private pinConfig?: PinConfig & {
        pinDisplayConfig: OptionalPinDisplayConfig;
    };
    private groupPinConfig?: PinConfig & {
        pinDisplayConfig: OptionalPinDisplayConfig;
    } & Pick<
            PinGroupingConfig,
            | "groupPinScalingFactor"
            | "groupScreenRatio"
            | "minGroupPinCount"
            | "debugPinGrouping"
            | "enabled"
        >;

    private pins: Pin[] = [];

    private pointerEventManager?: PointerEventManager;
    private pinGroupManager?: PinGroupManager;

    private showPins = true;
    private isSelectable = true;

    pinScalingMultiplier!: number;
    pinScale: number = 0.01;

    initialized = false;

    constructor(viewer: AdobeViewer, pinOptions: PinManagerOptions) {
        super();

        this.viewer = viewer;
        this.scene = viewer.scene;
        viewer.plugins.pins = this;

        if (!viewer.modelInitialized) {
            viewer.onModelChanged.addOnce(() => {
                this.initialize(pinOptions);
            });
        } else {
            this.initialize(pinOptions);
        }
    }

    private initialize(pinOptions: PinManagerOptions) {
        const scene = this.viewer.scene;
        if (!this.viewer.model) throw new Error("Model not loaded");
        this.rootMesh = this.viewer.model;
        if (!this.viewer.rootPlayerTransform) throw new Error("Model player transform node not loaded");
        this.playerRootMesh = this.viewer.rootPlayerTransform;
        if (!scene) {
            throw new Error("View failed to load scene");
        }

        const camera = this.setupScene(this.viewer.scene);

        const { min, max } =
            pinOptions.modelBounds ||
            (this.rootMesh
                ? this.rootMesh.getHierarchyBoundingVectors()
                : scene.getWorldExtends());

        this.pinScalingMultiplier =
            pinOptions.pinDisplayConfig.pinScale?.multiplier ||
            DEFAULT_PIN_COLOR_CONFIG.pinScale.multiplier;

        this.pinScale = Vector3.Distance(min, max) * this.pinScalingMultiplier;

        this.pinConfig = {
            radius: this.pinScale,
            ...pinOptions,
            pinTextures: this.createPinTextures(
                scene,
                pinOptions.pinDisplayConfig,
            ),
        };

        if (pinOptions.grouping) {
            const groupPinTextures = this.createGroupPinTextures(
                scene,
                pinOptions.pinDisplayConfig.spriteSize,
                pinOptions.grouping,
                this.pinConfig.pinTextures,
            );

            this.groupPinConfig = {
                radius:
                    this.pinScale *
                    (pinOptions.grouping.groupPinScalingFactor || 1),
                ...pinOptions,
                pinTextures: groupPinTextures,
                groupPinScalingFactor:
                    pinOptions.grouping.groupPinScalingFactor,
                groupScreenRatio: pinOptions.grouping.groupScreenRatio,
                minGroupPinCount: pinOptions.grouping.minGroupPinCount,
                debugPinGrouping: pinOptions.grouping.debugPinGrouping,
                enabled: pinOptions.grouping.enabled,
            };
        }

        this.pinViewport = this.getViewPort(camera);

        this.tracer = new NormalTracer(
            this.viewer,
            this.pinConfig,
            pinOptions.pinDisplayConfig.headTemporaryUrl ||
                pinOptions.pinDisplayConfig.headUrl,
            this.pinViewport.viewportPixelSize * this.pinScalingMultiplier,
        );

        window.addEventListener("resize", this.updateViewport);
        window.document.addEventListener(
            "visibilitychange",
            this.updatePinScale,
        );

        this.groupPins();

        this.viewer.renderLoop.toggle();
    }

    private setupScene(scene: Scene) {
        this.scene = scene;

        // Enable additional rendering layer and setup new layer
        RenderingManager.AUTOCLEAR = true;
        RenderingManager.MAX_RENDERINGGROUPS = 4;
        scene.renderingManager.setRenderingAutoClearDepthStencil(
            3, // renderingGroupId The rendering group id corresponding to its index
            true, // autoClearDepthStencil Automatically clears depth and stencil between groups if true.
            true, // depth Automatically clears depth between groups if true and autoClear is true.
            true, // stencil Automatically clears stencil between groups if true and autoClear is true.
        );

        this.pointerEventManager = new PointerEventManager(this.viewer, () => {
            this.deselectPins();
            this.emit(PinEvents.pinDeselected);
        });

        const camera = (this.camera = this.viewer.camera);

        let groupTimeout = 0;
        const onCameraChanged = () => {
            this.emit(PinEvents.cameraMove);
            this.checkLayers();
            if (this.pointerEventManager) {
                // Disable hover stuff while camera is moving
                // Approximately the time of one frame, assuming 60 fps
                this.pointerEventManager.disableInteractionToggle.toggle(17);
            }
            if (!groupTimeout) {
                groupTimeout = window.setTimeout(() => {
                    this.groupPins();
                    groupTimeout = 0;
                }, 500);
            }
        };

        camera.onViewMatrixChangedObservable.add(onCameraChanged);

        // Ensure 5 seconds of rendering and layer checking
        const minimumInitialRenderUpdateTime = 5000;
        this.viewer.scene.onAfterRenderObservable.addOnce(() => {
            const refreshView = () => {
                this.checkLayers();
            };
            scene.registerBeforeRender(refreshView);
            setTimeout(() => {
                scene.unregisterBeforeRender(refreshView);
            }, minimumInitialRenderUpdateTime);
            this.viewer.renderLoop.toggle(minimumInitialRenderUpdateTime);
        });

        if (this.playerRootMesh) {
            let lastScale: Vector3 | undefined;
            let lastPosition: Vector3 | undefined;
            let lastRotation: Vector3 | undefined;
            this.playerRootMesh.onAfterWorldMatrixUpdateObservable.add((node) => {
                if (this.initialized && this.pins.length > 0) {
                    // this is needed to prevent updatePinScale() from firing everytime the asset is rotated
                    // for some reason node.scaling.equals(lastScale) is false when only rotating the asset
                    if (
                        (!lastScale || !node.scaling.equals(lastScale)) &&
                        (!lastPosition || !node.position.equals(lastPosition)) &&
                        (!lastRotation ||
                            (node.rotation && !node.rotation.equals(lastRotation)))
                    ) {
                        lastScale = node.scaling.clone();
                        lastPosition = node.position.clone();
                        lastRotation = node.rotation.clone();
                        // reading the size of the nodes causes this event to fire again
                        this.updatePinScale();
                    }
                    this.updatePinPositions();
                    this.checkLayers();
                }
            });
        }

        this.initialized = true;
        this.emit(PinEvents.pinManagerInitialized);
        return camera;
    }

    private getAsyncProps() {
        const {
            initialized,
            scene,
            tracer,
            pinConfig,
            pinViewport,
            pointerEventManager,
            camera,
        } = this;
        if (
            !initialized ||
            !scene ||
            !tracer ||
            !pinConfig ||
            !pinViewport ||
            !camera ||
            !pointerEventManager
        ) {
            throw new Error(
                `PinManager not initialized please wait for ${PinEvents.pinManagerInitialized} before calling this method`,
            );
        }
        return {
            initialized,
            scene,
            tracer,
            pinConfig,
            pinViewport,
            pointerEventManager,
            camera,
        };
    }

    private getAllPins() {
        const pins = [...this.pins];
        if (this.pinGroupManager) {
            Object.values(this.pinGroupManager.pinGroups).forEach(
                (pinGroup) => {
                    if (pinGroup.groupPin) {
                        pins.push(pinGroup.groupPin);
                    }
                },
            );
        }
        return pins;
    }

    /**
     * Start Public API
     **/
    async setPins(pins: SerializedPin[]) {
        console.log("Setting pins", pins.length);

        const pinsPrevState: Record<string, PinState> = {}
        pins.forEach((serializedPin) => {
            const pin = this.getPinById(serializedPin.id);
            if (pin) pinsPrevState[serializedPin.id] = pin.state;
        })

        const { tracer, pinViewport, pinConfig } = this.getAsyncProps();
        this.clearPins();
        this.viewer.renderLoop.toggle();

        pins.forEach((pinData) => {
            let pinPrevState;
            if (pinsPrevState[pinData.id]) {
                pinPrevState = pinsPrevState[pinData.id];
            }
            const pin = new Pin({
                scene: this.scene,
                pinViewport: pinViewport,
                pinOptions: pinConfig,
                modelRootMesh: this.rootMesh,
                state: pinPrevState,
            })
            pin.pinData = { ...pinData };

            this.attachPinPointerEvents(pin);

            pin.positionFromData();
            pin.clampPinSize();
            pin.isEnabled = this.showPins

            this.pins.push(pin);
        });

        this.groupPins();

        if (!tracer.tracing) {
            this.viewer.renderLoop.toggle(); 
        }
    }

    clearPins() {
        const { pointerEventManager } = this.getAsyncProps();
        pointerEventManager.removeAllHandlers();
        this.getAllPins().forEach((pin) => {
            pin.dispose();
        });
        this.pins = [];
        this.clearGroups();
    }

    addPin(
        id: string,
        onComplete: (pin: SerializedPin, createMode?: boolean) => void,
        onCancel?: (createMode: boolean) => void,
    ) {
        const { pinViewport, pinConfig } = this.getAsyncProps();
        const newPin = new Pin({
            scene: this.scene,
            pinViewport: pinViewport,
            pinOptions: pinConfig,
            modelRootMesh: this.rootMesh
        });
        newPin.pinData = createPinData({ id });

        this._modifyPin(
            newPin,
            onComplete,
            (create) => {
                newPin.dispose();
                onCancel?.(create);
            },
            true,
        );
    }

    updatePin(
        id: string,
        onComplete: (pin: SerializedPin, createMode?: boolean) => void,
        onCancel?: (createMode: boolean) => void,
    ) {
        this.getAsyncProps();
        const pin = this.getPinById(id);
        if (pin) {
            this._modifyPin(pin, onComplete, onCancel);
        } else {
            if (onCancel) {
                onCancel(false);
            }
            throw new Error(`Pin Id ${id} not found`);
        }
    }

    updatePinScale = () => {
        if (window.document.hidden) return;

        const { min, max } = this.rootMesh
            ? this.rootMesh.getHierarchyBoundingVectors()
            : this.viewer.scene.getWorldExtends();

        this.pinScale = Vector3.Distance(min, max) * this.pinScalingMultiplier;

        this.updateViewport();

        if (this.pinConfig && this.pinViewport) {
            this.pinConfig.radius = this.pinScale;
            this.tracer = new NormalTracer(
                this.viewer,
                this.pinConfig,
                this.pinConfig.pinDisplayConfig.headUrl,
                this.pinViewport.viewportPixelSize * this.pinScalingMultiplier,
            );
            this.getAllPins().forEach((pin) => {
                pin.radius = this.pinScale;
                pin.positionFromData();
                pin.clampPinSize();
            });
            this.checkLayers();
            this.groupPins();
        }
    };

    updatePinPositions() {
        this.pins.forEach((pin) => {
            pin.positionFromData();
        });
    }

    cancelAddOrUpdate() {
        const { tracer } = this.getAsyncProps();
        tracer.stop();
    }

    selectPin(id: string) {
        const pinToSelect = this.getPinById(id);
        if (pinToSelect) {
            if (
                pinToSelect.state === PinState.occluded ||
                !pinToSelect.visible
            ) {
                this.showPinById(id);
            }
            this.deselectPins();
            pinToSelect.state = PinState.selected;
            this.groupPins();
            this.viewer.renderLoop.toggle(100);
        }
    }

    deselectPins() {
        this.getAllPins().forEach((pin) => {
            if (pin.state === PinState.selected) {
                pin.state = PinState.normal;
            }
        });
        this.groupPins();
        this.viewer.renderLoop.toggle(100);
    }

    highlightPin(id: string) {
        const pinToHighlight = this.getPinById(id);
        if (pinToHighlight) {
            const pinGroup = this.pinGroupManager?.pinToGroupMap[id];
            if (
                pinGroup?.groupPin &&
                pinGroup.groupPin.state !== PinState.hover
            ) {
                pinGroup.groupPin.state = PinState.hover;
            } else {
                if (
                    pinToHighlight.state !== PinState.occluded &&
                    pinToHighlight.state !== PinState.selected
                ) {
                    pinToHighlight.state = PinState.hover;
                }
            }
            this.viewer.renderLoop.toggle(100);
        }
    }

    unhighlightPins() {
        this.getAllPins().forEach((pin) => {
            if (pin.state === PinState.hover) {
                pin.state = PinState.normal;
            }
        });
        this.viewer.renderLoop.toggle(100);
    }

    showPinById(id: string) {
        const pin = this.getPinById(id);
        pin && this.showPin(pin);
    }

    showPin(pinToShow: Pin) {
        const { camera } = this.getAsyncProps();
        if (pinToShow?.pinData?.cameraPosition && camera) {
            this.animateCamera(
                "position",
                camera.position,
                new Vector3(...pinToShow.pinData.cameraPosition),
                1.5,
            );
            this.viewer.renderLoop.toggle(100);
            this.checkLayers();
        }
    }

    getPinBoundingBox(id: string) {
        const pin = this.getPinById(id);
        if (pin) {
            return pin.getSpriteScreenBoundingBox();
        }
        return;
    }

    filteredPins: Record<string, Pin> = {}; // store filtered pins for grouping
    filterPins(visiblePins: Set<string>) {
        this.filteredPins = {};
        this.pins.forEach((pin) => {
            if (pin.pinData?.id) {
                if (!pin.isEnabled) {
                    this.filteredPins[pin.pinData.id] = pin;
                }

                if (this.showPins) {
                    pin.isEnabled = visiblePins.has(pin.pinData?.id);
                }
            }
        });
        this.groupPins();
    }

    toggleShowPins(show: boolean) {
        // toggle only affects pins visible after filtering
        this.showPins = show;
        this.pins.forEach((pin) => {
            if (!show) {
                pin.isEnabled = false;
            } else {
                if (
                    !pin.pinData?.id ||
                    (pin.pinData?.id && !this.filteredPins[pin.pinData?.id])
                ) {
                    pin.isEnabled = true;
                }
            }
        });
        this.groupPins();
    }

    setPinsSelectable(selectable: boolean) {
        this.isSelectable = selectable;
        this.getAllPins().forEach((pin) => {
            if (pin.state === PinState.hover) {
                pin.state = PinState.normal;
            } else if (pin.state === PinState.selected) {
                this.emit(PinEvents.pinDeselected, pin.pinData);
            }
        });
        this.deselectPins();
    }

    toggleGrouping(on: boolean) {
        if (this.groupPinConfig) {
            this.groupPinConfig.enabled = on;
            this.groupPins();
        }
    }

    /**
     * end Public API
     **/

    private getPinById(id: string) {
        return this.pins.find((matchPin) => matchPin.pinData?.id === id);
    }

    private _modifyPin(
        pin: Pin,
        onComplete: (pin: SerializedPin) => void,
        onCancel?: (createdPin: boolean) => void,
        create = false,
    ) {
        const { tracer } = this.getAsyncProps();
        tracer.trace(pin, (hit) => {
            if (hit && pin.pinData) {
                this.setCameraPositionData(pin.pinData);
                pin.updatePinData(hit.mesh, hit.point, hit.normal);
                if (create) {
                    this.pins.push(pin);
                    this.emit(PinEvents.pinSelected, {
                        pinData: pin.pinData,
                        boundingBox: pin.getSpriteScreenBoundingBox(),
                    });
                }
                if (onComplete) {
                    onComplete(pin.pinData);
                }
            } else {
                if (!create) {
                    pin.positionFromData();
                    pin.setVisible(true);
                    pin.isPickable = true;
                }
                if (onCancel) {
                    onCancel(create);
                }
            }
        });
    }

    dispose() {
        this.clearPins();
        if (this.pinConfig?.pinTextures) {
            this.pinConfig.pinTextures?.headSprites.normal?.dispose();
            this.pinConfig.pinTextures?.headSprites.edit?.dispose();
            this.pinConfig.pinTextures?.headSprites.hover?.dispose();
            this.pinConfig.pinTextures?.headSprites.selected?.dispose();
            this.pinConfig.pinTextures?.headSprites.occluded?.dispose();
            this.pinConfig.pinTextures?.poleMat.dispose();
        }
        this.removeAllListeners();
        window.removeEventListener("resize", this.updateViewport);
        window.document.removeEventListener(
            "visibilitychange",
            this.updatePinScale,
        );

        RenderingManager.AUTOCLEAR = false;
        RenderingManager.MAX_RENDERINGGROUPS = 2;
        if (this.viewer?.plugins?.pins) {
            delete this.viewer.plugins.pins;
        }
    }

    private attachPinPointerEvents(pin: Pin) {
        const { scene, pointerEventManager } = this.getAsyncProps();
        const data = pin.pinData;
        if (data) {
            pointerEventManager.attachPinHandler({
                pin,
                clickHandler: () => {
                    if (!pin.visible || !this.isSelectable) {
                        return;
                    }
                    const meshBoundingBox = pin.getSpriteScreenBoundingBox();
                    const event: PinEvent = {
                        pointer: {
                            x: scene.pointerX,
                            y: scene.pointerY,
                        },
                        boundingBox: meshBoundingBox,
                        pinData: data,
                    };
                    pin.state = PinState.selected;
                    this.emit(PinEvents.pinSelected, event);
                    this.viewer.renderLoop.toggle(100);
                },
                moveHandler: (over) => {
                    if (!pin.visible || !this.isSelectable) {
                        return;
                    }
                    this.emit(
                        over ? PinEvents.pinHoverStart : PinEvents.pinHoverEnd,
                        pin.pinData,
                    );
                    if (
                        pin.state !== PinState.selected &&
                        pin.state !== PinState.occluded
                    ) {
                        pin.state = over ? PinState.hover : PinState.normal;
                    }
                    this.viewer.renderLoop.toggle(100);
                },
            });
        }
    }

    private updateViewport = () => {
        if (window.document.hidden) return;

        const { camera } = this.getAsyncProps();
        const pinViewport = (this.pinViewport = this.getViewPort(camera));

        this.pins.forEach((pin) => {
            pin.pinViewport = pinViewport;
        });

        this.getAllPins;
    };

    private getViewPort(camera: Camera) {
        const engine = this.viewer.engine;
        const rect = engine.getRenderingCanvasClientRect();

        if (!rect) {
            throw new Error("Failed to get rendering client rect");
        }

        const { width: viewportWidth, height: viewportHeight } = rect;

        const viewport = camera.viewport.toGlobal(
            viewportWidth,
            viewportHeight,
        );

        const viewportPixelSize = Math.sqrt(
            Math.pow(viewportHeight, 2) + Math.pow(viewportHeight, 2),
        );

        const minScaling =
            this.pinConfig?.pinDisplayConfig.pinScale?.minScaleSize ||
            DEFAULT_PIN_COLOR_CONFIG.pinScale.minScaleSize;

        const maxScaling =
            this.pinConfig?.pinDisplayConfig.pinScale?.maxScaleSize ||
            DEFAULT_PIN_COLOR_CONFIG.pinScale.maxScaleSize;

        const minSpriteSize =
            viewportPixelSize * this.pinScalingMultiplier * minScaling;
        const maxSpriteSize =
            viewportPixelSize * this.pinScalingMultiplier * maxScaling;

        return {
            viewport,
            viewportPixelSize,
            minSpriteSize,
            maxSpriteSize,
        };
    }

    private getCameraVerticalLength() {
        if (this.camera) {
            const targetDistance = Vector3.Distance(
                this.camera.position,
                this.camera.target,
            );
            return Math.tan(this.camera.fov) * targetDistance;
        }
        return 1;
    }

    clearGroups() {
        if (this.pinGroupManager) {
            Object.values(this.pinGroupManager.pinGroups).forEach(
                (pinGroup) => {
                    if (pinGroup.groupPin)
                        this.pointerEventManager?.removePinHandler(
                            pinGroup.groupPin,
                        );
                },
            );
            this.pinGroupManager.destroy();
            this.pinGroupManager = undefined;
        }
    }

    private groupPins() {
        if (!this.groupPinConfig?.enabled) {
            this.clearGroups();
            return;
        }

        const lengthChange =
            this.pinGroupManager &&
            Object.keys(this.pinGroupManager.pins).length !== this.pins.length;
        const turningOffPins =
            !!this.pinGroupManager && !this.groupPinConfig?.enabled;
        const turningOnPins =
            !this.pinGroupManager && this.groupPinConfig?.enabled;

        if (this.pinGroupManager && (lengthChange || turningOffPins)) {
        }

        if (!this.pinGroupManager) {
            this.pinGroupManager = new PinGroupManager(
                (count) =>
                    new Pin({
                        scene: this.scene,
                        pinViewport: this.getGroupPinViewport(),
                        pinOptions: this.groupPinConfig!,
                        modelRootMesh: this.rootMesh,
                        spriteIndex: Math.min(count, 8),
                    }),
            );
            this.pinGroupManager.buildDistanceMap(this.pins);
        } else if (lengthChange) {
            this.pinGroupManager.buildDistanceMap(this.pins);
        }

        const groupDistance =
            this.getCameraVerticalLength() *
            this.groupPinConfig.groupScreenRatio;

        Object.values(this.pinGroupManager.pinGroups).forEach((pinGroup) => {
            if (pinGroup.groupPin)
                this.pointerEventManager?.removePinHandler(pinGroup.groupPin);
        });

        this.pinGroupManager.updateDistance(
            groupDistance,
            !lengthChange && !turningOnPins,
        );

        Object.values(this.pinGroupManager.pinGroups).forEach(
            ({ groupPin, members, hostPin }) => {
                if (groupPin) {
                    let disableInteraction = false;
                    this.pointerEventManager?.attachPinHandler({
                        pin: groupPin,
                        clickHandler: () => {
                            disableInteraction = true;
                            groupPin.state = PinState.normal;
                            this.focusGroup([hostPin, ...members]);
                        },
                        moveHandler(over) {
                            if (
                                !disableInteraction &&
                                groupPin.state !== PinState.occluded
                            ) {
                                groupPin.state = over
                                    ? PinState.hover
                                    : PinState.normal;
                            }
                        },
                    });
                }
            },
        );
        this.viewer.renderLoop.toggle();
    }

    getGroupPinViewport() {
        const scaleFactor = this.groupPinConfig?.groupPinScalingFactor || 1;
        const groupViewport = { ...this.pinViewport! };
        groupViewport.minSpriteSize = scaleFactor * groupViewport.minSpriteSize;
        groupViewport.maxSpriteSize = scaleFactor * groupViewport.maxSpriteSize;

        return groupViewport;
    }

    focusGroup(pins: Pin[]) {
        const camera = this.camera as ArcRotateCamera;
        const bounds = pins.reduce(
            (bounds, pin) => {
                bounds.max = Vector3.Maximize(bounds.max, pin.head.position);
                bounds.min = Vector3.Minimize(bounds.min, pin.head.position);
                return bounds;
            },
            { min: pins[0].head.position, max: pins[0].head.position },
        );

        if (!this.viewer.canvas) throw new Error("Canvas not found");

        const rect = this.viewer.canvas.getBoundingClientRect();
        if (rect && camera) {
            camera.computeWorldMatrix();
            const aspect = rect.width / rect.height;
            // Babylon default FOV is always vertical
            const fov = Math.min(camera.fov, camera.fov * aspect);
            const center = Vector3.Center(bounds.max, bounds.min);
            const radius = Vector3.Distance(center, bounds.max);
            const offset = radius / Math.sin(fov / 2);
            this.animateCamera(
                "target",
                Vector3.FromArray(camera.target.asArray()),
                center,
                0.5,
            );
            this.animateCamera("radius", camera.radius, offset, 0.5);
        }
    }

    animateCamera(
        prop: "alpha" | "radius" | "target" | "position",
        fromValue: any,
        targetValue: any,
        duration: number,
    ) {
        const camera = this.camera as ArcRotateCamera;
        if (camera) {
            var ease = new CubicEase();
            ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            Animation.CreateAndStartAnimation(
                window.crypto.randomUUID(),
                camera,
                prop,
                60,
                duration * 60,
                fromValue,
                targetValue,
                Animation.ANIMATIONLOOPMODE_CONSTANT,
                ease,
            );
        }
    }

    private checkLayers() {
        this.pins.forEach((pin) => {
            const isOccluded = pin.mesh.isOccluded;

            if (isOccluded && pin.state !== PinState.occluded) {
                if (pin.state === PinState.selected) {
                    pin.wasSelected = true;
                    this.emit(PinEvents.pinDeselected, pin.pinData);
                }
                if (pin.state === PinState.hover) {
                    this.emit(PinEvents.pinHoverEnd, pin.pinData);
                }
                pin.state = PinState.occluded;
            }

            if (!isOccluded && pin.state === PinState.occluded) {
                if (pin.wasSelected) {
                    pin.state = PinState.selected;
                    if (pin.pinData) {
                        this.selectPin(pin.pinData.id);
                    }
                    this.emit(PinEvents.pinSelected, pin.pinData);
                    pin.wasSelected = false;
                } else {
                    pin.state = PinState.normal;
                }
            }

            pin.clampPinSize();
        });

        if (this.pinGroupManager) {
            Object.values(this.pinGroupManager.pinGroups).forEach(
                (pinGroup) => {
                    if (pinGroup.groupPin) {
                        pinGroup.groupPin.clampPinSize();
                    }
                },
            );
        }
    }

    private createPinTextures(
        scene: Scene,
        pinDisplayConfig: OptionalPinDisplayConfig,
    ): PinTextures {
        const {
            spriteSize,
            headUrl,
            headHoverUrl,
            headSelectedUrl,
            headEditUrl,
            headOccludedUrl,
            poleColor = DEFAULT_PIN_COLOR_CONFIG.poleColor,
            poleAlpha = DEFAULT_PIN_COLOR_CONFIG.poleAlpha,
        } = pinDisplayConfig;
        const poleMat = new StandardMaterial("poleMat", this.scene);
        poleMat.diffuseColor = new Color3(...poleColor);
        poleMat.alpha = poleAlpha;
        poleMat.disableLighting = true;

        const headSpriteManager = new SpriteManager(
            "headSprite",
            headUrl,
            100,
            spriteSize,
            scene,
        );
        const headHoverSpriteManager = new SpriteManager(
            "headHoverSprite",
            headHoverUrl,
            100,
            spriteSize,
            scene,
        );
        const headSelectedSpriteManager = new SpriteManager(
            "headSelectedSprite",
            headSelectedUrl || headUrl, // Backwards compatibility
            100,
            spriteSize,
            scene,
        );
        const headEditSpriteManager = new SpriteManager(
            "headSelectedSprite",
            headEditUrl || headUrl, // Backwards compatibility
            100,
            spriteSize,
            scene,
        );
        const headOccludedSpriteManager = new SpriteManager(
            "headOccludedSprite",
            headOccludedUrl,
            100,
            spriteSize,
            scene,
        );

        [
            headSpriteManager,
            headHoverSpriteManager,
            headSelectedSpriteManager,
            headEditSpriteManager,
            headOccludedSpriteManager,
        ].forEach((manager) => {
            manager.isPickable = true;
            manager.renderingGroupId = 3;
        });
        headOccludedSpriteManager.isPickable = false;

        return {
            poleMat,
            headSprites: {
                [PinState.normal]: headSpriteManager,
                [PinState.edit]: headEditSpriteManager,
                [PinState.hover]: headHoverSpriteManager,
                [PinState.selected]: headSelectedSpriteManager,
                [PinState.occluded]: headOccludedSpriteManager,
            },
        };
    }

    createGroupPinTextures(
        scene: Scene,
        spriteSize: number,
        groupPinConfig: PinGroupingConfig,
        basePinTextures: PinTextures,
    ): PinTextures {
        const { groupPinHeadUrl, groupPinHoverUrl, groupPinHeadOccludedUrl } =
            groupPinConfig;

        const groupSpriteManager = new SpriteManager(
            "groupSprite",
            groupPinHeadUrl,
            100,
            spriteSize,
            scene,
        );
        const groupHoverSpriteManager = new SpriteManager(
            "groupHoverSprite",
            groupPinHoverUrl,
            100,
            spriteSize,
            scene,
        );
        const groupOccludedSpriteManager = new SpriteManager(
            "groupOccludedSprite",
            groupPinHeadOccludedUrl,
            100,
            spriteSize,
            scene,
        );

        [
            groupSpriteManager,
            groupHoverSpriteManager,
            groupOccludedSpriteManager,
        ].forEach((manager) => {
            manager.isPickable = true;
            manager.renderingGroupId = 3;
        });
        groupOccludedSpriteManager.isPickable = false;

        return {
            poleMat: basePinTextures.poleMat,
            headSprites: {
                ...basePinTextures.headSprites,
                normal: groupSpriteManager,
                hover: groupHoverSpriteManager,
                occluded: groupOccludedSpriteManager,
            },
        };
    }

    private setCameraPositionData(pinData: SerializedPin) {
        const { camera } = this.getAsyncProps();
        pinData.cameraPosition = serializeVec3(camera.position);
    }
}
