/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import EventEmitter from "events";

import { SerializedPin } from "../babylon/pins/PinDataTypes";

import type {
    Comment,
    CcxCommentsApi,
    AnnotationEvent,
    CommentUpdateContext,
    CommentUpdateDelta,
    CommentInteractionEvent,
    Selector,
} from "@ccx-public/ccx-comments";

export enum CommentsApiEvent {
    initialized = "initialized",
    annotationChanged = "annotationChanged",
    temporaryCommentSubmitted = "temporaryCommentSubmitted",
    temporaryCommentConverted = "temporaryCommentConverted",
    nodeChanged = "nodeChanged",
    commentInteraction = "commentInteraction",
    commentsSuccess = "commentsSuccess",
    commentsUpdate = "commentsUpdate",
    commentsError = "commentsError",
    commentsFiltered = "commentsFiltered",
    annotationToggle = "annotationToggle",
    enableComments = "enabledComments",
    disableComments = "disabledComments"
}

export interface PinnedComment extends Comment {
    pinData?: SerializedPin;
}

const Section_Name = "3d-viewer";

export class CommentsApi extends EventEmitter {
    private ccxCommentsApi?: CcxCommentsApi;
    initialized = false;

    constructor(
        private assetId: string,
    ) {
        super();
    }

    initializeApi(CcxCommentsApi: CcxCommentsApi) {
        this.ccxCommentsApi = CcxCommentsApi;
        if (!this.initialized) {
            CcxCommentsApi.registerAnnotationSection(Section_Name);
        }
        this.initialized = true;
        this.emit(CommentsApiEvent.initialized, { CcxCommentsApi });
        // @ts-ignore
        window.ccxCommentsApi = CcxCommentsApi;
    }

    getComments() {
        return this.ccxCommentsApi?.getComments();
    }

    getCommentsWithPinData() {
        const comments = this.ccxCommentsApi?.getComments() || [];
        const pinnedComments = comments.filter((comment) => {
            return (
                comment.target?.selector.type === "AdobeMultiDimensionSelector"
            );
        });
        return pinnedComments as Comment[];
    }

    getCommentById(commentId: string) {
        const allComments = this.getComments();
        const comment = allComments?.find(
            (comment) => comment.id === commentId,
        );
        return comment;
    }

    getPinDataFromSelector(selector: Selector) {
        const pinData = {
            pinUnscaledModelRootPosition: selector.pinModelRootPosition,
            ...(selector.pinNormal && {
                pinNormal: selector.pinNormal,
            }),
            ...(selector.cameraPosition && {
                cameraPosition: selector.cameraPosition,
            }),
            ...(selector.modelPathSchema && {
                modelPath: selector.modelPathSchema,
            }),
        };
        return pinData;
    }

    getPinDataForComment(commentId: string) {
        try {
            const comment = this.getCommentById(commentId);
            const selector = comment?.target?.selector;
            if (selector && selector.type === "AdobeMultiDimensionSelector") {
                const selectorPinData = this.getPinDataFromSelector(selector);
                const pinData = { id: commentId, ...selectorPinData };
                return pinData as SerializedPin;
            }
        } catch (e) {
            console.error("pinData not found for comment: ", commentId);
        }
        return;
    }

    updatePinDataForComment(id: string, pinData: SerializedPin) {
        const comment = this.getCommentById(id);
        const selector = comment?.target?.selector;
        if (selector) {
            this.ccxCommentsApi?.batchCommentUpdate(
                {
                    id: {
                        selector: {
                            ...selector,
                            pinModelRootPosition:
                                pinData.pinUnscaledModelRootPosition,
                            ...(pinData.pinNormal && {
                                pinNormal: pinData.pinNormal,
                            }),
                            ...(pinData.cameraPosition && {
                                cameraPosition: pinData.cameraPosition,
                            }),
                            ...(pinData.modelPath && {
                                modelPathSchema: pinData.modelPath,
                            }),
                        },
                        source: selector.source,
                        state: selector.state,
                    },
                },
                id,
            );
        }
        console.log("updatePinDataForComment success", pinData);
        return pinData;
    }

    removePinDataForComment(id: string) {
        const comment = this.getCommentById(id);
        const selector = comment?.target?.selector;
        if (selector) {
            this.ccxCommentsApi?.batchCommentUpdate(
                {
                    id: {
                        selector: {
                            "3dContext": selector["3dContext"],
                            type: selector.type,
                            node: selector.node,
                            pinModelRootPosition: [],
                            source: selector.source,
                            state: selector.state,
                        },
                        source: selector.source,
                        state: selector.state,
                    },
                },
                id,
            );
        }
    }

    setPendingPin(pinData: SerializedPin) {
        console.log("setPendingPin", pinData);
        this.ccxCommentsApi?.createPendingAnnotation({
            "3dData": {
                pinModelRootPosition: pinData.pinUnscaledModelRootPosition,
                ...(pinData.pinNormal && { pinNormal: pinData.pinNormal }),
                ...(pinData.cameraPosition && {
                    cameraPosition: pinData.cameraPosition,
                }),
                ...(pinData.modelPath && {
                    modelPathSchema: pinData.modelPath,
                }),
            },
            nodeId: this.assetId,
        });
    }

    cancelPendingPin() {
        this.ccxCommentsApi?.endAnnotating();
    }

    disableComments() {
        this.ccxCommentsApi?.disableComments({});
        this.emit(CommentsApiEvent.disableComments);
    }

    enableComments() {
        this.ccxCommentsApi?.enableComments({});
        this.emit(CommentsApiEvent.enableComments);
    }

    showComment(commentId?: string) {
        if (commentId === undefined) {
            this.ccxCommentsApi?.showComment();
            return undefined;
        } else {
            if (!commentId.includes("asset")) return;
            let comment;
            try {
                comment = this.ccxCommentsApi?.showComment(commentId);
            } catch (e) {
                console.error(e);
            }
            return comment;
        }
    }

    highlightComment(commentId: string) {
        return this.ccxCommentsApi?.highlightComment(commentId);
    }

    unhighlightComment() {
        return this.ccxCommentsApi?.unhighlightComment();
    }

    onAnnotatingChange(event: AnnotationEvent, disableColorPicker?: boolean) {
        console.log("onAnnotatingChange", event);
        this.emit(CommentsApiEvent.annotationChanged, event);
        if (disableColorPicker) {
            const shadowRoot =
                document.getElementsByTagName("cc-comments-list")[0]?.shadowRoot;
            const colorPicker = shadowRoot?.getElementById(
                "color-picker-button",
            );
            if (colorPicker) {
                colorPicker.style.display = "none";
            }
        }
    }
    onNodeChange(...props: any[]) {
        console.log("onNodeChange", props);
        this.emit(CommentsApiEvent.nodeChanged, ...props);
    }
    onCommentsSuccess(...props: any[]) {
        console.log("onCommentsSuccess", props);
        this.emit(CommentsApiEvent.commentsSuccess, ...props);
    }
    onCommentsUpdate(context: CommentUpdateContext, delta: CommentUpdateDelta) {
        console.log("onCommentsUpdate", { context, delta });
        if (delta.reason === "filter" || delta.reason === "counts-change") {
            this.emit(
                CommentsApiEvent.commentsFiltered,
                delta.data.visibleComments,
            );
        }

        if (delta.reason !== "filter") {
            this.emit(CommentsApiEvent.commentsUpdate, { context, delta });
        }
    }
    onCommentsError(...props: any[]) {
        console.log("onCommentsError", props);
        this.emit(CommentsApiEvent.commentsError, ...props);
    }
    onCommentInteraction(event: CommentInteractionEvent) {
        console.log("onCommentInteraction", event);
        this.emit(CommentsApiEvent.commentInteraction, event);
    }
    onAnnotationToggle() {
        this.emit(CommentsApiEvent.annotationToggle);
    }

    // for pin debugging purposes
    // async onCommentInteraction(event: CommentInteractionEvent) {
    //     const pinData = await this.getPinDataForComment(event.id);
    //     console.log("onCommentInteraction", event, pinData);
    //     this.emit(CommentsApiEvent.commentInteraction, event);
    // }
    destroy() {
        this.ccxCommentsApi?.unregisterAnnotationSection(Section_Name);
        this.ccxCommentsApi = undefined;
    }
}
