/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ToastContainer } from "@react-spectrum/toast";
import { JanusRoutes as Routes } from "@shared/common/src/routes/JanusRoutes";
import { Routes as BrowserRoutes, Route } from "react-router-dom";

import { VrPageWrapper } from "./components/VrPageWrapper";
import { Home } from "./pages/Home";
import { Landing } from "./pages/Landing";
import { MetaSSO } from "./pages/MetaSSO";
import { MetaVRAppRedirect } from "./pages/MetaVRAppRedirect";
import { OneUp } from "./pages/OneUp";
import { TwoUp } from "./pages/TwoUp";
import { AboutHighFive } from "./pages/vr/AboutHighFive";
import { AccessCheck } from "./pages/vr/AccessCheck";
import { AccessRequestedDialog } from "./pages/vr/AccessRequestedDialog";
import { AccessRequiredDialog } from "./pages/vr/AccessRequiredDialog";
import { AppSettingsDialog } from "./pages/vr/AppSettingsDialog";
import { AssertLogOut } from "./pages/vr/AssertLogOut";
import { AssetNotFoundDialog } from "./pages/vr/AssetNotFoundDialog";
import { EnterReviewDialog } from "./pages/vr/EnterReviewDialog";
import { LegalNotice } from "./pages/vr/LegalNotice";
import { LogInCheck } from "./pages/vr/LogInCheck";
import { LogInRequiredDialog } from "./pages/vr/LogInRequiredDialog";
import { ReportAbuseDialog } from "./pages/vr/ReportAbuseDialog";
import { SignedOut } from "./pages/vr/SignedOut";
import { StartScreen } from "./pages/vr/StartScreen";
import { Tutorials } from "./pages/vr/Tutorials";
import "./App.css";

export function App() {
    return (
        <>
            <ToastContainer />
            <BrowserRoutes>
                <Route path={Routes.index.path} element={<Landing />} />
                <Route path={Routes.home.path} element={<Home />} />
                <Route path={Routes.homeModal.path} element={<Home />} />
                <Route path={Routes.twoUp.path} element={<TwoUp />} />
                <Route path={Routes.twoUpModal.path} element={<TwoUp />} />
            </BrowserRoutes>
            <VrPageWrapper>
                <BrowserRoutes>
                    <Route path={Routes.oneUp.path} element={<OneUp />} />

                    <Route
                        path={Routes.logInCheck.path}
                        element={<LogInCheck />}
                    />
                    <Route
                        path={Routes.startScreen.path}
                        element={<StartScreen />}
                    />
                    <Route
                        path={Routes.signedOut.path}
                        element={<SignedOut />}
                    />
                    <Route
                        path={Routes.assertLogOut.path}
                        element={<AssertLogOut />}
                    />
                    <Route
                        path={Routes.accessCheck.path}
                        element={<AccessCheck />}
                    />
                    <Route
                        path={Routes.enterReview.path}
                        element={<EnterReviewDialog />}
                    />
                    <Route
                        path={Routes.logInRequired.path}
                        element={<LogInRequiredDialog />}
                    />
                    <Route
                        path={Routes.accessRequired.path}
                        element={<AccessRequiredDialog />}
                    />
                    <Route
                        path={Routes.accessRequested.path}
                        element={<AccessRequestedDialog />}
                    />
                    <Route
                        path={Routes.assetNotFound.path}
                        element={<AssetNotFoundDialog />}
                    />
                    <Route
                        path={Routes.appSettings.path}
                        element={<AppSettingsDialog />}
                    />
                    <Route
                        path={Routes.legalNotice.path}
                        element={<LegalNotice />}
                    />
                    <Route
                        path={Routes.aboutHighFive.path}
                        element={<AboutHighFive />}
                    />
                    <Route
                        path={Routes.reportAbuse.path}
                        element={<ReportAbuseDialog />}
                    />
                    <Route
                        path={Routes.reportAbuse.path}
                        element={<ReportAbuseDialog />}
                    />
                    <Route path={Routes.metaSSO.path} element={<MetaSSO />} />
                    <Route path={Routes.shareDemo.path} element={<Home />} />
                    <Route
                        path={Routes.shareDemoTwoUp.path}
                        element={<TwoUp />}
                    />
                    <Route path={Routes.visionPro.path} element={<Home />} />
                    <Route
                        path={Routes.visionProTwoUp.path}
                        element={<TwoUp />}
                    />
                    <Route
                        path={Routes.tutorial.path}
                        element={<Tutorials />}
                    />
                    <Route path={Routes.metaVrAppRedirect.path} element={<MetaVRAppRedirect />} />
                </BrowserRoutes>
            </VrPageWrapper>
        </>
    );
}
