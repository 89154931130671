/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { JanusRoutes } from "@shared/common/src/routes/JanusRoutes";
import { useEffect, useState } from "react";

import { sessionIdKey } from "./useAnalytics";
import { useMetaLinkedAccount } from "./useMetaLinkedAccount";
import {
    ASSET_APIS,
    HI5_API_KEY,
    HI5_WEBVIEW_API_KEY,
    getHref,
    RW_APP_ID,
    RW_APP_KEY,
    RW_ORG_ID,
} from "../config";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function useOpenInVR(assetUrn?: string) {
    
    const { accessToken } = useHi5UserContext();
    const [isOpenInVrReady, setIsOpenInVrReady] = useState(false);
    const { metaUserId } = useMetaLinkedAccount();

    useEffect(() => {
        setIsOpenInVrReady(!!(accessToken && metaUserId !== undefined));
    }, [accessToken, metaUserId]);

    async function getJumpUrl() {
        const redirectUrl = getHref(
            JanusRoutes.accessCheck.path, {
                urn: assetUrn ?? "",
                region: 'US',
                sessionId: sessionStorage.getItem(sessionIdKey) ?? "",
            });

        const response = await fetch(
            `${
                ASSET_APIS.ims
            }/ims/jumptoken/v1?client_id=${HI5_API_KEY}&target_client_id=${HI5_WEBVIEW_API_KEY}&target_redirect_uri=${encodeURIComponent(
                redirectUrl,
            )}&target_remember_me=true`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        const parsedBody = await response.json();

        let jumpUrl = "";
        if (parsedBody && parsedBody.jump) {
            const failRedirectUrl = getHref(JanusRoutes.logInCheck.path);
            jumpUrl = `${parsedBody.jump}?jump_fail_uri=${failRedirectUrl}`;
        } else {
            console.error("error creating jump url");
        }
        console.log("jumpUrl", jumpUrl);
        return jumpUrl;
    }

    // TODO - not sure this localStorage is needed given that the URN
    // could be in the query param
    async function linkAccount() {
        // save the current assetUrn so that we can redirect to it after completing Meta SSO
        if (assetUrn) localStorage.setItem("savedReviewUrn", assetUrn);
        const redirectUrl = getHref(JanusRoutes.metaSSO.path);
        window.location.href = `https://auth.oculus.com/sso/?organization_id=${RW_ORG_ID}&redirect_uri=${redirectUrl}`;
    }

    async function getMetaId(orgScopedId: string) {
        const access_token = `OC|${RW_APP_ID}|${RW_APP_KEY}`;
        const response = await fetch(
            `https://graph.oculus.com/${orgScopedId}?access_token=${access_token}&fields=id,alias`,
            {
                method: "GET",
                cache: "default",
            },
        );
        const parsedBody = await response.json();
        console.log("getMetaId", parsedBody);

        if (!(parsedBody && parsedBody.id)) {
            console.error("error getting Meta user id");
            return;
        }
        return { userId: parsedBody.id, userIdAlias: parsedBody.alias };
    }

    /**
     * Generates an Oculus public Group Launch deep link https://developer.oculus.com/documentation/native/ps-group-launch/#create-a-public-group-launch-deep-link
     * @returns ComponentData
     */
    async function generateVrLink(metaUserId: string | undefined, destination_api_name: string) {
        if (!metaUserId) return;
        const jumpUrl = await getJumpUrl();

        const body = new FormData();
        body.append("access_token", `OC|${RW_APP_ID}|${RW_APP_KEY}`);
        body.append("destination_api_name", `${destination_api_name}`);
        body.append("create_room", "false");
        body.append("fields", "url");
        body.append("deeplink_message_override", jumpUrl);
        body.append("valid_for", "1"); // 1 hour validity
        body.append("leader", metaUserId);

        const response = await fetch(
            `https://graph.oculus.com/${RW_APP_ID}/app_deeplink_private`,
            {
                method: "POST",
                cache: "default",
                body,
            },
        );
        const parsedBody = await response.json();
        return parsedBody.url;
    }

    return { generateVrLink, getMetaId, linkAccount, isOpenInVrReady };
}
