/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { AppRoutes } from "@shared/types/src/routes"

export const JanusRoutes: AppRoutes = {
    index: {
        path: "/",
        name: "Hi5 Web Portal",
    },
    home: {
        path: "/home",
        name: "Hi5 Web Portal",
    },
    homeModal: {
        path: "/home/:modal/:assetId",
        name: "Import Modal",
    },
    twoUp: {
        path: "/view",
        name: "Comment and Pin 3d",
    },
    twoUpModal: {
        path: "/view/enter",
        name: "Enter Review Two Up"
    },
    oneUp: {
        path: "/comment",
        name: "Comment",
        isVr: true,
    },
    noUp: {
        path: "/comment-headless",
        name: "Comment API Only",
    },
    logInCheck: {
        path: "/log-in-check",
        name: "VR log in check",
        isVr: true,
    },
    startScreen: {
        path: "/start-screen",
        name: "VR start screen",
        isVr: true,
    },
    signedOut: {
        path: "/signed-out",
        name: "VR signed out",
        isVr: true,
    },
    assertLogOut: {
        path: "/assert-log-out",
        name: "VR assert log out",
        isVr: true,
    },
    accessCheck: {
        path: "/access-check",
        name: "VR access check",
        isVr: true,
    },
    enterReview: {
        path: "/enter-review",
        name: "VR enter review",
        isVr: true,
    },
    logInRequired: {
        path: "/log-in-required",
        name: "VR log in required",
        isVr: true,
    },
    accessRequired: {
        path: "/access-required",
        name: "VR access required",
        isVr: true,
    },
    accessRequested: {
        path: "/access-requested",
        name: "VR access requested",
        isVr: true,
    },
    assetNotFound: {
        path: "/asset-not-found",
        name: "VR asset not found",
        isVr: true,
    },
    reportAbuse: {
        path: "/report-abuse",
        name: "Report abuse",
        isVr: true,
    },
    appSettings: {
        path: "/app-settings",
        name: "VR app settings",
        isVr: true,
    },
    legalNotice: {
        path: "/legal-notice",
        name: "Legal Notice",
        isVr: true,
    },
    aboutHighFive: {
        path: "/about-high-five",
        name: "About Project High Five",
        isVr: true,
    },
    metaSSO: {
        path: "/meta-sso",
        name: "Redirect from Meta to review page",
    },
    shareDemo: {
        path: "/share-demo",
        name: "Share demo",
    },
    shareDemoTwoUp: {
        path: "/share-demo/view",
        name: "Share demo review",
    },
    visionPro: {
        path: "/vision-pro",
        name: "Vision Pro demo",
    },
    visionProTwoUp: {
        path: "/vision-pro/view",
        name: "Vision Pro demo review",
    },
    shareAudio: {
        path: "/share-audio",
        name: "Share audio"
    },
    tutorial: {
        path: "/tutorial",
        name: "Tutorial",
        isVr: true,
    },
    metaVrAppRedirect: {
        path: "/meta-vr-app-redirect",
        name: "Meta VR App Redirect",
    },
} as const;
