/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeViewer } from "@components/studio/src/scene/AdobeViewer";
import { useEffect, useState } from "react";

import {
    addOriginalFileFormat,
    addPhysicalSizeFromViewer,
    fetchReportInfo,
    getEmptyAssetTechInfo,
    AssetTechInfo
} from "@src/util/HealthCheckUtils";

export function useGlbInfo(
    glbUrl?: string,
    viewerInstance?: AdobeViewer,
    originalExtension?: string,
) {
    const [assetTechInfo, setAssetTechInfo] = useState<AssetTechInfo>(getEmptyAssetTechInfo());

    useEffect(() => {
        if (glbUrl && viewerInstance) {
            viewerInstance.onModelLoaded.addOnce(() => {
                fetchReportInfo(glbUrl).then((report) => {
                    setAssetTechInfo((currentInfo) => {
                        const newInfo = {
                            ...currentInfo,
                            ...report
                        };
                        addPhysicalSizeFromViewer(viewerInstance, newInfo);
                        return newInfo;
                    });
                });
            });
        }
    }, [viewerInstance, glbUrl]);

    useEffect(() => {
        if (originalExtension) {
            setAssetTechInfo((currentInfo) => {
                const newInfo = { ...currentInfo };
                return addOriginalFileFormat(newInfo, originalExtension);
            });
        }
    }, [originalExtension]);

    return { assetTechInfo };
}
