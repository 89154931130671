/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View, Flex, Text, Button } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Move from "@src/assets/images/tutorial-move.mp4";
import SnapTurn from "@src/assets/images/tutorial-snap-turn.mp4";
import Teleport from "@src/assets/images/tutorial-teleport.mp4";
import Pin from "@src/assets/images/tutorial-pin.mp4";
import Panels from "@src/assets/images/tutorial-panels.mp4";
import ObjManip from "@src/assets/images/tutorial-obj-manip.mp4";
import Highlight from "@src/assets/images/tutorial-highlight.mp4";
import Refresher from "@src/assets/images/tutorial-refresher.mp4";
import { WelcomePage } from "../dialogs/TutorialWelcomeDialog";
import { useSearchParams } from "react-router-dom";
import { usePostMessageContext } from "@src/contexts/PostMessageContext";
import { useTutorialEnabled } from "@src/hooks/useTutorialEnabled";

type TutorialPageInfo = {
    page: number;
    video: string;
    title: string;
    description: string;
};

type TutorialPage = {
    [page: string]: TutorialPageInfo;
};

const pages: TutorialPage = {
    1: {
        page: 1,
        video: Move,
        title: "tutorial.move.title",
        description: "tutorial.move.description",
    },
    2: {
        page: 2,
        video: SnapTurn,
        title: "tutorial.snapTurn.title",
        description: "tutorial.snapTurn.description",
    },
    3: {
        page: 3,
        video: Teleport,
        title: "tutorial.teleport.title",
        description: "tutorial.teleport.description",
    },

    4: {
        page: 4,
        video: Pin,
        title: "tutorial.pin.title",
        description: "tutorial.pin.description",
    },
    5: {
        page: 5,
        video: Panels,
        title: "tutorial.panels.title",
        description: "tutorial.panels.description",
    },
    6: {
        page: 6,
        video: ObjManip,
        title: "tutorial.moveObject.title",
        description: "tutorial.moveObject.description",
    },
    7: {
        page: 7,
        video: Highlight,
        title: "tutorial.highlight.title",
        description: "tutorial.highlight.description",
    },
    8: {
        page: 8,
        video: Refresher,
        title: "tutorial.refresher.title",
        description: "tutorial.refresher.description",
    },
};

export function Tutorials() {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const { t } = useTranslation("vr");
    const { postMessageBusApi } = usePostMessageContext();
    const { setTutorialEnabledFalse } = useTutorialEnabled();

    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("urn") || "";

    useEffect(() => {
        setTutorialEnabledFalse();
    }, []);

    function onCloseTutorial() {
        if (postMessageBusApi) {
            if (assetId) {
                postMessageBusApi.showDialog("enter-review", `urn=${assetId}`);
            } else {
                postMessageBusApi.showDialog("start-screen");
            }
        }
    }

    useEffect(() => {
        if (currentPage === 9) {
            postMessageBusApi?.resizeWindow("tutorial-end");
        }
    }, [currentPage]);

    return (
        <>
            {currentPage === 0 ? (
                <WelcomePage
                    setCurrentPage={setCurrentPage}
                    onCloseTutorial={onCloseTutorial}
                />
            ) : currentPage === 9 ? (
                <View
                    UNSAFE_style={{
                        borderRadius: "40px",
                        overflow: "hidden",
                    }}>
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="size-400"
                        UNSAFE_style={{ padding: "80px" }}>
                        <Text
                            UNSAFE_style={{
                                fontSize: "54px",
                                fontWeight: "bold",
                            }}>
                            {t("tutorial.end.title")}
                        </Text>
                        <Text UNSAFE_style={{ fontSize: "33px" }}>
                            {t("tutorial.end.description.1")}
                            <div
                                style={{
                                    fontStyle: "italic",
                                    display: "inline-block",
                                }}>
                                {t("button.tutorial")}{" "}
                            </div>
                            {t("tutorial.end.description.2")}
                        </Text>
                        <Button
                            marginTop="3vh"
                            width="60%"
                            height="75px"
                            variant="cta"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}
                            onPress={onCloseTutorial}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.75em",
                                }}>
                                {t("tutorial.button.ok")}
                            </Text>
                        </Button>
                    </Flex>
                </View>
            ) : (
                <View
                    width="1200px"
                    height="1000px"
                    padding="60px"
                    UNSAFE_style={{
                        borderRadius: "40px",
                    }}>
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        height="100%">
                        <View borderRadius="large">
                            <video
                                src={pages[currentPage].video}
                                autoPlay
                                loop
                                width="100%"
                                style={{ borderRadius: "30px" }}
                            />
                        </View>
                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            height="30%">
                            <Flex
                                direction="row"
                                alignItems="baseline"
                                justifyContent="space-between">
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "54px",
                                        fontWeight: "bold",
                                    }}>
                                    {t(pages[currentPage].title)}
                                </Text>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "33px",
                                        fontWeight: "bold",
                                        color: "var(--spectrum-global-color-gray-400)",
                                    }}>
                                    {t("tutorial.page", { val: currentPage })}
                                </Text>
                            </Flex>
                            <Text UNSAFE_style={{ fontSize: "33px" }}>
                                {t(pages[currentPage].description)}
                            </Text>
                            <Flex
                                direction="row"
                                justifyContent="space-between"
                                gap="size-500">
                                <Button
                                    marginTop="1vh"
                                    width="100%"
                                    height="75px"
                                    variant="primary"
                                    style="fill"
                                    UNSAFE_style={{
                                        borderRadius: "100px",
                                    }}
                                    onPress={onCloseTutorial}>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.75em",
                                        }}>
                                        {t("tutorial.button.skipTour")}
                                    </Text>
                                </Button>
                                <Button
                                    marginTop="1vh"
                                    width="100%"
                                    height="75px"
                                    variant="cta"
                                    style="fill"
                                    UNSAFE_style={{
                                        borderRadius: "100px",
                                    }}
                                    onPress={() =>
                                        setCurrentPage(
                                            (prevValue) => prevValue + 1,
                                        )
                                    }>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.75em",
                                        }}>
                                        {currentPage === 8
                                            ? t("tutorial.button.finish")
                                            : t("tutorial.button.next")}
                                    </Text>
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </View>
            )}
        </>
    );
}
