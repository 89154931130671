/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Text } from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { type JoinReviewOptions } from "@src/contexts/NetworkContext";
import { useOpenInVR } from "@src/hooks/useOpenInVR";
import { OpenInVRDialog } from "@src/pages/dialogs/OpenInVRDialog";

interface OpenInVRProps {
    assetUrn?: string;
    joinReviewOptions?: JoinReviewOptions;
}

export function OpenInVR({ assetUrn, joinReviewOptions }: OpenInVRProps) {
    const { t } = useTranslation("web");

    const [searchParams] = useSearchParams();

    const shouldShowDialog = searchParams.get("accountLinked") || undefined;
    const { showModal } = useMessagingContext();
    const { isOpenInVrReady } = useOpenInVR(assetUrn);

    useEffect(() => {
        console.log("shouldShowDialog", shouldShowDialog);
        if (shouldShowDialog) showVRDialog();
    }, [shouldShowDialog]);

    function showVRDialog(destination?: string) {
        showModal(
            <OpenInVRDialog
                assetUrn={assetUrn}
                joinReviewOptions={joinReviewOptions}
            />,
            { isDismissable: true },
        );
    }

    return (
        <>
            <Button
                id={`Coachmark-openInVRButton`}
                variant="secondary"
                isDisabled={!isOpenInVrReady}
                onPress={() => showVRDialog()}>
                <Text>{t("openInVr.button")}</Text>
            </Button>
        </>
    );
}
