/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Text,
    Image,
    View,
    Button,
    Footer,
    Picker,
    Item,
    Link,
} from "@adobe/react-spectrum";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import landingImage from "../assets/images/rw_landing.png";
import { AppHeader } from "@src/components/AppHeader";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useLocaleContext } from "@src/contexts/LocaleContext";
import { useThemeContext } from "@src/contexts/ThemeContext";
import { useRedirects } from "@src/hooks/useRedirects";
import { legalNoticeLinks } from "@src/util/LinkUtils";

import type { CSSProperties, Key } from "react";

export function Landing() {
    const { t } = useTranslation(["common", "web"]);
    const { accessToken, logIn, imsReady } = useHi5UserContext();

    const { locale, availableLocales, changeLocale } = useLocaleContext();
    const { simplifiedThemeOptions, currentTheme, handleThemeChange } =
        useThemeContext();

    const { homeRedirect } = useRedirects();

    useEffect(() => {
        if (imsReady && accessToken) {
            homeRedirect();
        }
        return;
    }, [accessToken, imsReady]);

    return (
        <View>
            <Flex direction="column" height="100%">
                <AppHeader />
                <View
                    backgroundColor="gray-100"
                    paddingX="4vw"
                    paddingY="15vh"
                    flexGrow={1}>
                    <Flex direction="row" gap="size-200">
                        <Flex direction="column" maxWidth="40vw">
                            <View paddingY="10vh">
                                <View marginBottom="1vh">
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "2.75em",
                                            fontWeight: 700,
                                        }}>
                                        {t("web:home.welcome")}
                                    </Text>
                                </View>
                                <View marginBottom="3vh">
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1em",
                                            fontWeight: 700,
                                        }}>
                                        {t("web:landing.details")}
                                    </Text>
                                </View>
                                <Flex alignItems="start">
                                    <Button
                                        onPress={logIn}
                                        variant="accent"
                                        minWidth="72px">
                                        <Text>
                                            {t("web:landing.getStarted")}
                                        </Text>
                                    </Button>
                                </Flex>
                            </View>
                        </Flex>
                        <View>
                            <Image
                                width="50vw"
                                height="28vw"
                                minWidth="100px"
                                minHeight="56px"
                                alt={t("common:header.appIcon")}
                                src={landingImage}
                                UNSAFE_style={{
                                    borderRadius: "8px",
                                }}
                            />
                        </View>
                    </Flex>
                </View>
                <View backgroundColor={"gray-75"} width="100%">
                    <Flex
                        width="100%"
                        justifyContent={"center"}
                        alignItems={"center"}
                        minHeight="60px">
                        <View flexGrow={0} width="70%">
                            <Footer
                                UNSAFE_style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "16px",
                                    display: "flex",
                                }}>
                                <Picker
                                    isQuiet
                                    label={t("web:account.language")}
                                    labelPosition="side"
                                    items={availableLocales}
                                    defaultSelectedKey={locale}
                                    onSelectionChange={(key: Key) => {
                                        changeLocale(key as string);
                                    }}
                                    UNSAFE_style={{
                                        maxWidth: "200px",
                                        paddingRight: "8px",
                                    }}>
                                    {(item) => (
                                        <Item key={item.code}>
                                            {item.language}
                                        </Item>
                                    )}
                                </Picker>
                                <Picker
                                    isQuiet
                                    label={t(
                                        "web:account.preferences.colorTheme",
                                    )}
                                    labelPosition="side"
                                    items={simplifiedThemeOptions}
                                    defaultSelectedKey={currentTheme}
                                    onSelectionChange={(key: Key) => {
                                        handleThemeChange(key as string);
                                    }}
                                    UNSAFE_style={
                                        {
                                            maxWidth: "150px",
                                            "--spectrum-field-default-width":
                                                "initial",
                                            paddingRight: "8px",
                                        } as CSSProperties
                                    }>
                                    {(item) => (
                                        <Item key={item.theme}>
                                            {t(`web:${item.name}`)}
                                        </Item>
                                    )}
                                </Picker>
                                <Flex>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1em",
                                            fontWeight: 500,
                                            color: "var(--spectrum-global-color-gray-200))",
                                        }}>
                                        {t("web:landing.footer.copyright", {
                                            year: new Date().getFullYear(),
                                        })}
                                    </Text>
                                </Flex>
                                {legalNoticeLinks.map(({ label, link }) => {
                                    return (
                                        <View>
                                            <Link
                                                href={link}
                                                target="_blank"
                                                isQuiet
                                                variant="secondary">
                                                {t(label)}
                                            </Link>
                                        </View>
                                    );
                                })}
                            </Footer>
                        </View>
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
