/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { useTutorialEnabled } from "@src/hooks/useTutorialEnabled";

export function LogInCheck() {
    const [searchParams] = useSearchParams();
    const { postMessageBusApi } = usePostMessageContext();
    const { authState } = useHi5UserContext();
    const { tutorialEnabled } = useTutorialEnabled();

    const [isVRReady, setIsVRReady] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [useSUSI, setUseSUSI] = useState(false);

    useEffect(() => {
        if (!searchParams) return;
        const maybeError = searchParams.get("error");
        if (maybeError && maybeError === "security-jump-confirmation") {
            const maybeRedirect = searchParams.get("error_redirect_uri");
            if (maybeRedirect) {
                setIsRedirecting(true);
                try {
                    const url = new URL(maybeRedirect);
                    // if we get here, url seems valid.
                    window.location.replace(url);
                } catch (err) {
                    setIsRedirecting(false);
                    console.warn("Invalid redirect URL", err);
                }
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (!postMessageBusApi) return;
        postMessageBusApi.addEventListener("vr-ready", (data: any) => {
            setUseSUSI(data.detail.useSUSI);
            setIsVRReady(true);
        });
    }, [postMessageBusApi]);

    useEffect(() => {
        if (isRedirecting) return;
        if (!postMessageBusApi) return;
        if (authState === "verifying") return;

        if (!isVRReady) {
            console.log("LogInCheck, VR not ready");
            return;
        }

        if (authState === "authorized" || authState === "authenticated") {
            if (tutorialEnabled) {
                postMessageBusApi.showDialog("tutorial");
            } else {
                postMessageBusApi.showDialog("start-screen");
            }
        }
        if (authState === "failed") {
            postMessageBusApi.showDialog("signed-out", `use-susi=${useSUSI}`);
            return;
        }
    }, [postMessageBusApi, isVRReady, isRedirecting, authState]);

    return <div> {authState === "authorized"  && <span data-uia="authorized"> </span>} Checking if logged in...</div>;
}
