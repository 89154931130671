/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2025 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Image, Text, View } from "@adobe/react-spectrum";
import Globe from "@spectrum-icons/workflow/Globe";
import UserGroup from "@spectrum-icons/workflow/UserGroup";
import { useTranslation } from "react-i18next";

import { AssetActionMenu } from "./AssetActionMenu";
import { ProgressCircleView } from "./ProgressCircleView";
import noThumbnail from "../assets/images/no-thumbnail.svg";
import {
    getTrimmedString,
    getRelativeFormattedTime,
} from "../util/StringUtils";
import { useAcpContext } from "@src/contexts/AcpContext";
import { useContentListContext } from "@src/contexts/ContentListContext";
import { useIsHover } from "@src/hooks/useIsHover";
import { useIsVisible } from "@src/hooks/useIsVisible";
import { useIsVRPage } from "@src/hooks/useIsVRPage";
import { useThumbnail } from "@src/hooks/useThumbnail";

interface AssetViewProps {
    assetId: string;
}

export function AssetListView({
    assetId,
}: AssetViewProps) {
    const { t } = useTranslation("common");
    const { isVisible, setRef } = useIsVisible();
    const { isHovered, setIsHoveredRef } = useIsHover();
    const { locale } = useContentListContext();
    const { useCollaboratorCount, useReviewListItem } =
        useAcpContext();

    const {data} = useReviewListItem(assetId);
    const { data: collaboratorCount } = useCollaboratorCount(assetId, isVisible);

    const { thumbnailState, thumbnailUrl } = useThumbnail(assetId, isVisible);

    const { isVRPage } = useIsVRPage();

    function collaborators() {
        let label = "";
        if (collaboratorCount === -1) {
            label = t("collaborators.public");
        } else if (collaboratorCount === 1) {
            label = t("collaborators.private");
        } else {
            label = collaboratorCount
                ? `${collaboratorCount} ${t("collaborators.members")}`
                : t("collaborators.members");
        }

        return (
            <Flex
                direction="row"
                gap="size-100"
                alignItems="center"
                width="30%">
                {collaboratorCount === -1 ? (
                    <Globe
                        aria-label={t("accessibility.asset.public")}
                        size={isVRPage ? "S" : "XS"}
                    />
                ) : (
                    <UserGroup
                        aria-label={t("accessibility.asset.collaborators")}
                        size={isVRPage ? "S" : "XS"}
                    />
                )}
                <Text
                    UNSAFE_style={{
                        fontSize: isVRPage ? "1.75em" : "",
                    }}>
                    {label}
                </Text>
            </Flex>
        );
    }

    return (
        <View
            ref={(ref) => {
                setRef(ref?.UNSAFE_getDOMNode())
                setIsHoveredRef(ref?.UNSAFE_getDOMNode())}}
            backgroundColor={isHovered ? "gray-200" : {}}
            UNSAFE_style={{transition: "background-color 0.2s"}}
            borderBottomWidth="thin"
            borderBottomColor="light"
            width="100%"
            overflow="hidden"
            data-uia="review">
            <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                height={isVRPage ? "8vw" : "size-800"}
                marginY="1vh">
                <Flex direction="row" justifyContent="center" width="10%">
                    {thumbnailUrl || thumbnailState === "error" ? (
                        <Image
                            minWidth={isVRPage ? "6vw" : "size-600"}
                            minHeight={isVRPage ? "6vw" : "size-600"}
                            maxWidth={isVRPage ? "6vw" : "size-600"}
                            maxHeight={isVRPage ? "6vw" : "size-600"}
                            alt={t("accessibility.asset.thumbnail")}
                            src={
                                thumbnailUrl
                                    ? thumbnailUrl
                                    : noThumbnail
                            }
                            UNSAFE_style={{
                                borderRadius: "8px",
                            }}
                        />
                    ) : (
                        <ProgressCircleView />
                    )}
                </Flex>
                <View width="50%">
                    <Text
                        UNSAFE_style={
                            isVRPage
                                ? {
                                      fontSize: "1.75em",
                                      paddingLeft: "8px",
                                  }
                                : {}
                        }>
                        {data?.displayName &&
                            getTrimmedString(data.displayName, 30)}
                    </Text>
                </View>
                {collaborators()}
                <Flex direction="row" justifyContent="center" width="15%">
                    <Text
                        data-uia="asset-age"
                        UNSAFE_style={{
                            color: "rgb(150,150,150)",
                            fontSize: isVRPage ? "1.75em" : "",
                        }}>
                        {data?.modifyDate &&
                            getRelativeFormattedTime(data.modifyDate, locale)}
                    </Text>
                </Flex>
                {data && (
                    <AssetActionMenu asset={data} isForVr={isVRPage} />
                )}{" "}
            </Flex>
        </View>
    );
}
